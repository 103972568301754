import React, { Fragment, useCallback, useEffect, useRef, useState } from "react"
import { Box3, MathUtils, Mesh, Quaternion, Vector3, MeshMatcapMaterial } from "three"
import { useGLTF } from "@react-three/drei"
import { ConnectorValues } from "../../../../../../../../utils/Types"
import { MAIN_MODEL } from "../utils/ConnectorUtils"

interface Props {
    connector: ConnectorValues;
    position?: Vector3;
    rotation?: Quaternion;
    onMeshInstantiated: (mesh: Mesh, cleanup?: () => void, box?: Box3) => void;
    onRotationChange: (mesh: Mesh) => void;
    color: string;
    degrees?: number;
}

const ConnectorRender = (props: Props) => {
    const connectorRef = useRef<Mesh>(null)
    const markerRef = useRef<Mesh | null>(null)
    const [instanciated, setInstanciated,] = useState(false)
    const [initialRotation, setInitialRotation,] = useState<number | undefined>(undefined)
    const importedGLTF = useGLTF(props.connector.fileURL)
    const [material, setMaterial,] = useState<MeshMatcapMaterial | null>(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleImportMesh = useCallback((mesh: Mesh | null) => {
        const importedMesh = importedGLTF.nodes[MAIN_MODEL] as Mesh
        const name = importedMesh.name
        const geometry = importedMesh.geometry
        const material = importedMesh.material
        const position = importedMesh.position
        const rotation = importedMesh.rotation

        if (mesh) {
            mesh.name = name
            mesh.geometry = geometry
            mesh.material = material
            mesh.position.set(position.x, position.y, position.z)
            mesh.rotation.set(rotation.x, rotation.y, rotation.z)
        }
    }, [importedGLTF.nodes,])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setConnectorPositionBySelectedMarker = useCallback(() => {
        if (markerRef.current && connectorRef.current) {
            markerRef.current.attach(connectorRef.current)

            markerRef.current.position.x = props.connector.position.x
            markerRef.current.position.y = props.connector.position.y
            markerRef.current.position.z = props.connector.position.z

            const q = new Quaternion(
                props.connector.rotation.x,
                props.connector.rotation.y,
                props.connector.rotation.z,
                props.connector.rotation.w
            )

            markerRef.current.quaternion.set(q.x, q.y, q.z, q.w)

            markerRef.current.rotateY(MathUtils.degToRad(180))
        } else if (connectorRef.current) {
            if (props.position) {
                connectorRef.current.position.x = props.position.x
                connectorRef.current.position.y = props.position.y
                connectorRef.current.position.z = props.position.z
            }
            if (props.rotation) {
                const q = new Quaternion(
                    props.rotation.x,
                    props.rotation.y,
                    props.rotation.z,
                    props.rotation.w
                ).normalize()
                connectorRef.current.quaternion.set(q.x, q.y, q.z, q.w)
            }
        }
        setRotation(props.degrees || 0)
        // const boundingBox = connectorRef.current!.geometry.boundingBox
        const boundingBox = new Box3().setFromObject(connectorRef.current!)

        props.onMeshInstantiated(connectorRef.current!, undefined, boundingBox)

    }, [props.connector.position, props.connector.rotation, props.position, props.rotation,])

    const setRotation = (degrees: number) => {
        if (markerRef.current) {
            const currentRotationZ = markerRef.current.rotation.z
            let rotationZ = currentRotationZ
            if (initialRotation === undefined) {
                setInitialRotation(currentRotationZ)
                rotationZ = currentRotationZ
            } else {
                rotationZ = initialRotation
            }
            const newRotationZ = rotationZ + MathUtils.degToRad(degrees)
            markerRef.current.rotation.z = newRotationZ
            markerRef.current.updateMatrixWorld(true)
        }
    }

    useEffect(() => {
        if (connectorRef.current && instanciated) {
            setRotation(props.degrees || 0)
            connectorRef.current.updateMatrixWorld(true)
            props.onRotationChange(connectorRef.current)
        }
    }, [props.degrees,])

    useEffect(() => {
        if (instanciated) {
            setConnectorPositionBySelectedMarker()
        }
    }, [instanciated, setConnectorPositionBySelectedMarker,])

    useEffect(() => {
        setInstanciated(true)
        handleImportMesh(connectorRef.current)
    }, [handleImportMesh, props.connector.name,])

    useEffect(() => {
        const newMaterial = new MeshMatcapMaterial({ color: props.color, })
        newMaterial.color.convertSRGBToLinear()
        setMaterial(newMaterial)
    }, [props.color,])

    const getMarker = () => {
        const models = importedGLTF.nodes.Scene.children as Mesh[]
        const name = props.connector.initialMarkerName
        const markerMesh = models.find((m) => m.name === name)
        if (markerMesh) {
            return (
                <mesh
                    key={name}
                    name={name}
                    ref={markerRef}
                    scale={markerMesh.scale}
                    position={markerMesh.position}
                    rotation={markerMesh.rotation}
                    geometry={markerMesh.geometry}
                    dispose={null}
                >
                    <meshMatcapMaterial
                        alphaTest={0}
                        visible={false} />
                </mesh>
            )
        }
    }

    return (
        <Fragment>
            <mesh ref={connectorRef}>
                {material && <primitive object={material} attach="material" />}
            </mesh>
            {getMarker()}
        </Fragment>
    )
}

export default ConnectorRender