/* eslint-disable max-len */
/* eslint-disable max-statements */
import { useContext, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { Box3Helper, Intersection, Mesh, Object3D, Vector3, } from "three"
import { partsSelector } from "../../state/scene/atoms"
import { useGetPart } from "../../state/scene/hooks"
import { allConnections, } from "../../state/scene/selectors"
import { useMultipleUpdates } from "../../state/scene/setters"
import { PartConnectionType } from "../../state/scene/types"
import {
    assertPartType,
    GenericPartState,
    PartTypeEnum,
    TubeMarkerEnum,
    XYZ,
    XYZW,
} from "../../utils/Types"
import { filterWithValue } from "../../../common/utils/utils"
import { roundLength } from "../../utils/utils"
import { MultipleMovementContext, MM_PartType } from "./MultipleMovementProvider"
import { LengthUtils } from "./utils/LengthUtils"
import {
    SegmentedTubeMarkers
} from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import { isInConnection } from "../../state/scene/util"
import useCamera from "../cameraProvider/useCamera"
import { useThree } from "@react-three/fiber"
import { getRotationMarker } from
    "../../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"

const useRegisterMultipleMovement = (props: MM_PartType) => {
    const context = useContext(MultipleMovementContext)

    useEffect(() => {
        context?.registerPart({
            id: props.id,
            attachToMarker: props.attachToMarker,
            detachFromMarker: props.detachFromMarker,
            updateTransforms: props.updateTransforms,
            checksOnLengthMove: props.checksOnLengthMove,
            checksOnSegmentedTubeLengthMove: props.checksOnSegmentedTubeLengthMove,
            checksOnRotationMove: props.checksOnRotationMove,
            getMaxLength: props.getMaxLength,
            getPosAndRot: props.getPosAndRot,
        })

        return () => context?.unregisterPart(props.id)
    }, [])
}

// eslint-disable-next-line max-lines-per-function
const useMultipleMovement = (partId: string,) => {
    const { scene, gl, } = useThree()
    const context = useContext(MultipleMovementContext)
    const getPart = useGetPart()
    const part = useRecoilValue(partsSelector({ id: partId, }))!
    const connections = useRecoilValue(allConnections)
    const multiplePartUpdater = useMultipleUpdates()
    const {
        getCamera,
        drawVector3Point,
        enableCamera,
        disableCamera,
        fitAndLookAtBox,
        saveCameraState,
        resetCameraState,
    } = useCamera()

    const canEditLength = () => {
        assertPartType(part, PartTypeEnum.tube)
        return LengthUtils.getLengthFloatingMarker({
            part,
            getPart,
            connections,
        })
    }

    const canEditSegmentedTubeLength = (
        markers: SegmentedTubeMarkers,
        unsnappedMarkersNames: string[]
    ) => {
        assertPartType(part, PartTypeEnum.segmentedTube)
        const filteredConnections = connections.filter(
            connection => unsnappedMarkersNames.every(
                unsnappedMarkerName =>
                    !isInConnection(connection, partId, unsnappedMarkerName)
            ))
        return LengthUtils.getLengthAxisMarker({
            part,
            getPart,
            connections: filteredConnections,
            markers,
        })
    }

    const canEditRotation = () => {
        assertPartType(part, PartTypeEnum.connector)
        return LengthUtils.getRotationAxisMarker({
            part,
            getPart,
            connections,
        })
    }

    const canEditSlide = (
        sliderPartId?: string,) => {
        if (part.type === PartTypeEnum.connector || part.type === PartTypeEnum.segmentedTube) {
            return LengthUtils.getSlideAxisMarker({
                part,
                getPart,
                connections,
                sliderPartId,
            })
        }
    }

    const drawMaker = (marker: Mesh, color: any) => {
        const boundingBox = marker.geometry.boundingBox
        const centerPosition = new Vector3()
        boundingBox?.getCenter(centerPosition)
        marker.localToWorld(centerPosition)
        drawVector3Point(centerPosition, scene, color)
    }


    const attachToMove = (partsIds: string[], marker: Mesh) => {
        context!.getParts(partsIds).forEach(part => {
            marker.geometry.computeBoundingBox()
            //console.log(partsIds)
            //drawMaker(marker, 0x000000)
            part.attachToMarker(marker)
        })
    }

    const detachMarkers = (
        partsIds: string[],
        marker: Mesh,
        getExactSnaps?: boolean,
        checkCollisions?: boolean,
        resetGuidelines?: boolean
    ) => {
        return context!.getParts(partsIds).map(part => {
            return part.detachFromMarker(
                marker,
                connections,
                getExactSnaps,
                checkCollisions,
                resetGuidelines
            )
        })
            .flat(1)
    }

    const checkMaxLength = (
        partsIds: string[],
        direction: Vector3,
    ) => {
        const maxLengths = context?.getParts(partsIds).map(part => {
            return part.getMaxLength(direction, connections)
        })!
        const orderedMaxLengths = filterWithValue(maxLengths)
        if (orderedMaxLengths.length === 0) {
            return null
        }
        orderedMaxLengths.sort((a, b) => a - b)
        return orderedMaxLengths[0]
    }

    const handleLengthMovement = (
        partsIds: string[],
        onDrag: (distance: number) => void,
        onSnap: (newConnection: PartConnectionType) => void,
        direction: Vector3,
        lengthDirection: number
    ) => {
        const snaps: {
            distance: number,
            selectedObject: Intersection,
            newConnection: PartConnectionType,
        }[] = []
        const drags: {
            distance: number,
            checksOnLengthMove: (
                elongationDirection: Vector3,
                connections: PartConnectionType[],
                lengthDirection: number
            ) => void,
        }[] = []
        const parts = context?.getParts(partsIds)!
        parts.forEach(part => {
            const corrections = part.checksOnLengthMove(direction, connections, lengthDirection)
            if (corrections.snap) {
                snaps.push({
                    distance: corrections.snapDistance,
                    selectedObject: corrections.selectedObject!,
                    newConnection: corrections.newConnections[0],
                })
            }
            if (corrections.drag) {
                drags.push({
                    distance: corrections.dragDistance,
                    checksOnLengthMove: part.checksOnLengthMove,
                })
                onDrag(corrections.dragDistance)
            }
        })
        if (drags.length > 0) {
            drags.sort((a, b) => Math.abs(b.distance) - Math.abs(a.distance))
            const maxDrag = drags[0]
            onDrag(maxDrag.distance)
            maxDrag.checksOnLengthMove(direction, connections, lengthDirection)
        }
        if (snaps.length > 0) {
            snaps.sort((a, b) => a.distance - b.distance)
            const minSnap = snaps[0]
            onSnap(minSnap.newConnection)
        }
        parts.forEach(part => {
            if (part.updateTransforms) {
                part.updateTransforms()
            }
        })
    }

    const handleSegmentedTubeLenghMovement = (
        partsIds: string[],
        intersectableMeshes: Object3D[],
    ) => {
        const parts = context?.getParts(partsIds)!
        parts.forEach(part => {
            part.checksOnSegmentedTubeLengthMove(intersectableMeshes, connections)
        })
        parts.forEach(part => {
            if (part.updateTransforms) {
                part.updateTransforms()
            }
        })
    }

    const handleRotationMovement = (
        partsIds: string[],
        intersectableMeshes: Object3D[]
    ) => {
        context?.getParts(partsIds).forEach(part => {
            part.checksOnRotationMove(connections, intersectableMeshes)
            if (part.updateTransforms) {
                part.updateTransforms()
            }
        })
    }

    const handleSlideMovement = (
        partsIds: string[],
        intersectableMeshes: Object3D[],
        checkSnaps?: boolean,
    ) => {
        const parts = context?.getParts(partsIds)
        parts?.forEach(part => {
            part.checksOnSegmentedTubeLengthMove(intersectableMeshes, connections, checkSnaps)
        })
        parts?.forEach(part => {
            if (part.updateTransforms) {
                part.updateTransforms()
            }
        })
    }

    const saveSegmentedTubeSliderChanges = (
        partIds: string[],
        newConnections: PartConnectionType[]
    ) => {
        const historyKey = crypto.randomUUID()
        const updaters: {
            id: string,
            updater: ((p: GenericPartState) => void),
        }[] = context!.getParts(partIds).map(part => {
            const posAndRot = part.getPosAndRot()
            return {
                id: part.id,
                updater: (p: GenericPartState) => {
                    const oldPos = p.position
                    p.position = { x: posAndRot.pos.x, y: posAndRot.pos.y, z: posAndRot.pos.z, }
                    p.rotation = {
                        x: posAndRot.rot.x,
                        y: posAndRot.rot.y,
                        z: posAndRot.rot.z,
                        w: posAndRot.rot.w,
                    }
                    if (p.markerOffset) {
                        // Calculate position difference
                        const posDiff = {
                            x: p.position.x - oldPos.x,
                            y: p.position.y - oldPos.y,
                            z: p.position.z - oldPos.z,
                        }
                        // Add difference to existing marker offset
                        p.markerOffset = {
                            x: (p.markerOffset.x as number) + posDiff.x,
                            y: (p.markerOffset.y as number) + posDiff.y,
                            z: (p.markerOffset.z as number) + posDiff.z,
                        }
                    }
                },
            }
        })
        multiplePartUpdater(updaters, newConnections, false, historyKey)
    }

    const saveLengthChanges = (
        newTubeValues: {
            length: number,
            originMarkerName: TubeMarkerEnum,
            pos?: XYZ,
            rot?: XYZW,
        },
        partIds: string[],
        newConnections: PartConnectionType[]
    ) => {
        const historyKey = crypto.randomUUID()
        const updaters: {
            id: string,
            updater: ((p: GenericPartState) => void),
        }[] = context!.getParts(partIds).map(part => {
            const posAndRot = part.getPosAndRot()
            return {
                id: part.id,
                updater: (p: GenericPartState) => {
                    p.position = { x: posAndRot.pos.x, y: posAndRot.pos.y, z: posAndRot.pos.z, }
                    p.rotation = {
                        x: posAndRot.rot.x,
                        y: posAndRot.rot.y,
                        z: posAndRot.rot.z,
                        w: posAndRot.rot.w,
                    }
                },
            }
        })
        updaters.push({
            id: part.id,
            updater: (p: GenericPartState) => {
                assertPartType(p, PartTypeEnum.tube)
                p.length = roundLength(newTubeValues.length)
                p.originMarkerName = newTubeValues.originMarkerName
                if (newTubeValues.pos) {
                    p.position = {
                        x: newTubeValues.pos.x,
                        y: newTubeValues.pos.y,
                        z: newTubeValues.pos.z,
                    }
                }
                if (newTubeValues.rot) {
                    p.rotation = {
                        x: newTubeValues.rot.x,
                        y: newTubeValues.rot.y,
                        z: newTubeValues.rot.z,
                        w: newTubeValues.rot.w,
                    }
                }
            },
        })
        multiplePartUpdater(updaters, newConnections, false, historyKey)
    }

    const saveRotationChanges = (
        newConnectorValues: {
            posAndRot: {
                pos: XYZ,
                rot: XYZW,
            },
            rotationMarkerName: string,
        },
        partIds: string[],
        newConnections: PartConnectionType[],
        specialRotationMarker?: boolean,
    ) => {
        const historyKey = crypto.randomUUID()
        const updaters: {
            id: string,
            updater: ((p: GenericPartState) => void),
        }[] = context!.getParts(partIds).map(part => {
            const posAndRot = part.getPosAndRot()
            //drawVector3Point(posAndRot.pos, scene, 0x0000FF)
            return {
                id: part.id,
                updater: (p: GenericPartState) => {
                    const oldPos = p.position
                    p.position = { x: posAndRot.pos.x, y: posAndRot.pos.y, z: posAndRot.pos.z, }
                    p.rotation = {
                        x: posAndRot.rot.x,
                        y: posAndRot.rot.y,
                        z: posAndRot.rot.z,
                        w: posAndRot.rot.w,
                    }
                    if (p.markerOffset) {
                        // Calculate position difference
                        const posDiff = {
                            x: p.position.x - oldPos.x,
                            y: p.position.y - oldPos.y,
                            z: p.position.z - oldPos.z,
                        }
                        // Add difference to existing marker offset
                        p.markerOffset = {
                            x: (p.markerOffset.x as number) + posDiff.x,
                            y: (p.markerOffset.y as number) + posDiff.y,
                            z: (p.markerOffset.z as number) + posDiff.z,
                        }
                    }
                },
            }
        })
        updaters.push({
            id: part.id,
            updater: (p: GenericPartState) => {
                p.rotation = newConnectorValues.posAndRot.rot
                p.position = newConnectorValues.posAndRot.pos
                if (p.type === PartTypeEnum.connector) {
                    if (!specialRotationMarker) {
                        p.initialMarkerName = newConnectorValues.rotationMarkerName
                    }
                    p.rotationMarkerName = newConnectorValues.rotationMarkerName
                }
            },
        })
        multiplePartUpdater(updaters, newConnections, false, historyKey)
    }

    const saveRotationChangesModified = (
        newConnectorValues: {
            posAndRot: {
                pos: XYZ,
                rot: XYZW,
            },
            rotationMarkerName: string,
            initialMarkerName: string,
        },
        partIds: string[],
        newConnections: PartConnectionType[],
        specialRotationMarker?: boolean,
    ) => {
        const historyKey = crypto.randomUUID()
        //console.log(specialRotationMarker, "saveRotationChangesModified specialRotationMarker in saveRotationChanges")
        //console.log(newConnectorValues, " saveRotationChangesModified newConnectorValues in saveRotationChanges")

        const updaters: {
            id: string,
            updater: ((p: GenericPartState) => void),
        }[] = context!.getParts(partIds).map(part => {
            const posAndRot = part.getPosAndRot()
            //drawVector3Point(posAndRot.pos, scene, 0x0000FF)
            return {
                id: part.id,
                updater: (p: GenericPartState) => {
                    const oldPos = p.position
                    p.position = { x: posAndRot.pos.x, y: posAndRot.pos.y, z: posAndRot.pos.z, }
                    p.rotation = {
                        x: posAndRot.rot.x,
                        y: posAndRot.rot.y,
                        z: posAndRot.rot.z,
                        w: posAndRot.rot.w,
                    }
                    if (p.markerOffset) {
                        // Calculate position difference
                        const posDiff = {
                            x: p.position.x - oldPos.x,
                            y: p.position.y - oldPos.y,
                            z: p.position.z - oldPos.z,
                        }
                        // Add difference to existing marker offset
                        p.markerOffset = {
                            x: (p.markerOffset.x as number) + posDiff.x,
                            y: (p.markerOffset.y as number) + posDiff.y,
                            z: (p.markerOffset.z as number) + posDiff.z,
                        }
                    }
                },
            }
        })
        updaters.push({
            id: part.id,
            updater: (p: GenericPartState) => {
                p.rotation = newConnectorValues.posAndRot.rot
                p.position = newConnectorValues.posAndRot.pos
                if (p.type === PartTypeEnum.connector) {
                    p.rotationMarkerName = newConnectorValues.rotationMarkerName
                    p.initialMarkerName = newConnectorValues.initialMarkerName
                }
            },
        })
        multiplePartUpdater(updaters, newConnections, false, historyKey)
    }

    return {
        canEditLength,
        canEditRotation,
        canEditSlide,
        canEditSegmentedTubeLength,
        attachToMove,
        detachMarkers,
        handleLengthMovement,
        handleSegmentedTubeLenghMovement,
        handleRotationMovement,
        handleSlideMovement,
        saveLengthChanges,
        saveRotationChanges,
        saveRotationChangesModified,
        checkMaxLength,
        saveSegmentedTubeSliderChanges,
    }
}

export { useRegisterMultipleMovement, useMultipleMovement, }