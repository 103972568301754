/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable max-len */
import React from "react"
import { notification, Spin } from "antd"
import type { NotificationArgsProps } from "antd"
import isMobile from "ismobilejs"

interface MessageConfig {
  duration?: number;
  showCloseIcon?: boolean;
  forceShow?: boolean;
  persistAcrossSessions?: boolean;
  key?: string;
  showSpinner?: boolean;
  showUpTo?: number;
  minTimeBetweenShows?: number;
  buttonText?: string;
  onButtonClick?: () => void;
}

const defaultConfig: MessageConfig = {
  duration: 1,
  showCloseIcon: false,
  forceShow: false,
  persistAcrossSessions: true,
  showSpinner: false,
  showUpTo: undefined,
  minTimeBetweenShows: 0,
  buttonText: undefined,
  onButtonClick: undefined,

}

class MessageTracker {
  private shownMessages: Map<string, { count: number, lastShownTime: number, }>
  private storageKey = "shownMessages"
  private useLocalStorage: boolean

  constructor() {
    this.useLocalStorage = this.isLocalStorageAvailable()
    this.shownMessages = new Map(this.getStoredMessages())
  }

  private updateStorage() {
    if (this.useLocalStorage) {
      localStorage.setItem(this.storageKey, JSON.stringify(Array.from(this.shownMessages)))
    }
  }

  private isLocalStorageAvailable(): boolean {
    try {
      localStorage.setItem("test", "test")
      localStorage.removeItem("test")
      return true
    } catch (e) {
      return false
    }
  }

  private getStoredMessages(): [string, { count: number, lastShownTime: number, }][] {
    if (this.useLocalStorage) {
      const stored = localStorage.getItem(this.storageKey)
      if (stored) {
        try {
          const parsed = JSON.parse(stored)
          if (Array.isArray(parsed) && parsed.every(item =>
            Array.isArray(item) && item.length === 2
            && typeof item[1] === "object" && "count" in item[1] && "lastShownTime" in item[1]
          )) {
            return parsed
          }
        } catch (e) {
          console.warn("Failed to parse stored messages. Resetting storage.")
        }
      }
    }
    return []
  }

  hasShown(key: string, showUpTo?: number, minTimeBetweenShows?: number): boolean {
    const messageData = this.shownMessages.get(key)
    if (!messageData) { return false }

    const { count, lastShownTime, } = messageData
    const now = Date.now()

    if (minTimeBetweenShows && (now - lastShownTime) / 1000 < minTimeBetweenShows) {
      return true
    }

    return showUpTo ? count >= showUpTo : count > 0
  }

  clearMessage(key: string) {
    this.shownMessages.delete(key)
    //console.log("cleared message", key)
    if (this.useLocalStorage) {
      this.updateStorage()
    }
  }

  markAsShown(key: string, persist: boolean) {
    const messageData = this.shownMessages.get(key) || { count: 0, lastShownTime: 0, }
    messageData.count += 1
    messageData.lastShownTime = Date.now()
    this.shownMessages.set(key, messageData)
    if (persist && this.useLocalStorage) {
      this.updateStorage()
    }
  }

}

const messageTracker = new MessageTracker()

const showMessage = (
  message: string,
  config: MessageConfig = {}
) => {
  const mergedConfig = { ...defaultConfig, ...config, }
  const messageKey = mergedConfig.key || message

  if (!mergedConfig.forceShow && messageTracker.hasShown(messageKey, mergedConfig.showUpTo, mergedConfig.minTimeBetweenShows)) {
    return
  }

  messageTracker.markAsShown(messageKey, mergedConfig.persistAcrossSessions || false)

  const notificationConfig: NotificationArgsProps = {
    message: (
      <div>
        {mergedConfig.showSpinner && (
          <Spin size="small" style={{ marginRight: "8px", }} />
        )}
        {message}
        {mergedConfig.buttonText && mergedConfig.onButtonClick && (
          <button
            onClick={() => {
              mergedConfig.onButtonClick?.()
              notification.destroy(messageKey)
            }}
            style={{
              borderRadius: "4px",
              border: "1px solid #ccc",
              background: "black",
              color: "white",
              marginLeft: "8px",
              pointerEvents: "auto",
              fontWeight: "bold",
              marginTop: "8px",
              cursor: "pointer",
            }}
          >
            {mergedConfig.buttonText}
          </button>
        )}
      </div>
    ),
    description: "",
    placement: "bottomRight",
    pauseOnHover: true,
    key: messageKey,
    duration: mergedConfig.duration,
    closeIcon: mergedConfig.showCloseIcon ? <span style={{ pointerEvents: "auto", cursor: "pointer", }}>×</span> : null,
    className: "custom-notification",
    style: {
      background: "transparent",
      padding: "8px 12px",
      marginRight: isMobile(window.navigator).phone ? "0px" : "30px",
    },
  }
  //notification.destroy(messageKey)
  notification.open(notificationConfig)
}

// Predefined messages
const messages = {
  partAdded: (partName: string, config?: MessageConfig) =>
    showMessage(`Part "${partName}" added to design`, {
      ...config,
      key: "partAdded",
    }),
  designSaved: (config?: MessageConfig) =>
    showMessage("Design saved successfully", {
      ...config,
      key: "designSaved",
    }),
  errorOccurred: (config?: MessageConfig) =>
    showMessage("An error occurred. Please try again.", {
      ...config,
      key: "errorOccurred",
    }),
  // Add more predefined messages as needed
}

// Custom message function
const customMessage = (text: string, config?: MessageConfig) => {
  showMessage(text, config)
}


export const messageUtils = {
  ...messages,
  custom: customMessage,
  clearMessage: (key: string) => {
    messageTracker.clearMessage(key)
    notification.destroy(key)  // Add this line to remove the UI notification
  },
}