/* eslint-disable max-statements */
import { useEffect, useState, useCallback } from "react"
import { useThree } from "@react-three/fiber"

export const useBlockUI = () => {
  const [blockUI, setBlockUI,] = useState(false)
  const { gl, } = useThree()

  useEffect(() => {
    let originalCursor: string | undefined
    let clickHandler: ((e: MouseEvent) => void) | undefined
    let overlayElement: HTMLDivElement | undefined

    if (blockUI) {
      // Store the original cursor style
      originalCursor = gl.domElement.style.cursor

      // Set cursor to wait (spinner)
      gl.domElement.style.cursor = "wait"

      // Create an overlay to prevent clicks
      overlayElement = document.createElement("div")
      overlayElement.style.position = "fixed"
      overlayElement.style.top = "0"
      overlayElement.style.left = "0"
      overlayElement.style.width = "100%"
      overlayElement.style.height = "100%"
      overlayElement.style.zIndex = "9999"
      overlayElement.style.cursor = "wait"

      // Disable clicking
      clickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
      }
      overlayElement.addEventListener("click", clickHandler, true)
      overlayElement.addEventListener("mousedown", clickHandler, true)
      overlayElement.addEventListener("mouseup", clickHandler, true)

      document.body.appendChild(overlayElement)
    } else {
      // Restore original cursor
      if (originalCursor !== undefined) {
        gl.domElement.style.cursor = originalCursor
      }

      // Remove overlay if it exists
      if (overlayElement && overlayElement.parentNode) {
        overlayElement.parentNode.removeChild(overlayElement)
      }
    }

    // Cleanup function
    return () => {
      if (overlayElement && overlayElement.parentNode) {
        overlayElement.parentNode.removeChild(overlayElement)
      }
      if (originalCursor !== undefined) {
        gl.domElement.style.cursor = originalCursor
      }
    }
  }, [blockUI, gl.domElement,])

  const block = useCallback(() => {
    setBlockUI(true)
  }, [])

  const unblock = useCallback(() => {
    setBlockUI(false)
  }, [])

  return {
    blockUI,
    block,
    unblock,
  }
}