/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useState } from "react"
import { Collapse, Tooltip } from "antd"
import { RetweetOutlined, DragOutlined, CopyOutlined, SwapOutlined } from "@ant-design/icons"
import { partsListStyles, rowTemplate } from "./partsListHelpers"
import { PartTypeAPI } from "../../../../../../../../common/api/Types"
import { useRecoilValue, useRecoilCallback } from "recoil"
import { initialData } from "../../../../../../../state/atoms"
import { SwappablePartsList } from "./SwappablePartsList"
import { css } from "styled-components"
import styled from "styled-components"
import { SwapJob, swapJobsAtom, partsByApiTypeSelector } from "../../../../../../../state/scene/atoms"
import { useSetRecoilState } from "recoil"
import { openPartsComparisonWindow } from "./PartsComparisonWindow"
import { useMarkerComparison } from "../../../../../../../hooks/useMarkerComparison"
import { useComponentRegistry } from "../../../../../../../providers/multiselectProvider/useComponentMethods"
import { Marker } from "../../../../../../../utils/Types"
import { useLevaControls } from "../../../../../../../providers/debugProvider/useLevaControls"
import { Mesh } from "three"

interface SamePartsPanelProps {
    typeKey: string;
    sameParts: any[];
    handleItemHover: (itemIds: string[]) => void;
    handleItemLeave: (itemIds: string[]) => void;
    sceneCallbacks: any;
    getRows: (parts: any[]) => any[];
}

export type CompatiblePart = {
    part: PartTypeAPI,
    markerMap: { [key: string]: string, } | undefined,
}

const ActiveIcon = styled(SwapOutlined) <{ $isActive?: boolean, }>`
    ${props => props.$isActive && css`
        color: #1890ff;
    `}
`

const StyledCollapse = styled(Collapse)`
    margin-bottom: 10px;
`

const StyledCollapsePanel = styled(Collapse.Panel)`
    > .ant-collapse-content-box {
        padding: 0;
    }
`



export const SamePartsPanel: React.FC<SamePartsPanelProps> = ({
    typeKey,
    sameParts,
    handleItemHover,
    handleItemLeave,
    sceneCallbacks,
    getRows,
}) => {
    const allParts = useRecoilValue(initialData)
    const [compatibleParts, setCompatibleParts,] = useState<CompatiblePart[]>([])
    const [isSwapActive, setIsSwapActive,] = useState(false)
    const setSwapJobs = useSetRecoilState(swapJobsAtom)
    const { getQuaternionAndRunComparison, } = useMarkerComparison()
    const {getComponent,} = useComponentRegistry()
    const {admin, multiswapDebug,} = useLevaControls()

    const getPartsOfType = useRecoilCallback(({snapshot,}) => async (apiTypeId: string) => {
        return await snapshot.getPromise(partsByApiTypeSelector(apiTypeId))
    }, [])

    const getCompatiblePartsByType = (thisPart: PartTypeAPI, parts: PartTypeAPI[]) => {
        return parts?.filter(p => {
            if (p.id === thisPart.apiTypeId) {
                // ignore the same part
                return false
            }

            const sourceConnectorCounts = new Map()
            const targetConnectorCounts = new Map()

            thisPart.connections.forEach(c => {
                sourceConnectorCounts.set(c.connectorId,
                    ((sourceConnectorCounts.get(c.connectorId) as number) ?? 0) + 1)
            })

            p.connections.forEach(c => {
                targetConnectorCounts.set(c.connectorId,
                    ((targetConnectorCounts.get(c.connectorId) as number) ?? 0) + 1)
            })

            return Array.from(sourceConnectorCounts.entries()).every(([id, count,]) =>
                targetConnectorCounts.get(id) === count)
                && sourceConnectorCounts.size === targetConnectorCounts.size
        })
    }

    const getQuaternionCompatibleParts = async (thisPart: PartTypeAPI, parts: PartTypeAPI[]) => {
        // Now we can use getPartsOfType inside the async function
        const partsOfSameType = await getPartsOfType(thisPart.id)

        const thisPartComponent = getComponent(partsOfSameType[0].id)
        const markers = thisPartComponent?.getAllMarkers(true) as Mesh[]
        const innerMarkers = markers?.filter((m: Mesh) => m.name.includes("inner") || m.name.includes("TOP") || m.name.includes("BOTTOM"))

        const compatibleParts = []
        const incompatibleParts = []

        for (const part of parts) {
            const quaternionCompatibleParts = await getQuaternionAndRunComparison(thisPart, part, innerMarkers, {
                enabled: true,
                visualize: true,
            })
            if (quaternionCompatibleParts.isCompatible) {
                compatibleParts.push({
                    part,
                    markerMap: quaternionCompatibleParts.equivalences,
                })
            } else {
                incompatibleParts.push(part)
            }
        }

        return { compatibleParts, incompatibleParts, }
    }

    const findCompatibleParts = async (part: PartTypeAPI) => {
        const thisPart = allParts?.parts.find(p => p.id === part.apiTypeId)
        if (!thisPart) {
            setCompatibleParts([])
            return
        }

        // Quick filter by connection count
        const partsWithSameNumberOfConnections = allParts?.parts.filter(p =>
            p.connections.length === thisPart.connections.length
        )

        // Detailed comparison of connector types
        const partsWithSameConnections = getCompatiblePartsByType(thisPart, partsWithSameNumberOfConnections!)
        const compatiblePartsList = (partsWithSameConnections || [])
        const compatiblePartsListFiltered = compatiblePartsList.filter(p => {
            if (p.id === part.apiTypeId) {
                return false
            }
            if (p.hideFromSidebar) {
                return false
            }
            return true
        })

        const compatibleAfterQuaternion = await getQuaternionCompatibleParts(thisPart, compatiblePartsListFiltered)

        setCompatibleParts(compatibleAfterQuaternion.compatibleParts)
        setIsSwapActive(true)

        // Open the comparison window
        if (multiswapDebug) {
            openPartsComparisonWindow(
                thisPart,
                compatibleAfterQuaternion.compatibleParts.map(p => p.part),
                compatibleAfterQuaternion.incompatibleParts
            )
        }
    }

    const handlePartSwap = (newPart: CompatiblePart) => {
        if (!newPart.part.id || !sameParts[0]?.id) { return }

        const newJob: SwapJob = {
            sourcePartIds: sameParts.map(p => p.id),
            targetPart: newPart,
            status: "pending",
        }
        setSwapJobs(prev => [...prev, newJob,])
    }

    const resetSwapState = () => {
        setIsSwapActive(false)
        setCompatibleParts([])
    }

    return (
        <StyledCollapse defaultActiveKey={[typeKey,]}>
            <StyledCollapsePanel
                key={typeKey}
                header={
                    <div
                        onMouseEnter={() => handleItemHover(sameParts.map(p => p.id))}
                        onMouseLeave={() => handleItemLeave(sameParts.map(p => p.id))}
                        style={{ display: "flex", justifyContent: "space-between", width: "100%", }}
                    >
                        <span>{`${sameParts[0].name} (${sameParts.length})`}</span>
                        <div style={{ display: "flex", gap: "8px", }}>
                            <Tooltip title="Duplicate">
                                <CopyOutlined
                                    style={{ cursor: "pointer", }}
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (sceneCallbacks?.duplicateSelectedParts) {
                                            sceneCallbacks
                                                .duplicateSelectedParts(false, sameParts.map(p => p.id))
                                        }
                                    }}
                                />
                            </Tooltip>
                            <Tooltip title="Rotate">
                                <RetweetOutlined
                                    style={{ cursor: "pointer", }}
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (sceneCallbacks?.setIdsAsHighlightedAndTurnOnControl) {
                                            sceneCallbacks.setIdsAsHighlightedAndTurnOnControl(
                                                sameParts.map(p => p.id),
                                                "rotate"
                                            )
                                        }
                                    }}
                                />
                            </Tooltip>
                            {(admin || multiswapDebug) && (
                                <Tooltip title="Swap">
                                    <ActiveIcon
                                        $isActive={isSwapActive}
                                        style={{ cursor: "pointer", }}
                                        onClick={e => {
                                        e.stopPropagation()
                                        findCompatibleParts(sameParts[0])
                                    }}
                                />
                            </Tooltip>
                            )}
                            <Tooltip title="Move">
                                <DragOutlined
                                    style={{ cursor: "pointer", }}
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (sceneCallbacks?.setIdsAsHighlightedAndTurnOnControl) {
                                            sceneCallbacks.setIdsAsHighlightedAndTurnOnControl(
                                                sameParts.map(p => p.id),
                                                "translate"
                                            )
                                        }
                                    }}
                                />
                            </Tooltip>
                        </div>
                    </div>
                }
            >
                {compatibleParts.length > 0 && (
                    <SwappablePartsList
                        compatibleParts={compatibleParts}
                        onPartSelect={handlePartSwap}
                        onCollapse={resetSwapState}
                    />
                )}
                {!compatibleParts.length && (
                    <partsListStyles.RowsContainer>
                        {sameParts.map((part, index) => {
                            const formattedRow = getRows([part,])[0]
                            return rowTemplate({
                                ...formattedRow,
                                key: `${part.id}-${index}`,
                                inStock: true,
                                onMouseEnter: () => handleItemHover([part.id,]),
                                onMouseLeave: () => handleItemLeave([part.id,]),
                            })
                        })}
                    </partsListStyles.RowsContainer>
                )}
            </StyledCollapsePanel>
        </StyledCollapse>
    )
}