/* eslint-disable max-len */
import { m } from "framer-motion"
import { MutableRefObject } from "react"
import {
    Mesh,
    Object3D,
    Quaternion,
    Scene,
} from "three"
import {
    ConnectionTypeAPI,
    SegmentedTubeSectionType
} from "../../../../../../../../../common/api/Types"
import { ObjDictionary } from "../../../../../../../../../common/utils/utils"
import { SegmentedTubeValues } from "../../../../../../../../exportables"
import { ConnectionOfPart, PartConnectionType } from "../../../../../../../../state/scene/types"
import { isInConnection } from "../../../../../../../../state/scene/util"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { SegmentedTubeInfo } from "../types/types"
import SegmentedTubeUtils from "./SegmentedTubeUtils"

const useDetachFromMarker = (props: { scene: Scene, }) => {
    const { scene, } = props

    return (
        marker: Mesh,
        tube: SegmentedTubeValues,
        info: MutableRefObject<SegmentedTubeInfo>,
        partConnectionsValue: ConnectionOfPart[],
        compatibilityList: ObjDictionary<ConnectionTypeAPI>,
        allConnections: PartConnectionType[],
        movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
        getExactSnaps?: boolean,
        resetGuidelines?: boolean,
    ) => {
        let newConnectionsValue: PartConnectionType[] = []
        if (resetGuidelines) {
            SegmentedTubeUtils.removeGuidelines(scene, info)
        }
        const position = MeshUtils.copyWorldPosition(info.current.attachmentPoint!)
        const rotation = MeshUtils.copyWorldQuaternion(info.current.attachmentPoint!)
        marker.remove(info.current.attachmentPoint!)
        scene.add(info.current.attachmentPoint!)
        info.current.attachmentPoint?.position.set(position.x, position.y, position.z)
        const normalizedRotation = new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w).normalize()
        info.current.attachmentPoint?.quaternion.set(normalizedRotation.x, normalizedRotation.y, normalizedRotation.z, normalizedRotation.w)


        if (getExactSnaps) {
            const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
                scene,
                tube,
                info,
                partConnectionsValue,
                compatibilityList,
                allConnections,
                movableSection,
                [],
                []
            )
            newConnectionsValue = newConnections
        }
        return newConnectionsValue

    }
}

const checkMultipleMoveSnap = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    allConnections: PartConnectionType[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
) => {
    const { newConnections, } = SegmentedTubeUtils.checkSnapOnEditionMovableSection(
        scene,
        tube,
        info,
        partConnectionsValue,
        compatibilityList,
        allConnections,
        movableSection,
        [],
        []
    )
    if (newConnections.length > 0) {
        return {
            snap: true,
            snapDistance: 0,
            newConnections: newConnections,
        } as const
    } else {
        return {
            snap: false,
        } as const
    }
}

const checkMultipleMoveAligments = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSections: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>[],
    intersectableMeshes: Object3D[]
) => {
    SegmentedTubeUtils.checkAlignments(
        scene,
        tube,
        info,
        partConnectionsValue,
        movableSections,
        intersectableMeshes,
    )
}

const isMovableMarkerConnected = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    connections: PartConnectionType[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const movableSection = movableSectionName === SegmentedTubeSectionType.END
        ? info.current.endSection : info.current.startSection
    const movableMarkersNames = Object.keys(movableSection)
        .map(key => movableSection[key].inner?.name)
    return connections.some(connection => movableMarkersNames.some(
        markerName => isInConnection(connection, tube.id, markerName)))
}

export const MultipleMovementUtils = {
    useDetachFromMarker,
    checkMultipleMoveSnap,
    checkMultipleMoveAligments,
    isMovableMarkerConnected,
}