import React from "react"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import DirectionArrow from "../../../../../../../../assets/icons/DirectionArrow"
import { ActionButtonContainer } from "../../ActionPanelStyles"
import ActionButton from "../../../../../../../../../common/components/ActionButton"
import ContentTooltip from "../../../../../../../../../common/components/Tooltip/ContentTooltip"
import type { ContentTooltipProps }
    from "../../../../../../../../../common/components/Tooltip/ContentTooltip"

interface DirectionSelectorProps {
    direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>;
    setDirection: (
        direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
    ) => void;
}

const DirectionSelector: React.FC<DirectionSelectorProps> = ({ direction, setDirection, }) => {
    const directionArrowStyle: React.CSSProperties = {
        position: "relative",
        width: "15px",
        height: "20px",
        zIndex: 2,
        cursor: "pointer",
    }

    const colors = {
        arrow: {
            selected: "#fff",
            unselected: "#ababab",
        },
        background: {
            selected: "#0088ff",
            unselected: "#f0f0f0",
        },
    }

    const isLeftSelected = direction === SegmentedTubeSectionType.END

    const commonTooltipProps: ContentTooltipProps = {
        side: "top",
        sideOffset: 5,
        showIcon: false,
        tooltipContent: "Reduce / Grow Other Direction",
        children: <></>,
    }

    return (
        <>
            <ActionButtonContainer>
                <ActionButton
                    minSize
                    style={{
                        border: "none",
                        background: isLeftSelected
                            ? colors.background.selected : colors.background.unselected,
                        width: "24px",
                        cursor: isLeftSelected
                            ? "not-allowed" : "pointer",
                    }}
                    disabled={isLeftSelected}
                    onClick={() => {
                        setDirection(SegmentedTubeSectionType.END)
                    }}
                >
                    <ContentTooltip {...commonTooltipProps} disabled={isLeftSelected}>
                        <DirectionArrow
                            style={{
                                ...directionArrowStyle,
                                transform: "rotate(180deg)",
                                paddingBottom: "5px",
                                cursor: isLeftSelected
                                    ? "not-allowed" : "pointer",
                            }}
                            fill={isLeftSelected
                                ? colors.arrow.selected
                                : colors.arrow.unselected}
                        />
                    </ContentTooltip>
                </ActionButton>
                <ActionButton
                    minSize
                    style={{
                        background: isLeftSelected
                            ? colors.background.unselected : colors.background.selected,
                        width: "24px",
                        border: "none",
                        cursor: isLeftSelected
                            ? "pointer" : "not-allowed",
                    }}
                    disabled={!isLeftSelected}
                    onClick={() => {
                        setDirection(SegmentedTubeSectionType.START)
                    }}
                >
                    <ContentTooltip {...commonTooltipProps} disabled={!isLeftSelected} >
                        <DirectionArrow
                            style={{
                                ...directionArrowStyle,
                                paddingTop: "5px",
                                cursor: isLeftSelected
                                    ? "pointer" : "not-allowed",
                            }}
                            fill={isLeftSelected
                                ? colors.arrow.unselected
                                : colors.arrow.selected}
                        />
                    </ContentTooltip >
                </ActionButton>
            </ActionButtonContainer >
        </>
    )
}

export default DirectionSelector