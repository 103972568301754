/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react"
import { Text, Line, Html } from "@react-three/drei"
import { Vector3, Object3D } from "three"
import { ArrowHelper } from "three"
import tinycolor2 from "tinycolor2"
import Draggable from "react-draggable"

const DEBUG_TEXT_PADDING = 0.1
const DEBUG_ARROW_OFFSET = 0.02

export interface DebugEntry {
    contents: string[];
    objects: Object3D[];
}

export interface VisualDebugData {
    [scope: string]: DebugEntry;
}

interface VisualDebugProps {
    contents: VisualDebugData;
    position: Vector3 | null;
    visible?: boolean;
    isSelected?: boolean;
    partId?: string;
}

const VisualDebug: React.FC<VisualDebugProps> = ({ contents, position, visible = true, isSelected = false, partId, }) => {
    const colorRef = useRef(
        tinycolor2.random()
            .darken(30)
            .saturate(20)
            .toHexString()
    )
    const [selectedScope, setSelectedScope,] = useState<string>(() => {
        const scopes = Object.keys(contents)
        return scopes.includes("default") ? "default" : scopes[0] || "default"
    })
    const [isOpen, setIsOpen,] = useState(true)

    // Reset isOpen when isSelected changes
    useEffect(() => {
        if (isSelected) {
            setIsOpen(true)
        }
    }, [isSelected,])

    if (!visible || !position) { return null }

    const scopes = Object.keys(contents)
    const currentEntry = contents[selectedScope]

    return (
        <>
            {/* Debug Objects in Scene */}
            {isSelected && currentEntry?.objects?.map((obj, index) => (
                <primitive key={`${selectedScope}-obj-${index}`} object={obj} />
            ))}

            {/* HTML overlay when selected and open */}
            {isSelected && isOpen && position && currentEntry?.contents?.length > 0 && (
                <Html
                    position={position}
                    center
                    style={{
                        position: "absolute",
                        pointerEvents: "auto",
                    }}
                >
                    <Draggable>
                        <div
                            style={{
                                background: "rgba(0, 0, 0, 0.8)",
                                color: "white",
                                padding: "10px",
                                borderRadius: "5px",
                                fontFamily: "monospace",
                                maxWidth: "500px",
                                width: "500px",
                                transform: "translate(-50%, -50%)",
                                boxShadow: "0 0 10px rgba(0,0,0,0.5)",
                                position: "relative",
                            }}
                        >
                            <div
                                onClick={() => setIsOpen(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    background: "rgba(255, 255, 255, 0.1)",
                                    borderRadius: "3px",
                                }}
                            >
                                ✕
                            </div>
                            {partId && (
                                <div>
                                    <span style={{ fontSize: "10px", }}>Part ID:</span>
                                    <span style={{ fontSize: "10px", }}>{partId}</span>
                                </div>
                            )}
                            <select
                                value={selectedScope}
                                onChange={(e) => setSelectedScope(e.target.value)}
                                style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    background: "rgba(255, 255, 255, 0.1)",
                                    color: "white",
                                    border: "1px solid rgba(255, 255, 255, 0.3)",
                                    borderRadius: "3px",
                                    padding: "5px",
                                }}
                            >
                                {scopes.map(scope => (
                                    <option key={scope} value={scope}>
                                        {scope}
                                    </option>
                                ))}
                            </select>
                            {currentEntry?.contents.map((line, i) => (
                                <div key={i}>
                                    {i + 1}. {line}
                                </div>
                            ))}
                        </div>
                    </Draggable>
                </Html>
            )}
        </>
    )
}

export default VisualDebug
