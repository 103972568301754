/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import {
    ButtonContainer,
    NumberSliderContainer,
    SliderContainer,
} from "../../ActionPanelStyles"
import { TubeValues } from "../../../../../../../../utils/Types"
import { roundLength, roundNumber } from "../../../../../../../../utils/utils"
import { TubeInternalsType } from "../types/types"
import Button from "../../../../../../../../../common/components/Button"
import NumberSlider from "../../sliders/Slider"
import { messageUtils } from "../../../../LowerRightMessages"


interface Props {
    tube: TubeValues;
    // selectedId: string | null;
    setTubeLength: (value: number, ignoreGuidelines?: boolean) => void;
    setTubeState: (value: number, ignoreGuidelines?: boolean) => void;
    getDragState: () => { drag: boolean, dragValue: number, };
    getSnapState: () => { maxPossibleLength: number, isSnapped: boolean, hasCollied: boolean, };
    handleMouseDown: (e: any) => void;
    userUnit: string;
    updateUnit: (unit: string) => void;
    onFinishEditing: () => void;
    getTubeInternals: () => React.MutableRefObject<TubeInternalsType>;
}

enum SliderFrom {
    change = "change",
    mouseUp = "mouseUp"
}

type SliderState = {
    value: number,
    from: SliderFrom | null,
    ignoreGuidelines?: boolean,
}

const LengthSlider = (props: Props) => {
    const [slider, setSlider,] = useState<SliderState>({
        value: props.tube.defaultLength,
        from: null,
        ignoreGuidelines: false,
    })

    const snapState = props.getSnapState()
    const mmSnapState = props.getSnapState()

    const [unitedAdjustedMaxPossibleLength, setUnitedAdjustedMaxPossibleLength,] = useState(props.userUnit === "cm" ? snapState.maxPossibleLength * 2.54 : snapState.maxPossibleLength)
    const [unitedAdjustedMmMaxPossibleLength, setUnitedAdjustedMmMaxPossibleLength,] = useState(props.userUnit === "cm" ? mmSnapState.maxPossibleLength * 2.54 : mmSnapState.maxPossibleLength)

    useEffect(() => {
        //these are the unit adjusted values to use for the slider
        setUnitedAdjustedMaxPossibleLength(props.userUnit === "cm" ? snapState.maxPossibleLength * 2.54 : snapState.maxPossibleLength)
        setUnitedAdjustedMmMaxPossibleLength(props.userUnit === "cm" ? mmSnapState.maxPossibleLength * 2.54 : mmSnapState.maxPossibleLength)
    }, [props.userUnit,])


    const handleSlider = (e: number, ignoreGuidelines?: boolean,
        forceImmediateUpdate?: boolean) => {
        const { snapState, mmSnapState, } = props.getTubeInternals().current

        if (e >= props.tube.maxLength || e >= unitedAdjustedMaxPossibleLength) {
            messageUtils.custom("You've reached the max value because it has snapped to another part or it's the max length for the part", {
                duration: 5, forceShow: true,
            })
        } else if (e <= 1) {
            messageUtils.custom("You've reached the min value for this part", {
                duration: 5, forceShow: true,
            })
        }

        if (forceImmediateUpdate && !ignoreGuidelines) {
            props.handleMouseDown(e)
            setTimeout(() => {
                handleMouseUp(e)
                //if its snapped and the length is between the max length and 2 inches less than the max length, let the user know that its easier to unsnap using the slider
                if (snapState.isSnapped && e > unitedAdjustedMaxPossibleLength - 2 && e < unitedAdjustedMaxPossibleLength) {
                    messageUtils.custom("If you're trying to Unsnap, it's easier using the slider", {
                        duration: 8, forceShow: true, minTimeBetweenShows: 3,
                    })
                }
            }, 50)
        }

        //this is where user is typing - when they press enter, ignoreGuidelines is true so top
        //part of the function is is run and bottom part is skipped
        if (forceImmediateUpdate && ignoreGuidelines) {
            return
        }

        if (snapState.maxLengthSetted && e >= unitedAdjustedMaxPossibleLength) {
            setSlider({ value: unitedAdjustedMaxPossibleLength, from: SliderFrom.change, })
        } else if (mmSnapState.maxLengthSetted && e >= unitedAdjustedMmMaxPossibleLength) {
            setSlider({ value: unitedAdjustedMmMaxPossibleLength, from: SliderFrom.change, })
        } else {
            setSlider({ value: e, from: SliderFrom.change, ignoreGuidelines, })
        }
        //this is to handle when slider update is from typing or the up
        //down arrows keys to trigger the mouse up and down functions


    }

    useEffect(() => {
        if (slider.from === SliderFrom.change) {
            props.setTubeLength(slider.value, slider.ignoreGuidelines)
        }
        if (slider.from === SliderFrom.mouseUp) {
            const dragState = props.getDragState()
            props.setTubeState(dragState.dragValue)
        }
    }, [slider,])

    const handleMouseUp = (e: number) => {
        const dragState = props.getDragState()
        if ((snapState.isSnapped || snapState.hasCollied) && e > snapState.maxPossibleLength) {
            setSlider({ value: snapState.maxPossibleLength, from: SliderFrom.mouseUp, })
            messageUtils.custom("This part is at its max length because it has snapped with another part!", {
                duration: 1.5, forceShow: true, showSpinner: true,
            })
        } else if ((snapState.isSnapped || snapState.hasCollied) && e < snapState.maxPossibleLength) {
            setSlider({ value: e, from: SliderFrom.mouseUp, })
        } else {
            props.setTubeState(e, true)
        }
    }

    const getSliderValue = () => {
        const value = slider.value
        if (value > unitedAdjustedMaxPossibleLength) {
            return unitedAdjustedMaxPossibleLength
        }
        if (snapState.isSnapped && value < unitedAdjustedMaxPossibleLength) {
            return value
        }

        return roundLength(value, 3)
    }

    useEffect(() => {
        setSlider({
            value: props.tube.unitRealValue || props.tube.length,
            from: null,
        })
    }, [props.tube.length, props.tube.unitRealValue,])


    // 3 decimals for cm, 1 for in
    const scalingFactor = props.userUnit === "cm" ? 1000 : 10

    return <SliderContainer>
        <NumberSliderContainer>
            <NumberSlider
                unit={props.userUnit}
                min={1}
                max={props.tube.maxLength}
                step={0.1}
                handleMouseUp={handleMouseUp}
                onChange={handleSlider}
                getValue={() => roundNumber(getSliderValue(), 2)}
                inputNumber={true}
                handleMouseDown={props.handleMouseDown}
                inputFilter={
                    (val: number) => Math.floor(val * scalingFactor) / scalingFactor
                }
                updateUnit={props.updateUnit}
                scalingDimension="length"
                maxFallback={snapState ? unitedAdjustedMaxPossibleLength : props.tube.maxLength}
            />
        </NumberSliderContainer>
    </SliderContainer>
}

export default LengthSlider