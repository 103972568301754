/* eslint-disable no-nested-ternary */
import isMobile from "ismobilejs"
import styled from "styled-components"
import {
    SliderContainer as SliderContainerBase,
    ActionButtonContainer as ActionButtonContainerBase,
    LeftContent as LeftContentBase,
    RightContent as RightContentBase,
    ButtonContainer as ButtonContainerBase,
} from "../../ActionPanelStyles"

const isPhone = isMobile(window.navigator).phone
const isApple = isMobile(window.navigator).apple

export const SliderContainer = styled(SliderContainerBase)`
    border-radius: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: none;
    backdrop-filter: none;
    background-color: white;
    border: 1px solid #d2d3d2;
    box-shadow: none;
    position: relative;
    padding-bottom:16px;
`

export const ActionButtonContainer = styled(ActionButtonContainerBase)`
    button {
        border-bottom: none;
    }
    button:first-child {
        border-radius: 6px 0px 0px 0px;
    }
    button:last-child:not(:first-child) {
        border-left: none;
        border-radius: 0px 6px 0px 0px;
    }
`

export const LeftContent = styled(LeftContentBase)`
    flex: 1 1 0;
`

export const RightContent = styled(RightContentBase)`
    margin-left: 17px;
    margin-right: 0px;
    gap:8px;
`

export const ButtonContainer = styled(ButtonContainerBase)`
    padding-top: 0;
`

export const Wrapper = styled.div`
    padding: 16px;
`

export const LeftRightContainer = styled(Wrapper)`
    display: flex;
    align-items: center;
    padding: ${isPhone ? "0 9px 9px 9px" : "16px"};
`
export const SliderText = styled.div`
    width: 100%;
    text-align: center;
    font-size: 15px;
    color: grey;
    top: 5px;
    padding: ${isPhone ? "9px" : "16px"};
    height: ${isPhone
        ? isApple
            ? "78px"
            : "73px"
        : "96px"};
    /* height: 78px; */
    display: flex;
    justify-content: center;
    align-items: center;
`
