import { PartTypeAPI } from "../api/Types"

const STOREPREFIX = "tubes-designer-"

const getAccessors = <T>(keyParam: string, storage: Storage, getInitial: () => T) => {
    const keyStorage = `${STOREPREFIX}${keyParam}`
    const getter = (): T => {
        const content = storage[keyStorage]
        if (content) {
            return JSON.parse(content)
        } else {
            const value: T = getInitial()
            if (value) {
                storage[keyStorage] = JSON.stringify(value)
            }
            return value
        }
    }

    const setter = (content: T) => {
        storage[keyStorage] = JSON.stringify(content)
    }

    const deleter = () => {
        storage.removeItem(keyStorage)
    }

    return [getter, setter, deleter,] as const
}

export const [getDesign, setDesign, deleteDesign,] = getAccessors<string | null>(
    "design", localStorage, () => (null)
)

export const [getLogged, setLogged,] = getAccessors<string | null>(
    "logged", localStorage, () => (null)
)

export const [getSessionTimer, setSessionTimer,] = getAccessors<string | null>(
    "sessionTimer", sessionStorage, () => (null)
)

export const [getAutofocusSettings, setAutofocusSettings,] = getAccessors<boolean | null>(
    "autofocusSettings", localStorage, () => (null)
)

export const [getRecentPartsBase, setRecentPartsBase,] = getAccessors<string[] | null>(
    "recentParts", localStorage, () => (null)
    )
