import React, { useEffect, useState } from "react"
import { GizmoHelper, GizmoViewcube } from "@react-three/drei"
import { useRecoilValue } from "recoil"
import { layoutState } from "../../state/layoutState"
import isMobile from "ismobilejs"

const DRAWER_WIDTH = 185
const TRANSITION_DURATION = 1000

const getGizmoMargin = (isDrawerOpen: boolean, isMobileDevice: boolean) => {
    const baseLeftMargin = isMobileDevice ? 55 : 80
    if (isMobileDevice) {
        return [baseLeftMargin, 100,] as [number, number]
    }
    const leftMargin = isDrawerOpen ? baseLeftMargin + DRAWER_WIDTH : baseLeftMargin
    return [leftMargin, 100,] as [number, number]
}

export const CameraGizmo: React.FC = () => {
    const layoutValue = useRecoilValue(layoutState)
    const isDrawerOpen = layoutValue.isDrawerOpen

    const isMobileDevice = isMobile(window.navigator).any
    const targetMargin = getGizmoMargin(isDrawerOpen, isMobileDevice)
    const [currentMargin, setCurrentMargin,] = useState(targetMargin)

    useEffect(() => {
        const startMargin = currentMargin[0]
        const endMargin = targetMargin[0]
        const startTime = Date.now()

        const animate = () => {
            const elapsed = Date.now() - startTime
            const progress = Math.min(elapsed / TRANSITION_DURATION, 1)

            // Ease out cubic function
            const easeOut = 1 - Math.pow(1 - progress, 3)

            const newLeftMargin = startMargin + (endMargin - startMargin) * easeOut
            setCurrentMargin([newLeftMargin, 100,])

            if (progress < 1) {
                requestAnimationFrame(animate)
            }
        }

        animate()
    }, [isDrawerOpen,])

    return (
        <GizmoHelper
            visible={false}
            alignment="bottom-left"
            margin={currentMargin}
        >
            <GizmoViewcube
                opacity={1}
                onClick={(e) => {
                    // deactivate clicking behavior, cause it fires unexpectedly
                    return null
                }}
            />
        </GizmoHelper>
    )
}