/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { createContext, useContext, useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { swapJobsAtom, type SwapJob } from "../../state/scene/atoms"
import { PartTypeAPI } from "../../../common/api/Types"
import { useAIProvider } from "../../hooks/useAIProvider"
import { ComponentMethods, useComponentRegistry } from "../multiselectProvider/useComponentMethods"
import { useBlockUI } from "../../hooks/useBlockUI"
import { useNewPart } from "../../state/scene/setters"
import { DoubleSide, Mesh, MeshBasicMaterial, Quaternion, Vector3 } from "three"
import { CompatiblePart } from "../../components/main/DesignScreen/mainLayout/PartsModal/partListModal/partLists/SamePartsPanel"
import { Marker, PartTypeEnum, TubeMarkerEnum } from "../../utils/Types"
import { getMarkerNumber, isInner } from "../../utils/MarkerUtil"
import { PosAndRotType } from "../../state/types"
import { useLevaControls } from "../debugProvider/useLevaControls"

interface MultiSwapContextType {
    handlePartSwap: (sourcePartIds: string[], newPart: CompatiblePart) => void;
    swapJobs: SwapJob[];
}

const MultiSwapContext = createContext<MultiSwapContextType | null>(null)

export const MultiSwapProvider: React.FC<{ children: React.ReactNode, }> = ({ children, }) => {
    const [swapJobs, setSwapJobs,] = useRecoilState(swapJobsAtom)
    const { getComponent, } = useComponentRegistry()
    const { block, unblock, } = useBlockUI()
    const createPart = useNewPart()
    const { multiswapDebug, } = useLevaControls()
    useEffect(() => {
        if (swapJobs.length > 0) {
            const currentJob = swapJobs[0]
            handlePartSwap(currentJob.sourcePartIds, currentJob.targetPart)
                .then(() => {
                    // Remove the completed job from the queue
                    setSwapJobs(prev => prev.slice(1))
                })
                .catch((error) => {
                    console.error("Failed to process swap job:", error)
                    // Remove the failed job and continue with the queue
                    setSwapJobs(prev => prev.slice(1))
                })
        }
    }, [swapJobs,])

    const getInitialMarkerInfoSegTubesConnectors = (partInfo: any, markerMap: { [key: string]: string, }) => {
        const initialMarkerName = partInfo.initialMarkerName
        const initialMarkerNameHasSuffix = initialMarkerName?.includes("_0")
        const initialMarkerNameWithoutSuffix = initialMarkerNameHasSuffix ? initialMarkerName.split("_0")[0] : initialMarkerName

        let newInitialMarkerName
        for (const [key, value,] of Object.entries(markerMap)) {
            if (value.includes(initialMarkerNameWithoutSuffix)) {
                newInitialMarkerName = key
            }
        }

        if (initialMarkerNameHasSuffix) {
            newInitialMarkerName = `${newInitialMarkerName}_0`
        }

        const position = partInfo.position
        const rotation = partInfo.rotation

        const posAndRot: PosAndRotType = {
            inner: {
                pos: position,
                rot: rotation,
            },
            outer: {
                pos: position,
                rot: rotation,
            },
        }

        return {
            posAndRot,
            initialMarkerName: newInitialMarkerName,
            length: partInfo.length,
            lengthNegativeSide: partInfo.lengthNegativeSide,
            markerOffset: partInfo.markerOffset,
        }
    }

    const getInitialMarkerInfoTubes = (partInfo: any, markerMap: { [key: string]: string, }) => {
        return {
            length: partInfo.length,
            originMarkerName: partInfo.originMarkerName,
            posAndRot: {
                inner: {
                    pos: partInfo.position,
                    rot: partInfo.rotation,
                },
                outer: {
                    pos: partInfo.position,
                    rot: partInfo.rotation,
                },
            },
        }
    }

    const getInitialMarkerInfo = (part: ComponentMethods, markerMap: { [key: string]: string, }) => {
        const partInfo = part.getPartInfo()
        if (partInfo.type === PartTypeEnum.tube) {
            return getInitialMarkerInfoTubes(partInfo, markerMap)
        }
        return getInitialMarkerInfoSegTubesConnectors(partInfo, markerMap)
    }

    const handlePartSwap = async (sourcePartIds: string[], newPart: CompatiblePart) => {
        block()
        const components = []
        const newParts = []
        try {
            for (const sourcePartId of sourcePartIds) {
                const part = getComponent(sourcePartId)
                if (!part) {
                    throw new Error(`Part ${sourcePartId} not found`)
                }
                if (!newPart.markerMap) {
                    throw new Error(`Marker map not found for part ${sourcePartId}`)
                }
                components.push(part)
                const markerInfo = getInitialMarkerInfo(part, newPart.markerMap)
                const partParams = {
                    duplicatedFrom: sourcePartId,
                    part: newPart.part,
                    ...markerInfo,
                }
                const newPartInstance = await createPart(partParams, "multiSwap")
                newParts.push({
                    instance: newPartInstance,
                    markerNameMap: newPart.markerMap,
                })

                part.deletePart()
            }
            return true // Indicate successful swap
        } catch (error) {
            console.error("Error in handlePartSwap", error)
            throw error // Propagate error to be handled in useEffect
        } finally {
            unblock()
            await wait(1000)
            if (multiswapDebug) {
                for (const newPart of newParts) {
                    const newPartComponent = getComponent(newPart.instance.newPartId)
                    if (newPartComponent && newPart.markerNameMap && multiswapDebug) {
                        visualDebugMarkerMap(newPartComponent, components[0], newPart.markerNameMap)
                        newPartComponent.updateColor(0x00000ff)
                    }
                }
            }
        }
    }

    const visualDebugMarkerMap = (newPart: ComponentMethods, oldPart: ComponentMethods, markerNameMap: { [key: string]: string, }) => {
        const newPartMarkers = newPart.getAllMarkers(true)
        const oldPartMarkers = oldPart.getAllMarkers(true)
        const newPartMesh = newPart.getMesh()
        const oldPartMesh = oldPart.getMesh()
        newPartMesh.material.visible = false
        oldPartMesh.material.visible = false

        const colors = [
            "#FF0000",
            "#00FF00",
            "#0000FF",
            "#FFFF00",
            "#00FFFF",
            "#FF00FF",
        ]


        for (const [index, key,] of Object.entries(markerNameMap)) {
            if (markerNameMap.hasOwnProperty(key) && key.includes("outer")) {
                const newMarker = newPartMarkers.find((m: Marker) => m.name.includes(markerNameMap[key]))
                const oldMarker = oldPartMarkers.find((m: Marker) => m.name.includes(key))

                if (!oldMarker) {
                    console.log("oldMarker not found", key)
                }
                if (!newMarker) {
                    console.log("newMarker not found", markerNameMap[key])
                }

                const setMarkerVisibility = (marker: Mesh, color: string) => {
                    marker.visible = true
                    const materials = Array.isArray(marker.material) ? marker.material : [marker.material,]
                    materials.forEach(mat => {
                        const material = mat as MeshBasicMaterial
                        material.visible = true
                        material.opacity = 1
                        material.transparent = false
                        material.color.set(color)
                        material.wireframe = false
                        material.side = DoubleSide
                    })
                }
                if (newMarker && oldMarker) {
                    const color = colors.pop()
                    setMarkerVisibility(newMarker, color!)
                    setMarkerVisibility(oldMarker, color!)
                }
            }
        }
    }

    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

    const value = {
        handlePartSwap,
        swapJobs,
    }

    return (
        <MultiSwapContext.Provider value={value}>
            {children}
        </MultiSwapContext.Provider>
    )
}

export const useMultiSwap = () => {
    const context = useContext(MultiSwapContext)
    if (!context) {
        throw new Error("useMultiSwap must be used within a MultiSwapProvider")
    }
    return context
}