export const roundNumber = (num: number, decimals: number) => {
    if (num === 0) {
        return 0
    }
    if (!num) {
        console.warn("Found part with no number", num)
        return 0
    }
    return Number(num.toFixed(decimals))
}

export const roundLength = (length: number, decimals = 2) => {
    return roundNumber(length, decimals)
}
