/* eslint-disable no-continue */
/* eslint-disable max-lines-per-function */
/* eslint-disable default-case */
/* eslint-disable no-console */
/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { createContext, useState, useEffect, useRef, useCallback, useContext } from "react"
import { Input, Button, Space, InputRef, message } from "antd"
import { EditOutlined, CodeOutlined, RocketOutlined, ScanOutlined } from "@ant-design/icons"
import ReactDOM from "react-dom"
import { MultiSelectContext } from "../multiselectProvider/MultiSelectProvider"
import { Html, } from "@react-three/drei"
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil"
import { boundingBoxAtom, sceneAtom } from "../../state/scene/atoms"
import { ComponentMethods, useComponentRegistry } from "../multiselectProvider/useComponentMethods"
import { Box3, BoxGeometry, BoxHelper, BufferGeometry, Euler, Line, LineBasicMaterial, MathUtils, Matrix4, Mesh, MeshBasicMaterial, Object3D, Quaternion, Scene, SphereGeometry, Vector3 } from "three"
import { useThree } from "@react-three/fiber"
import useCamera from "../cameraProvider/useCamera"
import { AIProviderStrategy, DEFAULT_MODEL, CONTEXT_PROMPT } from "./AIProviderStrategy"
import { OpenAIStrategy } from "./OpenAIStrategy"
import { OpenRouterStrategy } from "./OpenRouterStrategy"
import { AIControls } from "../../components/AIControls/AIControls"
import { useScreenshot } from "../../utils/useScreenshot"
import { useAddPartOutsideModal } from "../../hooks/useAddPartOutsideModal"
import { initialData } from "../../state/atoms"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { DesignsApi, SimplifiedDesignStateOutput } from "../../../common/api/firebase/designs"
import { areDirectionsFacingEachOther, calculateRotationFromFriendlyNames, getDirectionalRelationship } from "../../utils/MarkerUtil"
import { connectionTypesSelector, sizesSelector } from "../../state/initialDataSelectors"
import { ConnectionTypeAPI, PartTypeAPI, SizeAPI } from "../../../common/api/Types"
import { extractNumberFromString, ObjDictionary } from "../../../common/utils/utils"
import { convertFractionToDecimal, normalizeDirectionalName } from "../../utils/MarkerUtil"
import { useGlobalAnimation } from "../../components/main/DesignScreen/scene/part/parts/utils/animations/GlobalAnimationProvider"
import { PartConnectionType } from "../../state/scene/types"
import { isCompatibleWith2Meshes } from "../../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { MeshUtils } from "../../utils/MeshUtils"
import { useMultipleUpdates, useNewPart } from "../../state/scene/setters"
import { SoundHelper } from "../../components/main/DesignScreen/utils/SoundHelper"
import { ApiClient } from "../../../common/api/ApiClient"
import { AIProviderContext } from "../../hooks/useAIProvider"
import { drawVector3Point } from "../../utils/PartUtils"
type ParsedResponse = ReturnType<typeof DesignsApi.parseTextToJson>

export const AIProvider = ({
    children,
    // change this line to use the OpenRouterStrategy
    // strategy = new OpenAIStrategy(),
    strategy = new OpenRouterStrategy(),
}: {
    children: React.ReactNode,
    strategy?: AIProviderStrategy,
}) => {
    const [container, setContainer,] = useState<HTMLDivElement | null>(null)
    const sceneData = useRecoilValue(sceneAtom)
    const { getAndUploadScreenshot, } = useScreenshot()
    const { getComponent, } = useComponentRegistry()
    const recoilInitialData = useRecoilValue(initialData)
    const { useAiPanel, } = useLevaControls()
    const shouldUseAiPanel = useAiPanel || new URLSearchParams(window.location.search).has("ai")
    const [isLoading, setIsLoading,] = useState(false)
    const [lastRequestTime, setLastRequestTime,] = useState<number | undefined>()
    const parsedRef = useRef<ParsedResponse | undefined>(undefined)
    const [creatingNewParts, setCreatingNewParts,] = useState(false)
    const [finishedCreatingNewParts, setFinishedCreatingNewParts,] = useState(false)
    const [creatingNewPositionValues, setCreatingNewPositionValues,] = useState(false)
    const [finishedCreatingNewPositionValues, setFinishedCreatingNewPositionValues,] = useState(false)
    const [creatingNewRotationValues, setCreatingNewRotationValues,] = useState(false)
    const [startedAIcreationProcess, setStartedAIcreationProcess,] = useState(false)
    const [finishedAIcreationProcess, setFinishedAIcreationProcess,] = useState(false)
    const [finishedCreatingNewRotationValues, setFinishedCreatingNewRotationValues,] = useState(false)
    const [finishedSettingNewRotationValues, setFinishedSettingNewRotationValues,] = useState(false)
    const connectionTypes = useRecoilValue(connectionTypesSelector)
    const sizes = useRecoilValue(sizesSelector)
    const multipleUpdate = useMultipleUpdates()
    type ParsedRefType = typeof parsedRef.current
    const { triggerAnimation, } = useGlobalAnimation()
    const multiSelectContext = useContext(MultiSelectContext)
    const { scene, } = useThree()
    const createPart = useNewPart()

    useEffect(() => {
        // Create single container for AI controls
        const container = document.createElement("div")
        container.id = "ai-controls-container"
        Object.assign(container.style, {
            position: "fixed",
            zIndex: 1000,
            bottom: "20px",
            left: "20px",
        })
        document.body.appendChild(container)
        setContainer(container)

        // Cleanup
        return () => {
            document.body.removeChild(container)
        }
    }, [])


    const getLatestSceneData = useRecoilCallback(
        ({ snapshot, }) =>
            () => {
                return snapshot.getLoadable(sceneAtom).contents
            },
        [],
    )

    const handleCreateDebugVisualization = () => {
        console.log("Creating debug visualization")
    }

    // Initialize the AI strategy
    useEffect(() => {
        strategy.init()
    }, [strategy,])


    const createSimplifiedJSON = () => {
        const result = DesignsApi.simplifiedDesignState(JSON.stringify(sceneData), getComponent, connectionTypes, sizes)
        if (!result) { return }
        const { simplifiedDesign, simplifiedInstructions, } = result
        return { simplifiedDesign, simplifiedInstructions, }
    }

    const processInstructions = async (instructions?: string, json?: ParsedResponse) => {
        console.log(instructions, "instructions in processInstructions")
        console.log(json, "json in processInstructions")
        const parsed = json ?? DesignsApi.parseTextToJson(instructions ?? "")

        // Create partNames array if it doesn't exist
        if (parsed.parts && !parsed.partNames) {
            parsed.partNames = Object.values(parsed.parts).map(part => part.name)
        }

        parsedRef.current = parsed
        console.log(parsed, "parsed")

        // Convert parsed parts to the format expected by handleNotFoundParts
        if (parsed.partNames && Array.isArray(parsed.partNames)) {
            setCreatingNewParts(true)
            setFinishedCreatingNewParts(false)
            setStartedAIcreationProcess(true)
            // Use for...of instead of forEach for proper async iteration
            for (let index = 0; index < parsed.partNames.length; index++) {
                const partName = parsed.partNames[index]
                const simplifiedPart = parsed.parts[index + 1]

                // Wait 50ms before processing next part
                await new Promise(resolve => setTimeout(resolve, 50))

                let parts: PartTypeAPI[] = []

                if (simplifiedPart.apiTypeId) {
                    // Find the part in the initial data instead of looking at the API
                    parts = recoilInitialData?.parts?.filter(p => p.id === simplifiedPart.apiTypeId) ?? []
                    parts = parts.map(part => ({ ...part, }))
                } else {
                    parts = await ApiClient.getParts(
                        recoilInitialData?.sizes ?? {},
                        undefined,
                        partName,
                        false
                    )
                }

                const foundPart: PartTypeAPI | undefined = parts[0]
                //const foundPart = await findPartByName(partName)
                console.log(`Part name: ${partName}`, foundPart)
                //const adaptedPart = partAdapter(foundPart, recoilInitialData?.sizes ?? {})
                foundPart.preferedId = crypto.randomUUID()

                const randomPosition = new Vector3(Math.random() * 4, Math.random() * 4, Math.random() * 4)
                const defaultQuaternion = new Quaternion()

                const newPart: any = {
                    part: {
                        ...foundPart,
                    },
                    posAndRot: {
                        inner: {
                            pos: randomPosition,
                            rot: defaultQuaternion,
                        },
                        outer: {
                            pos: randomPosition,
                            rot: defaultQuaternion,
                        },
                    },
                }

                newPart.bulkCreated = true

                const { newPartId, } = await createPart(newPart)

                const matchingPart = Object.entries(parsed.parts).find(([key, part,]) =>
                    part.name === partName && !part.newPartId
                )

                if (!matchingPart) { continue }

                if (matchingPart && newPartId) {
                    const [partKey, partData,] = matchingPart
                    parsed.parts[partKey].newPartId = newPartId
                    parsedRef.current = parsed
                }
            }
        }

        // Final delay after all parts are processed
        await new Promise(resolve => setTimeout(resolve, 100))
    }

    const handleProcessInstructions = async (instructions?: string, json?: ParsedResponse) => {
        await processInstructions(instructions, json)
        setTimeout(() => {
            setFinishedCreatingNewParts(true)
            setCreatingNewParts(false)
        }, 500)
    }

    const handleGenerateCommand = useCallback(async (
        command: string,
        model: string = DEFAULT_MODEL,
        includeScreenshot = false,
        useFakeResponse = false
    ) => {
        if (!command) {
            message.error("Please enter a command")
            return
        }

        const sceneData = getLatestSceneData()

        const result = DesignsApi.simplifiedDesignState(JSON.stringify(sceneData), getComponent, connectionTypes, sizes)
        if (!result) {
            message.error("Failed to get current layout")
            return
        }
        const { simplifiedDesign, simplifiedInstructions, } = result

        let instructionsWithMessage = `User's request: ${command}\n\n`

        if (simplifiedInstructions.length > 0) {
            instructionsWithMessage
                = `${command}\n\nThis is the user's current design and assembly instructions.
            You can add or remove parts. This list is only for your consideration in each case the user's request affects the current parts.
            You will then generate the new design and instructions.
            \nUsers's existing assembly instructions:
            ${JSON.stringify(simplifiedInstructions).replace(/^\[|\]$/g, "")
                    .replace(/"/g, "")}`
        }

        //const currentLayout = createSimplifiedJSON()
        if (!simplifiedInstructions) {
            message.error("Failed to get current layout")
            return
        }

        setIsLoading(true)
        const startTime = performance.now()
        let accumulatedContent = ""



        try {
            if (useFakeResponse) {
                //
            }

            let screenshotUrl: string | undefined
            if (includeScreenshot) {
                try {
                    const screenshot = await getAndUploadScreenshot()
                    screenshotUrl = screenshot
                } catch (error) {
                    console.error("Failed to get screenshot:", error)
                    message.warning("Failed to include screenshot, proceeding without it")
                }
            }

            await strategy.generateResponse(
                instructionsWithMessage,
                instructionsWithMessage,
                {
                    onStart: () => {
                        //clearDebugVisualization()
                    },
                    onToken: (content) => {
                        accumulatedContent += content
                        console.log(accumulatedContent, "before try")

                        try {
                            console.log(accumulatedContent, "accumulatedContent in try")
                        } catch (error) {
                            // Parsing will fail until the JSON is complete, so ignore errors
                        }
                    },
                    onComplete: () => {
                        try {
                            console.log("on complete", accumulatedContent)
                            handleProcessInstructions(accumulatedContent)

                            message.success("Layout updated successfully")
                            // Validate and apply the modifications

                        } catch (error) {
                            console.error("Error in onComplete:", error)
                            console.log("accumulatedContent", accumulatedContent)
                            message.error("Failed to process response")
                        } finally {
                            const endTime = performance.now()
                            setLastRequestTime((endTime - startTime) / 1000)
                            setIsLoading(false)
                        }
                    },
                    onError: () => {
                        message.error("Failed to process command")
                        const endTime = performance.now()
                        setLastRequestTime((endTime - startTime) / 1000)
                        setIsLoading(false)
                    },
                },
                screenshotUrl,
                model
            )
        } catch (error) {
            console.error("Caught error in main try-catch:", error)
            message.error("Failed to process command")
            const endTime = performance.now()
            setLastRequestTime((endTime - startTime) / 1000)
            setIsLoading(false)
        }
    }, [strategy, getAndUploadScreenshot,])



    const buildMarkerRelationshipMap = (markers: any[]) => {
        const relationshipMap = new Map()
        //console.log(markers, "markers in buildMarkerRelationshipMap")
        markers.forEach((marker1, i) => {
            markers.forEach((marker2, j) => {
                if (i !== j) {
                    // Use facing instead of friendlyName
                    //console.log(marker1.facing, marker2.facing, "marker1.facing, marker2.facing")
                    const relationship = getDirectionalRelationship(
                        marker1.facing,
                        marker2.facing
                    )
                    relationshipMap.set(
                        `${marker1.originalName}-${marker2.originalName}`,
                        relationship
                    )
                }
            })
        })
        //console.log(relationshipMap, "relationshipMap")
        return relationshipMap
    }

    const createRotationValuesForNewParts = useCallback(async () => {
        if (!parsedRef.current?.parts) { return }

        setCreatingNewRotationValues(true)

        // Convert Object.entries to array for async iteration
        const entries = Object.entries(parsedRef.current.parts)

        for (const [key, part,] of entries) {
            // Add delay between iterations
            await new Promise(resolve => setTimeout(resolve, 100))

            if (!part.newPartId) {
                console.warn("No newPartId xxx", part)
                continue
            }

            const component = getComponent(part.newPartId)
            if (!component) {
                console.warn("No component found for part xxx", part.newPartId)
                continue
            }


            const matchConnectorToMarker = (
                connector: { name: string, ref?: string, facing?: string, },
                marker: { name: string, id: string, sizeId: string, },
                connectionTypes: ObjDictionary<ConnectionTypeAPI>,
                sizes: ObjDictionary<SizeAPI> | undefined
            ) => {
                // Get connection type and size info
                const connectionType = connectionTypes[marker.id]
                const size = sizes?.[marker.sizeId]

                if (!connectionType || !size || !connector || !connector.name) {
                    console.warn("Missing required data", { connectionType, size, connector, })
                    return false
                }

                // Normalize and split names into words
                const connectorWords = connector.name.toLowerCase().split(/[\s-]+/)
                const connectionTypeWords = connectionType.connectionName.toLowerCase().split(/[\s-]+/)

                //console.log("Matching words:", {
                //    connectorWords,
                //    connectionTypeWords,
                //    connectorName: connector.name,
                //    connectionTypeName: connectionType.connectionName,
                //})

                // Check connection type match - look for any matching gender words
                const isFemale = connectorWords.some(word =>
                    word === "f" || word === "female" || word.includes("fem")
                )
                const isMale = connectorWords.some(word =>
                    word === "m" || word === "male" || word.includes("mal")
                )

                const connectionMatches = (
                    (isFemale && connectionTypeWords.some(word =>
                        word === "female" || word.includes("fem")
                    ))
                    || (isMale && connectionTypeWords.some(word =>
                        word === "male" || word.includes("mal")
                    ))
                )

                // Normalize size for comparison
                const normalizedSize = convertFractionToDecimal(size.friendlyName)

                // Look for size match in any part of the connector name
                const sizeMatches = connectorWords.some(word => {
                    const cleanWord = word.replace(/["\s]/g, "")
                    const sizeVariations = [
                        normalizedSize.toString(),
                        `${normalizedSize}in`,
                        `${normalizedSize}inch`,
                        `${normalizedSize}inches`,
                    ]
                    return sizeVariations.some(variation => cleanWord.includes(variation))
                })

                /*console.log("Match results:", {
                    connectionMatches,
                    sizeMatches,
                    normalizedSize,
                })*/

                return connectionMatches && sizeMatches
            }

            // Usage in createRotationValuesForNewParts:
            parsedRef.current?.parts[key].connectors?.forEach((connector) => {
                const partInfo = component.getPartInfo()
                if (!partInfo) { return }

                // Get marker directions from component - this was for debugging
                //const markerDirections = component.getFacingDirectionOfMarkers()
                //console.log(markerDirections, "markerDirections")
                //const relationshipMap = buildMarkerRelationshipMap(markerDirections)

                const usedMarkerNames = new Set()

                if (part.connectors) {
                    // First pass - try to match each connector with its ideal marker
                    part.connectors.forEach((connector) => {
                        if (!partInfo) { return }

                        if (partInfo.markers) {
                            // Sort markers by matching score to prioritize best matches
                            const markerMatches = partInfo.markers
                                .filter((marker: any) => !usedMarkerNames.has(marker.name))
                                .map((marker: any) => ({
                                    marker,
                                    isMatch: matchConnectorToMarker(connector, marker, connectionTypes, sizes),
                                }))
                                .filter((match: any) => match.isMatch)

                            // Take the first available match
                            const bestMatch = markerMatches[0]
                            if (bestMatch) {
                                //connector.ref = bestMatch.marker.name
                                connector.sizeId = bestMatch.marker.sizeId
                                connector.typeId = bestMatch.marker.id
                                usedMarkerNames.add(bestMatch.marker.name)
                            }
                        } else if (partInfo.marker) {
                            // Regular tube handling
                            const tubeMarkers = component.getAllMarkers(false)
                            const markers = [
                                { name: tubeMarkers[0].name, id: partInfo.marker.id, sizeId: partInfo.marker.sizeId, },
                                { name: tubeMarkers[1].name, id: partInfo.marker.id, sizeId: partInfo.marker.sizeId, },
                            ]

                            // Find first unused matching marker
                            const availableMarker = markers.find(marker =>
                                !usedMarkerNames.has(marker.name)
                                && matchConnectorToMarker(connector, marker, connectionTypes, sizes)
                            )

                            if (availableMarker) {
                                //connector.ref = availableMarker.name
                                connector.sizeId = availableMarker.sizeId
                                connector.typeId = availableMarker.id
                                usedMarkerNames.add(availableMarker.name)
                            }
                        }
                    })
                }

            })



            const getAllRotationCombinations = (use45 = false) => {
                const angles = use45
                    ? [0, 45, 90, 135, 180, 225, 270, 315,] // All possible 45-degree increments
                    : [0, 90, 180, 270,] // All possible 90-degree increments

                const combinations = []

                for (let x = 0; x < angles.length; x++) {
                    for (let y = 0; y < angles.length; y++) {
                        for (let z = 0; z < angles.length; z++) {
                            combinations.push({
                                angles: { x: angles[x], y: angles[y], z: angles[z], },
                            })
                        }
                    }
                }

                return combinations
            }


            if (!part.newPartId) {
                console.warn("No newPartId or connectors", part)
                return
            }

            if (!part.connectors) {
                console.log("No connectors", part)
                return
            }

            //function to see if string include a dash
            const stringIncludesDash = (str: string) => {
                return str.includes("-")
            }

            //function to see if any of the connectors's facing values include a dash which would tell us that it's a 45 degree rotation
            const anyConnectorsFacingIncludeDash = part.connectors.some(connector => stringIncludesDash(connector.facing))

            console.log(anyConnectorsFacingIncludeDash, "anyConnectorsFacingIncludeDash")


            // Get all possible rotations and their marker positions
            const rotationMap = getAllRotationCombinations(anyConnectorsFacingIncludeDash).map(combo => {
                const rotatedRotationFriendlyNames = component.getFacingDirectionOfMarkers(combo.angles)
                //console.log(rotatedRotationFriendlyNames, "rotatedRotationFriendlyNames")
                return {
                    angles: combo.angles,
                    markerPositions: rotatedRotationFriendlyNames,
                }
            })

            // Store all possible rotations for reference
            if (parsedRef.current) {
                //parsedRef.current.parts[key].possibleRotations = rotationMap
                //this was for debugging
            }

            // Find the best rotation by matching connectors
            const connectorsWithFacingValues = part.connectors.filter(c => c.facing)
            let bestRotation = rotationMap[0] // Default to first rotation
            let bestMatchCount = 0

            rotationMap.forEach(rotationOption => {
                let currentMatchCount = 0

                //console.log(connectorsWithPositions, "connectorsWithPositions")

                connectorsWithFacingValues.forEach(connector => {
                    // Helper function to swap inner/outer in a string
                    const swapInnerOuter = (str: string) => {
                        if (!str) { return str }
                        return str.replace(/inner/i, "TEMP")
                            .replace(/outer/i, "inner")
                            .replace(/TEMP/i, "outer")
                    }

                    const markerMatch = rotationOption.markerPositions.find((marker: { originalName: string, facing: string, sizeId: string, typeId: string, }) => {
                        // Skip if either marker originalName or facing is empty
                        if (!marker.originalName || !marker.facing) {
                            return false
                        }

                        // Primary match attempt
                        const primaryMatch = (connector.facing === normalizeDirectionalName(marker.facing))
                            && (connector.sizeId === marker.sizeId)
                            && (connector.typeId === marker.typeId)

                        if (primaryMatch) {
                            return true
                        }

                        // Secondary match attempt with swapped inner/outer
                        if (connector.ref && marker.originalName) {
                            const swappedRef = swapInnerOuter(connector.ref)
                            const secondaryMatch = (connector.facing === normalizeDirectionalName(marker.facing))
                                && (swappedRef === marker.originalName)
                                && (connector.sizeId === marker.sizeId)
                                && (connector.typeId === marker.typeId)

                            return secondaryMatch
                        }

                        return false
                    })

                    if (markerMatch) {
                        currentMatchCount++
                    }
                })


                // Update best rotation if we found more matches
                if (currentMatchCount > bestMatchCount) {
                    bestMatchCount = currentMatchCount
                    bestRotation = rotationOption
                    console.log(`Found better rotation for ${part.name} with ${currentMatchCount} matches:`, {
                        angles: bestRotation.angles,
                        matchedConnectors: bestMatchCount,
                    })
                }
            })

            // Store the winning rotation
            if (parsedRef.current) {
                parsedRef.current.parts[key].rotation = bestRotation.angles
                parsedRef.current.parts[key].markerPositions = bestRotation.markerPositions
                parsedRef.current.parts[key].rotationQuaternion = bestRotation.markerPositions[0].initialMarkerWorldQuat

                // Update connector refs based on marker positions
                if (part.connectors) {
                    part.connectors.forEach(connector => {
                        if (!connector.facing || !connector.sizeId || !connector.typeId) { return }

                        // Find matching marker position
                        const matchingMarker = bestRotation.markerPositions.find((marker: any) =>
                            normalizeDirectionalName(connector.facing) === normalizeDirectionalName(marker.facing)
                            && connector.sizeId === marker.sizeId
                            && connector.typeId === marker.typeId
                        )

                        if (matchingMarker) {
                            connector.ref = matchingMarker.originalName
                            console.log(`Updated connector ref for ${part.name}:`, {
                                connectorName: connector.name,
                                newRef: connector.ref,
                                facing: connector.facing,
                                matchedMarker: matchingMarker,
                            })
                        } else {
                            console.log(`No matching marker found for ${part.name}:`, {
                                connector,
                                markerPositions: bestRotation.markerPositions,
                            })
                        }
                    })
                }

                console.log(`Final rotation for ${part.name} ${part.length ? `with length ${part.length}` : ""}:`, {
                    angles: bestRotation.angles,
                    rotationQuaternion: bestRotation.markerPositions[0].initialMarkerWorldQuat,
                    matchedConnectors: bestMatchCount,
                    totalConnectors: connectorsWithFacingValues.length,
                    updatedConnectors: part.connectors,
                })
            }

        }

        setFinishedCreatingNewRotationValues(true)
        setCreatingNewRotationValues(false)

    }, [connectionTypes, sizes, parsedRef.current,])

    const createReciprocalConnections = useCallback(() => {
        if (!parsedRef.current?.parts) { return }

        // Create a new ref with the same structure as parsedRef
        const reciprocal: ParsedRefType = JSON.parse(JSON.stringify(parsedRef.current))

        if (!reciprocal) { return }

        // Iterate through all parts and their connectors
        Object.entries(reciprocal.parts).forEach(([partId, part,]) => {
            if (!part.connectors) { return }

            part.connectors.forEach(connector => {
                if (!connector.connection) { return }

                const targetPartId = connector.connection.partId
                const targetPart = reciprocal.parts[targetPartId]

                if (!targetPart || !targetPart.connectors) { return }  // Changed continue to return

                // Find the matching connector on the target part

                const targetConnector = targetPart.connectors.find(c =>
                    c.name === connector?.connection?.name
                )

                // If we found the target connector but it doesn't have a connection, add it
                if (targetConnector && !targetConnector.connection) {
                    targetConnector.connection = {
                        partId: partId,
                        name: connector.name,
                    }
                }
            })
        })

        return reciprocal
    }, [parsedRef.current,])



    const createPositionValuesForNewParts = async () => {

        const reciprocalRef = createReciprocalConnections()

        console.log("reciprocalRef", reciprocalRef)

        if (!reciprocalRef) { return }

        console.log("Starting createPositionValuesForNewParts")

        if (!reciprocalRef?.parts) {
            console.warn("No parts found in reciprocalRef")
            return
        }

        setCreatingNewPositionValues(true)

        // 1. Find the first part that has connections
        const firstPartWithConnections = Object.entries(reciprocalRef.parts)
            .find(([_, part,]) =>
                part.connectors?.some(connector => connector.connection)
            )

        if (!firstPartWithConnections) {
            console.warn("No parts with connections found")
            return
        }

        console.log("firstPartWithConnections", firstPartWithConnections)

        const [firstPartKey, firstPart,] = firstPartWithConnections

        if (!firstPart.newPartId) {
            console.warn("No newPartId found for first part", firstPart)
            return
        }

        const firstPartComponent = getComponent(firstPart.newPartId)
        if (!firstPartComponent) {
            console.warn("No component found for first part", firstPart)
            return
        }

        const firstPartInfo = firstPartComponent.getPartInfo()
        const firstPartInitialMarkerName = firstPartInfo?.initialMarkerName || firstPartInfo.originMarkerName
        const firstPartRotation = firstPartInfo?.rotation

        if (!firstPartInitialMarkerName) {
            console.warn("No initialMarkerName found for first part", firstPart)
            return
        }

        // Find the connector with the initialMarkerName
        const initialConnector = firstPart.connectors?.find(
            (connector: any) => connector.ref === firstPartInitialMarkerName
        )

        const initialConnectorPos = initialConnector?.positionXYZ || new Vector3(0, 0, 0)

        if (initialConnectorPos) {
            // Use the positionXYZ from the connector
            const position = new Vector3(
                initialConnectorPos.x,
                initialConnectorPos.y,
                initialConnectorPos.z
            )

            // Update position and maintain current rotation
            await firstPartComponent.updatePositionAndRotation(position, firstPartRotation)
            await new Promise(resolve => setTimeout(resolve, 100))

        } else if (!firstPartInitialMarkerName) {
            console.warn("No initialMarkerName found for first part", firstPart)
            return
        }

        await positionPartAndConnections(firstPartKey, firstPart, reciprocalRef)

        // 3. Iteratively position remaining connected parts
        let hasNewPositions = true
        while (hasNewPositions) {
            hasNewPositions = false

            // Find all positioned parts that have unpositioned connections
            for (const [key, part,] of Object.entries(reciprocalRef.parts)) {
                if (!part.positioned) {
                    part.skippedTimes = (part.skippedTimes || 0) + 1
                    console.log(part.name, part.newPartId, "skipping", part.skippedTimes)
                    console.log("skipping", key, part)
                    continue
                }

                // Check if this part has any connectors to unpositioned parts
                const hasUnpositionedConnections = part.connectors?.some(connector => {
                    if (!connector.connection) { return false }
                    const targetPart = reciprocalRef.parts[connector.connection.partId]
                    return targetPart && !targetPart.positioned
                })

                if (hasUnpositionedConnections) {
                    await positionPartAndConnections(key, part, reciprocalRef)
                    hasNewPositions = true
                }
            }

            // Check if all remaining unpositioned parts have been skipped twice or more
            const remainingParts = Object.entries(reciprocalRef.parts)
                .filter(([_, part,]) => !part.positioned)

            const totalParts = Object.entries(reciprocalRef.parts).length

            const allRemainingPartsSkippedTwice = remainingParts.length > 0
                && remainingParts.every(([_, part,]) => (part.skippedTimes || 0) >= 2)

            if (allRemainingPartsSkippedTwice) {
                console.log("Positioning remaining skipped parts")
                // Position all remaining parts
                for (const [key, part,] of remainingParts) {
                    await positionPartAndConnections(key, part, reciprocalRef)
                }
                break // Exit the while loop
            }
        }

        console.log("Finished processing all parts, reciprocalRef:", reciprocalRef)
        console.log("Finished processing all parts, parsedRef.current:", parsedRef.current)
        setFinishedCreatingNewPositionValues(true)
        setCreatingNewPositionValues(false)
    }

    // Helper function to position a part and its direct connections
    const positionPartAndConnections = async (partKey: string, part: any, reciprocalRef: ParsedRefType) => {
        if (!part.newPartId || !part.connectors || !reciprocalRef || !parsedRef.current) {
            console.warn(`Missing newPartId or connectors for part ${partKey}:`, part)
            return
        }

        const component = getComponent(part.newPartId)
        if (!component) {
            console.warn(`Could not find component for part ${partKey}`)
            return
        }

        component.updateMatrix()

        // Mark this part as positioned
        if (reciprocalRef.parts) {
            reciprocalRef.parts[partKey].positioned = true
            parsedRef.current.parts[partKey].positioned = true
        }

        // Position all connected parts
        for (const connector of part.connectors) {
            if (!connector.connection || !connector.ref) {
                console.log(part.name, connector.name, "skipping", "no connection or ref", part, connector)
                continue
            }

            const targetPart = reciprocalRef.parts[connector.connection.partId]
            if (!targetPart?.newPartId) {
                console.log(part.name, connector.name, "skipping", "no target part or newPartId")
                continue
            }

            const targetComponent = getComponent(targetPart.newPartId)
            if (!targetComponent) {
                console.log(part.name, connector.name, "skipping", "no target component")
                continue
            }


            targetComponent.updateMatrix()

            const sourceMarkers = component.getAllMarkers()
            const targetMarkers = targetComponent.getAllMarkers()

            const sourceMarker = sourceMarkers.find((m: any) => m.name === connector.ref)
            if (!sourceMarker) {
                console.log(part.name, connector.name, "skipping", "no source marker")
                continue
            }

            const targetConnector = targetPart.connectors?.find(c =>
                connector.connection && c.name === connector.connection.name
            )


            if (!targetConnector?.ref) {
                console.log(part.name, connector.name, "skipping", "no target connector or ref")
                continue
            }

            const targetMarker = targetMarkers.find((m: any) => m.name === targetConnector.ref)
            if (!targetMarker) {
                console.log(part.name, connector.name, "skipping", "no target marker")
                continue
            }

            console.log("moving", targetPart.name, targetConnector?.name, "using its marker:", targetMarker.name, "to the position of", sourceMarker.name, part.name, connector.name)


            sourceMarker.updateWorldMatrix(true, true)
            const sourceMarkerWorldPosition = sourceMarker.getWorldPosition(new Vector3())

            targetComponent.setPositionWithMarker(targetMarker, sourceMarkerWorldPosition)

            // Mark target part as positioned
            if (reciprocalRef.parts && parsedRef.current) {
                reciprocalRef.parts[connector.connection.partId].positioned = true
                parsedRef.current.parts[connector.connection.partId].positioned = true
            }

            await new Promise(resolve => setTimeout(resolve, 100))
        }
    }

    useEffect(() => {
        if (finishedCreatingNewPositionValues && parsedRef.current?.parts) {
            setTimeout(() => {
                checkAndCreateConnections()
            }, 500)
        }
    }, [finishedCreatingNewPositionValues,])

    useEffect(() => {
        if (finishedSettingNewRotationValues && parsedRef.current?.parts) {
            createPositionValuesForNewParts()
        }
    }, [finishedSettingNewRotationValues,])

    useEffect(() => {
        //console.log("creatingNewParts", creatingNewParts)
        //console.log("finishedCreatingNewParts", finishedCreatingNewParts)

        if (!creatingNewParts && finishedCreatingNewParts) {
            createRotationValuesForNewParts()
        }
    }, [creatingNewParts, finishedCreatingNewParts,])

    useEffect(() => {
        if (startedAIcreationProcess && !finishedAIcreationProcess) {
            multiSelectContext?.setBlockUI(true)
        }
        if (finishedAIcreationProcess) {
            multiSelectContext?.setBlockUI(false)
        }
    }, [startedAIcreationProcess, finishedAIcreationProcess,])

    const drawLineBetweenPoints = (start: Vector3, end: Vector3, scene: Scene, color: string) => {
        // Create geometry for the line
        const points = []
        points.push(start)
        points.push(end)

        const geometry = new BufferGeometry().setFromPoints(points)

        // Create material for the line
        const material = new LineBasicMaterial({
            color: color,
            linewidth: 2,  // Note: linewidth only works in WebGLRenderer with special extensions
            depthTest: false,
            depthWrite: false,
        })

        // Create the line
        const line = new Line(geometry, material)

        // Add to scene
        scene.add(line)

        // Optional: Return the line if you need to remove it later
        return line
    }

    //a function to traverse through the parts keys and traverse through the connector
    //and for any connectors that have a xyz value, use it and draw points on the scene
    //use a different color for each part
    const drawConnectorPoints = (parts: any, drawLines = false) => {
        // Colors for different parts
        const colors = ["#ff0000", "#00ff00", "#0000ff", "#ffff00", "#ff00ff",]

        // Traverse through parts
        Object.keys(parts).forEach((partKey, partIndex) => {
            const part = parts[partKey]

            // Check if part has connectors
            if (part.connectors && Array.isArray(part.connectors)) {
                const connectorPositions: Vector3[] = []

                part.connectors.forEach((connector: any) => {
                    // Check if connector has positionXYZ
                    if (connector.positionXYZ) {
                        const { x, y, z, } = connector.positionXYZ

                        // Use color based on part index (cycle through colors if more parts than colors)
                        const color = colors[partIndex % colors.length]

                        const partId = part.newPartId || partKey
                        const last4Digits = partId.slice(-4)
                        const formattedLabel = `${last4Digits}-${connector.name}`

                        // Draw point at connector position
                        drawVector3Point(
                            new Vector3(x, y, z), scene,
                            color, 0.01, undefined, true, false, formattedLabel
                        )

                        // Store position for line drawing
                        connectorPositions.push(new Vector3(x, y, z))
                    }
                })

                // Draw lines between connector positions if drawLines is true
                if (drawLines && connectorPositions.length > 1) {
                    console.log("drawing lines", connectorPositions)
                    for (let i = 0; i < connectorPositions.length - 1; i++) {
                        const start = connectorPositions[i]
                        const end = connectorPositions[i + 1]
                        const distance = start.distanceTo(end)
                        console.log("part", partKey, part.name, distance, "distance in mm for ", distance * 100 / 2.54, "in", part.length ? part.length : "unknown")
                        drawLineBetweenPoints(start, end, scene, colors[partIndex % colors.length])
                    }
                }
            }
        })
    }

    //function to create a valid json out of paste text in json format
    const createValidJSON = (text: string) => {
        const cleanedText = text.replace(/'/g, '"')
        const json = JSON.parse(cleanedText)
        return json
    }

    const createValidJSONAndCreateSimplifiedDesignState = (text: string) => {
        const json = createValidJSON(text)
        drawConnectorPoints(json.parts, true)
        console.log(json, "validated json")
        //const result = DesignsApi.simplifiedDesignState(JSON.stringify(sceneData), getComponent, connectionTypes, sizes, json)
        handleProcessInstructions(undefined, json)
    }



    const checkAndCreateConnections = useCallback(() => {
        if (!parsedRef.current?.parts) { return }

        const TOLERANCE = 0.01
        const newConnections: PartConnectionType[] = []

        // Get all parts and their markers
        const partsWithMarkers = Object.entries(parsedRef.current.parts)
            .filter(([_, part,]) => part.newPartId)
            .map(([_, part,]) => {
                const component = getComponent(part.newPartId!)
                if (!component) { return null }
                const markers = component.getAllMarkers(false)
                return { part, markers, component, }
            })
            .filter(Boolean)

        // Compare each part's markers with every other part's markers
        partsWithMarkers.forEach((sourcePart, i) => {
            if (!sourcePart) { return }

            partsWithMarkers.slice(i + 1).forEach(targetPart => {
                if (!targetPart) { return }

                sourcePart.markers.forEach((sourceMarker: any) => {
                    targetPart.markers.forEach((targetMarker: any) => {
                        // Get world positions
                        const sourceWorldPos = new Vector3()
                        const targetWorldPos = new Vector3()
                        sourceMarker.getWorldPosition(sourceWorldPos)
                        targetMarker.getWorldPosition(targetWorldPos)

                        const sourceMarkerDirection = MeshUtils.copyWorldDirection(sourceMarker)
                        const targetMarkerDirection = MeshUtils.copyWorldDirection(targetMarker)

                        // Check distance
                        const distance = sourceWorldPos.distanceTo(targetWorldPos)
                        if (distance <= TOLERANCE) {
                            // Check compatibility
                            const areFacing = areDirectionsFacingEachOther(
                                sourceMarkerDirection,
                                targetMarkerDirection
                            )

                            //console.log(areFacing, "areFacing")

                            if (areFacing) {
                                const areCompatible = isCompatibleWith2Meshes(
                                    sourceMarker as Mesh,
                                    targetMarker as Mesh,
                                    connectionTypes,
                                    true
                                )

                                //console.log(areCompatible, "areCompatible", targetMarker.name, sourceMarker.name)

                                if (areCompatible) {
                                    // Create new connection
                                    //console.log(sourcePart, targetPart, "sourcePart, targetPart")
                                    const connection: PartConnectionType = {
                                        partA: {
                                            partId: sourcePart.part.newPartId!,
                                            markerName: sourceMarker.name,
                                        },
                                        partB: {
                                            partId: targetPart.part.newPartId!,
                                            markerName: targetMarker.name,
                                        },
                                    }

                                    console.log("connection created", sourceMarker.name, targetMarker.name, connection)
                                    newConnections.push(connection)

                                    // Trigger visual feedback
                                    triggerAnimation("snap", targetPart.part.newPartId!, targetMarker.name, "blue")
                                }
                            }
                        }
                        //sourcePart.component.getFacingDirectionOfMarkers(undefined, true, false)
                    })
                })
            })
        })

        // Update connections in scene state
        if (newConnections.length > 0) {
            multipleUpdate([], newConnections, false)
            SoundHelper.playSnap()
            message.success(`Created ${newConnections.length} new connections`)

            setTimeout(() => {
                newConnections.forEach((connection: PartConnectionType) => {
                    const updateInitialMarkerForPart = (partId: string, markerName: string) => {
                        if (markerName === "TOP" || markerName === "BOTTOM") {
                            const component = getComponent(partId)
                            if (component && component.updateInitialMarkerName) {
                                component.updateInitialMarkerName(markerName)
                            }
                        }
                    }

                    updateInitialMarkerForPart(connection.partA.partId, connection.partA.markerName)
                    updateInitialMarkerForPart(connection.partB.partId, connection.partB.markerName)
                })
            }, 200)
        }
        setFinishedAIcreationProcess(true)
        setStartedAIcreationProcess(false)

        return newConnections
    }, [parsedRef.current, connectionTypes,])

    useEffect(() => {
        if (finishedCreatingNewRotationValues && parsedRef.current?.parts) {
            console.log("FINAL step - just apply the values", finishedCreatingNewRotationValues, parsedRef.current?.parts)

            Object.entries(parsedRef.current.parts).forEach(([key, part,]) => {
                if (!part.newPartId) { return }

                const component = getComponent(part.newPartId)
                if (!component) {
                    console.warn("No component found for part", part.newPartId)
                    return
                }

                if (!part.rotationQuaternion) { return }

                //console.log(part.rotationQuaternion, "part.rotationQuaternion")

                const randomPosition = new Vector3(Math.random() * 4, Math.random() * 4, Math.random() * 4)

                const modifiedRotation = {
                    x: part.rotationQuaternion.x,
                    y: part.rotationQuaternion.y,
                    z: part.rotationQuaternion.z,
                    w: part.rotationQuaternion.w,
                }

                component.updatePositionAndRotation(randomPosition, modifiedRotation)
                if (part.length) {
                    const sanitizedLength = extractNumberFromString(part.length)
                    //console.log(sanitizedLength, "sanitizedLength")

                    if (component) {
                        //console.log("updating length", sanitizedLength)
                        component.updateLength(sanitizedLength)
                    }
                }
                setTimeout(() => {
                    //component.getFacingDirectionOfMarkers(undefined, true, false)
                }, 1000)
            })
            setTimeout(() => {
                setFinishedSettingNewRotationValues(true)
            }, 100)
        }

    }, [finishedCreatingNewRotationValues,])

    // Create the context value
    const contextValue = {
        createSimplifiedJSON,
        processInstructions: handleProcessInstructions,
        isLoading,
        createValidJSON,
    }

    return (
        <AIProviderContext.Provider value={contextValue}>
            {children}
            <Html wrapperClass="neutralHTML">
                {/*{container && shouldUseAiPanel && ReactDOM.createPortal(
                    <AIControls
                        isLoading={isLoading}
                        lastRequestTime={lastRequestTime}
                        onGenerateCommand={handleGenerateCommand}
                        onLogDesign={() => console.log("Log design", createSimplifiedJSON())}
                        onCreateDebugVisualization={handleCreateDebugVisualization}
                        getAndUploadScreenshot={getAndUploadScreenshot}
                        createValidJSONAndCreateSimplifiedDesignState={createValidJSONAndCreateSimplifiedDesignState}
                        processInstructions={handleProcessInstructions}
                    />,
                    container
                )}*/}
            </Html>
        </AIProviderContext.Provider>
    )
}