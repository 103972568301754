/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-params */
/* eslint-disable no-continue */
/* eslint-disable no-console */
/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */


import {
    areSameConnection
} from "../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { ModalState, ModalType } from "../../common/providers/modalProvider/modalProvider"
import { createLabelAtPosition, getOppositeTubeMarker } from "./MarkerUtil"
import { GenericPartState, PartTypeEnum, TubeMarkerEnum, XYZW } from "./Types"
import { PartConnectionType } from "../state/scene/types"
import { LineInfo } from "../components/ScalerUILines"
import { Box3Helper, Color, BoxGeometry, Camera, Mesh, Vector3, Raycaster, Scene, Box3, ArrowHelper, Object3D, SphereGeometry, CylinderGeometry, Matrix4, Quaternion, Material, MeshBasicMaterial, MathUtils, BufferGeometry, ConeGeometry } from "three"
import { convertCmToIn, convertInToCm } from "../components/main/DesignScreen/utils/UnitUtils"

export const handleCollisionOnCreation = (props: {
    buildCollider: () => void,
    updateCollider: (removePart?: () => void) => void,
    deleteCollider: () => void,
    partType: PartTypeEnum,
    onRemove: () => void,
    showModal: (props: ModalState) => void,
}) => {
    const part = props.partType === PartTypeEnum.tube ? "Tube" : "Connector"
    props.buildCollider()
    props.updateCollider(() => {
        props.showModal({
            title: `${part} is colliding`,
            subtitle: `The ${part.toLowerCase()} you just placed collides with another part.
                You can either delete it or keep it and fix the colliding parts yourself.`,
            onCancel: props.onRemove,
            cancelText: "Remove part",
            type: ModalType.confirm,
            onConfirm: () => { },
            confirmText: "Keep part",
        })
    })
}

type SegmentCalculationResult = {
    positiveLength: number,
    negativeLength: number,
    adjustedSegmentLength: number,
    scaleFactor: number,
}
export type Box3WithId = {
    partId: string,
    boundingBox: Box3,
}

export function drawVector3Point(
    vector3Point: Vector3,
    scene: Scene,
    color: number | string = 0xffff00,
    size = 0.002,
    duration?: number,
    dontUseHelperName?: boolean,
    wireframe = false,
    specificName?: string,
    dontAddToScene = false,
    geometryType: "sphere" | "cube" | "cone" | "cylinder" = "sphere",
    drawSpriteWithName?: boolean,
) {
    let geometry: BufferGeometry

    switch (geometryType) {
        case "cube":
            geometry = new BoxGeometry(size, size, size)
            break
        case "cone":
            geometry = new ConeGeometry(size * 0.7, size * 2, 8)
            // Rotate cone to point upward
            geometry.rotateX(-Math.PI / 2)
            break
        case "cylinder":
            geometry = new CylinderGeometry(size * 0.7, size * 0.7, size * 2, 8)
            break
        case "sphere":
        default:
            geometry = new SphereGeometry(size, 5, 5)
    }

    geometry.translate(vector3Point.x, vector3Point.y, vector3Point.z)

    const material = new MeshBasicMaterial({
        color,
        wireframe,
        transparent: true,
        opacity: 1,
        depthTest: false,
        depthWrite: false,
    })

    const mesh = new Mesh(geometry, material)
    mesh.name = dontUseHelperName ? "just a little dot" : "helper"
    if (specificName) {
        mesh.name = specificName
    }

    if (!dontAddToScene) {
        scene.add(mesh)
    }

    if (duration) {
        setTimeout(() => {
            scene.remove(mesh)
        }, duration)
    }

    if (drawSpriteWithName) {
        createLabelAtPosition(scene, vector3Point, specificName ?? "", {
            dontAddToScene: false,
            timer: duration,
            color: "white",
            fontSize: "20px",
        })
    }

    return mesh
}

export const convertFromInchToMeter = (value: number) => {
    return value * 2.54 / 100
}
export const getSceneAttachedMarker = (marker: Mesh, scene: Scene): Object3D => {
    if (!marker) { return marker }

    // Check if marker is directly attached to scene
    if (marker.parent === scene) {
        return marker
    }

    // Check if marker's parent is attached to scene
    if (marker.parent && marker.parent.parent === scene) {
        return marker.parent
    }

    // Check if marker's grandparent is attached to scene
    if (marker.parent?.parent && marker.parent.parent.parent === scene) {
        return marker.parent.parent
    }

    // Return original marker as fallback
    return marker
}


export const calculateTubeSegments = (params: {
    value: number,
    unit: "in" | "cm",
    startSegmentLength: number,
    endSegmentLength: number,
    segmentLength: number,
    currentPositiveLength: number,
    currentNegativeLength: number,
    movableSection: "START" | "END",
}): SegmentCalculationResult => {
    const {
        value,
        unit,
        startSegmentLength,
        endSegmentLength,
        segmentLength,
        currentPositiveLength,
        currentNegativeLength,
        movableSection,
    } = params

    // Convert to inches if needed
    let convertedValue = value
    if (unit === "cm") {
        convertedValue = convertCmToIn(value)
    }

    // Calculate segments
    const notRoundedSegmentsCount = (convertedValue - startSegmentLength - endSegmentLength) / segmentLength
    const roundedSegmentsCount = Math.round(notRoundedSegmentsCount)

    // Calculate adjusted segment length and scale factor
    const adjustedSegmentLength = (convertedValue - startSegmentLength - endSegmentLength) / roundedSegmentsCount
    const scaleFactor = adjustedSegmentLength / segmentLength

    // Calculate segment count
    const segmentedCount = Math.max(1, Math.round((convertedValue - startSegmentLength - endSegmentLength) / adjustedSegmentLength))

    // Calculate positive and negative lengths
    let positiveLength = segmentedCount - currentNegativeLength
    let negativeLength = currentNegativeLength

    if (movableSection === "START") {
        positiveLength = currentPositiveLength
        negativeLength = segmentedCount - currentPositiveLength
    }

    // Handle negative length correction
    if (negativeLength < 0 && positiveLength > 0) {
        positiveLength = positiveLength + negativeLength
        negativeLength = 0
    }

    return {
        positiveLength,
        negativeLength,
        adjustedSegmentLength,
        scaleFactor,
    }
}



export const whichBoxesAreAboveOrBelow = (
    sourceBox: Box3,
    otherBoxes: Box3WithId[],
    viewNormal: Vector3,
    debug?: boolean,
    scene?: Scene
): string[] => {
    // Get center of source box in world space
    const center = sourceBox.getCenter(new Vector3())

    // Create up and down directions based on view normal
    const upDirection = new Vector3(0, 1, 0)
    const rightDirection = new Vector3().crossVectors(viewNormal, upDirection)
        .normalize()
    const verticalDirection = new Vector3().crossVectors(rightDirection, viewNormal)
        .normalize()

    // Create raycasts up and down
    const rayUp = new Raycaster()
    const rayDown = new Raycaster()

    // Offset the ray origins slightly to avoid self-intersection
    const upOrigin = center.clone().add(verticalDirection.clone().multiplyScalar(0.01))
    const downOrigin = center.clone().add(verticalDirection.clone().multiplyScalar(-0.01))

    rayUp.set(upOrigin, verticalDirection)
    rayDown.set(downOrigin, verticalDirection.clone().negate())

    // Debug items to clean up later
    const cleanupItems: Object3D[] = []

    // Debug visualization
    if (debug && scene) {
        const upArrow = new ArrowHelper(verticalDirection, upOrigin, 2, "blue", 0.02, 0.02)
        const downArrow = new ArrowHelper(verticalDirection.clone().negate(), downOrigin, 2, "black", 0.02, 0.02)
        const upOriginSphere = new Mesh(new SphereGeometry(0.01, 32, 32), new MeshBasicMaterial({ color: "blue", }))
        upOriginSphere.position.copy(upOrigin)

        cleanupItems.push(upOriginSphere)

        const downOriginSphere = new Mesh(new SphereGeometry(0.01, 32, 32), new MeshBasicMaterial({ color: "black", }))
        downOriginSphere.position.copy(downOrigin)

        cleanupItems.push(downOriginSphere)

        scene.add(upOriginSphere)
        scene.add(downOriginSphere)
        scene.add(upArrow)
        scene.add(downArrow)
        cleanupItems.push(upArrow, downArrow)
    }

    // Track intersected parts
    const verticallyAlignedParts: string[] = []
    const tempMeshes: Mesh[] = []
    const debugHelpers: Box3Helper[] = []

    // Create all meshes first
    otherBoxes.forEach(({ partId, boundingBox, }) => {
        const boxGeometry = new BoxGeometry(
            boundingBox.max.x - boundingBox.min.x,
            boundingBox.max.y - boundingBox.min.y,
            boundingBox.max.z - boundingBox.min.z
        )
        const boxMesh = new Mesh(boxGeometry, new MeshBasicMaterial({ visible: false, }))
        boxMesh.position.copy(boundingBox.getCenter(new Vector3()))

        // Add this line to ensure the mesh's matrix is updated
        boxMesh.updateMatrix()
        boxMesh.updateMatrixWorld(true)

        tempMeshes.push(boxMesh)

        if (debug && scene) {
            const boxHelper = new Box3Helper(boundingBox, new Color(0x00ff00))
            scene.add(boxHelper)
            debugHelpers.push(boxHelper)
            scene.add(boxMesh)
        }
    })

    // Do all raycasting with increased ray length
    otherBoxes.forEach(({ partId, boundingBox, }, index) => {
        const boxMesh = tempMeshes[index]

        // Increase the far parameter of the raycaster to ensure it can reach distant objects
        rayUp.far = 1000
        rayDown.far = 1000

        // Check intersections
        const upIntersects = rayUp.intersectObject(boxMesh, false)
        const downIntersects = rayDown.intersectObject(boxMesh, false)

        if (upIntersects.length > 0 || downIntersects.length > 0) {
            verticallyAlignedParts.push(partId)

            // Debug: Highlight intersected boxes in red
            if (debug && scene) {
                const intersectedBoxHelper = new Box3Helper(boundingBox, new Color(0xff0000))
                scene.add(intersectedBoxHelper)
                debugHelpers.push(intersectedBoxHelper)

                // Add debug arrows to show actual intersection points
                if (upIntersects.length > 0) {
                    const arrowHelper = new ArrowHelper(
                        verticalDirection,
                        upIntersects[0].point,
                        0.5,
                        0xff0000,
                        0.02,
                        0.02
                    )
                    scene.add(arrowHelper)
                    cleanupItems.push(arrowHelper)
                }
                if (downIntersects.length > 0) {
                    const arrowHelper = new ArrowHelper(
                        verticalDirection.clone().negate(),
                        downIntersects[0].point,
                        0.5,
                        0x0000ff,
                        0.02,
                        0.02
                    )
                    scene.add(arrowHelper)
                    cleanupItems.push(arrowHelper)
                }
            }
        }
    })

    if (scene) {
        tempMeshes.forEach(mesh => scene.remove(mesh))
    }

    // Cleanup
    if (debug && scene) {
        setTimeout(() => {
            // Clean up all debug items
            cleanupItems.forEach(item => scene.remove(item))
            debugHelpers.forEach(helper => scene.remove(helper))
        }, 1000)
    }

    return verticallyAlignedParts
}

export const visualizeOrientedBoundingBox = (boundingBoxMesh: Mesh, scene: Scene) => {
    if (!boundingBoxMesh?.userData.getWorldCorners) {
        console.warn("Bounding box mesh missing required methods")
        return
    }

    console.log(boundingBoxMesh, "obb boundingBoxMesh")

    // Visualize corners
    const worldCorners = boundingBoxMesh.userData.getWorldCorners()
    worldCorners?.forEach((corner: Vector3) => {
        drawVector3Point(corner, scene, "red", 0.01, 1000, true, undefined, "worldCorner", undefined, "cube", false)
    })
    console.log(worldCorners, "obb worldCorners")

    // Log dimensions
    if (boundingBoxMesh.userData.getDimensions) {
        const dimensions = boundingBoxMesh.userData.getDimensions("in")
        const dimensionsInCM = boundingBoxMesh.userData.getDimensions("cm")
        console.log(dimensions, "obb dimensions from boundingbox mesh in inches")
        console.log(dimensionsInCM, "obb dimensions from boundingbox mesh in cm")
    }

    // Visualize center
    const worldCenter = boundingBoxMesh.userData.getWorldCenter()
    if (worldCenter) {
        drawVector3Point(worldCenter, scene, "blue", 0.01, 1000, true, undefined, "worldCenter", undefined, "cube")
    }
}

export const getSegTubeLengthInMeters = (
    tubeLength: number,
    tubeNegativeLength: number,
    segmentLength: number,
    endSegmentLength: number,
    startSegmentLength: number,
    segmentScaleFactor: number,
) => {
    return convertInToCm(((tubeLength + tubeNegativeLength) * segmentLength) + (endSegmentLength + startSegmentLength)) * (segmentScaleFactor ?? 1) / 100
}

export const createBoundingBox3FromMergedMesh = (mergedMesh: Mesh) => {
    const boundingBox3 = new Box3()
    boundingBox3.makeEmpty()
    mergedMesh.geometry.computeBoundingBox()
    boundingBox3.expandByObject(mergedMesh as Object3D)
    return boundingBox3

}

// Types for relative positions and spatial relationships




export const createBoxWithCenterSphere = (params: {
    width: number,
    height: number,
    depth: number,
    tubeId: string,
    sphereColor?: string,
    boxColor?: string,
    boxOpacity?: number,
    sphereVisible?: boolean,
    boxVisible?: boolean,
    debug?: boolean,
}) => {
    // Convert cm to three.js units (meters)

    params.debug && console.log("createBoxWithCenterSphere params", params)
    const width = params.width
    const height = params.height
    const depth = params.depth

    // Create box mesh
    const boxGeometry = new BoxGeometry(width, height, depth)
    const boxMaterial = new MeshBasicMaterial({
        color: params.boxColor || 0x00ff00,
        wireframe: true,
        transparent: true,
        opacity: params.boxOpacity || 0.5,
        visible: params.boxVisible || false,
    })
    const boxMesh = new Mesh(boxGeometry, boxMaterial)

    // Calculate and store the 8 corner points
    const halfWidth = width / 2
    const halfHeight = height / 2
    const halfDepth = depth / 2


    boxMesh.userData.center = new Vector3(0, 0, 0)



    // Store corners in local space
    boxMesh.userData.corners = [
        new Vector3(-halfWidth, -halfHeight, -halfDepth),  // bottom left back
        new Vector3(halfWidth, -halfHeight, -halfDepth),   // bottom right back
        new Vector3(-halfWidth, halfHeight, -halfDepth),   // top left back
        new Vector3(halfWidth, halfHeight, -halfDepth),    // top right back
        new Vector3(-halfWidth, -halfHeight, halfDepth),   // bottom left front
        new Vector3(halfWidth, -halfHeight, halfDepth),    // bottom right front
        new Vector3(-halfWidth, halfHeight, halfDepth),    // top left front
        new Vector3(halfWidth, halfHeight, halfDepth),     // top right front
    ]

    boxMesh.userData.getDimensions = (unit: "m" | "cm" | "in") => {
        const worldCorners = boxMesh.userData.getWorldCorners()

        // Calculate base dimensions in meters
        const dimensions = {
            width: worldCorners[1].distanceTo(worldCorners[0]),     // distance between adjacent corners on x-axis
            height: worldCorners[2].distanceTo(worldCorners[0]),    // distance between adjacent corners on y-axis
            depth: worldCorners[4].distanceTo(worldCorners[0]),     // distance between adjacent corners on z-axis
        }

        // Convert to requested unit
        const conversionFactor = unit === "cm" ? 100 : unit === "in" ? 39.3701 : 1
        return {
            width: dimensions.width * conversionFactor,
            height: dimensions.height * conversionFactor,
            depth: dimensions.depth * conversionFactor,
        }
    }


    // Add helper method to get world space corners
    boxMesh.userData.getWorldCorners = () => {
        const corners = boxMesh.userData.corners
        return corners.map((corner: Vector3) => {
            const worldCorner = corner.clone()
            boxMesh.localToWorld(worldCorner)
            return worldCorner
        })
    }



    boxMesh.userData.getWorldCenter = () => {
        const worldCenter = boxMesh.userData.center.clone()
        boxMesh.localToWorld(worldCenter)
        return worldCenter
    }

    // Create center sphere
    const sphereGeometry = new SphereGeometry(0.05, 32, 32)
    const sphereMaterial = new MeshBasicMaterial({
        color: params.sphereColor || "purple",
        depthTest: false,
        transparent: true,
        opacity: 0.1,
        depthWrite: false,
        visible: params.sphereVisible || false,
    })
    const centerSphere = new Mesh(sphereGeometry, sphereMaterial)

    // Attach box to sphere
    centerSphere.attach(boxMesh)

    boxMesh.name = `boxMesh-${params.tubeId}`
    boxMesh.userData.ignoreRaycast = true
    boxMesh.userData.type = "orientedBoundingBox"
    boxMesh.userData.partId = params.tubeId

    centerSphere.name = `centerSphere-${params.tubeId}`
    centerSphere.userData.ignoreRaycast = true
    centerSphere.userData.type = "centerOfOrientedBoundingBox"
    centerSphere.userData.partId = params.tubeId
    return { centerSphere, boxMesh, }
}

export const getPerpendicularToNormalIntersections = (
    scene: Scene,
    meshes: Mesh[],
    normal: Vector3,
    origin: Vector3,
    debug?: boolean,
) => {
    const cylindersForIntersectingMarkers: Mesh[] = []
    const debugObjects: Object3D[] = []

    // Create cylinders for each marker
    meshes.forEach(mesh => {
        const markerWorldPos = new Vector3()
        mesh.getWorldPosition(markerWorldPos)

        const geometry = new CylinderGeometry(0.01, 0.01, 20, 8)
        geometry.rotateX(Math.PI / 2)

        const material = new MeshBasicMaterial({
            color: 0xff0000,
            transparent: true,
            opacity: debug ? 0.05 : 0.1,
        })

        const cylinder = new Mesh(geometry, material)
        cylinder.position.copy(markerWorldPos)
        cylinder.lookAt(markerWorldPos.clone().add(normal.clone().multiplyScalar(-1)))
        cylinder.translateZ(-10)
        cylinder.userData.partId = mesh.userData.partId

        scene.add(cylinder)
        cylindersForIntersectingMarkers.push(cylinder)
    })

    // Create up and down directions based on view normal
    const upDirection = new Vector3(0, 1, 0)
    const rightDirection = new Vector3().crossVectors(normal, upDirection)
        .normalize()
    const verticalDirection = new Vector3().crossVectors(rightDirection, normal)
        .normalize()

    const rayUp = new Raycaster()
    const rayDown = new Raycaster()

    const upOrigin = origin.clone().add(verticalDirection.clone().multiplyScalar(0.01))
    const downOrigin = origin.clone().add(verticalDirection.clone().multiplyScalar(-0.01))

    rayUp.far = 1000
    rayDown.far = 1000

    rayUp.set(origin, verticalDirection)
    rayDown.set(origin, verticalDirection.clone().negate())

    if (debug) {
        // Add debug visualization
        const upArrow = new ArrowHelper(verticalDirection, upOrigin, 2, "blue", 0.02, 0.02)
        const downArrow = new ArrowHelper(verticalDirection.clone().negate(), downOrigin, 2, "red", 0.02, 0.02)

        const originSphere = new Mesh(
            new SphereGeometry(0.02, 32, 32),
            new MeshBasicMaterial({ color: "green", })
        )
        originSphere.position.copy(origin)

        scene.add(upArrow)
        scene.add(downArrow)
        scene.add(originSphere)
        debugObjects.push(upArrow, downArrow, originSphere)
    }

    const intersectsUp = rayUp.intersectObjects(cylindersForIntersectingMarkers, false)
    const intersectsDown = rayDown.intersectObjects(cylindersForIntersectingMarkers, false)

    // Cleanup cylinders

    if (debug) {
        // Clean up debug objects after a delay
        setTimeout(() => {
            console.log("cleaning up debug objects")
            debugObjects.forEach(obj => scene.remove(obj))
            cylindersForIntersectingMarkers.forEach(cylinder => scene.remove(cylinder))

        }, 10000)
    } else {
        // Cleanup cylinders
        cylindersForIntersectingMarkers.forEach(cylinder => scene.remove(cylinder))
    }

    return {
        upIntersections: intersectsUp,
        downIntersections: intersectsDown,
        verticalDirection,
    }
}

export const getConnectedMarkerNames = (
    connections: PartConnectionType[],
    partId: string,
    markerNameIncludes: string
): string[] => {
    const markerNames = connections
        .filter(connection => {
            // Check if partA matches our criteria and has the marker name we want
            const isPartAMatch = connection.partA.partId === partId
                && connection.partA.markerName.includes(markerNameIncludes)

            // Check if partB matches our criteria and has the marker name we want
            const isPartBMatch = connection.partB.partId === partId
                && connection.partB.markerName.includes(markerNameIncludes)

            return isPartAMatch || isPartBMatch
        })
        .map(connection => {
            // Return the marker name from our part's side of the connection
            if (connection.partA.partId === partId) {
                return connection.partA.markerName
            }
            return connection.partB.markerName
        })

    // Remove duplicates using Set and convert back to array
    return Array.from(new Set(markerNames))
}

export const getConnectedPartIds = (
    connections: PartConnectionType[],
    partId: string,
    markerNameIncludes?: string
): string[] => {
    return connections
        .filter(connection => {
            // Check if partA matches our criteria
            const isPartAMatch = connection.partA.partId === partId
                && (!markerNameIncludes || connection.partA.markerName.includes(markerNameIncludes))

            // Check if partB matches our criteria
            const isPartBMatch = connection.partB.partId === partId
                && (!markerNameIncludes || connection.partB.markerName.includes(markerNameIncludes))

            return isPartAMatch || isPartBMatch
        })
        .map(connection => {
            // Return the ID of the other part in the connection
            if (connection.partA.partId === partId) {
                return connection.partB.partId
            }
            return connection.partA.partId
        })
}



export const getAllConnectedPartIdsRecursiveWithNames = (
    connections: PartConnectionType[],
    partId: string,
    markerNames?: string[],
    alreadyVisited = new Set<string>(),
    isFirstLevel = true,
    originalPartId = partId // Add parameter to track the original part
): string[] => {
    // Mark this part as visited to avoid processing it again
    alreadyVisited.add(partId)

    // Get all parts directly connected to this part
    const directlyConnectedParts = connections
        .filter(connection => {
            const isPartA = connection.partA.partId === partId
            const isPartB = connection.partB.partId === partId

            // Skip connections that lead back to the original part
            if (!isFirstLevel && (connection.partA.partId === originalPartId || connection.partB.partId === originalPartId)) {
                return false
            }

            // Only check marker names at the first level
            if (isFirstLevel && markerNames) {
                const markerName = isPartA ? connection.partA.markerName : connection.partB.markerName
                return (isPartA || isPartB) && markerNames.some(name => markerName.includes(name))
            }

            return isPartA || isPartB
        })
        .map(connection =>
        (connection.partA.partId === partId
            ? connection.partB.partId
            : connection.partA.partId)
        )

    // For each connected part, if we haven't visited it yet,
    // recursively get its connections (passing isFirstLevel as false)
    for (const connectedId of directlyConnectedParts) {
        if (!alreadyVisited.has(connectedId)) {
            const moreConnections = getAllConnectedPartIdsRecursiveWithNames(
                connections,
                connectedId,
                markerNames,
                alreadyVisited,
                false, // subsequent recursive calls are not first level
                originalPartId // pass the original part ID through all recursive calls
            )
            directlyConnectedParts.push(...moreConnections)
        }
    }

    return Array.from(new Set(directlyConnectedParts))
}

export const getAllConnectedPartIdsRecursive = (
    connections: PartConnectionType[],
    partId: string,
    alreadyVisited = new Set<string>()
): string[] => {
    // Mark this part as visited to avoid processing it again
    alreadyVisited.add(partId)

    // Get all parts directly connected to this part
    const directlyConnectedParts = connections
        .filter(connection =>
            connection.partA.partId === partId
            || connection.partB.partId === partId
        )
        .map(connection =>
        (connection.partA.partId === partId
            ? connection.partB.partId
            : connection.partA.partId)
        )

    // For each connected part, if we haven't visited it yet,
    // recursively get its connections
    for (const connectedId of directlyConnectedParts) {
        if (!alreadyVisited.has(connectedId)) {
            const moreConnections = getAllConnectedPartIdsRecursive(
                connections,
                connectedId,
                alreadyVisited
            )
            directlyConnectedParts.push(...moreConnections)
        }
    }

    return Array.from(new Set(directlyConnectedParts))
}

export const getPositionInCM = (
    localPos: Vector3,
    totalLengthCM: number,
    sourceMarkerMesh: Mesh,
    camera: Camera
) => {
    // Get camera position relative to the marker mesh
    const cameraPosition = new Vector3()
    camera.getWorldPosition(cameraPosition)
    const markerPosition = new Vector3()
    sourceMarkerMesh.getWorldPosition(markerPosition)

    // Get marker's forward direction in world space
    const markerForward = new Vector3(0, 0, 1)
    markerForward.applyQuaternion(sourceMarkerMesh.quaternion)

    // Get vector from marker to camera
    const toCamera = cameraPosition.clone().sub(markerPosition)

    // Project camera vector onto the plane perpendicular to marker's forward
    toCamera.projectOnPlane(markerForward).normalize()

    // Get marker's right vector in world space
    const markerRight = new Vector3(1, 0, 0)
    markerRight.applyQuaternion(sourceMarkerMesh.quaternion)

    // Invert the flip logic
    const shouldFlip = toCamera.dot(markerRight) > 0  // Changed from < 0 to > 0
    const adjustedLocalX = shouldFlip ? -localPos.x : localPos.x

    console.log("getPositionInCM", {
        cameraPos: cameraPosition,
        markerPos: markerPosition,
        toCamera: toCamera,
        markerRight: markerRight,
        dot: toCamera.dot(markerRight),
        shouldFlip,
        localX: localPos.x,
        adjustedLocalX,
    })

    const halfLength = totalLengthCM / 2

    if (adjustedLocalX >= 0) {
        return halfLength - (adjustedLocalX * 100)
    } else {
        return (Math.abs(adjustedLocalX) * 100) + halfLength
    }
}

export const getPositionInMM = (localX: number, totalLengthMM: number) => {
    // First normalize the x coordinate to 0-1 range
    // Current range is roughly -1 to 1, so we add 1 and divide by 2
    const normalizedPosition = (localX + 1) / 2

    // Multiply by total length to get position in MM
    return normalizedPosition * totalLengthMM
}


export const getOffsetDirection = (line: LineInfo, partId: string) => {
    console.log("getting offset direction", line, partId)
    const markers = Object.values(line.markers)
    console.log("markers", markers)
    const markerInfo = markers.find((m: any) => m.partId === partId)

    if (!markerInfo) {
        console.warn("No marker info found for", partId)
        return "positive" // default fallback
    }

    const sectionType = markerInfo.marker.userData.sectionType

    if (sectionType === "START") {
        console.log("sectionType is START so returning positive")
        return "negative"
    } else {
        console.log("sectionType is not START so returning negative")
        return "positive"
    }


}


export const getConnectedPartsWithMarkers = (
    connections: PartConnectionType[],
    partId: string,
    markerNameIncludes?: string
): { connectedPartId: string, sourceMarker: string, connectedMarker: string, }[] => {
    return connections
        .filter(connection => {
            // Check if partA matches our criteria
            const isPartAMatch = connection.partA.partId === partId
                && (!markerNameIncludes || connection.partA.markerName.includes(markerNameIncludes))

            // Check if partB matches our criteria
            const isPartBMatch = connection.partB.partId === partId
                && (!markerNameIncludes || connection.partB.markerName.includes(markerNameIncludes))

            return isPartAMatch || isPartBMatch
        })
        .map(connection => {
            // If partA is our source part, return partB's info and vice versa
            if (connection.partA.partId === partId) {
                return {
                    connectedPartId: connection.partB.partId,
                    sourceMarker: connection.partA.markerName,
                    connectedMarker: connection.partB.markerName,
                }
            }
            return {
                connectedPartId: connection.partA.partId,
                sourceMarker: connection.partB.markerName,
                connectedMarker: connection.partA.markerName,
            }
        })
}

export const segmentedTubeCreateOBB = (
    masterMarker: Object3D,
    rotation: XYZW,
    scene: Scene,
    positionCenterOfBoundingBox?: Vector3,
) => {
    // Get master marker world position

    //i thought this function was complete but then realized that we are missing a key
    //concept here. which is the a center point that needs to be attached to every part.

    const masterClone = masterMarker.clone(true)
    masterClone.rotation.set(0, 0, 0)
    masterClone.rotateY(MathUtils.degToRad(180))




    let mergedMeshClone: Mesh | undefined
    masterClone.traverse((child) => {
        if (child.name.includes("MergedMesh")) {
            mergedMeshClone = child as Mesh
        }
    })

    if (mergedMeshClone) {
        mergedMeshClone.material = (mergedMeshClone.material as Material).clone()
        mergedMeshClone.geometry = mergedMeshClone.geometry.clone()

        const worldMatrix = new Matrix4()
        mergedMeshClone.updateWorldMatrix(true, false)
        worldMatrix.copy(mergedMeshClone.matrixWorld)

        mergedMeshClone.geometry.applyMatrix4(worldMatrix)

        //mergedMeshClone.position.set(0, 0, 0)
        mergedMeshClone.rotation.set(0, 0, 0)
        masterClone.rotateY(MathUtils.degToRad(180))

        mergedMeshClone.scale.set(1, 1, 1)
        mergedMeshClone.updateMatrix()

        scene.attach(mergedMeshClone)
        mergedMeshClone.updateMatrixWorld(true)

        // Get bounding box and its center when rotation is 0
        const mergedMeshCloneBox = new Box3()
        mergedMeshCloneBox.makeEmpty()
        mergedMeshClone.updateMatrixWorld(true)
        mergedMeshClone.geometry.computeBoundingBox()
        mergedMeshCloneBox.expandByObject(mergedMeshClone)

        // Get center of bounding box
        const boxCenter = new Vector3()
        mergedMeshCloneBox.getCenter(boxCenter)

        const masterCloneWorldPos = new Vector3()
        masterClone.getWorldPosition(masterCloneWorldPos)

        // Calculate offset from master marker to box center
        const offsetFromMarker = new Vector3()
        offsetFromMarker.subVectors(boxCenter, masterCloneWorldPos)

        const helper = new Box3Helper(mergedMeshCloneBox, new Color("blue"))
        helper.name = "mergedMeshCloneBox"
        //helper.position.copy(masterCloneWorldPos).add(offsetFromMarker)
        scene.add(helper)

        const size = new Vector3()
        mergedMeshCloneBox.getSize(size)

        const boxMaterial = new MeshBasicMaterial({
            color: 0x00ff00,
            wireframe: true,
            transparent: true,
            opacity: 0.5,
        })

        const boxGeometry = new BoxGeometry(size.x, size.y, size.z)
        const dimensions = {
            width: size.x,
            height: size.y,
            depth: size.z,
        }
        const orientedBox = new Mesh(boxGeometry, boxMaterial)

        const tubeQuaternion = new Quaternion(rotation.x, rotation.y, rotation.z, rotation.w).normalize()

        scene.add(orientedBox)
        orientedBox.position.copy(masterCloneWorldPos).add(offsetFromMarker)


        orientedBox.quaternion.copy(tubeQuaternion)
        //orientedBox.rotateY(MathUtils.degToRad(180))




        orientedBox.updateMatrixWorld(true)

        //create a new boundingbox around the oriented box
        const orientedBoxBox = new Box3()
        orientedBoxBox.makeEmpty()
        orientedBoxBox.expandByObject(orientedBox)

        const orientedBoxHelper = new Box3Helper(orientedBoxBox, new Color("green"))
        orientedBoxHelper.name = "orientedBoxBox"
        scene.add(orientedBoxHelper)

        const centerPositionOfBoundingBox = new Vector3()
        orientedBoxBox.getCenter(centerPositionOfBoundingBox)

        const sphereForCenterOfOBB = new Mesh(
            new SphereGeometry(0.01, 32, 32),
            new MeshBasicMaterial({ color: "purple", depthTest: false, depthWrite: false, })
        )

        sphereForCenterOfOBB.position.copy(centerPositionOfBoundingBox)
        scene.add(sphereForCenterOfOBB)

        sphereForCenterOfOBB.attach(orientedBox)

        if (positionCenterOfBoundingBox) {
            sphereForCenterOfOBB.position.copy(positionCenterOfBoundingBox)
        }

        // Cleanup
        masterClone.traverse((child) => {
            if (child instanceof Mesh) {
                if (child.geometry) {
                    child.geometry.dispose()
                }
                if (Array.isArray(child.material)) {
                    child.material.forEach(material => material.dispose())
                } else if (child.material) {
                    child.material.dispose()
                }
            }
        })
        masterClone.removeFromParent()
        masterClone.clear()

        return {
            orientedBox,
            dimensions,
            boundingBox: mergedMeshCloneBox,
        }
    }

    return null
}

export type PositionedItem = {
    id: string,
    position: number,
}

export const generateOffsets = (
    currentLength: number,
    growthAmount: number,
    items: PositionedItem[],
    fromStart = true
): { id: string, offset: number, }[] => {
    return items.map(item => {
        // Calculate percentage based on direction
        const positionPercentage = fromStart
            ? item.position / currentLength
            : (currentLength - item.position) / currentLength

        // Multiply that percentage by the growth amount to get the offset
        const offset = positionPercentage * growthAmount / 100

        return {
            id: item.id,
            offset: offset,
        }
    })
}

export const getFreeMarkers = (part: GenericPartState, connectedMarkers: string[]) => {
    if (part.type === PartTypeEnum.connector) {
        return part.markers.filter(m =>
            !connectedMarkers.some(cm => areSameConnection(cm, m.name)))
            .map(c => c.name)
    } else if (connectedMarkers.length === 0) {
        return [TubeMarkerEnum.BOTTOM, TubeMarkerEnum.TOP,]
    } else if (connectedMarkers.length === 1) {
        return [getOppositeTubeMarker(connectedMarkers[0] as TubeMarkerEnum),]
    } else {
        return []
    }
}

export const FIRST_PART_INITIAL_MARKER = "inner1"
