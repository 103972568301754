/* eslint-disable max-statements-per-line */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-params */
/* eslint-disable no-continue */
/* eslint-disable no-console */
/* eslint-disable no-negated-condition */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable complexity */
//eslint-disable-next-line max-lines-per-function

import React, { createContext, useCallback, useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import {
  ArrowHelper,
  Box3,
  Box3Helper,
  Camera,
  Color,
  DoubleSide,
  EdgesGeometry,
  Group,
  IcosahedronGeometry,
  LineBasicMaterial,
  LineSegments,
  Material,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  Quaternion,
  Vector3,
} from "three"
import { CameraControls } from "../cameraProvider/CameraControls"
import { useLevaControls } from "../debugProvider/useLevaControls"
import { sceneAtom, boundingBoxAtom } from "../../state/scene/atoms"
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { isItemSelected, shiftSelectedItemID } from "../../state/atoms"
//import { SelectionBox } from "three/examples/jsm/interactive/SelectionBox"
//import { SelectionHelper } from "three/examples/jsm/interactive/SelectionHelper"
import { useComponentRegistry } from "../../providers/multiselectProvider/useComponentMethods"
import useCamera from "../cameraProvider/useCamera"
import { useMultipleUpdates, useUnsnap } from "../../state/scene/setters"
import { PartConnectionType, SceneType } from "../../state/scene/types"
import { Html, TransformControls } from "@react-three/drei"
import { TransformControls as TransformControlsImpl } from "three-stdlib"
import MultiSelectUI from "./MultiSelectUI"
import TopRightButtons from "./TopRightButtons"
import { selectedPartSelector, undoRedoInProgressSelector } from "../../state/scene/selectors"
import { ROTATION_AXES_ENUM } from "../../components/main/DesignScreen/scene/part/parts/segmentedTube/types/types"
import hotkeys from "hotkeys-js"
import { ConvexHull } from "three/examples/jsm/math/ConvexHull"
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry"
import { MeshUtils, PolygonUtils } from "../../utils/MeshUtils"
import { areDirectionsFacingEachOther, innerToOuter, outerToInner, getRaycastsFromMeshEnds, RaycastResults, visualizePositionsWithArrow, shortenMarkerName } from "../../utils/MarkerUtil"
import { SoundHelper } from "../../components/main/DesignScreen/utils/SoundHelper"
import { isMarkerToMarkerConnected } from "../../state/scene/util"
import { isCompatibleWith2Meshes } from "../../components/main/DesignScreen/scene/part/parts/connector/utils/ConnectorUtils"
import { connectionTypesSelector } from "../../state/initialDataSelectors"
import useGetDebugVariables from "../../components/main/DesignScreen/utils/useGetDebugVariables"
import isMobile from "ismobilejs"
import usePinchZoom from "../../utils/usePinchGestures"
import { messageUtils } from "../../components/main/DesignScreen/scene/LowerRightMessages"
import { useGlobalAnimation } from "../../components/main/DesignScreen/scene/part/parts/utils/animations/GlobalAnimationProvider"
import { GenericPartState } from "../../utils/Types"
import { WritableDraft } from "immer/dist/internal"
import { debounce as debounceLodash } from "lodash"
import NameModal from "../../../common/components/NameModal"
import tinycolor from "tinycolor2"
import { getConnectedMarkerNames, getConnectedPartIds, getConnectedPartsWithMarkers, } from "../../utils/PartUtils"
import ScalerUILines, { DirectionalMarkers, LineInfo, viewForScalerUI } from "../../components/ScalerUILines"
import { drawVector3Point } from "../../utils/PartUtils"


const defaultConfigForScalerUI: ScalerUIConfig = {
  // Angle threshold for view activation (in degrees)
  bufferAngle: 12,

  // Padding around bounding box
  padding: 0.04,

  // Visual styling
  lineThickness: 10,
  activeColor: new Color("#00008B"),    // Dark Blue
  inactiveColor: new Color("#000000"),  // White
  hoverColor: new Color("#009000"),     // Green
  disabledColor: new Color("#4287f5"),  // Gray
  disabledLineThickness: 2,
  scaleFeedbackColor: new Color("#f542a7"), // Pink

  previewLines: {
    color: new Color("#009000"),
    lineWidth: 10,
    opacity: 1,
  },

  // Scale constraints
  constraints: {
    minScale: 0.25,      // Minimum scale factor
    maxScale: 4.0,       // Maximum scale factor
    snapInterval: 0.25,  // Snap to 0.25 increments
  },
}

interface Props {
  cameraControls: React.MutableRefObject<CameraControls | null>;
  designId: string | undefined;
  setSceneCallbacks: any;
  children: React.ReactNode;
  setAutofocusMode: (mode: boolean) => void;
  autofocusMode: boolean;
  sceneCallbacks: any;
}
interface DuplicatedPart {
  sourceId: string;
  duplicateId: string;
  sourceRotation: Quaternion;
  initialMarkerName: string;
  sourceLength: number;
  sourceNegativeLength: number;
  status: boolean;
  partUnits?: string;
  unitRealValue?: number;
  scaledSegmentLength?: number;
  segmentScaleFactor?: number;
}
interface PartInfoAndMarkerData {
  partId: string;
  partPosition: Vector3;
  partRotation: Quaternion;
  markers: { name: string, worldPosition: Vector3, worldQuaternion: Quaternion, }[];
  positionMarker?: string;
  rotationMarker?: string;
}

interface PartDataWithMarkerInfo {
  id: string;
  markers: {
    name: string, worldPosition: Vector3, worldQuaternion: Quaternion,
    markerDirection: Vector3, meshObject: Object3D, camera2dPosition?: { x: number, y: number, },
  }[];
  [key: string]: any;
}

export type ScaleConstraints = {
  minScale: number,
  maxScale: number,
  snapInterval: number,
}

export type ScalerUIConfig = {
  // Angle threshold for view activation (in degrees)
  bufferAngle: number,

  // Padding around bounding box
  padding: number,

  // Visual styling
  lineThickness: number,
  activeColor: Color,
  inactiveColor: Color,
  hoverColor: Color,
  disabledColor: Color,
  disabledLineThickness: number,
  scaleFeedbackColor: Color,

  // Scale constraints
  constraints: ScaleConstraints,

  // Preview lines
  previewLines: {
    color: Color,
    lineWidth: number,
    opacity: number,
  },
}

type xy = { x: number, y: number, }

type DirectlyConnectedMiddles = {
  start: {
    partIds: string[],
    offset: number,
  },
  end: {
    partIds: string[],
    offset: number,
  },
  middle: {
    partIds: string[],
    offset: number,
  },
}

type MarkersInInfo = {
  partId: string | number,
  markerName: string,
  newPosition: Vector3,
  isMovable: boolean,
  cumulativeOffset: number,
  middleConnectedPartIdsAtStart?: string[] | null,
  middleOffsetUsed?: number,
  prevPosition?: Vector3,
  middleConnectedPartIdsAtEnd?: string[] | null,
  offsetPerTube: number,
  connectedAtDepth?: number,
  connectedToId?: string,
  wasConnectedToMovableMarker?: boolean,
  firstParentConnectedToId?: string,
}

type MiddleConnectedInfo = {
  [connectedPartId: string]: {
    connectedToMarker: string,
    position: "start" | "middle" | "end",
    positionInCM: number,
    positionInLocalCoordinateOfConnectingMarker: Vector3,
    percentageOfLength: number,
    middleOffsetToUse?: number,
    middleOffsetUsed?: number,

  },
}

type MarkerOffsetInfo = {
  [partId: string | number]: {
    partLocation: "basePart" | "connectedPart",
    actionsApplied: string[],
    connectionChain: string[],
    connectedAtDepth: number,
    wasConnectedToMovableInChain: boolean,
    sourceMarker: string,
    startEndRaycasts?: RaycastResults,
    middleConnectedInfo?: MiddleConnectedInfo,
    targetMarker: string,
    offsetPerTube?: number,
    directlyConnectedToMiddle?: boolean,
    directlyConnectedMiddles?: DirectlyConnectedMiddles,
    connectedToId?: string,
    connectionChainAfter?: string[],
    indirectlyConnectedToMiddle?: boolean,
    indirectlyConnectedToMiddleViaPartId?: string,
    markers: MarkersInInfo[],
  },
}

export const MultiSelectContext = createContext<
  | {
    selectionMode: boolean,
    // eslint-disable-next-line func-call-spacing
    setSelectionMode: (mode: boolean) => void,
    newPartIds: DuplicatedPart[],
    checkDuplicationStatus: () => "not started" | "running" | "done",
    transformMode: string,
    setTransformMode: (mode: "translate" | "rotate" | "scale" | "off") => void,
    duplicateSelectedParts: (duplicateEverything: boolean, duplicateSpecificPartsIds?: string[]) => void,
    updateCounter: number,
    setUpdateCounter: (value: number) => void,
    setIdsAsHighlightedAndTurnOnControl: (ids: string[], control: "selection" | "translate" | "rotate") => void,
    hideMultiUIControls: boolean,
    setHideMultiUIControls: (hide: boolean) => void,
    resetSelection: () => void,
    onRotationSliderChange: (rotationAxis: ROTATION_AXES_ENUM, value: number) => void,
    blockUI: boolean,
    setBlockUI: (block: boolean) => void,
    updateMultiSelectProviderWithNewMakersInfo: () => void,
  }
  | undefined
>(undefined)


const MultiSelectProvider: React.FC<Props> = ({ children, cameraControls, designId, setSceneCallbacks, sceneCallbacks, setAutofocusMode, autofocusMode, }) => {
  const selectedPart = useRecoilValue(selectedPartSelector)
  const setIsSelected = useSetRecoilState(isItemSelected(selectedPart?.id ?? ""))
  const { scene, gl, } = useThree()

  const [selectionMode, setSelectionMode,] = useState(false)
  const [highlightedPartIdsLength, setHighlightedPartIdsLength,] = useState<number>(0)
  const highlightedPartIds = useRef<string[]>([])
  //const selectionBoxRef = useRef<SelectionBox | null>(null)
  //const selectionHelperRef = useRef<SelectionHelper | null>(null)

  const [selectionBox, setSelectionBox,] = useState<{ start: { x: number, y: number, }, end: { x: number, y: number, } | null, }>({ start: { x: 0, y: 0, }, end: null, })
  const selectionBoxOverlayRef = useRef<HTMLDivElement | null>(null)
  const [selectionBoxIsDragging, setSelectionBoxIsDragging,] = useState(false)

  const dontResetSelectionRef = useRef(false)


  const selectionBoundingBox = useRef<Box3>(new Box3())
  const positionForDuplicatedGroup = useRef<Vector3 | null>(null)
  const offsetForDuplicatedGroup = useRef<Vector3 | null>(null)
  const { isPinching, } = usePinchZoom(document.body)
  const boundingBoxRef = useRef<Box3>(new Box3())
  const multipleUpdate = useMultipleUpdates()
  const sceneData = useRecoilValue(sceneAtom)

  const { showClonedModels, boundingBoxFromHighlightedParts, showCollidingBoundingBoxes, keepClonedModels, view2DPolygon, view2DPoints, storeAndIncrementMiddles, seeRaycastsForEnds, showMarkerPositionForBaseParts, segmentedTubePositionWithMarker, createViewsDebug, middlePositionLogs, } = useLevaControls()


  const newPartIds = useRef<DuplicatedPart[]>([])

  const transformsAnchorRef = useRef<Object3D>(new Object3D())

  const highlightedPartsPositionRotationInfo = useRef<PartInfoAndMarkerData[]>([])

  const highlightedPartsConnections = useRef<PartConnectionType[]>([])

  const [transformMode, setTransformMode,] = useState<"off" | "translate" | "rotate" | "scale">(
    "off",
  )
  const [hideMultiUIControls, setHideMultiUIControls,] = useState(false)
  const transformControlsRef = useRef<TransformControlsImpl>(null)

  const [cameraState, setCameraState,] = useState<"rotate" | "pan" | "neither">("pan")
  const [onTopOfTransformControls, setOnTopOfTransformControls,] = useState(false)
  const [updateCounter, setUpdateCounter,] = useState(0)

  const copiedPartIds = useRef<string[]>([])

  const highlightedPartsMarkerInfo = useRef<PartDataWithMarkerInfo[]>([])
  const notHighlightedPartsMarkerInfo = useRef<PartDataWithMarkerInfo[]>([])
  const boundingBoxesOfNotHighlightedParts = useRef<{ boundingBox: Box3, partId: string, }[]>([])

  const [anchor, setAnchor,] = useState<Object3D | null>(null)

  const closeCompatibleMarkers = useRef<{ clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, distance3D?: number, }[]>([])
  const [anchorChanging, setAnchorChanging,] = useState(false)
  const checkIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const overlayCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null)
  const lastAnchorValue = useRef<Vector3>(new Vector3(0, 0, 0))
  const lastAnchorRotation = useRef<Quaternion>(new Quaternion())

  const [tempAnchorLock, setTempAnchorLock,] = useState(false)
  //const [anchorLockPositionValue, setAnchorLockPositionValue,] = useState<Vector3 | null>(null)
  const selectionBox2D = useRef<{ startPoint: xy, endPoint: xy, }>({ startPoint: { x: 0, y: 0, }, endPoint: { x: 0, y: 0, }, })

  const unsnap = useUnsnap()

  const connectionTypes = useRecoilValue(connectionTypesSelector)
  const { triggerAnimation, } = useGlobalAnimation()

  const { getVariables, } = useGetDebugVariables()
  const isAdmin = getVariables().isAdmin

  const [isCreatingModels, setIsCreatingModels,] = useState(false)
  const lastOffsetAppliedTime = useRef<number>(performance.now())
  const intersectingIds = useRef<string[]>([])
  const pointerStartPosition = useRef<{ x: number, y: number, }>({ x: 0, y: 0, })
  const CLICK_THRESHOLD = 5 // pixels

  const previousParentsToMergedMeshes = useRef<Map<string, { parentUuid: string, partId: string, }>>(new Map())

  const [blockUI, setBlockUI,] = useState(false)
  const undoRedoInProgress = useRecoilValue(undoRedoInProgressSelector)
  const setSceneAtom = useSetRecoilState(sceneAtom)
  const [showGroupNamingModal, setShowGroupNamingModal,] = useState(false)

  const [shiftSelectedItemIDValue, setShiftSelectedItemID,] = useRecoilState(shiftSelectedItemID)

  const [viewsForScalerUI, setViewsForScalerUI,] = useState<viewForScalerUI[] | null>(null)
  const [enableScalerUI, setEnableScalerUI,] = useState(false)
  const [lastViewsFirstPartId, setLastViewsFirstPartId,] = useState<string | null>(null)

  const [userIsScaling, setUserIsScaling,] = useState(false)
  const [resizeMode, setResizeMode,] = useState(false)

  const markerOffsetPositions = useRef<MarkerOffsetInfo>({})
  const [configForScalerUI, setConfigForScalerUI,] = useState<ScalerUIConfig | null>(null)

  //used for ctrl and shift detection
  const modifierKeyRef = useRef(false)

  useEffect(() => {
    // Create selection box element
    const selectionBoxElement = document.createElement("div")
    selectionBoxElement.style.position = "fixed"
    selectionBoxElement.style.border = "1px solid #55aaff"
    selectionBoxElement.style.backgroundColor = "rgba(75, 160, 255, 0.3)"
    selectionBoxElement.style.pointerEvents = "none"
    selectionBoxElement.style.display = "none"
    document.body.appendChild(selectionBoxElement)
    selectionBoxOverlayRef.current = selectionBoxElement

    return () => {
      // Clean up
      if (selectionBoxOverlayRef.current) {
        document.body.removeChild(selectionBoxOverlayRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (selectionBoxOverlayRef.current && selectionBoxIsDragging && selectionBox.start && selectionBox.end) {
      const left = Math.min(selectionBox.start.x, selectionBox.end.x)
      const top = Math.min(selectionBox.start.y, selectionBox.end.y)
      const width = Math.abs(selectionBox.end.x - selectionBox.start.x)
      const height = Math.abs(selectionBox.end.y - selectionBox.start.y)

      selectionBoxOverlayRef.current.style.left = `${left}px`
      selectionBoxOverlayRef.current.style.top = `${top}px`
      selectionBoxOverlayRef.current.style.width = `${width}px`
      selectionBoxOverlayRef.current.style.height = `${height}px`
      selectionBoxOverlayRef.current.style.display = "block"


    } else if (selectionBoxOverlayRef.current) {
      selectionBoxOverlayRef.current.style.display = "none"
    }
  }, [selectionBox.start.x, selectionBox.start.y, selectionBox.end?.x, selectionBox.end?.y, selectionBoxIsDragging,])

  useEffect(() => {
    if (isAdmin) {
      overlayCanvasRef.current = createOverlayCanvas()
      ctxRef.current = overlayCanvasRef.current.getContext("2d")

      return () => {
        if (overlayCanvasRef.current) {
          //console.log("removing overlay canvas")
          document.body.removeChild(overlayCanvasRef.current)
        }
      }
    }
  }, [])

  const requestidleCallbackWithFallback = (callback: () => void, timeout: number) => {
    if (typeof window !== "undefined" && "requestIdleCallback" in window) {
      (window as any).requestIdleCallback(callback, { timeout, })
    } else {
      setTimeout(callback, timeout)
    }
  }

  //canvas debugging methods
  const clearCanvas = () => {
    if (ctxRef.current && overlayCanvasRef.current) {
      ctxRef.current.clearRect(0, 0, overlayCanvasRef.current.width, overlayCanvasRef.current.height)
    }
  }
  const createOverlayCanvas = () => {
    const canvasId = '"multiselect"-overlay-canvas'

    const existingCanvas = document.getElementById(canvasId)
    if (existingCanvas) {
      //existingCanvas.remove()
      //console.log("existing canvas", existingCanvas)
    }
    const canvas = document.createElement("canvas",)
    canvas.id = canvasId
    canvas.style.position = "absolute"
    canvas.style.top = "0"
    canvas.style.left = "0"
    canvas.style.backgroundColor = "rgba(0, 0, 0, 0.0)"
    canvas.style.pointerEvents = "none"
    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    canvas.style.zIndex = "1000" // Ensure it's on top of other elements
    document.body.appendChild(canvas)
    //console.log("Overlay canvas created and added to the DOM")
    return canvas
  }

  const drawPoint = (ctx: CanvasRenderingContext2D, x: number, y: number, radius = 5, color = "red") => {
    //console.log("Drawing point:", { x, y, radius, color, })
    if (ctxRef.current && overlayCanvasRef.current) {
      ctxRef.current.beginPath()
      ctxRef.current.arc(x, y, radius, 0, 2 * Math.PI, false)
      ctxRef.current.globalAlpha = 0.40
      ctxRef.current.fillStyle = color
      ctxRef.current.fill()
    } else {
      console.warn("ctxRef.current or overlayCanvasRef.current is null or undefined")
    }

  }

  const drawRectangle = (x: number, y: number, width: number, height: number, color = "red") => {
    if (ctxRef.current) {
      ctxRef.current.beginPath()
      ctxRef.current.rect(x, y, width, height)
      ctxRef.current.fillStyle = color
      ctxRef.current.fill()
    }
  }

  const checkDuplicationStatus = () => {
    if (newPartIds.current.length === 0 || newPartIds.current.length === undefined) {
      return "not started"
    }
    const allCompleted = newPartIds.current.every(
      (part) =>
        part.status,
    )

    if (allCompleted) {
      return "done"
    }

    const anyStarted = newPartIds.current.some(
      (part) =>
        part.status,
    )
    return anyStarted ? "running" : "not started"
  }

  const getLatestSceneData = useRecoilCallback(
    ({ snapshot, }) =>
      () => {
        return snapshot.getLoadable(sceneAtom).contents
      },
    [],
  )

  const getLatestBoundingBox = useRecoilCallback(
    ({ snapshot, }) =>
      () => {
        return snapshot.getLoadable(boundingBoxAtom).contents
      },
    [],
  )

  boundingBoxRef.current = getLatestBoundingBox().box

  const { disableCamera, enableCamera, cameraPanZoomRotateStates, findEmptyPositionForBox, drawVector3Point, fitBox, fitBoxClassic, } = useCamera()

  const { getComponent, } = useComponentRegistry()

  const isInArea = (event: MouseEvent) => {
    const targetArea = {
      x: 0,
      y: window.innerHeight - 150,
      width: 150,
      height: 150,
    }
    const withinXRange
      = event.clientX >= targetArea.x && event.clientX <= targetArea.x + targetArea.width
    const withinYRange
      = event.clientY >= targetArea.y && event.clientY <= targetArea.y + targetArea.height

    return withinXRange && withinYRange
  }

  const updateHighlightedPartIdsLengthFromReftoState = () => {
    setHighlightedPartIdsLength(highlightedPartIds.current.length)
  }

  const cam = cameraControls.current

  useEffect(() => {
    //console.log(selectionMode, "selectionMode")
    //console.log(transformMode, "transformMode")
    if (cam && selectionMode) {
      /*selectionBoxRef.current = new SelectionBox(cam.camera, scene)
      selectionHelperRef.current = new SelectionHelper(
        gl,
        "custom-select-box",
      )*/
      messageUtils.custom("Switching to Selection Mode...", {
        duration: 1,
      })
      setTimeout(() => {
        messageUtils.custom("Click and drag to select parts", {
          duration: 3,
        })
      }, 1200)
      setTimeout(() => {
        messageUtils.custom("You can rotate, move, duplicate parts freely in this mode", {
          duration: 3,
        })
      }, 4400)

    }
    if (cam && !selectionMode && transformMode === "off") {
      // reset camera state when going back to hand mode
      setCameraState("pan")
    }
  }, [selectionMode,])

  /*const removeHiddenClassFromSelectionHelper = () => {
    if (selectionHelperRef.current && selectionHelperRef.current.element.classList.contains("hidden")) {
      selectionHelperRef.current.enabled = true
      selectionHelperRef.current.element.classList.remove("hidden")
    }
  }

  const addHiddenClassToSelectionHelper = () => {
    if (selectionHelperRef.current && !selectionHelperRef.current.element.classList.contains("hidden")) {
      selectionHelperRef.current.enabled = false
      selectionHelperRef.current.element.classList.add("hidden")
    }
  }*/

  useEffect(() => {
    if (cam) {
      cameraPanZoomRotateStates(cameraState, true, cam,)
    }
  }, [cameraState, cam,])

  const createSelectionBoundingBoxHelper = (removePrevious = false, color = "green") => {
    // Remove any existing selection bounding box helper
    if (removePrevious) {
      scene.children = scene.children.filter(child => child.name !== "selectionBoundingBoxHelper")
    }

    if (!selectionBoundingBox.current.isEmpty()) {
      const helper = new Box3Helper(selectionBoundingBox.current, new Color(color)) // Green color
      helper.name = "selectionBoundingBoxHelper"
      const material = helper.material as Material
      material.transparent = true
      material.opacity = 0.5
      material.depthTest = false
      scene.add(helper)
    }
  }

  //managing all things related to mouse and camera... camera and mouse states and selection box
  useEffect(() => {
    const handlePointerDown = (event: PointerEvent) => {

      const isMiddleMousePressed = event.buttons === 4 // 4 represents middle mouse button
      const isRightMousePressed = event.buttons === 2



      //this ref needst to be updated before because you can have a single click in pan mode and that resets the selection
      //without this update, that won't work
      pointerStartPosition.current = { x: event.clientX, y: event.clientY, }


      if (!selectionMode || isMiddleMousePressed || isRightMousePressed) {
        return
      }

      if (!isInArea(event) && cam && selectionMode) {
        //disableCamera(cameraControls.current || undefined)
      }


      /*selectionBoxRef.current.startPoint.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
        0.5,
      )*/

      selectionBox2D.current.startPoint.x = event.clientX
      selectionBox2D.current.startPoint.y = event.clientY



      if (!selectionBoxIsDragging && !isPointInExcludedArea(event.clientX, event.clientY)) {
        setSelectionBox({ start: { x: event.clientX, y: event.clientY, }, end: { x: event.clientX, y: event.clientY, }, },)
        setSelectionBoxIsDragging(true)
      }

    }



    const handlePointerMove = (event: PointerEvent) => {

      const cam = cameraControls.current

      //console.log(transformControlsRef, "transformcontrols ref")

      const isMiddleMousePressed = event.buttons === 4 // 4 represents middle mouse button
      const isRightMousePressed = event.buttons === 2

      //isInArea is used for the rotation control in lower left
      if ((isInArea(event) || isMiddleMousePressed || isRightMousePressed) && cam) {
        setCameraState("rotate")
        //addHiddenClassToSelectionHelper()
        document.body.style.cursor = "default"
      }


      // Not in the rotation control area
      if (!isInArea(event) && !isMiddleMousePressed && !isRightMousePressed && cam) {
        //console.log(selectionMode, "selectionMode")
        //console.log(transformMode, "transformMode")
        //console.log(onTopOfTransformControls, "onTopOfTransformControls")
        if (selectionMode) {
          //removeHiddenClassFromSelectionHelper()
          document.body.style.cursor = "default"
        }
        if (transformMode !== "off") {
          //addHiddenClassToSelectionHelper()
          document.body.style.cursor = "grab"
        }
        if (selectionMode) {
          //console.log("setting camera to neither", "transformMode:", transformMode, "selectionMode:", selectionMode, "onTopOfTransformControls:", onTopOfTransformControls)
          setCameraState("neither")
        }
        if (transformMode !== "off") {
          //console.log("setting camera to pan", "transformMode:", transformMode, "selectionMode:", selectionMode, "onTopOfTransformControls:", onTopOfTransformControls)
          setCameraState("pan")
        }
        if (!selectionMode && transformMode === "off") {
          //console.log("setting camera to pan", "transformMode:", transformMode, "selectionMode:", selectionMode, "onTopOfTransformControls:", onTopOfTransformControls)
          //addHiddenClassToSelectionHelper()
          setCameraState("pan")
        }
      }

      /*if (selectionBoxRef.current && selectionHelperRef.current && selectionHelperRef.current.isDown) {
        selectionBoxRef.current.endPoint.set(
          (event.clientX / window.innerWidth) * 2 - 1,
          -(event.clientY / window.innerHeight) * 2 + 1,
          0.5,
        )
      }*/
      if (selectionMode && selectionBoxIsDragging && !userIsScaling && !isMiddleMousePressed && !isRightMousePressed) {
        setSelectionBox(prev => ({ ...prev, end: { x: event.clientX, y: event.clientY, }, }))
      }
    }

    const getPartIdsWithinSelectionBox = (startPoint2D: xy, endpoint2D: xy,) => {
      const uniquePartIds = new Set()

      const isWithinBox = (point: xy, start: xy, end: xy,) => {
        if (!point?.x || !point?.y) {
          return false
        }
        // Calculate the corners of the box
        const left = Math.min(start.x, end.x)
        const right = Math.max(start.x, end.x)
        const top = Math.min(start.y, end.y)
        const bottom = Math.max(start.y, end.y)

        // Check if the point is within the box
        return point.x >= left && point.x <= right && point.y >= top && point.y <= bottom
      }

      const checkMarkers = (parts: PartDataWithMarkerInfo[]) => {
        //console.log(parts, "parts inside check markers")
        parts.forEach(part => {
          part.markers.forEach(marker => {
            //console.log(marker.camera2dPosition, "marker.camera2dPosition")
            if (isWithinBox(marker.camera2dPosition as xy, startPoint2D, endpoint2D)) {
              uniquePartIds.add(part.id)
            }
          })
        })
      }

      checkMarkers(notHighlightedPartsMarkerInfo.current)
      checkMarkers(highlightedPartsMarkerInfo.current)

      return Array.from(uniquePartIds)
    }

    const getPartIdsWithinSelectionBoxPolygon = (startPoint2D: xy, endpoint2D: xy) => {
      const uniquePartIds = new Set()

      // Calculate the corners of the box
      const left = Math.min(startPoint2D.x, endpoint2D.x)
      const right = Math.max(startPoint2D.x, endpoint2D.x)
      const top = Math.min(startPoint2D.y, endpoint2D.y)
      const bottom = Math.max(startPoint2D.y, endpoint2D.y)

      // Create selection box polygon
      const selectionPolygon = {
        center: {
          x: (left + right) / 2,
          y: (top + bottom) / 2,
        },
        vertices: [
          { x: left, y: top, },     // Top-left
          { x: right, y: top, },    // Top-right
          { x: right, y: bottom, }, // Bottom-right
          { x: left, y: bottom, },  // Bottom-left
        ],
      }

      const checkPartPolygon = (parts: PartDataWithMarkerInfo[]) => {
        parts.forEach(part => {
          if (part.twodPolygon) {
            // Check if polygons overlap using SAT (Separating Axis Theorem)
            if (PolygonUtils.polygonsOverlap(selectionPolygon, part.twodPolygon)) {
              uniquePartIds.add(part.id)
            }
          }
        })
      }

      checkPartPolygon(notHighlightedPartsMarkerInfo.current)
      checkPartPolygon(highlightedPartsMarkerInfo.current)

      return Array.from(uniquePartIds)
    }

    const isPointInExcludedArea = (x: number, y: number, debug = false): boolean => {
      const windowWidth = window.innerWidth
      const windowHeight = window.innerHeight

      if (debug) {
        console.log(windowWidth, "windowWidth")
        console.log(windowHeight, "windowHeight")
        console.log(x, "x")
        console.log(y, "y")
      }

      // Top area
      if (y <= 57) {
        return true
      }

      if (debug) {
        drawRectangle(0, 0, windowWidth, 57, "red")
        console.log("isPointInExcludedArea", x, y)
      }

      // Bottom left area
      if (x <= 170 && y >= windowHeight - 230) {
        return true
      }

      //draw debug for top right buttons
      if (debug) {
        drawRectangle(windowWidth - 170, 57, 170, 60, "red")
      }

      //Top right area
      if (x >= windowWidth - 170 && y >= 60 && y <= 120) {
        return true
      }

      if (debug) {
        drawRectangle(0, windowHeight - 230, 170, 230, "blue")
      }

      // Bottom center area
      const bottomCenterLeft = (windowWidth - 380) / 2
      const bottomCenterRight = bottomCenterLeft + 380
      let heightVar = 80

      // Check for actions-container and adjust heightVar
      const actionsContainer = document.querySelector("#actions-container")

      //console.log(actionsContainer, "actionsContainer")
      if (isMobile(window.navigator).phone) {
        heightVar = 120
      }
      if (actionsContainer && actionsContainer.children.length > 0) {
        heightVar = 150
      }

      if (x >= bottomCenterLeft && x <= bottomCenterRight && y >= windowHeight - heightVar) {
        return true
      }

      if (debug) {
        drawRectangle(bottomCenterLeft, windowHeight - heightVar, 380, heightVar, "green")
      }

      return false
    }

    const handlePointerUp = (event: PointerEvent) => {

      const dx = Math.abs(event.clientX - pointerStartPosition.current.x)
      const dy = Math.abs(event.clientY - pointerStartPosition.current.y)
      const isClick = dx <= CLICK_THRESHOLD && dy <= CLICK_THRESHOLD

      const isMiddleMousePressed = event.button === 1 // 1 represents middle mouse button but have to use button bc it tells you which button triggered the event
      const isRightMousePressed = event.button === 2

      if (!onTopOfTransformControls && !isPointInExcludedArea(event.clientX, event.clientY) && !anchorChanging && !isMiddleMousePressed && !isRightMousePressed) {
        if (isClick) {
          //console.log("resetSelection in handlePointerUp")
          //this handles only resetting when not doing a hold and drag
          //when on move move
          resetSelection()
        } else {
          //console.log("Drag detected, not resetting selection")
        }
      }

      if (!selectionMode || isInArea(event) || isMiddleMousePressed || isRightMousePressed) {
        return
      }

      if (!isInArea(event) && cam && selectionMode) {
        //enableCamera(cameraControls.current || undefined)
      }
      setSelectionBoxIsDragging(false)
      setSelectionBox(prev => ({ ...prev, end: { x: event.clientX, y: event.clientY, }, }))

      /*selectionBoxRef.current.endPoint.set(
        (event.clientX / window.innerWidth) * 2 - 1,
        -(event.clientY / window.innerHeight) * 2 + 1,
        0.5,
      )*/
      selectionBox2D.current.endPoint.x = event.clientX
      selectionBox2D.current.endPoint.y = event.clientY

      const singleClickCheck = () => {
        const xDistance = Math.abs(selectionBox2D.current.startPoint.x - selectionBox2D.current.endPoint.x)
        const yDistance = Math.abs(selectionBox2D.current.startPoint.y - selectionBox2D.current.endPoint.y)
        return xDistance < 1 && yDistance < 1
      }

      const isSingleClick = singleClickCheck()

      //const partIds = getPartIdsWithinSelectionBox(selectionBox2D.current.startPoint, selectionBox2D.current.endPoint)
      const partIdsPolygon = getPartIdsWithinSelectionBoxPolygon(selectionBox2D.current.startPoint, selectionBox2D.current.endPoint)
      //console.log(partIds, "partIds from new funk")
      //console.log(partIdsPolygon, "partIdsPolygon from new check")
      if (isSingleClick) {
        // Check if the click is outside excluded areas

        //console.log(isSingleClick, "isSingleClick")

        //this handles the resseting in selection mode

        if (!isPointInExcludedArea(event.clientX, event.clientY,)) {
          resetSelection()
        }
      } else if (partIdsPolygon.length > 0) {
        updateSelection(undefined, partIdsPolygon as string[], undefined, true)
      }
    }

    document.addEventListener("pointerdown", handlePointerDown)
    document.addEventListener("pointermove", handlePointerMove)
    document.addEventListener("pointerup", handlePointerUp)

    return () => {
      document.removeEventListener("pointerdown", handlePointerDown)
      document.removeEventListener("pointermove", handlePointerMove)
      document.removeEventListener("pointerup", handlePointerUp)
    }
  }, [selectionMode, transformMode, onTopOfTransformControls, selectionBoxIsDragging, transformControlsRef.current, anchorChanging,])


  //user could have been selecting something and we want to turn that off to prevent any strange behavior
  useEffect(() => {
    if (undoRedoInProgress) {
      setTransformMode("off")
      resetSelection()
      setSelectionMode(false)
    }
  }, [undoRedoInProgress,])
  useEffect(() => {
    //console.log(shiftSelectedItemIDValue, "shiftSelectedItemIDValue in multiselectprovider")
    let shiftSelectedItemInCurrentHighlightedParts = false
    //check to see if shiftSelectedItemIDValue is in highlightedPartIds.current
    //if it exists, user is trying to remove it from highlightedParts
    if (shiftSelectedItemIDValue) {
      shiftSelectedItemInCurrentHighlightedParts = highlightedPartIds.current.includes(shiftSelectedItemIDValue)
    }
    //if its already in highlighted parts, user is trying to remove it from highlightedParts
    if (shiftSelectedItemInCurrentHighlightedParts && shiftSelectedItemIDValue) {
      updateSelection(undefined, [], undefined, true, [shiftSelectedItemIDValue,])
    } else if (!shiftSelectedItemInCurrentHighlightedParts && shiftSelectedItemIDValue) {
      //console.log("user trying to add", shiftSelectedItemIDValue, "to highlighted parts")
      updateSelection(undefined, [shiftSelectedItemIDValue,], false, true)
    }
  }, [shiftSelectedItemIDValue,])

  const getOffsetFromFirstTube = () => {
    // Step 1: Retrieve the position values of the first tube using highlightedPartIds[0]

    const firstTubeId = highlightedPartIds.current[0]
    const firstTube = getComponent(firstTubeId)

    //console.log(firstTubeId, "getOffsetFromFirstTube firstTubeId")
    //console.log(firstTube, "getOffsetFromFirstTube firstTube")

    if (firstTubeId && firstTube) {
      const tubeInfo = firstTube.getPartInfo()
      //console.log(tubeInfo, "getOffsetFromFirstTube tubeinfo")
      const tubePosition = new Vector3(
        tubeInfo.position.x,
        tubeInfo.position.y,
        tubeInfo.position.z,
      )

      // Step 2: Calculate the world position of the center of the selectionBoundingBox
      const boundingBoxCenter = new Vector3()
      selectionBoundingBox.current.getCenter(boundingBoxCenter)

      //drawVector3Point(boundingBoxCenter, scene, 0xff0000, 0.005)
      //drawVector3Point(tubePosition, scene, 0xffff00, 0.005)

      // Step 3: Compute the offset values (x, y, z) between the two positions
      const offset = new Vector3(
        boundingBoxCenter.x - tubePosition.x,
        boundingBoxCenter.y - tubePosition.y,
        boundingBoxCenter.z - tubePosition.z,
      )

      // Step 4: Return these offset values as a Vector3
      return { offset, firstTubeId, tubePosition, }
    }
  }

  const updateSelectionBoundingBoxFromHighlightedPartIds = (debug = false) => {
    const containerBox = new Box3()
    highlightedPartIds.current.forEach((partId) => {
      const component = getComponent(partId)
      if (component) {
        const partBoundingBox = component.getBoundingBox()
        if (partBoundingBox !== undefined) {
          containerBox.union(partBoundingBox)
        }
        if (debug) {
          const helper = new Box3Helper(partBoundingBox, new Color(0xff0000)) // Red color
          helper.name = `debug_bounding_box_${partId}`
            ; (helper.material as Material).transparent = true
            ; (helper.material as Material).opacity = 0.5
          scene.add(helper)
        }
      }
    })
    selectionBoundingBox.current = containerBox

    if (containerBox.isEmpty() === false && debug) {
      const helper = new Box3Helper(containerBox, new Color("red"))
        ; (helper.material as Material).transparent = true
        ; (helper.material as Material).opacity = 0.2
      scene.add(helper)
    }
  }

  const selectAndSortMeshes = (meshes: Mesh[], limitMarkersPerModel?: number, forceIncludeMarkers?: string[]): Mesh[] => {
    return meshes
  }

  useEffect(() => {

    // Create unique scope for these bindings
    const SCOPE = "multiselect-provider"


    // Bind with scope
    if (selectionMode) {
      hotkeys.setScope(SCOPE)
      hotkeys("command+a,ctrl+a", { scope: SCOPE, }, (event, handler) => {
        event.preventDefault()
        highlightAllParts()
        setSelectionMode(true)
      })

      hotkeys("delete,backspace", { scope: SCOPE, }, (event, handler) => {
        event.preventDefault()
        if (highlightedPartIds.current.length > 0) {
          deleteSelectedParts()
        }
      })

      hotkeys("command+c,ctrl+c", { scope: SCOPE, }, (event, handler) => {
        event.preventDefault()
        copiedPartIds.current = highlightedPartIds.current
      })

      hotkeys("command+v,ctrl+v", { scope: SCOPE, }, (event, handler) => {
        event.preventDefault()
        if (copiedPartIds.current.length > 0) {
          duplicateSelectedParts(false, copiedPartIds.current)
        }
      })
    }
    else {
      hotkeys.deleteScope(SCOPE)
    }

    // Cleanup only unbinds this component's hotkeys
    return () => {
      hotkeys.deleteScope(SCOPE)
    }
  }, [selectionMode,])

  function getMiddleMeshes(allMeshes: Mesh[]): Record<string, Mesh> {
    const meshGroups: Record<string, Mesh[]> = {}

    // Group meshes by their prefix
    allMeshes.forEach(mesh => {
      const [prefix, number,] = mesh.name.split("_")
      if (!meshGroups[prefix]) {
        meshGroups[prefix] = []
      }
      meshGroups[prefix].push(mesh)
    })

    const middleMeshes: Record<string, Mesh> = {}

    // Find the middle mesh for each group with multiple occurrences
    Object.keys(meshGroups).forEach(prefix => {
      const group = meshGroups[prefix]
      if (group.length > 1) {
        // Sort the group by the number after the underscore
        group.sort((a, b) => {
          const numA = parseInt(a.name.split("_")[1], 10)
          const numB = parseInt(b.name.split("_")[1], 10)
          return numA - numB
        })

        // Find the middle index
        const middleIndex = Math.floor(group.length / 2)
        middleMeshes[prefix] = group[middleIndex]
      }
    })

    return middleMeshes
  }


  const createConnectionsPerIdMap = (connections: PartConnectionType[]): Record<string, { partId: string, markers: string[], }> => {
    return connections.reduce((acc: Record<string, { partId: string, markers: string[], }>, connection: PartConnectionType) => {
      const { partA, partB, } = connection

      const updateAcc = (part: { partId: string, markerName: string, }) => {
        if (!acc[part.partId]) {
          acc[part.partId] = { partId: part.partId, markers: [], }
        }
        acc[part.partId].markers.push(part.markerName)
      }

      updateAcc(partA)
      updateAcc(partB)

      return acc
    }, {})
  }

  const findMatchingConnections = (newConnections: PartConnectionType[]): PartConnectionType[] => {
    const currentConnections = getLatestSceneData().connections
    const matchingConnections: PartConnectionType[] = []

    currentConnections.forEach((currentConn: PartConnectionType) => {
      newConnections.forEach((newConn: PartConnectionType) => {
        const isMatch = (
          (currentConn.partA.partId === newConn.partA.partId && currentConn.partB.partId === newConn.partB.partId)
          || (currentConn.partA.partId === newConn.partB.partId && currentConn.partB.partId === newConn.partA.partId)
        )

        if (isMatch && !matchingConnections.some(conn =>
          (conn.partA.partId === currentConn.partA.partId && conn.partB.partId === currentConn.partB.partId)
          || (conn.partA.partId === currentConn.partB.partId && conn.partB.partId === currentConn.partA.partId)
        )) {
          matchingConnections.push(currentConn)
        }
      })
    })

    return matchingConnections
  }


  const checkConnectionsSnapUnsnap = (Unsnap = true, processNewConnections = false, historyKey?: string) => {
    const TOLERANCE = 0.05 // Adjust this value as needed
    const latestSceneData = getLatestSceneData()
    const connections = latestSceneData.connections

    //console.log(getLatestSceneData().connections, "getLatestSceneData().connections starting")

    const getWorldPosition = (markerName: string, partId: string) => {
      const worldPosition = new Vector3()
      scene.traverse((object) => {
        if (object.name === markerName && object.userData.partId === partId) {
          object.getWorldPosition(worldPosition)
          return false // Stop traversing once we find a match
        }
      })
      return worldPosition
    }

    const newConnections = connections.map((connection: PartConnectionType) => {
      const partAWorldPosition = getWorldPosition(connection.partA.markerName, connection.partA.partId)
      const partBWorldPosition = getWorldPosition(connection.partB.markerName, connection.partB.partId)

      return {
        partA: {
          ...connection.partA,
          worldPosition: partAWorldPosition,
        },
        partB: {
          ...connection.partB,
          worldPosition: partBWorldPosition,
        },
      }
    })

    const stillConnected: PartConnectionType[] = []
    const notConnectedAnymore: PartConnectionType[] = []
    let notConnectedAnymorePerID: Record<string, { partId: string, markers: string[], }> = {}

    newConnections.forEach((connection: PartConnectionType & { partA: { worldPosition: Vector3, }, partB: { worldPosition: Vector3, }, }) => {
      const distance = connection.partA.worldPosition.distanceTo(connection.partB.worldPosition)
      if (distance <= TOLERANCE) {
        stillConnected.push(connection)
      } else {
        notConnectedAnymore.push(connection)
      }
    })

    // Filter out connections where both parts are in highlightedPartIds.current or
    //both are not in highlightedPartIds.current
    const filteredNotConnectedAnymore = notConnectedAnymore.filter(connection => {
      const partAHighlighted = highlightedPartIds.current.includes(connection.partA.partId)
      const partBHighlighted = highlightedPartIds.current.includes(connection.partB.partId)
      return (partAHighlighted && !partBHighlighted) || (!partAHighlighted && partBHighlighted)
    })

    if (Unsnap) {
      // Create NotConnectedAnymorePerID
      notConnectedAnymorePerID = createConnectionsPerIdMap(filteredNotConnectedAnymore)

      //console.log(notConnectedAnymorePerID, "notConnectedAnymorePerID")

      //for debugging only to figure out which parts ara not connected anymore
      /* const notConnectedPartIds = Object.values(notConnectedAnymorePerID).map(({ partId, }) => partId)
      setTimeout(() => {
        resetSelection()
        updateSelection(undefined, notConnectedPartIds)
      }, 3000)*/
      //


      // Run unsnap for each partId
      const count = Object.values(notConnectedAnymorePerID).length

      if (Object.values(notConnectedAnymorePerID).length > 0) {
        Object.values(notConnectedAnymorePerID).forEach(({ partId, markers, }) => {
          unsnap(partId, markers, historyKey)
          //console.log("during unsnap -> usnapping for", partId, markers)
          triggerAnimation("unsnap", partId, markers[0], "black")
        })
        messageUtils.custom(`Unsnapping ${count} part${count > 1 ? "s" : ""}... `, {
          duration: 1, showSpinner: true, forceShow: true,
        })
        SoundHelper.playUnsnap()
      }

      if (processNewConnections) {
        const { threedResults, } = checkCloseCollinearMarkers3DOnly(false, 0.0005, true)

        //console.log(threedResults, "threedResults from close collinear")

        const newConnections = processFilteredResults(threedResults)

        //find the existing connections for the same parts as the new connections
        //need to remove these
        const matchingExistingConnections = findMatchingConnections(newConnections)

        //create them in a format for unsnap function
        const matchingExistingConnectionsPerID = createConnectionsPerIdMap(matchingExistingConnections)

        // Run unsnap for each partId
        if (Object.values(matchingExistingConnectionsPerID).length > 0) {
          SoundHelper.playUnsnap()
          Object.values(matchingExistingConnectionsPerID).forEach(({ partId, markers, }) => {
            unsnap(partId, markers, historyKey)
            const count = Object.values(matchingExistingConnectionsPerID).length
            triggerAnimation("unsnap", partId, markers[0], "black")
            messageUtils.custom(`Unsnapping ${count} part${count > 1 ? "s" : ""}... `, {
              duration: 1, showSpinner: true, forceShow: true,
            })
            //console.log("during unsnap for existing connections -> usnapping for", partId, markers)
          })
        }

        //now lets add the new connections
        if (newConnections.length > 0) {
          // console.log(newConnections, "newConnections")
          // console.log(connections, "connections")
          setTimeout(() => {
            multipleUpdate([], newConnections, false, historyKey)
            newConnections.forEach((connection: PartConnectionType) => {
              triggerAnimation("snap", connection.partB.partId, connection.partB.markerName, "blue")
            })
            SoundHelper.playSnap()
            messageUtils.custom("Snapping parts...", {
              duration: 1, showSpinner: true, forceShow: true,
            })
            //setTimeout(() => {
            //console.log(getLatestSceneData().connections, "getLatestSceneData().connections after multipleUpdate")
            //}, 200)
          }, 200)

          //have to wait for the connections to update
          setTimeout(() => {
            newConnections.forEach((connection: PartConnectionType) => {
              const updateInitialMarkerForPart = (partId: string, markerName: string) => {
                if (markerName === "TOP" || markerName === "BOTTOM") {
                  const component = getComponent(partId)
                  if (component && component.updateInitialMarkerName) {
                    component.updateInitialMarkerName(markerName)
                  }
                }
              }

              updateInitialMarkerForPart(connection.partA.partId, connection.partA.markerName)
              updateInitialMarkerForPart(connection.partB.partId, connection.partB.markerName)

            })

          }, 200)

        }


        //had to put this in for now because the middle of the segmented tubes are not considered in the snapping logic above.
        const processSnappingForSegmentedTubeParts = () => {

          //get only the parts that are highlighted that have anything from the non highlighted parts intersecting
          const intersectingHighlightedIds = checkHighlightedPartsIntersections()

          const intersectingHighlightedIdsThatAreSegmentedTubes = intersectingHighlightedIds.filter((partId) => {
            const component = getComponent(partId)
            if (!component) { return false }
            const partInfo = component.getPartInfo()
            return partInfo.type.includes("segmented_tube_part")
          })

          intersectingHighlightedIdsThatAreSegmentedTubes.forEach((partId, index) => {
            const component = getComponent(partId)
            if (!component) { return }
            setTimeout(() => {
              component.seeWhatToDisconnectedAndConnect?.()
            }, 50 * index)

          })
        }

        setTimeout(() => {
          processSnappingForSegmentedTubeParts()
        }, 300)

      }

      //console.log(getLatestSceneData().connections, "getLatestSceneData().connections ending")

    }
    setTimeout(() => {
      afterRotationMovementCleanup()
    }, 250)

    return { stillConnected, notConnectedAnymore, notConnectedAnymorePerID, }
  }

  const storeBoundingBoxesOfNotHighlightedParts = () => {
    //console.log(notHighlightedPartsMarkerInfo.current, "notHighlightedPartsMarkerInfo.current")
    boundingBoxesOfNotHighlightedParts.current = []
    notHighlightedPartsMarkerInfo.current.forEach((part, index) => {
      const component = getComponent(part.id)
      if (component) {
        const boundingBox = component.getBoundingBox()
        if (boundingBox) {
          boundingBoxesOfNotHighlightedParts.current.push({ boundingBox, partId: part.id, })
        }
      }
    })
    //console.log(boundingBoxesOfNotHighlightedParts.current, "boundingBoxesOfNotHighlightedParts.current")
    return boundingBoxesOfNotHighlightedParts.current
  }



  const createClonedPartsWithMarkerInfo = (debug = false) => {
    const latestSceneData = getLatestSceneData()
    //console.log(latestSceneData, "latestSceneData")
    const clonedParts = JSON.parse(JSON.stringify(latestSceneData.parts))

    const highlightedParts: { [partId: string]: PartDataWithMarkerInfo, } = {}
    const otherParts: { [partId: string]: PartDataWithMarkerInfo, } = {}

    Object.entries(clonedParts).forEach(([partId, part,]: [string, any,]) => {

      const component = getComponent(partId)
      const allMeshes: Mesh[] = component?.getAllMarkers() ?? []

      const initialMarkerName = component?.getPartInfo(partId)?.initialMarkerName

      const finalMeshes = selectAndSortMeshes(allMeshes, 20, initialMarkerName ? [initialMarkerName,] : undefined)

      const processedPart = {
        id: partId,
        markers: finalMeshes.map((mesh) => {
          const worldPosition = new Vector3()
          const worldQuaternion = new Quaternion()
          const markerDirection = MeshUtils.copyWorldDirection(mesh)

          mesh.getWorldPosition(worldPosition)
          mesh.getWorldQuaternion(worldQuaternion)

          if (debug) {
            // Create debug visuals
            const planeGeometry = new PlaneGeometry(0.01, 0.01)
            const planeMaterial = new MeshBasicMaterial({
              color: 0xff0000,
              side: DoubleSide,
              wireframe: true,
              depthTest: false,
            })
            const planeMesh = new Mesh(planeGeometry, planeMaterial)
            planeMesh.position.copy(worldPosition)
            planeMesh.quaternion.copy(worldQuaternion)
            scene.add(planeMesh)

            const arrowHelper = new ArrowHelper(
              markerDirection,
              worldPosition,
              0.1,
              0xff0000,
              0.02,
              0.01
            )
              ; (arrowHelper.line.material as LineBasicMaterial).depthTest = false
            //scene.add(arrowHelper)
          }

          return {
            name: mesh.name,
            worldPosition,
            worldQuaternion,
            markerDirection,
            meshObject: mesh,
          }
        }),
      }

      if (highlightedPartIds.current.includes(partId)) {
        highlightedParts[partId] = processedPart
      } else {
        otherParts[partId] = processedPart
      }
      //console.log(processedPart, "processedPart")
    })

    return { highlightedParts, otherParts, }
  }

  const highlightAllParts = () => {
    const allPartIds = Object.values(getLatestSceneData().parts).map((part: any) => part.id)
    highlightedPartIds.current = allPartIds
    updateSelection(undefined, allPartIds)
  }

  const createViewsForScalerUI = (debug = false, callback?: () => void) => {
    // Remove any existing debug arrows
    if (debug) {
      scene.children = scene.children.filter(child => !child.name?.includes("normal-debug-arrow"))
    }

    // Define some distinct colors for debugging
    const debugColors = [
      0xff0000, // red
      0x00ff00, // green
      0x0000ff, // blue
      0xff00ff, // magenta
      0xffff00, // yellow
      0x00ffff, // cyan
      0xff8000, // orange
      0x8000ff, // purple
    ]

    const normalGroups: Map<string, {
      normal: Vector3,
      partIds: string[],
      meshes: Mesh[],
      markers: { [partId: string]: Mesh[], },
      startAndEndMarkers: { [partId: string]: { startMarker: Mesh, endMarker: Mesh, }, },
      types: Set<string>,
      firstMarkerPosition: Vector3, // Store first marker position
      normalMarkers: { [partId: string]: Mesh, }, // Add this new field
    }> = new Map()

    // Process each highlighted part
    highlightedPartIds.current.forEach(partId => {
      const component = getComponent(partId)
      if (!component) { return }

      const partInfo = component.getPartInfo()
      if (!partInfo?.type) { return }

      //need all the markers for collisions to be more accurate
      const markers = component.getAllMarkers()
      if (!markers.length) { return }

      const filteredMarkers = markers.filter((marker: Mesh) => !marker.name.includes("_0"))
      const initialMarkerName = component?.getPartInfo(partId)?.initialMarkerName
      const initialMarker = markers.find((marker: Mesh) => marker.name.includes(initialMarkerName))
      const isTube = component.getPartInfo(partId)?.type?.toLowerCase().includes("tube")

      //use initial marker for the first marker normal when its not a tube - otherwise use the first marker
      const firstMarker = !isTube ? initialMarker : markers[0]
      const normal = MeshUtils.copyWorldDirection(firstMarker)

      //if the parts is a tube, then grab the start and end markers
      const { startMarker, endMarker, } = isTube ? component.getStartAndEndInnerMarkers() : { startMarker: undefined, endMarker: undefined, }

      debug && console.log(startMarker, endMarker, "start and end markers")



      // Get first marker's world position
      const firstMarkerPosition = new Vector3()
      firstMarker.updateMatrixWorld(true)
      firstMarker.getWorldPosition(firstMarkerPosition)

      debug && drawVector3Point(firstMarkerPosition, scene, 0xff0000, 0.011, 5000, true, undefined, `${partId}_${firstMarker.name}`)

      // Ensure we always use the "positive" direction for grouping
      // this was a horrible idea - it messed with the scaler UI
      const normalizedNormal = new Vector3()
      normalizedNormal.copy(normal)
      if (normalizedNormal.y < 0) {
        //normalizedNormal.multiplyScalar(-1)
      }

      // Add 5% buffer and round to nearest 5% increment
      const BUFFER = 0.05 // 5% buffer
      const ROUND_TO = 0.05 // Round to nearest 5%
      const roundWithBuffer = (value: number) => {
        const absValue = Math.abs(value)
        return Math.round(absValue / ROUND_TO) * ROUND_TO
      }

      // Create key using buffered values
      const key = `${roundWithBuffer(normalizedNormal.x)},${roundWithBuffer(normalizedNormal.y)},${roundWithBuffer(normalizedNormal.z)}`

      if (!normalGroups.has(key)) {
        normalGroups.set(key, {
          normal: normalizedNormal, // Store normalized direction
          partIds: [],
          meshes: [],
          markers: {},
          types: new Set(),
          firstMarkerPosition,
          startAndEndMarkers: {},
          normalMarkers: {}, // Initialize empty object for normal markers
        })
      }

      const group = normalGroups.get(key)!
      group.partIds.push(partId)
      group.meshes.push(component.getMesh())
      group.markers[partId] = filteredMarkers
      group.types.add(partInfo.type)
      group.startAndEndMarkers[partId] = { startMarker, endMarker, }
      group.normalMarkers[partId] = firstMarker // Store the marker used for normal calculation
    })

    const validViews: viewForScalerUI[] = []
    const discardedViews: viewForScalerUI[] = []

    let colorIndex = 0
    normalGroups.forEach(group => {
      const view = {
        normal: group.normal,
        meshes: group.meshes,
        uniqueIds: group.partIds,
        markers: group.markers,
        startAndEndMarkers: group.startAndEndMarkers,
        types: Array.from(group.types),
        normalMarkers: group.normalMarkers, // Add the normal markers to the view
        partsOrder: [],
        viewID: crypto.randomUUID(),
      }

      if (Array.from(group.types).some(type => type.toLowerCase().includes("tube"))) {
        validViews.push(view)

        if (debug) {
          const arrowLength = 0.5
          const arrowHelper = new ArrowHelper(
            group.normal,
            group.firstMarkerPosition, // Use the stored first marker position
            arrowLength,
            debugColors[colorIndex % debugColors.length],
            arrowLength * 0.2,
            arrowLength * 0.1
          )
          arrowHelper.name = `normal-debug-arrow-${colorIndex}`
            ; (arrowHelper.line.material as LineBasicMaterial).depthTest = false
            ; (arrowHelper.cone.material as MeshBasicMaterial).depthTest = false
          scene.add(arrowHelper)

          setTimeout(() => {
            scene.remove(arrowHelper)
          }, 10000)

          colorIndex++
        }
      } else {
        discardedViews.push(view)
      }
    })

    if (discardedViews.length > 0) {
      debug && console.log("Discarded views (no tubes found):", discardedViews)
    }

    debug && console.log("Created valid views for ScalerUI:", validViews)

    //if there are valid views, set the views and config for the scaler UI
    if (validViews.length > 0) {
      debug && console.log("enabling scaler UI", validViews)
      setViewsForScalerUI(validViews)
      setEnableScalerUI(true)
      //swap this for the right config
      setConfigForScalerUI(defaultConfigForScalerUI)
      if (callback) {
        return callback()
      }
    }
  }

  const updateSelection = (
    selectedObjects?: Object3D[],
    partIds?: string[],
    skipColorUpdate = false,
    appendIds = false,
    removeIds?: string[]
  ) => {
    const latestSceneData = getLatestSceneData()

    if (selectedObjects && selectedObjects.length === 0 && partIds?.length === 0 && !removeIds?.length) {
      return
    }

    if (userIsScaling) {
      return
    }

    //console.log("updateSelection", "selectedObjects:", selectedObjects, "partIds:", partIds, "removeIds:", removeIds, "appendIds:", appendIds)

    const newSelectedPartIds = selectedObjects
      ?.filter((obj) => obj.userData.partId)
      .map((obj) => obj.userData.partId)
      .filter((partId, index, self) => self.indexOf(partId) === index)

    // Handle removing IDs first
    if (removeIds?.length) {
      highlightedPartIds.current = highlightedPartIds.current.filter(
        id => !removeIds.includes(id)
      )

      // Restore original colors for removed parts
      removeIds.forEach(partId => {
        const component = getComponent(partId)
        if (component) {
          component.originalColor()
        }
      })
    }

    // Then handle adding new IDs
    if (partIds && !appendIds) {
      highlightedPartIds.current = partIds
    } else if (partIds && appendIds) {
      //console.log("appending partIds", partIds, "to highlightedPartIds.current", highlightedPartIds.current)
      highlightedPartIds.current = Array.from(
        new Set([...highlightedPartIds.current, ...partIds,])
      )
    } else if (newSelectedPartIds) {
      highlightedPartIds.current = Array.from(
        new Set([...highlightedPartIds.current, ...newSelectedPartIds,])
      )

    }

    setShiftSelectedItemID(null)
    // Rest of the function remains unchanged
    updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)
    updateHighlightedPartIdsLengthFromReftoState()

    highlightedPartsConnections.current = latestSceneData.connections.filter(
      (connection: PartConnectionType) =>
        highlightedPartIds.current.includes(connection.partA.partId)
        && highlightedPartIds.current.includes(connection.partB.partId)
    )

    const { highlightedParts, otherParts, } = createClonedPartsWithMarkerInfo(showClonedModels)

    highlightedPartsMarkerInfo.current = Object.values(highlightedParts)
    notHighlightedPartsMarkerInfo.current = Object.values(otherParts)

    //use component.getMesh to get the meshes of all highlgihg parts


    createViewsForScalerUI(createViewsDebug)
    storeBoundingBoxesOfNotHighlightedParts()
    debouncedGet2dPositionOfHighlightedMarkers(storePolygonsForMarkerInfo)

    // Change color of selected objects
    if (!skipColorUpdate) {
      highlightedPartIds.current.forEach((partId) => {
        if (partId) {
          const component = getComponent(partId)
          if (component) {
            component.updateColor(0x1b7fe3)
            const partBoundingBox = component.getBoundingBox()
            if (partBoundingBox !== undefined) {
              selectionBoundingBox.current.union(partBoundingBox)
            }
          }
        }
      })
    }

    //let users know they can also remove items by holding shift (mac) or ctrl (windows) to remove items from selection
    messageUtils.custom("Tip! You can also hold shift (mac) or ctrl (windows) to remove or add parts to selection", {
      duration: 8,
      showUpTo: 2,
      minTimeBetweenShows: 30,
    })
  }

  const removeHelpersFromScene = () => {
    scene.children = scene.children.filter(
      (child) => !(child instanceof Box3Helper) && !(child.name === "helper"),
    )
  }

  useEffect(() => {
    //console.log("useEffect for keyboard setup")

    const handleKeyDown = (event: KeyboardEvent) => {
      //console.log("key down:", event.key)
      if (event.key === "Shift" || event.key === "Control") {
        modifierKeyRef.current = true  // Update ref
      }
    }

    const handleKeyUp = (event: KeyboardEvent) => {
      //console.log("key up:", event.key)
      if (event.key === "Shift" || event.key === "Control") {
        modifierKeyRef.current = false  // Update ref
      }
    }

    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

  }, [])

  const resetSelection = () => {
    //disable reset selection if shift or ctrl is pressed
    //tried using a state for the dontselection but it kept getting stale values
    if ((modifierKeyRef.current) || blockUI || showGroupNamingModal || userIsScaling || dontResetSelectionRef.current) {
      return
    }
    isAdmin && console.log("resetSelection")
    if (highlightedPartIds.current.length > 0) {
      highlightedPartIds.current.forEach((partId) => {
        if (partId) {
          const component = getComponent(partId)
          if (component) {
            component.originalColor()
          }
        }
      })
    }

    highlightedPartIds.current = []
    updateHighlightedPartIdsLengthFromReftoState()

    //disable resize mode and clear views
    setViewsForScalerUI([])
    setResizeMode(false)


    selectionBoundingBox.current = new Box3()
    setTransformMode("off")
    removeHelpersFromScene()
  }

  const createDuplicatedConnections = (
    originalConnections: PartConnectionType[],
    duplicatedParts: DuplicatedPart[],
  ): PartConnectionType[] => {
    const idMap = new Map(duplicatedParts.map((part) => [part.sourceId, part.duplicateId,]))

    return originalConnections.map((connection) => ({
      partA: {
        partId: idMap.get(connection.partA.partId) || connection.partA.partId,
        markerName: connection.partA.markerName,
      },
      partB: {
        partId: idMap.get(connection.partB.partId) || connection.partB.partId,
        markerName: connection.partB.markerName,
      },
    }))
  }

  useEffect(() => {
    let originalCursor: string | undefined
    let clickHandler: ((e: MouseEvent) => void) | undefined
    let overlayElement: HTMLDivElement | undefined

    if (blockUI) {
      // Store the original cursor style
      originalCursor = gl.domElement.style.cursor

      // Set cursor to wait (spinner)
      gl.domElement.style.cursor = "wait"

      // Create an overlay to prevent clicks
      overlayElement = document.createElement("div")
      overlayElement.style.position = "fixed"
      overlayElement.style.top = "0"
      overlayElement.style.left = "0"
      overlayElement.style.width = "100%"
      overlayElement.style.height = "100%"
      overlayElement.style.zIndex = "9999"
      overlayElement.style.cursor = "wait"

      // Disable clicking
      clickHandler = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
      }
      overlayElement.addEventListener("click", clickHandler, true)
      overlayElement.addEventListener("mousedown", clickHandler, true)
      overlayElement.addEventListener("mouseup", clickHandler, true)

      document.body.appendChild(overlayElement)
    } else {
      // Restore original cursor
      if (originalCursor !== undefined) {
        gl.domElement.style.cursor = originalCursor
      }

      // Remove overlay if it exists
      if (overlayElement && overlayElement.parentNode) {
        overlayElement.parentNode.removeChild(overlayElement)
      }
    }

    // Cleanup function
    return () => {
      if (overlayElement && overlayElement.parentNode) {
        overlayElement.parentNode.removeChild(overlayElement)
      }
      if (originalCursor !== undefined) {
        gl.domElement.style.cursor = originalCursor
      }
    }
  }, [blockUI, gl.domElement,])


  const duplicateSelectedParts = async (duplicateEverything = false, duplicateSpecificPartsIds?: string[]) => {
    newPartIds.current = []

    setBlockUI(true)

    const latestSceneData = getLatestSceneData()
    boundingBoxRef.current = getLatestBoundingBox().box

    const historyKey = crypto.randomUUID()

    //need some time to get a new position in case it's not updated when someone
    //is duplicating with one intial part
    if (Object.values(latestSceneData.partsIds).length < 2) {
      setUpdateCounter(prev => prev + 1)
      //above gets a new empty position
      await new Promise(resolve => setTimeout(resolve, 50))
    }

    if (boundingBoxRef.current) {
      if (duplicateEverything) {
        selectionBoundingBox.current = boundingBoxRef.current
        highlightedPartIds.current = Object.values(latestSceneData.partsIds).map(
          (part: any) => part.id,
        )
        updateHighlightedPartIdsLengthFromReftoState()
      }

      if (duplicateSpecificPartsIds) {
        highlightedPartIds.current = duplicateSpecificPartsIds
        updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)
        updateHighlightedPartIdsLengthFromReftoState()
      }

      messageUtils.custom(`Duplicating ${highlightedPartIds.current.length} parts...`, {
        duration: 1, showSpinner: true, forceShow: true,
      })

      const { offset, firstTubeId, tubePosition, } = getOffsetFromFirstTube() ?? {}

      positionForDuplicatedGroup.current = findEmptyPositionForBox(
        scene,
        selectionBoundingBox.current,
        boundingBoxRef.current,
        false,
      )

      if (positionForDuplicatedGroup.current && offset && tubePosition) {
        const newPosition = positionForDuplicatedGroup.current.clone().sub(offset)
        const newOffset = newPosition.clone().sub(tubePosition)
        offsetForDuplicatedGroup.current = newOffset
      }
    } else {
      console.warn(boundingBoxRef.current, "boundingBoxRef.current is empty")
    }

    let duplicatedConnections: PartConnectionType[] = []

    newPartIds.current.forEach((part) => {
      part.status = false
    })

    const MAX_RETRIES = 5
    const RETRY_DELAY = 100 // milliseconds

    const checkPartExists = (
      newPartId: string,
      retries = 0,
      callback: (exists: boolean) => void,
    ) => {
      let newPartExists = false
      scene.traverse((object) => {
        if (object.userData.partId === newPartId) {
          newPartExists = true
          return false // Stop traversing once we find a match
        }
      })

      if (newPartExists || retries >= MAX_RETRIES) {
        callback(newPartExists)
        return
      }

      setTimeout(() => {
        checkPartExists(newPartId, retries + 1, callback)
      }, RETRY_DELAY)
    }


    const checkDuplicatedPartsExist = (retryCount = 0, maxRetries = 10) => {
      const latestSceneData = getLatestSceneData()
      const allDuplicatedPartsExist = newPartIds.current.every((newPart: DuplicatedPart) => {
        const partExists = Object.values(latestSceneData.parts).some(
          (part: any) => part.id === newPart.duplicateId,
        )

        let sceneChildrenMatch = false
        scene.traverse((object) => {
          if (
            object.userData.partId === newPart.duplicateId
          ) {
            sceneChildrenMatch = true
            return false // Stop traversing once we find a match
          }
        })
        return partExists && sceneChildrenMatch
      })

      const allConnectionsExistInScene = duplicatedConnections.every((conn) => {
        let partAExists = false
        let partBExists = false
        scene.traverse((object) => {
          if (
            object.userData.partId === conn.partA.partId
            && object.name === conn.partA.markerName
          ) {
            partAExists = true
          }
          if (
            object.userData.partId === conn.partB.partId
            && object.name === conn.partB.markerName
          ) {
            partBExists = true
          }
          if (partAExists && partBExists) {
            return false // Stop traversing if both parts are found
          }
        })

        return partAExists && partBExists
      })

      //console.log(allDuplicatedPartsExist, "allDuplicatedPartsExist")
      //console.log(allConnectionsExistInScene, "allConnectionsExistInScene")

      if (allDuplicatedPartsExist && allConnectionsExistInScene) {
        console.log(
          "All duplicated parts exist in the scene. Proceeding with the rest of the function.",
        )
        proceedWithDuplication()
      } else if (retryCount < maxRetries) {
        console.log(
          `Not all duplicated parts exist yet. Retrying in 500ms. Attempt ${retryCount + 1
          } of ${maxRetries}`,
        )
        setTimeout(() => checkDuplicatedPartsExist(retryCount + 1, maxRetries), 500)
      } else {
        console.error("Max retries reached. Some duplicated parts are missing from the scene.")
        resetSelection()
      }
    }

    const connectionsUpdate = (duplicatedConnections: PartConnectionType[]) => {
      if (duplicatedConnections.length > 0) {
        //console.log(duplicatedConnections, "duplicatedConnections")
        multipleUpdate([], duplicatedConnections, false, historyKey)
      }
    }

    const proceedWithDuplication = () => {
      duplicatedConnections = createDuplicatedConnections(
        highlightedPartsConnections.current,
        newPartIds.current,
      )
      connectionsUpdate(duplicatedConnections)
      resetSelection()
      setBlockUI(false)
      setTimeout(() => {
        setUpdateCounter(prev => prev + 1)
      }, 500)
      //console.log(newPartIds.current, "newPartIds.current")
    }

    if (highlightedPartIds.current.length > 0 && offsetForDuplicatedGroup.current) {
      const processPart = (index: number) => {
        if (index >= highlightedPartIds.current.length) {
          console.log("All parts processed. Checking duplicated parts exist...")
          checkDuplicatedPartsExist()
          return
        }

        const partId = highlightedPartIds.current[index]
        if (!partId) {
          processPart(index + 1)
          return
        }

        const component = getComponent(partId)
        if (!component) {
          console.warn("component not found", partId)
          processPart(index + 1)
          return
        }

        const tubeInfo = component.getPartInfo()

        if (!tubeInfo || !tubeInfo.position || !offsetForDuplicatedGroup.current) {
          console.warn("Invalid tube info or offset", partId)
          processPart(index + 1)
          return
        }

        const currentPosition = new Vector3(
          tubeInfo.position.x,
          tubeInfo.position.y,
          tubeInfo.position.z,
        )
        const newPosition = currentPosition.add(offsetForDuplicatedGroup.current)

        let currentOffset = new Vector3(0, 0, 0)
        let newOffsetPosition = new Vector3(0, 0, 0)
        if (tubeInfo.markerOffset) {
          currentOffset = new Vector3(
            tubeInfo.markerOffset.x,
            tubeInfo.markerOffset.y,
            tubeInfo.markerOffset.z,
          )
          newOffsetPosition = currentOffset.add(offsetForDuplicatedGroup.current)
        }
        component.duplicatePart(
          historyKey,
          newPosition,
          tubeInfo.initialMarkerName || tubeInfo.originMarkerName,
          tubeInfo.markerOffset ? newOffsetPosition : undefined,
          tubeInfo.rotation || undefined,
          tubeInfo.length ?? undefined,
          tubeInfo.lengthNegativeSide ?? undefined,
          tubeInfo.partUnits ?? undefined,
          tubeInfo.unitRealValue ?? undefined,
          tubeInfo.scaledSegmentLength ?? undefined,
          tubeInfo.segmentScaleFactor ?? undefined,
          tubeInfo.modifiedWidth ?? undefined,
          tubeInfo.modifiedWidthUnits ?? undefined,
          tubeInfo.modifiedHeight ?? undefined,
          tubeInfo.modifiedHeightUnits ?? undefined,
          tubeInfo.realWidth ?? undefined,
          tubeInfo.realHeight ?? undefined,
          tubeInfo.baseName ?? undefined,
        ).then((newPartId: string) => {
          newPartIds.current.push({
            sourceId: partId,
            duplicateId: newPartId,
            sourceRotation: tubeInfo.rotation,
            initialMarkerName: tubeInfo.initialMarkerName || tubeInfo.originMarkerName,
            sourceLength: tubeInfo.length,
            sourceNegativeLength: tubeInfo.lengthNegativeSide,
            partUnits: tubeInfo.partUnits,
            unitRealValue: tubeInfo.unitRealValue,
            scaledSegmentLength: tubeInfo.scaledSegmentLength,
            segmentScaleFactor: tubeInfo.segmentScaleFactor,
            status: true,
          })

          if (!newPartId) {
            console.warn("Failed to duplicate part", partId)
            processPart(index + 1)
            return
          }

          const checkNewPart = (retries = 0) => {
            checkPartExists(newPartId, retries, (exists) => {
              if (exists) {
                getComponent(newPartId)?.setUItoNone()
                //console.log(`New part ${newPartId} successfully created and found in scene.`)
                processPart(index + 1)
              } else if (retries < MAX_RETRIES) {
                setTimeout(() => checkNewPart(retries + 1), RETRY_DELAY)
              } else {
                console.warn(
                  `New part ${newPartId} not found in scene after ${MAX_RETRIES} attempts. Skipping.`,
                )
                processPart(index + 1)
              }
            })
          }

          checkNewPart()
        })

      }

      processPart(0)
    } else {
      //console.log(highlightedPartIds.current, "highlightedPartIds.current maybe empty")
      //console.log(offsetForDuplicatedGroup.current, "offsetForDuplicatedGroup.current maybe empty")
    }

  }

  const deleteSelectedParts = () => {
    if (highlightedPartIds.current.length > 0) {
      highlightedPartIds.current.forEach((partId) => {
        if (partId) {
          const component = getComponent(partId)
          if (component) {
            component.deletePart()
          }
        }
      })
    }
    messageUtils.custom(`Deleted ${highlightedPartIds.current.length} parts...`, {
      duration: 1, forceShow: true,
    })
    resetSelection()
  }

  const removeAnchors = () => {
    const anchorsToRemove: Object3D[] = []
    scene.traverse((object) => {
      if (object.name === "Transforms Anchor") {
        anchorsToRemove.push(object)
      }
    })

    anchorsToRemove.forEach((anchor) => {
      scene.remove(anchor)
    })
  }

  useEffect(() => {
    scene.add(transformsAnchorRef.current)

  }, [])

  const projectTo2D = (position: Vector3, camera: Camera): { x: number, y: number, } => {
    const vector = position.clone().project(camera)

    // Get the renderer's DOM element to account for any offset
    const rendererDomElement = gl.domElement
    const rect = rendererDomElement.getBoundingClientRect()

    return {
      x: Math.round((vector.x + 1) * rect.width / 2),
      y: (Math.round((-vector.y + 1) * rect.height / 2)) + rect.top,
    }
  }

  const debouncedGet2dPositionOfHighlightedMarkers = useCallback(
    debounceLodash((callback?: () => void) => {
      get2dPositionOfHighlightedMarkers(callback)
      //if (isAdmin) {
      //setTimeout(() => {
      // in case you need immediate debugging after the points are drawn
      //clearCanvas()
      //drawNotHighlightedParts()
      //drawHighlightedParts()
      //drawDebugPolygonsFromSceneData()
      //}, 150)
      //}
    }, 50),
    []
  )


  const drawNotHighlightedParts = () => {
    notHighlightedPartsMarkerInfo.current.forEach((part) => {
      part.markers.forEach((marker) => {
        if (marker.camera2dPosition) {
          drawPoint(ctxRef.current!, marker.camera2dPosition.x, marker.camera2dPosition.y, 1, "red")
        }
      })
    })
  }

  const drawHighlightedParts = () => {
    highlightedPartsMarkerInfo.current.forEach((part) => {
      part.markers.forEach((marker) => {
        if (marker.camera2dPosition) {
          drawPoint(ctxRef.current!, marker.camera2dPosition.x, marker.camera2dPosition.y, 1, "red")
        }
      })
    })
  }
  useEffect(() => {
    if (view2DPoints || view2DPolygon) {
      clearCanvas()
      view2DPoints && drawNotHighlightedParts()
      view2DPoints && drawHighlightedParts()
      view2DPolygon && drawDebugPolygonsFromSceneData()
    }
  }, [view2DPolygon, view2DPoints,])

  useEffect(() => {
    return () => {
      debouncedGet2dPositionOfHighlightedMarkers.cancel()
    }
  }, [debouncedGet2dPositionOfHighlightedMarkers,])

  const get2dPositionOfHighlightedMarkers = (callback?: () => void) => {


    const cam = cameraControls.current
    if (!cam) {
      return
    }

    if (notHighlightedPartsMarkerInfo.current.length === 0 && highlightedPartsMarkerInfo.current.length === 0) {
      console.warn("no parts!")
      return
    }

    // Project 3D positions to 2D for notHighlightedPartsMarkerInfo
    if (notHighlightedPartsMarkerInfo.current.length > 0) {
      notHighlightedPartsMarkerInfo.current = notHighlightedPartsMarkerInfo.current.map(part => ({
        ...part,
        markers: part.markers.map(marker => ({
          ...marker,
          camera2dPosition: projectTo2D(marker.worldPosition, cam.camera,),
        })),
      }))
    }

    // Project 3D positions to 2D for highlightedPartsMarkerInfo
    if (highlightedPartsMarkerInfo.current.length > 0) {
      highlightedPartsMarkerInfo.current = highlightedPartsMarkerInfo.current.map(part => ({
        ...part,
        markers: part.markers.map(marker => ({
          ...marker,
          camera2dPosition: projectTo2D(marker.worldPosition, cam.camera,),
        })),
      }))
    }

    //isAdmin && console.log(highlightedPartsMarkerInfo.current, "get2dPositionOfHighlightedMarkers: highlightedPartsMarkerInfo.current")
    //isAdmin && console.log(notHighlightedPartsMarkerInfo.current, "get2dPositionOfHighlightedMarkers: notHighlightedPartsMarkerInfo.current")


    if (callback) {
      return callback()
    }

  }

  const drawDebugPolygonsFromSceneData = () => {
    //const sceneData = getLatestSceneData()

    /*Object.values(sceneData.parts).forEach((part: any) => {
      if (part.twodPolygon && part.twodPolygon.vertices) {
        const polygon = {
          vertices: part.twodPolygon.vertices,
          center: part.twodPolygon.center,
        }
        PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
      }
    })*/

    notHighlightedPartsMarkerInfo.current.forEach((part) => {
      if (part.twodPolygon && part.twodPolygon.vertices) {
        const polygon = {
          vertices: part.twodPolygon.vertices,
          center: part.twodPolygon.center,
        }
        PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
      }
    })

    highlightedPartsMarkerInfo.current.forEach((part) => {
      if (part.twodPolygon && part.twodPolygon.vertices) {
        const polygon = {
          vertices: part.twodPolygon.vertices,
          center: part.twodPolygon.center,
        }
        PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
      }
    })
  }

  //another debug function if needed
  const drawDebugPolygonForHighlightedAndNotHighlighedParts = () => {
    notHighlightedPartsMarkerInfo.current.forEach((part) => {
      const polygon = PolygonUtils.createPolygon(part.markers.map(marker => ({
        x: marker.camera2dPosition?.x || 0,
        y: marker.camera2dPosition?.y || 0,
      })), 2)
      PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
    })
    highlightedPartsMarkerInfo.current.forEach((part) => {
      const polygon = PolygonUtils.createPolygon(part.markers.map(marker => ({
        x: marker.camera2dPosition?.x || 0,
        y: marker.camera2dPosition?.y || 0,
      })), 2)
      PolygonUtils.debugDrawPolygon(ctxRef.current!, polygon)
    })
  }

  const storePolygonsForMarkerInfo = () => {
    const cam = cameraControls.current
    if (!cam) {
      return
    }

    //this is mostly for tubes
    const minHeight = 70 / cam.distance

    // Prepare updates for both highlighted and not highlighted parts
    //const updates: {
    //  id: string,
    //  updater: (p: WritableDraft<GenericPartState>) => void,
    //}[] = []

    // Add updates for highlighted parts
    highlightedPartsMarkerInfo.current.forEach(part => {
      const vertices = part.markers.map(marker => ({
        x: marker.camera2dPosition?.x || 0,
        y: marker.camera2dPosition?.y || 0,
      }))
      if (vertices.length === 0) {
        return
      }
      const polygon = PolygonUtils.createPolygon(vertices, minHeight)
      part.twodPolygon = {
        center: polygon.center,
        vertices: polygon.vertices,
      }
      /*updates.push({
        id: part.id,
        updater: (p) => {
          if (polygon) {
            p.twodPolygon = {
              center: polygon.center,
              vertices: polygon.vertices,
            }
          }
        },
      })*/
    })

    // Add updates for not highlighted parts
    notHighlightedPartsMarkerInfo.current.forEach(part => {
      const vertices = part.markers.map(marker => ({
        x: marker.camera2dPosition?.x || 0,
        y: marker.camera2dPosition?.y || 0,
      }))
      if (vertices.length === 0) {
        return
      }
      const polygon = PolygonUtils.createPolygon(vertices, minHeight)
      part.twodPolygon = {
        center: polygon.center,
        vertices: polygon.vertices,
      }
      /*updates.push({
        id: part.id,
        updater: (p) => {
          if (polygon && p) {
            p.twodPolygon = {
              center: polygon.center,
              vertices: polygon.vertices,
            }
          }
        },
      })*/
    })

    // Execute all updates at once
    //multipleUpdate(updates, [], true)
  }

  useEffect(() => {
    const cam = cameraControls.current
    if (!cam) {
      return
    }




    //updating of 2d position of markers on the screen for snapping logic
    //you need a slight timeout bc the camera still moved for a little right after it says
    //controlend


    const onChange = (() => {
      debouncedGet2dPositionOfHighlightedMarkers(storePolygonsForMarkerInfo)
    })



    // we need both
    cam.addEventListener("controlend", onChange)
    cam.addEventListener("control", onChange)
    cam.addEventListener("update", onChange)

    // Clean up
    return () => {
      cam.removeEventListener("control", onChange)
      cam.removeEventListener("controlend", onChange)
      cam.removeEventListener("update", onChange)
    }
  }, [cameraControls,])

  const setupAnchorForTransform = (debug = false) => {
    removeAnchors()
    const anchor = new Object3D()
    updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)
    const boundingBoxCenter = new Vector3()
    selectionBoundingBox.current.getCenter(boundingBoxCenter)

    //createSelectionBoundingBoxHelper()

    if (debug) {
      drawVector3Point(boundingBoxCenter, scene, 0x00ff00, 0.008)
    }
    anchor.position.set(boundingBoxCenter.x, boundingBoxCenter.y, boundingBoxCenter.z)
    anchor.name = "Transforms Anchor"
    //console.log(transformMode, "transformMode in setupAnchorForTransform")

    if (transformMode === "rotate") {
      // Create a visible icosahedron for the anchor
      // Find the longest side of the bounding box
      const size = new Vector3()
      selectionBoundingBox.current.getSize(size)
      const shortestSide = Math.min(size.x, size.y, size.z)

      // Calculate the radius as 30% of the longest side
      const radius = shortestSide / 2
      const detail = 1 // Increase this value for a smoother shape with more faces
      const geometry = new IcosahedronGeometry(radius, detail)
      const material = new MeshBasicMaterial({ color: 0x000082, transparent: true, opacity: 0.3, })
      const visibleIcosahedron = new Mesh(geometry, material)

      // Create outline
      const edgesGeometry = new EdgesGeometry(geometry)
      const edgesMaterial = new LineBasicMaterial({
        color: 0x000082,
        linewidth: 1,
        transparent: true,
        opacity: 0.4,
        depthTest: false,
        depthWrite: false,
      })
      const outline = new LineSegments(edgesGeometry, edgesMaterial)

      // Add both the icosahedron and its outline to the anchor
      anchor.add(visibleIcosahedron)
      anchor.add(outline)
    }
    //setTransformsAnchor(anchor)
    transformsAnchorRef.current = anchor
    setAnchor(transformsAnchorRef.current)
    scene.add(transformsAnchorRef.current)

    //console.log("running set anchor position", transformsAnchorRef.current.position)
    //setAnchorPosition(transformsAnchorRef.current.position)

    lookForClonedGroups()
  }

  useEffect(() => {
    if (transformsAnchorRef.current) {
      //drawVector3Point(transformsAnchor.position, scene, "blue", 0.008)
      //console.log(transformsAnchor.position, "transformsAnchor.position useeffect")
    }
  }, [transformsAnchorRef.current, transformMode,])

  useEffect(() => {
    if (transformMode === "rotate" || transformMode === "scale" || transformMode === "translate") {
      setupAnchorForTransform(false)
      afterRotationMovementCleanup()
      setResizeMode(false)

    }
    if (transformMode === "off") {
      setSceneMarkerColorsBackToOriginal()
      setVisibilityOfClonedGroupChildren(false)
      removeAnchors()
      //cant turn this on because the camera panning is on
      //console.log("running destruction of models")
      removeHelpersFromScene()
    }
    if (!selectionMode) {
      setResizeMode(false)
    }
  }, [transformMode, selectionMode,])

  useEffect(() => {
    const latestSceneData = getLatestSceneData()
    const clonedParts = JSON.parse(JSON.stringify(latestSceneData.parts))

    // Count instanciated parts by looking at each nested object
    const instantiatedPartsCount = Object.values(clonedParts || {}).reduce<number>((count, partContainer: any) => {
      // Each part is directly under its ID
      if (partContainer && typeof partContainer === "object" && "instanciated" in partContainer && partContainer.instanciated) {
        return count + 1
      }
      return count
    }, 0)

    // I have to refactor this where it's used in other components to be this accurate as well
    // bc they currently increment this number for reasons that are not that great
    setUpdateCounter(instantiatedPartsCount)
  }, [sceneData,])


  const updateMultiSelectProviderWithNewMakersInfo = useCallback(
    debounceLodash(() => {
      const { highlightedParts, otherParts, } = createClonedPartsWithMarkerInfo(showClonedModels)
      highlightedPartsMarkerInfo.current = Object.values(highlightedParts)
      notHighlightedPartsMarkerInfo.current = Object.values(otherParts)
      debouncedGet2dPositionOfHighlightedMarkers(storePolygonsForMarkerInfo)
      storeBoundingBoxesOfNotHighlightedParts()
    }, 50),
    []
  )
  useEffect(() => {
    return () => {
      updateMultiSelectProviderWithNewMakersInfo.cancel()
    }
  }, [updateMultiSelectProviderWithNewMakersInfo,])



  useEffect(() => {
    //when user turns on selection mode, store the 2d positions so we can use it for selector
    const { highlightedParts, otherParts, } = createClonedPartsWithMarkerInfo(showClonedModels)
    highlightedPartsMarkerInfo.current = Object.values(highlightedParts)
    notHighlightedPartsMarkerInfo.current = Object.values(otherParts)
    //dont need to use the debounced version here
    get2dPositionOfHighlightedMarkers(storePolygonsForMarkerInfo)
    storeBoundingBoxesOfNotHighlightedParts()

  }, [selectionMode, updateCounter,])



  //on initial load we need this for some reason... seems like some of the values were not ready
  useEffect(() => {
    setTimeout(() => {
      //when user turns on selection mode, store the 2d positions so we can use it for selector
      const { highlightedParts, otherParts, } = createClonedPartsWithMarkerInfo(showClonedModels)
      highlightedPartsMarkerInfo.current = Object.values(highlightedParts)
      notHighlightedPartsMarkerInfo.current = Object.values(otherParts)
      debouncedGet2dPositionOfHighlightedMarkers(storePolygonsForMarkerInfo)
      storeBoundingBoxesOfNotHighlightedParts()
    }, 2000)

  }, [])


  const createClonedMarkerModel = (
    id: string,
    meshes: Mesh[],
    visible = true,
    offsetMarkerPosition?: Vector3,
    initialMarkerName?: string,
    currentTubePosition?: Vector3,
    limitMarkersPerModel?: number,
  ): Group => {
    const group = new Group()
    group.name = `cloned_${id}`

    //console.log("running createclonedmarkermodel", id, "visible", visible)


    // Combine selected middle markers with other markers
    const finalMeshes = selectAndSortMeshes(meshes, limitMarkersPerModel, initialMarkerName ? [initialMarkerName,] : undefined)

    finalMeshes.forEach((mesh) => {

      if (mesh.name === "BOTTOM" || mesh.name === "TOP"
        || mesh.name.includes("inner") || mesh.name.includes("outer")) {

        const clonedMesh = mesh.clone()
        clonedMesh.name = `cloned_${mesh.name}`
        clonedMesh.visible = visible

        // Remove children if the mesh name is "BOTTOM" or "TOP" for the tube case

        if (mesh.name === "BOTTOM" || mesh.name === "TOP" || mesh.name.includes("inner") || mesh.name.includes("outer")) {
          while (clonedMesh.children.length > 0) {
            clonedMesh.remove(clonedMesh.children[0])
          }
        }



        clonedMesh.material = new MeshBasicMaterial({
          color: 0xe8f0f8,
          wireframe: true,
          wireframeLinewidth: 5,
          transparent: true,
          opacity: 0.5,
          depthTest: false,
          depthWrite: false,
        })

        // Copy userData
        //clonedMesh.userData = { ...mesh.userData }

        // Copy position, rotation, and scale
        const worldPosition = new Vector3()
        const worldQuaternion = new Quaternion()

        mesh.getWorldPosition(worldPosition)
        mesh.getWorldQuaternion(worldQuaternion)

        clonedMesh.position.copy(worldPosition)
        clonedMesh.quaternion.copy(worldQuaternion)

        group.add(clonedMesh)
      }
    })

    if (offsetMarkerPosition && initialMarkerName) {
      const initialMarker = meshes.find(mesh => mesh.name === innerToOuter(initialMarkerName))
        || meshes.find(mesh => mesh.name === outerToInner(initialMarkerName))

      if (initialMarker) {
        const offsetMarker = initialMarker.clone()
        offsetMarker.name = "cloned_offsetMarker"
        offsetMarker.position.copy(offsetMarkerPosition)
        offsetMarker.material = new MeshBasicMaterial({
          color: 0x900090, // Purple color
          wireframe: false,
          wireframeLinewidth: 5,
          transparent: true,
          opacity: 1,
          depthTest: false,
          depthWrite: false,
          visible: visible,
        })
        group.add(offsetMarker)
      }
    }

    if (currentTubePosition && initialMarkerName) {
      const initialMarker = meshes.find(mesh => mesh.name === innerToOuter(initialMarkerName))
        || meshes.find(mesh => mesh.name === outerToInner(initialMarkerName))

      if (initialMarker) {
        const offsetPositionMarker = initialMarker.clone()
        offsetPositionMarker.name = "cloned_offsetPositionMarker"
        offsetPositionMarker.position.copy(currentTubePosition)
        offsetPositionMarker.material = new MeshBasicMaterial({
          color: 0x900090, // Purple color
          wireframe: false,
          wireframeLinewidth: 5,
          transparent: true,
          opacity: 1,
          depthTest: false,
          depthWrite: false,
          visible: visible,
        })
        group.add(offsetPositionMarker)
      }
    }


    //now using the new bounding boxes
    const component = getComponent(id)
    if (component) {
      const boundingBoxMesh = component.getBoundingBoxMesh()
      const boundingBoxMeshClone = boundingBoxMesh.clone()
      const boundingBoxMeshClonePosition = new Vector3()
      boundingBoxMesh.getWorldPosition(boundingBoxMeshClonePosition)
      boundingBoxMeshClone.position.copy(boundingBoxMeshClonePosition)
      const boundingBoxMeshCloneRotation = new Quaternion()
      boundingBoxMesh.getWorldQuaternion(boundingBoxMeshCloneRotation)
      boundingBoxMeshClone.quaternion.copy(boundingBoxMeshCloneRotation)
      boundingBoxMeshClone.name = `boundingBox_${id}`
      boundingBoxMeshClone.material = new MeshBasicMaterial({
        color: 0x900090, // Purple color
        wireframe: false,
        wireframeLinewidth: 5,
        transparent: true,
        opacity: 1,
        depthTest: false,
        depthWrite: false,
        visible: visible,
      })
      boundingBoxMeshClone.visible = true
      group.attach(boundingBoxMeshClone)
    }

    group.position.set(0, 0, 0)

    return group
  }

  const destroyClonedMarkerModel = (id: string, archived?: boolean) => {
    //console.log("running destroy")

    const prefix = archived ? "archived" : "cloned"
    const clonedModels = scene.children.filter(child =>
      ((child as Object3D).isObject3D || child instanceof Group)
      && child.name.includes(prefix)
      && child.name.includes(id)
    ) as (Object3D | Group)[]

    //console.log(clonedModels, "clonedModels",)

    clonedModels.forEach(clonedModel => {
      // Recursively dispose of geometries and materials
      clonedModel.traverse((child) => {
        if (child instanceof Mesh) {
          if (child.geometry) {
            child.geometry.dispose()
          }
          if (child.material) {
            if (Array.isArray(child.material)) {
              child.material.forEach((material) => material.dispose())
            } else {
              child.material.dispose()
            }
          }
        } else if (child instanceof LineSegments) {
          if (child.geometry) {
            child.geometry.dispose()
          }
          if (child.material) {
            if (Array.isArray(child.material)) {
              child.material.forEach((material) => material.dispose())
            } else {
              child.material.dispose()
            }
          }
        }
      })

      // Remove from parent
      if (clonedModel.parent) {
        clonedModel.parent.remove(clonedModel)
      }

      // Clear any references
      clonedModel.clear()

      // If it's a Group, remove all children
      if (clonedModel instanceof Group) {
        while (clonedModel.children.length > 0) {
          clonedModel.remove(clonedModel.children[0])
        }
      }
    })

    // Force a garbage collection (optional, and only works if your environment supports it)
    if (typeof window !== "undefined" && "gc" in window) {
      (window as any).gc()
    }
  }

  const destroyAllClonedModels = () => {
    highlightedPartIds.current.forEach((id) => {
      destroyClonedMarkerModel(id, true)
      destroyClonedMarkerModel(id, false)
    })
  }

  const archiveAllClonedModels = (visible = true, destroyAfterArchive = true, functionToRunAfterArchive?: () => void, delayBeforeRunningFunction?: number) => {
    highlightedPartIds.current.forEach((id) => {
      const clonedObjects = scene.children.filter(child =>
        ((child as Object3D).isObject3D || child instanceof Group)
        && child.name.includes("cloned")
        && child.name.includes(id)
      )

      clonedObjects.forEach(obj => {
        obj.name = `archived_${obj.name}`
        obj.visible = visible
        //console.log(obj, `archiveAllClonedModels ${obj instanceof Group ? "groupContainer" : "clonedModel"}`, visible)
      })
    })
    if (destroyAfterArchive) {
      destroyAllClonedModels()
    }
    if (functionToRunAfterArchive) {
      setTimeout(() => {
        functionToRunAfterArchive()
      }, delayBeforeRunningFunction ?? 0)
    }

  }

  const getPartInfoAndMarkerData = (partId: string, markers: Mesh[]) => {
    const component = getComponent(partId)
    if (!component) {
      console.warn(`Component not found for partId: ${partId}`)
      return null
    }

    const partInfo = component.getPartInfo()
    if (!partInfo) {
      console.warn(`PartInfo not found for partId: ${partId}`)
      return null
    }

    const partPosition = new Vector3(partInfo.position.x, partInfo.position.y, partInfo.position.z)
    const partRotation = new Quaternion(
      partInfo.rotation.x,
      partInfo.rotation.y,
      partInfo.rotation.z,
      partInfo.rotation.w,
    )

    const markerData = markers.map((marker) => {
      marker.rotateY(MathUtils.degToRad(180))

      const worldPosition = new Vector3()
      const worldQuaternion = new Quaternion()
      marker.getWorldPosition(worldPosition)
      marker.getWorldQuaternion(worldQuaternion)
      marker.rotateY(MathUtils.degToRad(-180))

      return {
        name: marker.name,
        worldPosition,
        worldQuaternion,
      }
    })

    const positionMarker = markerData.find(
      (m) => m.worldPosition.distanceTo(partPosition) < 0.001, // Use a small threshold for floating-point comparison
    )

    const rotationMarker = markerData.find(
      (m) => Math.abs(m.worldQuaternion.dot(partRotation) - 1) < 0.001, // Use a small threshold for quaternion comparison
    )

    return {
      partId,
      partPosition,
      partRotation,
      markers: markerData,
      positionMarker: positionMarker?.name,
      rotationMarker: rotationMarker?.name,
    }
  }


  const getMarkersandCreateClonedModelsForHighlightedPartIds = () => {
    setIsCreatingModels(true)
    highlightedPartIds.current.forEach((partId) => {
      const component = getComponent(partId)
      if (component) {
        let markers: Mesh[] = component.getAllMarkers()

        //console.log(markers, "from get all markers")

        const initialMarkerName = component.getPartInfo(partId)?.initialMarkerName

        markers = selectAndSortMeshes(markers, 20, initialMarkerName ? [initialMarkerName,] : undefined)

        markers = markers.filter((marker: Mesh) => marker !== null && marker !== undefined)

        const partInfo = component.getPartInfo()

        const currentTubePosition = new Vector3(partInfo.position.x, partInfo.position.y, partInfo.position.z)

        let clonedModel

        if (partInfo.markerOffset && partInfo.initialMarkerName) {
          const offsetMarkerPosition = new Vector3(
            partInfo.markerOffset.x,
            partInfo.markerOffset.y,
            partInfo.markerOffset.z
          )
          clonedModel = createClonedMarkerModel(
            partId,
            markers,
            false,
            offsetMarkerPosition,
            partInfo.initialMarkerName,
            undefined,
            20,
          )
        } else {
          clonedModel = createClonedMarkerModel(partId, markers, false, undefined, partInfo.initialMarkerName, currentTubePosition, 20)
        }

        scene.add(clonedModel)

        const partInfoAndMarkerData = getPartInfoAndMarkerData(partId, markers)

        if (partInfoAndMarkerData) {

          const existingIndex = highlightedPartsPositionRotationInfo.current.findIndex(
            (info: any) => info.partId === partInfoAndMarkerData.partId
          )

          if (existingIndex !== -1) {
            // Remove the existing entry
            highlightedPartsPositionRotationInfo.current.splice(existingIndex, 1)
          }

          highlightedPartsPositionRotationInfo.current.push(partInfoAndMarkerData)
        }
      }
    })
    setIsCreatingModels(false)
  }

  async function updatePartPositionAndRotation(partId: string, historyKey?: string) {
    const partPositionRotationInfoFromClonedModel: any = highlightedPartsPositionRotationInfo.current.find(
      (part: any) => part.partId === partId,
    )

    if (partPositionRotationInfoFromClonedModel) {
      const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
      if (clonedGroup) {
        const component = getComponent(partId)
        const partInfoRef = component?.getPartInfo()

        let initialMarkerNameWithFallBack

        if (partInfoRef) {
          initialMarkerNameWithFallBack = partInfoRef.initialMarkerName || partInfoRef.originMarkerName
        }

        let positionMarker: Object3D | Group | undefined
        let rotationMarker: Object3D | Group | undefined
        let offsetMarker: Object3D | Group | undefined

        //console.log(partInfoRef.initialMarkerName, "partinfo initial marker name")

        if (partPositionRotationInfoFromClonedModel) {
          if (
            partInfoRef.markerOffset
            && initialMarkerNameWithFallBack
          ) {
            positionMarker = clonedGroup.getObjectByName(
              `cloned_${innerToOuter(initialMarkerNameWithFallBack)}`,
            ) as Object3D | Group

            const markerPosition = new Vector3()
            positionMarker.getWorldPosition(markerPosition)

            offsetMarker = clonedGroup.getObjectByName(
              "cloned_offsetMarker",
            ) as Object3D | Group

            //console.log(clonedGroup, offsetMarker, "offsetMarker x")
            //console.log("found both offset and position markers in part info")
          }
          else if (partPositionRotationInfoFromClonedModel.positionMarker) {
            positionMarker = clonedGroup.getObjectByName(`cloned_${partPositionRotationInfoFromClonedModel.positionMarker}`) as
              | Object3D
              | Group

            //console.log(positionMarker.name, "positionMarker name")
            if (positionMarker.name.includes("-") || /inner\d+_/.test(positionMarker.name) || /outer\d+_/.test(positionMarker.name)) {
              positionMarker = clonedGroup.getObjectByName(
                `cloned_${innerToOuter(initialMarkerNameWithFallBack)}`,
              ) as Object3D | Group

              if (!positionMarker) {
                positionMarker = clonedGroup.getObjectByName(
                  `cloned_${outerToInner(initialMarkerNameWithFallBack)}`,
                ) as Object3D | Group
              }
            }
            //console.log("found position marker in in  partPositions")
          }

          else if (!partPositionRotationInfoFromClonedModel.positionMarker) {
            //console.log(clonedGroup, "cloned group here yo")
            //console.log(partInfoRef, "partInfoRef here yo")
            positionMarker = clonedGroup.getObjectByName(
              `cloned_${innerToOuter(initialMarkerNameWithFallBack)}`,
            ) as Object3D | Group
            //console.log(positionMarker, "positionMarker here yo")
            //console.log(initialMarkerNameWithFallBack, "initialMarkerNameWithFallBack here yo")

            if (!positionMarker && initialMarkerNameWithFallBack.includes("outer")) {
              positionMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("outer", "inner")}`,
              ) as Object3D | Group
            }

            if (!positionMarker && initialMarkerNameWithFallBack.includes("inner")) {
              positionMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("inner", "outer")}`,
              ) as Object3D | Group
            }

            //console.log("couldn't find position marker in in  partPositions so set it using initial marker name")
          }

          rotationMarker = clonedGroup.getObjectByName(`cloned_${partPositionRotationInfoFromClonedModel.rotationMarker}`) as
            | Object3D
            | Group

          if (!rotationMarker) {
            rotationMarker = clonedGroup.getObjectByName(
              `cloned_${initialMarkerNameWithFallBack}`,
            ) as Object3D | Group

            if (!rotationMarker && initialMarkerNameWithFallBack.includes("inner")) {
              rotationMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("inner", "outer")}`,
              ) as Object3D | Group
            }
            if (!rotationMarker && initialMarkerNameWithFallBack.includes("outer")) {
              rotationMarker = clonedGroup.getObjectByName(
                `cloned_${initialMarkerNameWithFallBack.replace("outer", "inner")}`,
              ) as Object3D | Group
            }
          }

          if (!rotationMarker) {
            console.warn("rotationMarker not found", initialMarkerNameWithFallBack, clonedGroup)
          }

          if (!positionMarker) {
            console.warn("positionMarker not found", initialMarkerNameWithFallBack, clonedGroup)
          }
        }

        //console.log(clonedGroup, "cloned group")
        //console.log(positionMarker, "positionMarker", rotationMarker, "rotationMarker")

        //console.log(clonedGroup, "cloned group")
        //console.log(positionMarker, "positionMarker", rotationMarker, "rotationMarker")

        if (!offsetMarker && partInfoRef.lengthNegativeSide) {
          positionMarker = clonedGroup.getObjectByName(
            "cloned_offsetPositionMarker",
          ) as Object3D | Group

        }

        if (positionMarker && rotationMarker) {
          const newPosition = new Vector3()
          const newOffsetPosition = new Vector3()
          const newRotation = new Quaternion()

          rotationMarker.rotateY(MathUtils.degToRad(180))
          rotationMarker.getWorldQuaternion(newRotation).normalize()
          positionMarker.getWorldPosition(newPosition)

          if (offsetMarker) {
            offsetMarker.getWorldPosition(newOffsetPosition)
          }
          rotationMarker.rotateY(MathUtils.degToRad(-180))

          const modifiedRotation = {
            x: newRotation.x ?? (newRotation as any)._x,
            y: newRotation.y ?? (newRotation as any)._y,
            z: newRotation.z ?? (newRotation as any)._z,
            w: newRotation.w ?? (newRotation as any)._w,
          }

          // Get the component and update its position and rotation
          if (component && component.updatePositionAndRotation) {
            if (offsetMarker) {
              await component.updatePositionAndRotation(newOffsetPosition, modifiedRotation, newOffsetPosition, historyKey)
            } else {
              await component.updatePositionAndRotation(newPosition, modifiedRotation, undefined, historyKey)
            }
          }
        }
      }
    }
  }
  const applyRotationPositionToHighlightedParts = async (rotationDiff?: Quaternion, debug = false, rotationAxis?: ROTATION_AXES_ENUM, value?: number, historyKey?: string) => {
    if (debug) {
      drawVector3Point(transformsAnchorRef.current.position, scene, "red", 0.005)
    }

    const historyKeyToUse = historyKey || crypto.randomUUID()

    //console.log(
    //  highlightedPartsPositionRotationInfo.current,
    //  "highlightedPartsPositionRotationInfo.current",
    //)

    // Apply rotation to the master group when user is using the sphere
    // the direct controls to rotate

    if (rotationAxis && value) {
      //console.log(rotationAxis, "rotationAxis in applyRotationPositionToHighlightedParts")
      //console.log(value, "value in applyRotationPositionToHighlightedParts")
      if (rotationAxis === ROTATION_AXES_ENUM.X) {
        transformsAnchorRef.current.rotateX(value)
      }
      if (rotationAxis === ROTATION_AXES_ENUM.Y) {
        transformsAnchorRef.current.rotateY(value)
      }
      if (rotationAxis === ROTATION_AXES_ENUM.Z) {
        transformsAnchorRef.current.rotateZ(value)
      }
    }

    if (transformsAnchorRef.current) {
      if (debug) {
        drawVector3Point(transformsAnchorRef.current.position, scene, "red", 0.0005)
      }
    } else {
      console.warn("transformsAnchor is null")
    }

    //grab the rotations from the markers in the group

    const updatePromises = highlightedPartIds.current.map(partId => updatePartPositionAndRotation(partId, historyKeyToUse))

    await Promise.all(updatePromises)
    setBlockUI(false)

    // Needed to wait for all the values to update on all parts
    setTimeout(() => {
      updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)
    }, 100)
  }

  const setIdsAsHighlightedAndTurnOnControl = (ids: string[], control: "selection" | "translate" | "rotate",) => {
    setIsSelected(false)

    resetSelection()

    setTimeout(() => {
      updateSelection(undefined, ids, false)
      setTimeout(() => {
        if (control === "selection") {
          setSelectionMode(true)
        }
        if (control === "translate") {
          setSelectionMode(false)
          setTimeout(() => {
            setTransformMode("translate")
          }, 300)
        }
        if (control === "rotate") {
          setSelectionMode(false)
          setTimeout(() => {
            setTransformMode("rotate")
          }, 300)
        }
      }, 200)
    }, 200)

  }

  useEffect(() => {
    setSceneCallbacks((prev: any) => ({
      ...prev,
      setIdsAsHighlightedAndTurnOnControl,
      duplicateSelectedParts,
      resetSelection,
    }))
  }, [])


  useEffect(() => {
    //console.log(selectedPart, "selectedPart")
    if (selectedPart) {
      setHideMultiUIControls(true)
      setSelectionMode(false)
      //console.log(highlightedPartIds.current, "highlightedPartIds.current in use effect")
      if (!highlightedPartIds.current.includes(selectedPart.id)) {
        resetSelection()
      }
    }
    if (!selectedPart) {
      setHideMultiUIControls(false)
      setTransformMode("off")
      resetSelection()
    }
  }, [selectedPart,])


  const lookForClonedGroups = () => {
    //console.log("lookForClonedGroups")
    for (const partId of highlightedPartIds.current) {
      const component = getComponent(partId)
      if (component) {
        const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
        if (!clonedGroup) {
          if (!isCreatingModels) {
            getMarkersandCreateClonedModelsForHighlightedPartIds()
          }
          break
        }
      }
    }
  }

  const updateColorsOfHighlightedParts = () => {
    //console.log("updateColorsOfHighlightedParts")
    //update the colors of the highlighted parts
    highlightedPartIds.current.forEach(partId => {
      const component = getComponent(partId)
      if (component) {
        component.updateColor(0x1b7fe3)
      }
    })
  }

  const afterRotationMovementCleanup = () => {
    //console.log("afterRotationMovementCleanup")
    archiveAllClonedModels(showClonedModels, !keepClonedModels, getMarkersandCreateClonedModelsForHighlightedPartIds, 200)
    setUpdateCounter(prevState => prevState + 1)
    updateColorsOfHighlightedParts()
  }

  const processFilteredResults = (results: any[]) => {
    const uniquePairs = new Map()

    // Step 1: Filter results to ensure unique pairings
    results.forEach(({ clonedMarker, sceneMarkerMesh, }) => {
      const clonedPartId = clonedMarker.userData.partId
      const scenePartId = sceneMarkerMesh.userData.partId

      const pairKey = [clonedPartId, scenePartId,].sort((a, b) => a.localeCompare(b)).join("-")

      if (!uniquePairs.has(pairKey)) {
        uniquePairs.set(pairKey, { clonedMarker, sceneMarkerMesh, })
      }
    })

    // Step 2: Create the array of connections
    const connections = Array.from(uniquePairs.values()).map(({ clonedMarker, sceneMarkerMesh, }) => ({
      partA: {
        markerName: sceneMarkerMesh.name,
        partId: sceneMarkerMesh.userData.partId,
      },
      partB: {
        markerName: clonedMarker.name.replace("cloned_", ""),
        partId: clonedMarker.userData.partId,
      },
    }))

    return connections
  }

  const preventDefault = (event: TouchEvent) => {
    event.preventDefault()
  }

  const startCheckInterval = () => {
    // Always clear existing interval first
    if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current)
    }
    // Set new interval
    checkIntervalRef.current = setInterval(() => {
      checkForCloseCollinearMarkersAndColorThem()
    }, 300)
  }
  const stopCheckInterval = () => {
    if (checkIntervalRef.current) {
      clearInterval(checkIntervalRef.current)
      checkIntervalRef.current = null
    }
  }

  const transformsOnMouseUp = () => {
    const historyKey = crypto.randomUUID()
    setBlockUI(true)
    setTimeout(() => {
      const partCount = highlightedPartIds.current.length

      messageUtils.custom(`Updating positions + rotations for ${partCount} part${partCount > 1 ? "s" : ""}`, {
        duration: 1, showSpinner: true, forceShow: true,
      })

      //const point1 = performance.now()

      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current)
      }


      enableCamera(cameraControls.current || undefined)
      setTimeout(() => {
        setAnchorChanging(false)
      }, 200)

      // Clear the interval
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current)
        checkIntervalRef.current = null
      }

      document.body.removeEventListener("touchmove", preventDefault)

      //set visibilityforallClonedgroups
      // setCameraState("pan")
      setOnTopOfTransformControls(false)
      reattachMergedMeshesToPreviousParents()
      setBlockUI(false)

      requestidleCallbackWithFallback(() => {
        setSceneMarkerColorsBackToOriginal()
        setVisibilityOfClonedGroupChildren(false)
      }, 300)

      //console.log(transformMode, "transformMode in transformsOnMouseUp")

      if (transformsAnchorRef.current) {

        //const point1 = performance.now()
        if (transformMode === "translate" || transformMode === "rotate") {
          //const point1 = performance.now()
          applyRotationPositionToHighlightedParts(undefined, false, undefined, undefined, historyKey)
          //const point2 = performance.now()
          //console.log(`applyRotationPositionToHighlightedParts took ${point2 - point1} milliseconds`)
        }
        //const point2 = performance.now()
        //console.log(`applyRotationPositionToHighlightedParts took ${point2 - point1} milliseconds`)

        // Update the selection bounding box
        updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)

        //console.log(highlightedPartsConnections.current, "highlightedPartsConnections.current")
        //console.log(connectedSets, "connectedSets")
      }

      //rename so that any other translate or rotate functions dont interfere and get confused grabbing an outdated model

      setTimeout(() => {
        checkConnectionsSnapUnsnap(true, true, historyKey)
        setTimeout(() => {
          createViewsForScalerUI(createViewsDebug)
        }, 300)
      }, 250)

      //const point2 = performance.now()
      //console.log(`transformMouseUp took ${point2 - point1} milliseconds`)
    }, 0)


  }

  const transformsOnMouseDown = () => {
    disableCamera(cameraControls.current || undefined)
    document.body.addEventListener("touchmove", preventDefault, { passive: false, })
    setOnTopOfTransformControls(true)
    //const point1 = performance.now()
    attachHighlightedPartsToTransformControls()
    //const point2 = performance.now()
    //console.log(`attachHighlightedPartsToTransformControls took ${point2 - point1} milliseconds`)
    setAnchorChanging(true)
    // Start the interval immediately
    startCheckInterval()
    // Give message to users that they can hold the parts close to where they want it and then it'll stick
    messageUtils.custom("Move the parts near your desired location, wait a moment, and a green indicator will show when they're ready to snap together.", {
      duration: 8,
      showUpTo: 3,
      minTimeBetweenShows: 10,
    })
  }


  const onRotationSliderChange = (rotationAxis: ROTATION_AXES_ENUM, value: number) => {
    if (!rotationAxis || !value) {
      console.warn("rotationAxis and value are undefined")
      return
    }

    if (transformsAnchorRef.current) {

      if (transformMode === "rotate") {
        setBlockUI(true)
        attachHighlightedPartsToTransformControls()
        applyRotationPositionToHighlightedParts(undefined, false, rotationAxis, value)
        reattachMergedMeshesToPreviousParents()
        setBlockUI(false)

      } else {
        console.warn("transformMode is not rotate")
        return
      }

      // Update the selection bounding box
      updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)

    }


    setTimeout(() => {
      afterRotationMovementCleanup()
      createViewsForScalerUI(createViewsDebug)
    }, 300)

    //console.log(highlightedPartsConnections.current, "highlightedPartsConnections.current")
    //console.log(connectedSets, "connectedSets")
  }

  const setVisibilityOfClonedGroupChildren = (visibility = true, setColor = 0x1a548e) => {
    highlightedPartIds.current.forEach((partId) => {
      const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
      if (clonedGroup) {
        clonedGroup.visible = visibility
        clonedGroup.traverse((child) => {
          child.visible = visibility
          if (child instanceof Mesh) {
            child.material.color.setHex(setColor)
          }
        })
        //console.log("setting visibility of cloned group to ", clonedGroup.uuid, visibility)
      }
    })
  }

  const attachHighlightedPartsToTransformControls = () => {
    highlightedPartIds.current.forEach((partId) => {
      const component = getComponent(partId)
      if (component) {
        // Find the cloned group for this part
        const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group
        if (clonedGroup && transformsAnchorRef.current) {
          // Add the cloned group to the master group
          requestidleCallbackWithFallback(() => setVisibilityOfClonedGroupChildren(true), 200)
          transformsAnchorRef.current.attach(clonedGroup)
        } else {
          console.warn(`Cloned group not found for part ${partId}`)
        }

        // Find and attach any meshes with the name "MergedMesh_${partId}"

        let mesh = component.getMesh(0x1b7fe3)
        //console.log(mesh, "mesh we got back")
        if (mesh.name.includes("BOTTOM") || mesh.name.includes("TOP")) {
          //console.log("case, mesh.name", mesh)
          //return
        }
        if (mesh.name.includes("delete_after_use")) {
          component.changeVisibilityOnSpecificIndex(false)
        }
        if (mesh.name.includes("MergedMesh")) {
          //this is because we need the origin in order to avoid strange matrix behaviour
          mesh = mesh.parent.parent
        }
        if (mesh && transformsAnchorRef.current) {
          const parentUuid = mesh.parent ? mesh.parent.uuid : scene.uuid

          previousParentsToMergedMeshes.current.set(mesh.uuid, { parentUuid, partId, })
          //console.log(mesh.uuid, "mesh.uuid", parentUuid, "parentUuid", partId, "partId")
          //console.log(previousParentsToMergedMeshes.current, "previousParentsToMergedMeshes.current in attachHighlightedPartsToTransformControls")
          transformsAnchorRef.current.attach(mesh)
        } else {
          console.warn(`Merged mesh not found for part ${partId}`)
        }
      }
    })
    //console.log(previousParentsToMergedMeshes.current, "at attaching previousParentsToMergedMeshes.current")
  }

  const createDebugBox = (box: Box3, color: number) => {
    const boxHelper = new Box3Helper(box, new Color(color))
    boxHelper.name = "debugBox"
    return boxHelper
  }

  const clearDebugBoxes = () => {
    scene.children
      .filter(child => child.name === "debugBox")
      .forEach(child => scene.remove(child))
  }
  const getTransformedSelectionBoundingBox = (debug = false) => {
    if (!transformsAnchorRef.current) {
      return null
    }

    // Create a single combined box
    const combinedBox = new Box3()
    let hasBox = false

    transformsAnchorRef.current.children.forEach(child => {
      if (child.name.includes("cloned_")) {
        child.children.forEach(childObject => {
          if (childObject.name.includes("boundingBox")) {
            const boundingBoxMesh = childObject as Mesh
            const geometry = boundingBoxMesh.geometry

            if (!geometry.boundingBox) {
              geometry.computeBoundingBox()
            }

            if (geometry.boundingBox) {
              const box = new Box3().copy(geometry.boundingBox)
              box.applyMatrix4(boundingBoxMesh.matrixWorld)

              if (!hasBox) {
                combinedBox.copy(box)
                hasBox = true
              } else {
                combinedBox.union(box)
              }
            }
          }
        })
      }
    })

    if (debug && hasBox) {
      clearDebugBoxes()
      // Add visual debug box for current selection (blue)
      scene.add(createDebugBox(combinedBox, 0x0000ff))

      // Add expanded box for intersection testing (yellow)
      const expandedBox = combinedBox.clone().expandByScalar(0.005)
      scene.add(createDebugBox(expandedBox, 0xffff00))
    }

    return hasBox ? combinedBox : null
  }


  const reattachMergedMeshesToPreviousParents = () => {
    //console.log(previousParentsToMergedMeshes.current, "previousParentsToMergedMeshes.current")
    previousParentsToMergedMeshes.current.forEach((parentInfo, uuid) => {
      //console.log(parentInfo, "parentInfo", uuid, "uuid")
      const mesh = scene.getObjectByProperty("uuid", uuid) as Mesh
      if (mesh) {
        if (mesh.name.includes("delete_after_use")) {
          const component = getComponent(parentInfo.partId)
          if (component) {
            component.changeVisibilityOnSpecificIndex(true)
            setTimeout(() => {
              mesh.removeFromParent()
              component.originalColor()
            }, 100)
          }
          return
        }
        if (parentInfo.parentUuid) {
          const parent = scene.getObjectByProperty("uuid", parentInfo.parentUuid) as Object3D
          //console.log(parent, "parent", mesh, "mesh")
          if (parent && mesh) {
            parent.attach(mesh)
          }
        } else {
          console.warn(`Parent not found for mesh ${uuid}`)
        }
        if (mesh.name.includes("BOTTOM") || mesh.name.includes("TOP")) {
          const component = getComponent(parentInfo.partId)
          if (component) {
            component.originalColor()
          }
        }
      }
    })
    previousParentsToMergedMeshes.current.clear()
  }

  const checkCloseCollinearMarkers3DOnly = (debug = false, distanceThresholdthreeD = 3, checkForCompatibility = false, skipResults?: boolean, intersectingBoxFactor?: number) => {
    const DISTANCE_THRESHOLD_FOR_3D = distanceThresholdthreeD
    const threedResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, }[] = []
    if (!transformsAnchorRef.current) {
      return { threedResults: [], uniqueClosestResults: [], }
    }

    const boundingBoxOfGroupAttachedToAnchor: Mesh | null = null
    const box3OfGroupAttachedToAnchor: Box3 | null = null
    const boundingBoxOfGroupAttachedToAnchorsArray: Box3[] = []
    const intersectingIdsSet = new Set<string>()


    const notHighlightedPartsSet = new Set(boundingBoxesOfNotHighlightedParts.current.map(box => box.partId))

    transformsAnchorRef.current.children.forEach(child => {
      const cam = cameraControls.current
      if (child.name.includes("cloned_") && cam) {
        child.children.forEach(childObject => {
          if (childObject.name.includes("boundingBox")) {
            const boundingBoxMesh = childObject as Mesh
            const geometry = boundingBoxMesh.geometry

            if (!geometry.boundingBox) {
              geometry.computeBoundingBox()
            }

            if (geometry.boundingBox) {
              const box = new Box3().copy(geometry.boundingBox)
              box.applyMatrix4(boundingBoxMesh.matrixWorld)
              box.expandByScalar(intersectingBoxFactor || 0.005)
              boundingBoxOfGroupAttachedToAnchorsArray.push(box)
            }
          }
        })
      }
    })

    boundingBoxOfGroupAttachedToAnchorsArray.forEach(box => {
      boundingBoxesOfNotHighlightedParts.current.forEach(notHighlightedBox => {
        if (box.intersectsBox(notHighlightedBox.boundingBox) && notHighlightedPartsSet.has(notHighlightedBox.partId)) {
          intersectingIdsSet.add(notHighlightedBox.partId)
        }
      })
    })



    //intersectingIds.current = Array.from(intersectingIdsSet)
    //console.log(intersectingIds, "intersectingIds")
    // Get the set of valid IDs from notHighlightedPartsMarkerInfo
    const validIds = new Set(notHighlightedPartsMarkerInfo.current.map(part => part.id))

    // Filter the intersectingIdsSet to only include valid IDs
    const filteredIntersectingIds = Array.from(intersectingIdsSet).filter(id => validIds.has(id))

    // Set the filtered IDs to intersectingIds.current
    intersectingIds.current = filteredIntersectingIds


    if (skipResults) {
      return { threedResults: [], uniqueClosestResults: [], }
    }
    transformsAnchorRef.current.children.forEach(child => {
      child.children
        .filter(childObject => !childObject.name.includes("boundingBox"))
        .forEach(clonedMarker => {
          const clonedMarkerPosition = new Vector3()
          clonedMarker.getWorldPosition(clonedMarkerPosition)

          const clonedMarkerDirection = MeshUtils.copyWorldDirection(clonedMarker)
          intersectingIds.current.forEach(id => {
            const partInfo = notHighlightedPartsMarkerInfo.current.find(part => part.id === id)
            if (partInfo) {
              //console.log(partInfo, "partInfo")
              partInfo.markers.forEach(sceneMarker => {
                const sceneMarkerMesh = sceneMarker.meshObject

                const sceneMarkerPosition = new Vector3()
                sceneMarkerPosition.setFromMatrixPosition(sceneMarkerMesh.matrixWorld)


                const distance3D = clonedMarkerPosition.distanceTo(sceneMarkerPosition)

                const potentialMatches: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, }[] = []

                // First, filter based on distance only
                if (distance3D <= DISTANCE_THRESHOLD_FOR_3D) {
                  potentialMatches.push({ clonedMarker, sceneMarkerMesh, distance3D, })
                }

                // Process the potential matches
                potentialMatches.forEach(match => {
                  if (areDirectionsFacingEachOther(clonedMarkerDirection, sceneMarker.markerDirection)) {
                    if (checkForCompatibility) {
                      const isMarkerAlreadyConnected = isMarkerToMarkerConnected(
                        match.clonedMarker.userData.partId,
                        match.clonedMarker.name,
                        match.sceneMarkerMesh.userData.partId,
                        match.sceneMarkerMesh.name,
                        getLatestSceneData().connections
                      )

                      if (!isMarkerAlreadyConnected) {
                        const compatibleWith = isCompatibleWith2Meshes(
                          match.clonedMarker as Mesh,
                          match.sceneMarkerMesh as Mesh,
                          connectionTypes,
                          true
                        )
                        if (compatibleWith) {
                          threedResults.push(match)
                        }
                      }
                    } else {
                      // If we're not checking for compatibility, all direction-facing matches are valid
                      threedResults.push(match)
                    }
                  }
                })
              })
            }
          })
        })
    }
    )

    return { threedResults, }
  }

  const visualizeBoundingBox = (box: Box3, color: string) => {
    const helper = new Box3Helper(box, new Color(color))
    helper.name = "debug_bounding_box"
      ; (helper.material as Material).transparent = true
      ; (helper.material as Material).opacity = 0.5
      ; (helper.material as Material).depthTest = false
    scene.add(helper)
  }

  const checkHighlightedPartsIntersections = (debug = false) => {
    const intersectingHighlightedIds = new Set<string>()
    // Remove any existing debug helpers


    if (debug) {
      scene.children = scene.children.filter(child => !child.name?.includes("debug_bounding_box"))
    }

    // Check each highlighted part ID
    for (const highlightedId of highlightedPartIds.current) {
      const component = getComponent(highlightedId)
      if (!component) { continue }

      const boundingBoxOfHighlightedPart = component.getBoundingBox()

      const expandedBoundingBoxOfHighlightedPart = boundingBoxOfHighlightedPart.clone().expandByScalar(0.01)

      // Visualize highlighted part's bounding box in blue
      debug && visualizeBoundingBox(expandedBoundingBoxOfHighlightedPart, "blue")

      // Check if this highlighted part intersects with ANY non-highlighted part
      for (const { boundingBox, } of boundingBoxesOfNotHighlightedParts.current) {
        // Visualize non-highlighted part's bounding box in red
        debug && visualizeBoundingBox(boundingBox, "red")

        if (expandedBoundingBoxOfHighlightedPart.intersectsBox(boundingBox)) {
          intersectingHighlightedIds.add(highlightedId)
          break // Stop checking other non-highlighted parts once we find an intersection
        }
      }
    }

    // Log for debugging
    debug && console.log("Highlighted parts:", highlightedPartIds.current)
    debug && console.log("Non-highlighted bounding boxes:", boundingBoxesOfNotHighlightedParts.current)
    debug && console.log("Intersecting IDs:", Array.from(intersectingHighlightedIds))

    return Array.from(intersectingHighlightedIds)
  }

  const checkIntersectionsBetweenAnchorChildrenAndNotHighlightedParts = (
    startExpand = 0.5,
    maxExpand = 2.0,
    expandStep = 0.5
  ) => {
    const transformedBox = getTransformedSelectionBoundingBox(showCollidingBoundingBoxes)
    if (!transformedBox) { return [] }

    // Try increasingly larger expansions until we find intersections or hit max
    for (let currentExpand = startExpand; currentExpand <= maxExpand; currentExpand += expandStep) {
      // Create new box for each iteration to avoid cumulative expansion
      const transformBoxClone = new Box3().copy(transformedBox)
      transformBoxClone.expandByScalar(currentExpand)

      const intersectingIds = boundingBoxesOfNotHighlightedParts.current
        .filter(box => transformBoxClone.intersectsBox(box.boundingBox))
        .map(box => box.partId)

      if (intersectingIds.length > 0) {
        return Array.from(new Set(intersectingIds))
      }
    }

    return [] // Return empty array if no intersections found at any expansion level
  }

  const checkCloseCollinearMarkers = (debug = false, distanceThresholdthreeD = 0.5, distanceThreshold2D = 25, checkForCompatibility = false) => {

    const startingExpandScalar = 0.5

    const intersectingPartIds = checkIntersectionsBetweenAnchorChildrenAndNotHighlightedParts(startingExpandScalar, 2.5, 0.5)


    //be careful in reducing the 2d distance, it will increase the results by quite a lot and make this slow

    const DISTANCE_THRESHOLD_FOR_3D = distanceThresholdthreeD
    const DISTANCE_THRESHOLD_2D = distanceThreshold2D
    const DISTANCE_THRESHOLD_2D_FOR_MIDDLES = 25
    const twodresults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, matchingSceneMarkerSlidePoint?: Vector3, }[] = []
    const threedDistanceFilteredResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, matchingSceneMarkerSlidePoint?: Vector3, }[] = []
    const nonMiddleResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, }[] = []
    const atLeastOneEndResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, }[] = []

    if (!transformsAnchorRef.current) {
      return { twodresults, threedDistanceFilteredResults, uniqueClosestResults: [], }
    }

    //console.log(intersectingIds.current, "intersectingIds.current")


    const connections = getLatestSceneData().connections

    transformsAnchorRef.current.children.forEach(child => {
      const cam = cameraControls.current
      if (child.name.includes("cloned_") && cam) {
        child.children
          .filter(childObject => !childObject.name.includes("boundingBox"))
          .forEach(clonedMarker => {
            const clonedMarkerPosition = new Vector3()
            clonedMarker.getWorldPosition(clonedMarkerPosition)
            const clonedMarkerDirection = MeshUtils.copyWorldDirection(clonedMarker)
            const clonedMarker2DPosition = projectTo2D(clonedMarkerPosition, cam.camera)

            const partsToCheck = intersectingPartIds

            if (partsToCheck.length < 1) {
              return { twodresults: [], threedDistanceFilteredResults: [], uniqueClosestResults: [], }
            }


            //const time = performance.now()

            partsToCheck.forEach(id => {
              const partInfo = notHighlightedPartsMarkerInfo.current.find(part => part.id === id)
              if (partInfo) {
                partInfo.markers.forEach(sceneMarker => {
                  const sceneMarkerMesh = sceneMarker.meshObject
                  const sceneMarker2DPosition = sceneMarker.camera2dPosition

                  //console.log(sceneMarkerMesh, "sceneMarkerMesh")
                  //console.log(sceneMarker2DPosition, "sceneMarker2DPosition")

                  if (!sceneMarker2DPosition) {
                    console.warn("Scene marker 2D position is undefined")
                    return
                  }

                  const distance2D = Math.sqrt(
                    Math.pow(clonedMarker2DPosition.x - sceneMarker2DPosition.x, 2)
                    + Math.pow(clonedMarker2DPosition.y - sceneMarker2DPosition.y, 2)
                  )

                  const potentialMatches: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, matchingSceneMarkerSlidePoint?: Vector3, }[] = []

                  const sceneMarkerUnderscores = sceneMarkerMesh.name.split("_").length - 1
                  const isMiddleMarker = sceneMarkerUnderscores > 0

                  //here we only use inners and outers so we need to get the localslide points from the corresponding mesh marker
                  let distanceThreshold = DISTANCE_THRESHOLD_2D
                  let optimizedSlidePoints: { position: Vector3, }[] = []

                  if (isMiddleMarker) {
                    const correspondingMeshMarker = scene.getObjectById(sceneMarkerMesh.userData.correspondingMeshMarkerId) as Mesh
                    distanceThreshold = DISTANCE_THRESHOLD_2D_FOR_MIDDLES
                    const localSlidePoints = correspondingMeshMarker?.userData.localSlidePoints
                    optimizedSlidePoints = correspondingMeshMarker?.userData.localSlidePoints?.length > 0 ? localSlidePoints : []
                  }


                  if (isMiddleMarker && optimizedSlidePoints?.length > 0) {

                    // Check each local slide point
                    optimizedSlidePoints.forEach((localSlidePoint: { position: Vector3, }) => {
                      // Create new Vector3 for each slide point
                      const globalSlidePointPos = new Vector3()
                      sceneMarkerMesh.localToWorld(globalSlidePointPos.copy(localSlidePoint.position))

                      const slidePoint2D = projectTo2D(globalSlidePointPos, cam.camera)
                      //drawPoint(ctxRef.current!, clonedMarker2DPosition.x, clonedMarker2DPosition.y, 3, "green")

                      // Calculate distance to this slide point
                      const distance2DToSlidePoint = Math.sqrt(
                        Math.pow(clonedMarker2DPosition.x - slidePoint2D.x, 2)
                        + Math.pow(clonedMarker2DPosition.y - slidePoint2D.y, 2)
                      )

                      if (distance2DToSlidePoint <= distanceThreshold) {
                        //const randomColor = tinycolor.random().toHexString()
                        potentialMatches.push({
                          clonedMarker,
                          sceneMarkerMesh,
                          distance2D: distance2DToSlidePoint,
                          matchingSceneMarkerSlidePoint: globalSlidePointPos,
                        })
                        //drawVector3Point(globalSlidePointPos, scene, randomColor, 0.001)
                        //console.log("Found matching distance", distance2DToSlidePoint)
                      } else {
                        //console.log("2D point too far", globalSlidePointPos, "distance:", distance2DToSlidePoint)
                      }
                    })
                  } else {
                    // Regular distance check for non-middle markers or middle markers without slide points
                    if (distance2D <= distanceThreshold) {
                      potentialMatches.push({ clonedMarker, sceneMarkerMesh, distance2D, })
                    }
                  }

                  //console.log(potentialMatches, "potentialMatches")

                  // Process the potential matches
                  potentialMatches.forEach(match => {
                    const clonedMarkerUnderscores = match.clonedMarker.name.split("_").length - 1
                    const sceneMarkerUnderscores = match.sceneMarkerMesh.name.split("_").length - 1

                    if (areDirectionsFacingEachOther(clonedMarkerDirection, sceneMarker.markerDirection)) {
                      if (checkForCompatibility) {
                        const isMarkerAlreadyConnected = isMarkerToMarkerConnected(
                          match.clonedMarker.userData.partId,
                          match.clonedMarker.name,
                          match.sceneMarkerMesh.userData.partId,
                          match.sceneMarkerMesh.name,
                          connections
                        )

                        if (!isMarkerAlreadyConnected) {
                          const compatibleWith = isCompatibleWith2Meshes(
                            match.clonedMarker as Mesh,
                            match.sceneMarkerMesh as Mesh,
                            connectionTypes,
                            true
                          )

                          if (compatibleWith) {
                            //if ((clonedMarkerUnderscores === 1 && sceneMarkerUnderscores === 0) || (clonedMarkerUnderscores === 1 || sceneMarkerUnderscores === 0)) {
                            twodresults.push(match)
                            //}
                          }
                        }
                      } else /*if ((clonedMarkerUnderscores === 1 && sceneMarkerUnderscores === 0) || (clonedMarkerUnderscores === 1 || sceneMarkerUnderscores === 0))*/ {
                        // If we're not checking for compatibility, all direction-facing matches are valid
                        twodresults.push(match)
                      }
                      // Check if the cloned marker name has only one underscore and scene marker mesh has no underscores
                    }
                  })
                })
              }
            })


          })
      }
    })


    //nonMiddleResults.sort((a, b) => a.distance2D - b.distance2D)
    //atLeastOneEndResults.sort((a, b) => a.distance2D - b.distance2D)

    twodresults.sort((a, b) => a.distance2D - b.distance2D)

    const closestResult: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }[] = []
    const uniqueClosestResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D: number, distance2D: number, }[] = []

    //if (!(nonMiddleResults.length > 0 || atLeastOneEndResults.length > 0)) {

    //now filter for the results that are just way too far away in 3d space

    //console.log(twodresults.length, "twodresults length")
    twodresults.forEach(({ clonedMarker, sceneMarkerMesh, distance2D, matchingSceneMarkerSlidePoint, }) => {
      const clonedMarkerPosition = new Vector3()
      clonedMarker.getWorldPosition(clonedMarkerPosition)


      //matchingSceneMarkerSlidePoint && drawVector3Point(matchingSceneMarkerSlidePoint, scene, "red", 0.005)

      const sceneMarkerPosition = new Vector3()
      sceneMarkerPosition.setFromMatrixPosition(sceneMarkerMesh.matrixWorld)

      const sceneMarkerPositionToUse = matchingSceneMarkerSlidePoint ?? sceneMarkerPosition

      const distance3D = clonedMarkerPosition.distanceTo(sceneMarkerPositionToUse)

      if (distance3D < DISTANCE_THRESHOLD_FOR_3D) {
        threedDistanceFilteredResults.push({ clonedMarker, sceneMarkerMesh, distance3D, distance2D, matchingSceneMarkerSlidePoint, })
      }


    })

    threedDistanceFilteredResults.sort((a, b) => a.distance3D - b.distance3D)

    //const endTime = performance.now()
    //const timeTaken = endTime - startTime
    //console.log(`Time taken: ${timeTaken} milliseconds`)

    return { twodresults, threedDistanceFilteredResults, uniqueClosestResults, closestResult, nonMiddleResults, atLeastOneEndResults, }
  }

  const setSceneMarkerColorsBackToOriginal = (color = "white", visibility = false) => {
    closeCompatibleMarkers.current.forEach(({ sceneMarkerMesh, }) => {
      if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
        sceneMarkerMesh.material.color.setColorName(color) // Red color for cloned markers
        sceneMarkerMesh.visible = visibility
        sceneMarkerMesh.material.visible = visibility
        //console.log(sceneMarkerMesh, "sceneMarkerMesh in setSceneMarkerColorsBackToOriginal")
      }
    })
    scene.traverse((object) => {
      if (object instanceof Mesh && object.userData.highlighted) {
        if (object.material instanceof MeshBasicMaterial) {
          object.material.color.setColorName(color)
          object.visible = visibility
          object.material.visible = visibility
        }
        object.userData.highlighted = false
      }
    })
  }

  // Make sure to clear the interval when the component unmounts
  useEffect(() => {
    //console.log = function() {};
    return () => {
      if (checkIntervalRef.current) {
        clearInterval(checkIntervalRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (onTopOfTransformControls) {
      disableCamera(cameraControls.current || undefined)
    } else {
      enableCamera(cameraControls.current || undefined)
    }
  }, [onTopOfTransformControls,])

  useEffect(() => {
    if (isPinching && selectionMode) {
      setSelectionBoxIsDragging(false)
    }
  }, [isPinching, selectionMode,])

  const transformsOnChange = () => {
    //console.log("transformsOnChange")
  }



  const checkForCloseCollinearMarkersAndColorThem = () => {

    const currentTime = performance.now()
    const timeSinceLastOffset = currentTime - lastOffsetAppliedTime.current


    //commenting this out because I am getting intersecting boxes inside the checkCloseCollinearMarkers function instead
    //checkCloseCollinearMarkers3DOnly(false, undefined, false, true, 0.20)

    //console.log(timeSinceLastOffset, "timeSinceLastOffset")

    if (timeSinceLastOffset < 200) {
      return
    }

    const newAnchorPosition = new Vector3()
    transformsAnchorRef.current?.getWorldPosition(newAnchorPosition)

    const newAnchorRotation = new Quaternion()
    transformsAnchorRef.current?.getWorldQuaternion(newAnchorRotation)

    //console.log(newAnchorPosition, "newAnchorPosition in check")
    //console.log(lastAnchorValue.current, "lastAnchorValue.current in check in collinears")

    if ((transformMode === "translate" && !newAnchorPosition.equals(lastAnchorValue.current))
      || (transformMode === "rotate" && !newAnchorRotation.equals(lastAnchorRotation.current))) {

      lastAnchorValue.current = newAnchorPosition
      lastAnchorRotation.current = newAnchorRotation
      // Check for close and collinear markers

      //console.log("running on check")

      const { threedDistanceFilteredResults, twodresults, } = checkCloseCollinearMarkers()

      //console.log(threedDistanceFilteredResults, "threedDistanceFilteredResults")
      //console.log(twodresults, "twodresults")


      let resultsToUse: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance3D?: number, distance2D: number, }[] = []

      if (threedDistanceFilteredResults.length > 0) {
        resultsToUse = [threedDistanceFilteredResults[0],]
      } else if (twodresults.length > 0) {
        resultsToUse = [twodresults[0],]
      }

      if (resultsToUse) {
        // Set visibility of markers that are no longer in uniqueClosestResults to false

        if (closeCompatibleMarkers.current.length > 0 && closeCompatibleMarkers.current[0]) {
          closeCompatibleMarkers.current.forEach(({ clonedMarker, sceneMarkerMesh, }) => {
            if (resultsToUse.length > 0 && resultsToUse[0]) {
              const isClonedMarkerStillClose = resultsToUse.some(
                (result) => result.clonedMarker === clonedMarker
              )
              const isSceneMarkerMeshStillClose = resultsToUse.some(
                (result) => result.sceneMarkerMesh === sceneMarkerMesh
              )

              if (!isClonedMarkerStillClose) {
                if (clonedMarker instanceof Mesh && clonedMarker.material instanceof MeshBasicMaterial) {
                  clonedMarker.material.visible = false
                  clonedMarker.visible = false
                }
              }

              if (!isSceneMarkerMeshStillClose) {
                if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
                  sceneMarkerMesh.material.visible = false
                  sceneMarkerMesh.visible = false
                }
              }
            }
          })
        }

        if (resultsToUse) {
          resultsToUse.forEach(({ clonedMarker, sceneMarkerMesh, }) => {
            const isMarkerAlreadyConnected = isMarkerToMarkerConnected(
              clonedMarker.userData.partId,
              clonedMarker.name,
              sceneMarkerMesh.userData.partId,
              sceneMarkerMesh.name,
              getLatestSceneData().connections
            )

            if (isMarkerAlreadyConnected) {
              // If the marker is already connected, don't change its color
              // Just ensure it's visible
              if (clonedMarker instanceof Mesh && clonedMarker.material instanceof MeshBasicMaterial) {
                clonedMarker.visible = true
                clonedMarker.material.visible = true
              }
              if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
                sceneMarkerMesh.material.visible = true
                sceneMarkerMesh.visible = true
                sceneMarkerMesh.material.wireframe = true
              }
            } else {
              const compatibleWith = isCompatibleWith2Meshes(
                clonedMarker as Mesh,
                sceneMarkerMesh as Mesh,
                connectionTypes,
                true
              )

              const color = compatibleWith ? 0x00FF00 : 0xFFFF00 // Green if compatible, yellow otherwise

              if (clonedMarker instanceof Mesh && clonedMarker.material instanceof MeshBasicMaterial) {
                clonedMarker.material.color.setHex(color)
                clonedMarker.visible = true
                clonedMarker.material.visible = true
              }
              if (sceneMarkerMesh instanceof Mesh && sceneMarkerMesh.material instanceof MeshBasicMaterial) {
                sceneMarkerMesh.material.color.setHex(color)
                sceneMarkerMesh.material.visible = true
                sceneMarkerMesh.visible = true
                sceneMarkerMesh.material.wireframe = true
                sceneMarkerMesh.userData.highlighted = true
              }
            }
          })
        }


        const applyOffsetToClonedGroups = (uniqueClosestResults: { clonedMarker: Object3D, sceneMarkerMesh: Object3D, distance2D: number, distance3D?: number, matchingSceneMarkerSlidePoint?: Vector3, }[]) => {
          uniqueClosestResults.forEach(({ clonedMarker, sceneMarkerMesh, matchingSceneMarkerSlidePoint, }) => {

            const partId = clonedMarker.userData.partId
            const clonedGroup = scene.getObjectByName(`cloned_${partId}`) as Group

            const clonedGroupWorldPosition = new Vector3()
            clonedGroup.getWorldPosition(clonedGroupWorldPosition)

            const clonedGroupWorldQuaternion = new Quaternion()
            clonedGroup.getWorldQuaternion(clonedGroupWorldQuaternion)

            const anchorPosition = new Vector3()
            transformsAnchorRef.current?.getWorldPosition(anchorPosition)

            const anchorRotation = new Quaternion()
            transformsAnchorRef.current?.getWorldQuaternion(anchorRotation)

            //console.log("setting anchor position")

            if (clonedGroup) {
              if (clonedGroup.children.includes(clonedMarker)) {
                // Get the world position of the cloned marker
                const clonedMarkerWorldPosition = new Vector3()
                clonedMarker.getWorldPosition(clonedMarkerWorldPosition)

                const clonedMarkerWorldQuaternion = new Quaternion()
                clonedMarker.getWorldQuaternion(clonedMarkerWorldQuaternion)

                // Get the world position of the scene marker
                const sceneMarkerWorldPosition = new Vector3()
                sceneMarkerMesh.getWorldPosition(sceneMarkerWorldPosition)

                const sceneMarkerWorldPositionToUse = matchingSceneMarkerSlidePoint ?? sceneMarkerWorldPosition

                const sceneMarkerWorldQuaternion = new Quaternion()
                sceneMarkerMesh.getWorldQuaternion(sceneMarkerWorldQuaternion)

                // Calculate the offset between the scene marker and the cloned marker
                const offset = new Vector3()
                offset.subVectors(clonedMarkerWorldPosition, sceneMarkerWorldPositionToUse)

                const offsetRotation = new Quaternion()
                offsetRotation.multiplyQuaternions(clonedMarkerWorldQuaternion, sceneMarkerWorldQuaternion.clone().invert())

                //debug
                //drawVector3Point(clonedGroupWorldPosition, scene, "red", 0.005)
                //drawVector3Point(clonedMarkerWorldPosition, scene, "blue", 0.005)
                //drawVector3Point(sceneMarkerWorldPosition, scene, "green", 0.005)

                // Add the offset to the cloned group's world position
                clonedGroupWorldPosition.add(offset)
                clonedGroupWorldQuaternion.multiply(offsetRotation)

                lastOffsetAppliedTime.current = currentTime

                //console.log(timeSinceLastOffset, "timeSinceLastOffset")

                anchorPosition.sub(offset)
                anchorRotation.multiply(offsetRotation)

                //drawVector3Point(anchorPosition, scene, "purple", 0.005)

                transformsAnchorRef.current.position.set(anchorPosition.x, anchorPosition.y, anchorPosition.z)
              }
            }
          })

        }

        closeCompatibleMarkers.current = resultsToUse ?? []

        if (resultsToUse.length > 0) {
          //console.log(closestResult, "closestResult in checkForCloseCollinearMarkersAndColorThem")

          applyOffsetToClonedGroups([resultsToUse[0],])
          messageUtils.custom("You can now let go of the mouse if this is where you wanted to snap!", {
            duration: 5,
            showUpTo: 3,
            minTimeBetweenShows: 10,
          })
        }
      }

    }
  }

  /*useEffect(() => {
    //console.log(tempAnchorLock, "temp anchor lock")
    if (tempAnchorLock && anchorLockPositionValue) {
      transformsAnchorRef.current.position.set(anchorLockPositionValue.x, anchorLockPositionValue.y, anchorLockPositionValue.z)
    }
  }, [tempAnchorLock,])*/


  const createGroup = useCallback((name: string) => {
    const randomId = crypto.randomUUID()
    const randomColor = tinycolor.random()
      .darken(20)  // Darken the color slightly
      .saturate(20) // Increase saturation
      .setAlpha(1) // Ensure full opacity
      .toHexString()
    //console.log("createGroup", name, highlightedPartIds.current)
    setSceneAtom((prevScene: SceneType) => {
      const existingGroups = prevScene.groups || []
      const movedParts: { partId: string, fromGroup: string, }[] = []

      // Remove parts from their existing groups and track the changes
      const updatedGroups = existingGroups.map(group => {
        const overlappingParts = group.partIds.filter(partId =>
          highlightedPartIds.current.includes(partId)
        )

        if (overlappingParts.length > 0) {
          overlappingParts.forEach(partId => {
            movedParts.push({ partId, fromGroup: group.name, })
          })

          return {
            ...group,
            partIds: group.partIds.filter(partId => !highlightedPartIds.current.includes(partId)),
          }
        }
        return group
      })

      // Create new group with all highlighted parts
      const newGroup = {
        name,
        id: randomId,
        partIds: highlightedPartIds.current,
        color: randomColor,
      }

      // Show messages about moved parts
      movedParts.forEach(({ partId, fromGroup, }) => {
        messageUtils.custom(`Part ID ${partId} was moved from group "${fromGroup}" to "${name}"`, {
          duration: 8,
          forceShow: true,
          showUpTo: 3,
          minTimeBetweenShows: 10,
        })
      })

      // Filter out any empty groups
      const finalGroups = updatedGroups.filter(group => group.partIds.length > 0)

      return {
        ...prevScene,
        groups: [...finalGroups, newGroup,],
      }
    })
  }, [highlightedPartIds.current,])

  const handleCancel = () => {
    setShowGroupNamingModal(false)
  }

  const handleCreateGroup = (name: string) => {
    createGroup(name)
    messageUtils.custom(`Group ${name} created successfully! You can find it in the parts list.`, {
      duration: 5,
      showUpTo: 3,
      minTimeBetweenShows: 10,
    })
    setShowGroupNamingModal(false)
  }




  const handleScaleStart = (view: viewForScalerUI, position: Vector3, scaleDirection: "horizontal" | "vertical") => {
    //console.log("Scale started:", view, position, scaleDirection)
  }

  const getAllConnectedParts = (
    partId: string,
    line: LineInfo,
    latestSceneDataConnections: any,
    orderedParts: string[],
    processedParts = new Set<string>(),
    connectionChain: string[] = [],
    wasConnectedToMovableInChain = false
  ): {
    partId: string,
    connectedToId: string,
    sourceMarker: string,
    targetMarker: string,
    depth: number,
    firstParentConnectedToId: string,
    indirectlyConnectedToMiddle?: boolean,
    indirectlyConnectedToMiddleViaPartId?: string,
    connectionChain: string[],
    wasConnectedToMovableInChain: boolean,
    directlyConnectedToMiddle?: boolean,
    connectionChainAfter?: string[],
  }[] => {
    if (processedParts.has(partId)) {
      return []
    }
    processedParts.add(partId)
    // Include the current partId in the chain
    const currentChain = [...connectionChain, partId,]

    // Get all direct connections without filtering orderedParts yet
    const directConnections = latestSceneDataConnections
      .filter((connection: PartConnectionType) => {
        const connectedId = connection.partA.partId === partId ? connection.partB.partId : connection.partA.partId
        return (connection.partA.partId === partId || connection.partB.partId === partId)
          && !orderedParts.includes(connectedId)
      })
      .map((connection: PartConnectionType) => {
        const isPartA = connection.partA.partId === partId
        const connectedPartId = isPartA ? connection.partB.partId : connection.partA.partId
        const sourceMarker = isPartA ? connection.partA.markerName : connection.partB.markerName
        const targetMarker = isPartA ? connection.partB.markerName : connection.partA.markerName

        const isConnectedToMovable = line.markers.some(m =>
          m.marker.name === sourceMarker
          || m.marker.name === innerToOuter(sourceMarker)
          || m.marker.name === outerToInner(sourceMarker)
        ) && line.markers.some(m =>
          (m.marker.name === sourceMarker
            || m.marker.name === innerToOuter(sourceMarker)
            || m.marker.name === outerToInner(sourceMarker))
          && m.partId === partId
        )

        return {
          partId: connectedPartId,
          connectedToId: partId,
          firstParentConnectedToId: partId,
          sourceMarker,
          targetMarker,
          depth: 1,
          connectionChain: currentChain, // Use currentChain instead of creating new array
          wasConnectedToMovableInChain: wasConnectedToMovableInChain || isConnectedToMovable,
        }
      })

    const indirectConnections = directConnections
      .filter((connection: { partId: string, }) => !orderedParts.includes(connection.partId))
      .flatMap((connection: { partId: string, connectionChain: string[], wasConnectedToMovableInChain: boolean, }) =>
        getAllConnectedParts(
          connection.partId,
          line,
          latestSceneDataConnections,
          orderedParts,
          processedParts,
          connection.connectionChain, // Pass the current chain to recursive calls
          connection.wasConnectedToMovableInChain
        ).map((indirect) => ({
          ...indirect,
          depth: indirect.depth + 1,
          firstParentConnectedToId: partId,
          wasConnectedToMovableInChain: indirect.wasConnectedToMovableInChain,
        }))
      )

    // Combine all connections
    const allConnections = [...directConnections, ...indirectConnections,]

    // Create a Map to store unique connections, using partId as the key
    const uniqueConnections = new Map()

    allConnections
      .filter(connection => !orderedParts.includes(connection.partId))
      .forEach(connection => {
        const key = connection.partId
        // Only keep the connection with the shortest depth if there's a duplicate
        if (!uniqueConnections.has(key) || connection.depth < uniqueConnections.get(key).depth) {
          uniqueConnections.set(key, connection)
        }
      })

    // Convert back to array and sort by depth
    const result = Array.from(uniqueConnections.values()).sort((a, b) => a.depth - b.depth)
    //console.log("result", result, "orderedParts", orderedParts)
    return result
  }

  const storeOffsetsForMiddleConnectedPartPositionsUsingBaseParts = (offsetDistanceInMm: number, line: LineInfo, debug = false) => {
    const latestSceneData = getLatestSceneData()

    Object.entries(markerOffsetPositions.current).forEach(([partId, data,]) => {
      if (data.partLocation === "basePart") {
        if (markerOffsetPositions.current[partId].actionsApplied.includes("distributeMiddleConnectedParts")) {
          debug && console.log("already distributed middle connected parts for part", partId)
          return
        }

        const basePartComponent = getComponent(partId)
        if (!basePartComponent) { return }

        const partInfo = basePartComponent.getPartInfo()
        const isTube = partInfo.type.includes("tube")

        if (!isTube) {
          debug && console.log("skipping non-tube part since it can't have middle connected parts", partId)
          return
        }

        const offsetValueInCm = offsetDistanceInMm * 100

        const currentLengthInInches = ((partInfo.lengthNegativeSide + partInfo.length)
          * partInfo.segmentLength * (partInfo.segmentScaleFactor ?? 1)) + (partInfo.endSegmentLength + partInfo.startSegmentLength)

        const currentLengthInCM = currentLengthInInches * 2.54

        //not used here but good for debugging if you need to
        const newTotalLengthInCM = currentLengthInCM + offsetValueInCm

        // Get only the connected middle marker names
        const connectedMiddleMarkers = getConnectedMarkerNames(
          latestSceneData.connections,
          partId,
          "_0"
        )

        const connectedPartsToThisMiddle = getConnectedPartIds(
          latestSceneData.connections,
          partId,
          "_0"
        )

        if (connectedMiddleMarkers.length === 0) {
          debug && console.warn("no connected middle markers found for part", partId)
          return
        }

        // Initialize middleConnectedInfo if it doesn't exist
        markerOffsetPositions.current[partId].middleConnectedInfo = {}

        // Get all markers from the component
        const allComponentMarkers = basePartComponent.getEveryMarker()


        debug && console.log("running loop for these markers", connectedMiddleMarkers, "for part", partId)

        // Process each connected middle marker
        connectedMiddleMarkers.forEach((markerName) => {
          const middleMarker = allComponentMarkers.find(
            (marker: Mesh) => marker.name === markerName
          )
          if (!middleMarker) { return }

          // Get parts connected to this specific middle marker
          const connectedPartsWithMarkers = getConnectedPartsWithMarkers(
            latestSceneData.connections,
            partId,
            markerName
          )

          debug && console.log("connectedPartsWithMarkers", connectedPartsWithMarkers, "for part", partId)


          if (connectedPartsWithMarkers.length === 0) {
            return // Skip if no parts connected to this middle
          }

          // Get all markers from connected parts for raycasting
          const relevantMarkers: Mesh[] = []
          connectedPartsWithMarkers.forEach(({ connectedPartId, }) => {
            const component = getComponent(connectedPartId)
            if (component) {
              relevantMarkers.push(...component.getEveryMarker())
            }
          })

          // Update matrix world before raycasting
          middleMarker.updateMatrixWorld(true)

          // Perform raycast and get where the raycasts hit markers and also
          // where they were drawn. This also gives us the origins to use
          // for the two ends of the middle mesh
          const raycasts = getRaycastsFromMeshEnds(
            middleMarker,
            1,
            0.5,
            scene,
            cameraControls.current?.camera,
            0.01,
            relevantMarkers,
            seeRaycastsForEnds,
          )

          //console.log("raycasts", raycasts)

          if (!raycasts?.firstRaycastOriginPos) {
            debug && console.warn("no raycasts could be drawn for part, stopping function", partId)
            return
          }

          const { start, end, } = raycasts.firstRaycastOriginPos


          const markers: MarkersInInfo[] = markerOffsetPositions.current[partId].markers

          let startingVector3Point: Vector3
          const movingMarker = line.markers.find(m => m.partId === partId)

          //console.log("movingMarker", movingMarker)

          if (movingMarker) {
            const movingMarkerWorldPos = new Vector3()
            movingMarker.marker.getWorldPosition(movingMarkerWorldPos)

            const startDistance = start.distanceTo(movingMarkerWorldPos)
            const endDistance = end.distanceTo(movingMarkerWorldPos)

            startingVector3Point = startDistance < endDistance ? start.clone() : end.clone()
          } else {
            // Fallback to start if no moving marker found
            startingVector3Point = start.clone()
          }

          const randomColor = tinycolor.random()

          //console.log("startingVector3Point", startingVector3Point)
          //drawVector3Point(startingVector3Point, scene, randomColor.toHexString(), 0.03)

          const filteredMarkers = markers.filter((marker: MarkersInInfo) => !marker.markerName.includes("_0"))

          // Find closest markers to start and end positions
          let closestStartMarker: MarkersInInfo | null = null
          let closestEndMarker: MarkersInInfo | null = null
          let minStartDistance = Infinity
          let minEndDistance = Infinity

          filteredMarkers.forEach(marker => {
            if (!marker.newPosition) { return }

            const meshMarker = allComponentMarkers.find((m: Mesh) => m.name === marker.markerName)
            if (!meshMarker) { return }

            const worldPos = new Vector3()
            meshMarker.getWorldPosition(worldPos)

            const startDistance = worldPos.distanceTo(start)
            const endDistance = worldPos.distanceTo(end)

            if (startDistance < minStartDistance) {
              minStartDistance = startDistance
              closestStartMarker = marker as MarkersInInfo
            }

            if (endDistance < minEndDistance) {
              minEndDistance = endDistance
              closestEndMarker = marker as MarkersInInfo
            }
          })

          // Process start and end results
          const startIds = (raycasts.uniquePartIds.start as string[])?.filter(id =>
            connectedPartsToThisMiddle.includes(id)
          ) || []

          const endIds = (raycasts.uniquePartIds.end as string[])?.filter(id =>
            connectedPartsToThisMiddle.includes(id)
          ) || []

          // Calculate middle parts
          const startAndEndIds = new Set([...startIds, ...endIds,])
          const middleOnlyIds = connectedPartsToThisMiddle.filter(id =>
            !startAndEndIds.has(id)
          )

          // Calculate offsets based on movable markers
          const baseOffset = Number(markerOffsetPositions.current[partId]?.offsetPerTube) || 0
          const startOffset = Number((closestStartMarker as unknown as MarkersInInfo)?.isMovable ? baseOffset : 0) || 0
          const endOffset = Number((closestEndMarker as unknown as MarkersInInfo)?.isMovable ? baseOffset : 0) || 0
          const storePartInfo = (partIds: string[], offset: number, isMiddle = false) => {
            partIds.forEach(connectedPartId => {
              const connectedPartInfo = connectedPartsWithMarkers.find(
                info => info.connectedPartId === connectedPartId
              )

              if (!connectedPartInfo) {
                debug && console.warn("connectedPartInfo not found for connecting part", connectedPartId, "sourcepart being:", partId, "connectedPartsWithMarkers", connectedPartsWithMarkers)
                return
              }

              // Get the source and connected markers from the components
              const baseComponent = getComponent(partId)
              const connectedComponent = getComponent(connectedPartId)

              if (!baseComponent || !connectedComponent) {
                debug && console.warn("baseComponent or connectedComponent not found for part", connectedPartId)
                return
              }

              const sourceMarkerMesh = baseComponent.getEveryMarker()
                .find((marker: Mesh) => marker.name === connectedPartInfo.sourceMarker)
              const connectedMarkerMesh = connectedComponent.getEveryMarker()
                .find((marker: Mesh) => marker.name === connectedPartInfo.connectedMarker)

              if (!sourceMarkerMesh || !connectedMarkerMesh) {
                console.warn("sourceMarkerMesh or connectedMarkerMesh not found for part", connectedPartId)
                return
              }

              connectedMarkerMesh.updateMatrixWorld(true)
              sourceMarkerMesh.updateMatrixWorld(true)

              const worldPos = new Vector3()
              connectedMarkerMesh.getWorldPosition(worldPos)

              let positionInCM = 0
              let percentageOfLength = 0
              let middleOffsetToUse = offset
              let localPosOfConnectedMarker = new Vector3()

              if (isMiddle) {
                //do not use the invert matrix method because it's not reliable. use world to local instead
                const localPosOfStartingVector3Point = sourceMarkerMesh.worldToLocal(startingVector3Point.clone())
                localPosOfConnectedMarker = sourceMarkerMesh.worldToLocal(worldPos.clone())

                //the logic is written this way because you want the part that is closest to the moving marker to move the most. that's why you basically reverse the order by subtracting the position from the total length

                debug && console.log("localPosOfConnectedMarker", localPosOfConnectedMarker, "localPosOfStartingVector3Point", localPosOfStartingVector3Point)
                const distanceInMM = Math.abs(localPosOfConnectedMarker.x - localPosOfStartingVector3Point.x)


                positionInCM = (currentLengthInCM - (distanceInMM * 100))

                debug && console.log("positionInCM", positionInCM, "currentLengthInCM", currentLengthInCM, "distanceInMM", distanceInMM)
                percentageOfLength = positionInCM / currentLengthInCM

                // Calculate middle offset based on position along the tube
                middleOffsetToUse = percentageOfLength * (markerOffsetPositions.current[partId]?.offsetPerTube || 0)
              }

              // Initialize middleConnectedInfo if it doesn't exist
              if (!markerOffsetPositions.current[partId]?.middleConnectedInfo) {
                markerOffsetPositions.current[partId] = {
                  ...markerOffsetPositions.current[partId],
                  middleConnectedInfo: {},
                }
              }

              const middleConnectedInfo = markerOffsetPositions.current[partId]?.middleConnectedInfo
              if (middleConnectedInfo && !middleConnectedInfo[connectedPartId]) {
                middleConnectedInfo[connectedPartId] = {
                  connectedToMarker: connectedPartInfo.sourceMarker,
                  position: startIds.includes(connectedPartId) ? "start"
                    : endIds.includes(connectedPartId) ? "end" : "middle",
                  positionInLocalCoordinateOfConnectingMarker: localPosOfConnectedMarker,
                  positionInCM,
                  percentageOfLength,
                  middleOffsetToUse,
                }

              } else {
                debug && console.warn("Already stored info for part", connectedPartId)
              }
            })
          }

          // Call the function for each type of part
          storePartInfo(startIds, startOffset)
          storePartInfo(endIds, endOffset)
          storePartInfo(middleOnlyIds, baseOffset, true)

        })

        markerOffsetPositions.current[partId].actionsApplied.push("distributeMiddleConnectedParts")
      }
    })
  }

  const incrementNewPositionsToMiddleConnectedParts = (line: LineInfo, offsetDistanceInMm: number, debug = false) => {
    // Iterate through all parts in markerOffsetPositions

    const offsetVector = new Vector3()
    if (line.offsetDirection) {
      offsetVector.copy(line.offsetDirection)
    }


    Object.entries(markerOffsetPositions.current).forEach(([partId, partData,]) => {
      // Check if this part has been processed by distributeMiddleConnectedParts
      if (partData.actionsApplied.includes("distributeMiddleConnectedParts") && partData.middleConnectedInfo) {

        const component = getComponent(partId)
        if (!component) {
          debug && console.warn("component not found for part", partId)
          return
        }

        // Process each connected part from middleConnectedInfo
        Object.entries(partData.middleConnectedInfo).forEach(([connectedPartId, connectedInfo,]) => {
          const connectedPartData = markerOffsetPositions.current[connectedPartId]
          if (!connectedPartData) {
            debug && console.warn("connected part data not found for part", connectedPartId)
            return
          }

          if (connectedPartData.actionsApplied.includes("addedOffsetToDistributedMiddlePart") || connectedPartData.actionsApplied.includes("addedOffsetToDistributedMiddlePartChain")) {
            debug && console.log("already added offset to distributed middle part", connectedPartId)
            return
          }

          let oneMarkerAppliedConnectedPart = false

          // Apply offset to all markers of the connected part
          connectedPartData.markers.forEach(marker => {

            if (connectedInfo.middleOffsetToUse && connectedPartData.offsetPerTube) {
              if (Math.abs(marker.cumulativeOffset) + Math.abs(connectedInfo.middleOffsetToUse) > Math.abs(connectedPartData.offsetPerTube)) {
                //you can never go over the offset per tube
                //console.log("cumulativeOffset + (connectedInfo.middleOffsetToUse ?? 0) > (connectedPartData.offsetPerTube ?? 0)", marker.cumulativeOffset + (connectedInfo.middleOffsetToUse ?? 0), (connectedPartData.offsetPerTube ?? 0))
                return
              }
            }

            if (marker.newPosition && partData.offsetPerTube) {
              // Store previous position
              marker.prevPosition = marker.newPosition.clone()

              // Calculate and store new position
              marker.newPosition = marker.newPosition.clone().add(
                offsetVector.clone().multiplyScalar(connectedInfo.middleOffsetToUse ?? 0)
              )
              debug && visualizePositionsWithArrow(scene, marker.prevPosition, marker.newPosition, {
                color: tinycolor.random().toHexString(),
                timer: 10000,
                dontAddToScene: true,
                numbers: [shortenMarkerName(marker.markerName), shortenMarkerName(marker.markerName),],
              })
              marker.middleOffsetUsed = connectedInfo.middleOffsetToUse
              connectedInfo.middleOffsetUsed = connectedInfo.middleOffsetToUse
              oneMarkerAppliedConnectedPart = true
            } else {
              debug && console.warn(
                `Skipping marker ${marker.markerName} update - missing position or offset for part ${connectedPartId}`
              )
            }
          })

          oneMarkerAppliedConnectedPart && connectedPartData.actionsApplied.push("addedOffsetToDistributedMiddlePart")

          // Get connection chain from the ref
          const connectionChainAfter = markerOffsetPositions.current[connectedPartId]?.connectionChainAfter

          if (!connectionChainAfter) {
            debug && console.warn("no connection chain after found for part", connectedPartId)
            return
          }

          debug && console.log("connectionChainAfter", connectionChainAfter, "for part", connectedPartId)

          if (connectionChainAfter.length > 0) {
            connectionChainAfter.forEach(chainPartId => {
              const chainPartData = markerOffsetPositions.current[chainPartId]

              if (chainPartData.actionsApplied.includes("addedOffsetToDistributedMiddlePartChain") || chainPartData.actionsApplied.includes("addedOffsetToDistributedMiddlePart")) {
                debug && console.log("already added offset to distributed middle part chain", chainPartId)
                return
              }

              if (chainPartData) {
                let oneMarkerApplied = false
                chainPartData.markers.forEach(marker => {

                  if (connectedInfo.middleOffsetToUse && connectedPartData.offsetPerTube) {
                    if ((Math.abs(marker.cumulativeOffset) + Math.abs(connectedInfo.middleOffsetToUse)) > Math.abs(connectedPartData.offsetPerTube)) {
                      //you can never go over the offset per tube
                      debug && console.log("going over cumulative offset for part", chainPartId, "cumulativeOffset + (connectedInfo.middleOffsetToUse ?? 0) > (connectedPartData.offsetPerTube ?? 0)", marker.cumulativeOffset + (connectedInfo.middleOffsetToUse ?? 0), (connectedPartData.offsetPerTube ?? 0))
                      return
                    }
                  }

                  if (marker.newPosition && partData.offsetPerTube) {
                    // Store previous position
                    marker.prevPosition = marker.newPosition.clone()

                    // Calculate and store new position
                    marker.newPosition = marker.newPosition.clone().add(
                      offsetVector.clone().multiplyScalar(connectedInfo.middleOffsetToUse ?? 0)
                    )

                    marker.middleOffsetUsed = connectedInfo.middleOffsetToUse

                    connectedInfo.middleOffsetUsed = connectedInfo.middleOffsetToUse
                    oneMarkerApplied = true
                  } else {
                    debug && console.warn(
                      `Skipping marker ${marker.markerName} update - missing position or offset for part ${chainPartId}`
                    )
                  }
                })
                oneMarkerApplied && chainPartData.actionsApplied.push("addedOffsetToDistributedMiddlePartChain")
              }
            })
          } else {
            debug && console.log("no connection chain after found for part", connectedPartId)
          }

        })
      }
    })
  }

  const scalerUIStoreMarkerOffsets = (view: viewForScalerUI, line: LineInfo, totalDistance: number, visualize = true) => {
    markerOffsetPositions.current = {}

    // Get the sequences for this orientation
    const sequences = view.normalLineSequences || [[],]

    // Calculate total number of tubes across all sequences
    const allTubes = sequences.flatMap(sequence =>
      sequence.filter(partId => {
        const component = getComponent(partId)
        return component?.getPartInfo().type.includes("tube")
      })
    )

    //const offsetPerTube = totalDistance / allTubes.length
    const offsetVector = new Vector3()
    if (line.offsetDirection) {
      offsetVector.copy(line.offsetDirection)
    }

    const allOrderedParts = sequences.flatMap(sequence => sequence)

    /*if (line.position === "top" || line.position === "left") {
      sequences.reverse()
    }*/


    // Process each line sequence separately
    sequences.forEach(lineSequence => {
      // Reset cumulative offset for each new line
      let cumulativeOffset = 0

      const orderedPartsOfThisSequence = [...lineSequence,]

      const tubesInThisSequenceCount = lineSequence.reduce((count, partId) => {
        const component = getComponent(partId)
        return count + (component?.getPartInfo().type.includes("tube") ? 1 : 0)
      }, 0)

      /*const sequenceTotalLength = lineSequence.reduce((total, partId) => {
        const component = getComponent(partId)
        if (!component) { return total }

        // Get depth which represents length in meters
        const partLength = component.getBoundingBoxMesh().userData.getDimensions().depth
        console.log("partLength for partId", partId, partLength)
        return total + partLength
      }, 0)

      debug && console.log(sequenceTotalLength, "sequenceTotalLength")
      debug && console.log(view.obb?.width, "obb width", view.obb?.height, "obb height", view.obb?.depth, "obb depth")*/

      //this might be a bit counterintuitive, but the distance is being grown by the eye of the user so its distance per sequence really - so totalDistance is = total distance per sequence
      const resultOffsetPerTubeForSequence = totalDistance / tubesInThisSequenceCount
      const offsetPerTubeForSequence = resultOffsetPerTubeForSequence === Infinity ? 0 : resultOffsetPerTubeForSequence

      if (offsetPerTubeForSequence === Infinity) {
        console.warn("offsetPerTubeForSequence is Infinity, this should not happen")
      }

      visualize && console.log("tubesInThisSequenceCount", tubesInThisSequenceCount, "offsetPerTubeForSequence", offsetPerTubeForSequence)

      // Apply same reversal logic per line
      if (line.position === "top" || line.position === "left") {
        orderedPartsOfThisSequence.reverse()
      }

      visualize && console.log("orderedParts order we're using", orderedPartsOfThisSequence)

      // Process each part in this line sequence
      orderedPartsOfThisSequence.forEach((partId) => {
        const component = getComponent(partId)
        if (!component) { return }

        const partInfo = component.getPartInfo()
        const isTube = partInfo.type.includes("tube")
        const isSegmentedTube = partInfo.type.includes("segmented_tube_part")
        //needed to account for the non-zero middles of old designs
        const markers = isSegmentedTube ? component.getAllMarkers(true) : component.getEveryMarker()

        /*const widthOfPartFromBoxMesh = getComponent(partId)?.getBoundingBoxMesh().userData.getDimensions().depth

        const offsetBasedOnPercentage = widthOfPartFromBoxMesh / view.obb!.width
        const offsetBasedOnPercentageOfSequence = sequenceTotalLength / view.obb!.width
        let distanceToUse = offsetBasedOnPercentage * totalDistance

        const worldCenter = component.getBoundingBoxMesh().userData.getWorldCenter()

        //draw a raycsating from this wolrd center towards the raycasting plane and see if it hits the plane

        const planePosition = new Vector3()
        rayCastingPlaneForActiveLine.getWorldPosition(planePosition)
        const toPlane = new Vector3().subVectors(planePosition, worldCenter)
        // Check if normal points towards or away from plane
        const dotProduct = toPlane.dot(view.normal)

        // Use either normal or its inverse, but keep it straight
        const directionToUse = new Vector3()
        if (dotProduct > 0) {
          directionToUse.copy(view.normal)
        } else {
          directionToUse.copy(view.normal).negate()
        }
        directionToUse.normalize()

        const raycaster = new Raycaster()
        raycaster.set(worldCenter, directionToUse)

        const arrowHelper = new ArrowHelper(directionToUse, worldCenter, 1, 0x0000ff, 0.05, 0.05)
        scene.add(arrowHelper)

        const intersection = raycaster.intersectObject(rayCastingPlaneForActiveLine)
        console.log(intersection, "intersection")

        let adjustedDistance = undefined
        let percentageUsed = undefined

        // If we have an intersection, adjust the distance calculation
        if (intersection && intersection[0]) {
          adjustedDistance = intersection[0].distance - (widthOfPartFromBoxMesh / 2)
          percentageUsed = adjustedDistance / view.obb!.width
          distanceToUse = (1 - percentageUsed) * totalDistance
        }

        console.log("adjustedDistance", adjustedDistance, "for part", partId, "where the width of the part is", widthOfPartFromBoxMesh, "and the total distance is", totalDistance, "distance to use is", distanceToUse, "percentageUsed", percentageUsed)

        if (!adjustedDistance) {
          console.warn("no adjusted distance found for part", partId, "widthOfPartFromBoxMesh", widthOfPartFromBoxMesh, "view.obb!.width", view.obb!.width, "totalDistance", totalDistance)
        }

        console.log("offsetDistanceBasedOnPercentage", offsetBasedOnPercentage)
        console.log("distanceToUse", distanceToUse, "totalDistance", totalDistance)
        console.log("offsetforthistubesequence old", offsetPerTubeForSequence)*/

        // Get connected parts (same as before)
        const latestSceneData = getLatestSceneData()
        const connectedPartsRecursive = getAllConnectedParts(partId, line, latestSceneData.connections, allOrderedParts)
        const connectedPartsRecursiveWithMiddleCxInfo = connectedPartsRecursive.map(part => {

          const enhanced = { ...part, }

          // 1. Check if sourceMarker includes _0 and depth is 1
          if (part.sourceMarker.includes("_0") && part.depth === 1) {
            enhanced.directlyConnectedToMiddle = true
          }

          // 3. Find all parts that have this part in their connectionChain
          const connectionChainAfter = connectedPartsRecursive
            .filter(otherPart =>
              otherPart.connectionChain.includes(part.partId)
            )
            .map(otherPart => otherPart.partId)

          if (enhanced.directlyConnectedToMiddle) {
            enhanced.connectionChainAfter = connectionChainAfter
          }

          // 3. Check if this part's connectionChain includes any directly connected middle parts
          const hasMiddleConnectedPartInChain = connectedPartsRecursive.some(otherPart =>
            otherPart.sourceMarker.includes("_0")
            && otherPart.depth === 1
            && part.connectionChain.includes(otherPart.partId)
          )

          if (hasMiddleConnectedPartInChain && !enhanced.directlyConnectedToMiddle) {
            enhanced.indirectlyConnectedToMiddle = true
            // Find the first middle-connected part in the chain
            const middleConnectedPart = connectedPartsRecursive.find(otherPart =>
              otherPart.sourceMarker.includes("_0")
              && otherPart.depth === 1
              && part.connectionChain.includes(otherPart.partId)
            )
            if (middleConnectedPart) {
              enhanced.indirectlyConnectedToMiddleViaPartId = middleConnectedPart.partId
            }
          }

          return enhanced
        })




        visualize && console.log("connectedPartsRecursive", connectedPartsRecursive)
        visualize && console.log("connectedPartsRecursiveWithMiddleCxInfo", connectedPartsRecursiveWithMiddleCxInfo)

        const randomColor = tinycolor.random().toHexString()
        const timeToDisplayArrow = 10000


        // Process markers for this part
        markers.forEach((marker: Mesh) => {
          if (marker.name.includes("mesh")) { return }

          marker.updateMatrixWorld(true)

          const worldPos = new Vector3()
          marker.getWorldPosition(worldPos)

          const isMovable = line.markers.some(m =>
            (
              m.marker.name === marker.name
              || m.marker.name === innerToOuter(marker.name)
              || m.marker.name === outerToInner(marker.name)
            )
            && m.partId === partId
          )

          //cumulative offset key in the markerOffsetPosition should store the offset we used for positioning the part

          //initialize the partId in the markerOffsetPositions if it doesn't exist
          if (!markerOffsetPositions.current[partId]) {
            markerOffsetPositions.current[partId] = {
              markers: [],
              partLocation: "basePart",
              actionsApplied: [],
              connectionChain: [],
              connectedAtDepth: 0,
              wasConnectedToMovableInChain: false,
              sourceMarker: "",
              targetMarker: "",
              offsetPerTube: offsetPerTubeForSequence,
            }
          }

          if (isMovable) {
            if (isTube) {
              const totalOffset = cumulativeOffset + offsetPerTubeForSequence
              const offsetPos = worldPos.clone().add(offsetVector.clone().multiplyScalar(totalOffset))



              if (visualize) {
                const debugObjects = visualizePositionsWithArrow(scene, worldPos, offsetPos, {
                  color: randomColor,
                  timer: timeToDisplayArrow,
                  dontAddToScene: true,
                  numbers: [shortenMarkerName(marker.name), shortenMarkerName(marker.name),],
                })

                component.visualDebugSetter(`Movable tube ${marker.name} position incremented by ${totalOffset}`, "Incrementing base part position: movable", false, debugObjects)

              }

              markerOffsetPositions.current[partId].markers.push({
                partId,
                markerName: marker.name,
                newPosition: offsetPos.clone(),
                isMovable: true,
                connectedAtDepth: 0,
                cumulativeOffset: totalOffset,
                offsetPerTube: offsetPerTubeForSequence,
              })

              if (visualize) {
                drawVector3Point(offsetPos, scene, 0xff0000, 0.009, 9000, true, undefined,
                  `${isTube ? "tube" : "connector"} ${partId}_${marker.name}`, false, "cylinder")
              }
            } else {
              const offsetPos = worldPos.clone().add(offsetVector.clone().multiplyScalar(cumulativeOffset))

              if (visualize) {
                const debugObjects = visualizePositionsWithArrow(scene, worldPos, offsetPos, {
                  color: randomColor,
                  timer: timeToDisplayArrow,
                  dontAddToScene: true,
                  numbers: [shortenMarkerName(marker.name), shortenMarkerName(marker.name),],
                })

                component.visualDebugSetter(`Movable connector ${marker.name} position incremented by ${cumulativeOffset}`, "Incrementing base part position: movable", false, debugObjects)
              }

              markerOffsetPositions.current[partId].markers.push({
                partId,
                markerName: marker.name,
                connectedAtDepth: 0,
                newPosition: offsetPos.clone(),
                isMovable: true,
                cumulativeOffset,
                offsetPerTube: offsetPerTubeForSequence,
              })
            }
          } else {
            const offsetPos = worldPos.clone().add(offsetVector.clone().multiplyScalar(cumulativeOffset))

            if (visualize) {
              const debugObjects = visualizePositionsWithArrow(scene, worldPos, offsetPos, {
                color: randomColor,
                timer: timeToDisplayArrow,
                dontAddToScene: true,
                numbers: [shortenMarkerName(marker.name), shortenMarkerName(marker.name),],
              })

              component.visualDebugSetter(`Non-movable marker ${marker.name} position incremented by ${cumulativeOffset}`, "Incrementing base part position", false, debugObjects)
            }

            markerOffsetPositions.current[partId].markers.push({
              partId,
              connectedAtDepth: 0,
              markerName: marker.name,
              newPosition: offsetPos.clone(),
              isMovable: false,
              cumulativeOffset,
              offsetPerTube: offsetPerTubeForSequence,
            })

            if (visualize) {
              drawVector3Point(offsetPos, scene, 0x00ff00, 0.005, 9000, true, undefined,
                `${isTube ? "tube" : "connector"} ${partId}_${marker.name}`, false, "cylinder")
            }
          }
        })

        visualize && console.log("connectedParts", connectedPartsRecursive, "partId", partId, "cumulativeOffset", cumulativeOffset, "offsetPerTube", offsetPerTubeForSequence)


        const offsetToUse = cumulativeOffset

        // Process connected parts (same logic as before)
        connectedPartsRecursiveWithMiddleCxInfo.forEach(({
          partId: connectedPartId,
          connectedToId,
          sourceMarker,
          targetMarker,
          wasConnectedToMovableInChain,
          connectionChain,
          firstParentConnectedToId,
          directlyConnectedToMiddle,
          connectionChainAfter,
          indirectlyConnectedToMiddle,
          indirectlyConnectedToMiddleViaPartId,
          depth,
        }) => {


          if (!markerOffsetPositions.current[connectedPartId]) {
            markerOffsetPositions.current[connectedPartId] = {
              markers: [],
              partLocation: "connectedPart",
              actionsApplied: [],
              connectedAtDepth: depth,
              offsetPerTube: offsetPerTubeForSequence,
              connectionChain,
              sourceMarker,
              targetMarker,
              wasConnectedToMovableInChain,
              directlyConnectedToMiddle,
              connectionChainAfter,
              connectedToId,
              indirectlyConnectedToMiddle,
              indirectlyConnectedToMiddleViaPartId,
            }
          }

          visualize && console.log("connectedPartsRecursive connectedPartId", connectedPartId, "partId", partId, "offsetPerTubeForSequence", offsetPerTubeForSequence, "cumulativeOffset", cumulativeOffset)

          // Check if we already processed this part
          // Check if we already processed this part
          const existingEntry = markerOffsetPositions.current[connectedPartId]?.markers.find(
            entry => entry.partId === connectedPartId
          )

          // Check if ANY part in the connection chain was connected to a movable marker
          const wasConnectedToMovable = connectedPartsRecursive.find(
            connection => connection.partId === connectedPartId
          )?.wasConnectedToMovableInChain || false

          visualize && console.log(wasConnectedToMovable, "wasConnectedToMovable", partId, "partId")

          // If any part in the chain was connected to a movable marker,
          // use the offset with offsetPerTube

          const connectedComponent = getComponent(connectedPartId)
          if (!connectedComponent) { return }

          //important to only do this when you're dealing with a tube
          const offsetToUse = (wasConnectedToMovable && isTube)
            ? cumulativeOffset + offsetPerTubeForSequence
            : cumulativeOffset

          // New logic for handling existing entries based on depth
          if (existingEntry) {
            // For depth 0 or 1, keep the entry with lowest depth
            if ((depth <= 1 && existingEntry.connectedAtDepth && existingEntry.connectedAtDepth <= 1)) {
              if (existingEntry.connectedAtDepth <= depth) {
                visualize && console.log(`Keeping existing entry for ${connectedPartId} due to lower/equal depth: ${existingEntry.connectedAtDepth} vs ${depth},`)
                visualize && connectedComponent.visualDebugSetter(`Keeping existing entry for ${connectedPartId} due to lower/equal depth: ${existingEntry.connectedAtDepth} vs ${depth}. cumulative offset was: ${existingEntry.cumulativeOffset} vs ${offsetToUse}. Connected to: ${existingEntry.connectedToId} with first parent being ${existingEntry.firstParentConnectedToId}`, "connected part position increment", false)
                return
              }
            }
            // For depth > 1, keep the entry with highest cumulative offset
            else if (depth > 1 && existingEntry.connectedAtDepth && existingEntry.connectedAtDepth > 1) {
              if (existingEntry.cumulativeOffset >= offsetToUse) {
                visualize && console.log(`Keeping existing entry for ${connectedPartId} due to higher/equal cumulative offset: ${existingEntry.cumulativeOffset} vs ${offsetToUse}`)
                visualize && connectedComponent.visualDebugSetter(`Keeping existing entry for ${connectedPartId} due to higher/equal cumulative offset: ${existingEntry.cumulativeOffset} vs ${offsetToUse}`, "connected part position increment", false)
                return
              }
            }
            // Remove the existing entry as we'll add the new one
            markerOffsetPositions.current[connectedPartId].markers = markerOffsetPositions.current[connectedPartId].markers.filter(
              entry => entry.partId !== connectedPartId
            )
          }

          const isConnectedPartASegmentedTube = connectedComponent.getPartInfo().type.includes("segmented_tube_part")
          //needed to account for the non-zero middles of old designs
          const connectedMarkers = isConnectedPartASegmentedTube ? connectedComponent.getAllMarkers(true) : connectedComponent.getEveryMarker()

          const randomColorMuchDarker = tinycolor(randomColor).darken(30)
            .toHexString()
          connectedMarkers.forEach((marker: Mesh) => {
            if (marker.name.includes("mesh")) { return }

            const worldPos = new Vector3()
            marker.getWorldPosition(worldPos)
            const offsetPos = worldPos.clone().add(offsetVector.clone().multiplyScalar(offsetToUse))

            if (visualize) {
              const debugObjects = visualizePositionsWithArrow(scene, worldPos, offsetPos, {
                timer: timeToDisplayArrow,
                color: randomColorMuchDarker,
                dontAddToScene: true,
                numbers: [shortenMarkerName(marker.name), shortenMarkerName(marker.name),],
              })

              connectedComponent.visualDebugSetter(`Setting postioning using part ${connectedPartId} with offset: ${offsetToUse}, was connectable ${wasConnectedToMovable}, cumulative offset was ${cumulativeOffset}, offsetPerTube was ${offsetPerTubeForSequence} with depth ${depth}`, "connected part position increment", false, debugObjects)
            }

            markerOffsetPositions.current[connectedPartId].markers.push({
              partId: connectedPartId,
              markerName: marker.name,
              newPosition: offsetPos.clone(),
              isMovable: false,
              cumulativeOffset: offsetToUse,
              offsetPerTube: offsetPerTubeForSequence,
              connectedToId,
              firstParentConnectedToId,
              wasConnectedToMovableMarker: wasConnectedToMovable,
              connectedAtDepth: depth,
            })

            if (visualize) {
              drawVector3Point(offsetPos, scene, 0x0000ff, 0.005, 9000, true, undefined,
                `connected ${connectedPartId}_${marker.name}`, false, "cylinder")
            }
          })
        })

        if (isTube) {
          visualize && console.log("incrementing cumulativeOffset", cumulativeOffset, "offsetPerTubeForSequence", offsetPerTubeForSequence)
          cumulativeOffset += offsetPerTubeForSequence
        }
      })
    })


    return markerOffsetPositions.current
  }


  const getPartIndexInViewOrder = (partId: string, view: viewForScalerUI): number => {
    // Check if view and partsOrder exist
    if (!view || !view.partsOrder) {
      return -1
    }

    // Return the index of the partId in the partsOrder array
    // Will return -1 if not found
    return view.partsOrder.indexOf(partId)
  }
  const handleScaleEnd = async (view: viewForScalerUI, line: LineInfo, distance: number, scaleDirection: "horizontal" | "vertical" | null, callback?: () => void, debug = false) => {
    if (!scaleDirection) {
      console.log("❌ No scale direction provided")
      return
    }

    const randomHistoryKey = crypto.randomUUID()

    setAutofocusMode(false)

    debug && console.log("view", view, "line", line, "distance", distance, "scaleDirection", scaleDirection)

    const distanceConvertedToInches = distance * 100 / 2.54

    debug && console.log("distanceConvertedToInches", distanceConvertedToInches)

    let distanceWithOffsetVector3: Vector3 | null = null

    if (line.offsetDirection) {
      // Create the offset using the stored direction
      const offset = new Vector3()
      offset.copy(line.offsetDirection).multiplyScalar(distance)
      distanceWithOffsetVector3 = offset
    }

    debug && console.log(distanceWithOffsetVector3, "distanceWithOffsetVector3", distance, "distance", line.offsetDirection, "line.offsetDirection")

    scalerUIStoreMarkerOffsets(view, line, distance, showMarkerPositionForBaseParts)

    storeAndIncrementMiddles && storeOffsetsForMiddleConnectedPartPositionsUsingBaseParts(distance, line, middlePositionLogs)

    if (line) {
      storeAndIncrementMiddles && incrementNewPositionsToMiddleConnectedParts(line, distance, middlePositionLogs)
    } else {
      console.warn("no line found, skipping incrementNewPositionsToMiddleConnectedParts")
    }

    debug && console.log("markerOffsetPositions", markerOffsetPositions.current)

    // PHASE 1: Position base parts first
    debug && console.log("🔄 PHASE 1: Positioning base parts")

    const baseParts = Object.keys(markerOffsetPositions.current)
      .filter(partId =>
        markerOffsetPositions.current[partId].partLocation === "basePart"
      )

    if (baseParts.length === 0) {
      debug && console.warn("⚠️ No base parts found")
      return
    }

    for (const partId of baseParts) {
      debug && console.log("📍 Positioning base part:", partId)

      const component = getComponent(partId)
      if (!component) {
        console.warn("⚠️ No component found for part:", partId)
        continue
      }

      const partInfo = component.getPartInfo()
      const initialMarkerName = partInfo.initialMarkerName || partInfo.originMarkerName
      const isTube = partInfo.type.includes("tube")
      const partPrefix = isTube ? "T_" : "C_"

      const markerOffset = markerOffsetPositions.current[partId]?.markers.find(
        offset => offset.partId === partId && (offset.markerName === initialMarkerName)
      )

      const movableMarker = markerOffsetPositions.current[partId]?.markers.find(
        offset => offset.partId === partId && (offset.isMovable)
      )

      if (markerOffset) {

        const valueInCm = markerOffset.offsetPerTube * 100
        const valueInInches = valueInCm / 2.54

        const partIndexInViewOrder = getPartIndexInViewOrder(partId, view)

        debug && component.visualDebugSetter(`Position update ${partId.slice(-4)} using initialMarker: ${initialMarkerName} and using offset: ${markerOffset.cumulativeOffset.toFixed(2)}, in inches ${(markerOffset.cumulativeOffset * 100 / 2.54).toFixed(2)}, is connected to ${markerOffset.connectedToId?.slice(-4)} with depth of ${markerOffset.connectedAtDepth}, index in view order: ${partIndexInViewOrder}`)
        debug && console.log("📐 Position update for", isTube ? "tube" : "connector", ":", {
          partId,
          from: partInfo.position,
          to: markerOffset.newPosition,
          initialMarker: initialMarkerName,
        })

        if (isTube && markerOffset.offsetPerTube) {
          debug && component.visualDebugSetter(`Updating length: ${partPrefix}${partId.slice(-4)} from ${partInfo.length.toFixed(2)} - adding ${valueInInches.toFixed(2)} in inches`)
          debug && console.log("📏 Updating tube length:", {
            partId,
            newAddedLengthInInches: valueInInches,
          })
          markerOffsetPositions.current[partId].actionsApplied ??= []
          markerOffsetPositions.current[partId].actionsApplied.push("basePart_updateLength")

          await component.addToLength(markerOffset.offsetPerTube, movableMarker?.markerName, movableMarker?.newPosition, undefined, segmentedTubePositionWithMarker ?? false, randomHistoryKey)

          // Store the offset that was applied
        } else {
          debug && component.visualDebugSetter(`Updating position for ${partPrefix}${partId.slice(-4)} using offset: ${markerOffset.cumulativeOffset.toFixed(2)} and marker: ${markerOffset.markerName}`)
          await component.updatePositionAndRotation(markerOffset.newPosition, partInfo.rotation, undefined, randomHistoryKey)
          markerOffsetPositions.current[partId].actionsApplied ??= []
          markerOffsetPositions.current[partId].actionsApplied.push("basePart_positioned")
        }

        debug && console.log("✅ Updated position for:", partId)
        debug && component.visualDebugSetter(`Updated position for ${partPrefix}${partId.slice(-4)}`)
      }
    }

    const remainingParts = Object.keys(markerOffsetPositions.current)
      .filter(partId => {
        const actions = markerOffsetPositions.current[partId].actionsApplied || []
        return !actions.some(action => action.includes("basePart"))
      })

    // PHASE 2: Position connected parts
    debug && console.log("🔄 PHASE 2: Positioning connected parts")
    for (const partId of remainingParts) {
      const component = getComponent(partId)
      if (!component) { continue }

      const partInfo = component.getPartInfo()
      const initialMarkerName = partInfo.initialMarkerName || partInfo.originMarkerName
      const isPartATube = partInfo.type.includes("tube")
      const partPrefix = isPartATube ? "T_" : "C_"

      const partData = markerOffsetPositions.current[partId]
      if (!partData) {
        console.warn("⚠️ No marker data found for part:", partId)
        continue
      }

      const markerOffset = partData.markers.find(m => m.markerName === initialMarkerName)
      if (!markerOffset) {
        console.warn("⚠️ No marker offset found for initial marker:", initialMarkerName)
        continue
      }

      const allMarkers = component.getEveryMarker()
      const initialMarker = allMarkers.find((marker: Mesh) => marker.name === initialMarkerName)

      debug && console.log("📐 Position update for remaining part:", {
        partId,
        connectedToId: markerOffset.connectedToId,
        firstParentConnectedToId: markerOffset.firstParentConnectedToId,
        appliedOffset: markerOffset.cumulativeOffset,
        from: partInfo.position,
        to: markerOffset.newPosition,
        initialMarker: initialMarkerName,
      })
      debug && component.visualDebugSetter(`📐 Position update for ${partId.slice(-4)}: appliedOffset: ${markerOffset.cumulativeOffset.toFixed(2)}, connectedTo: ${markerOffset.connectedToId?.slice(-4)}, initialMarker: ${initialMarkerName}`)

      if (partInfo.type.includes("segmented_tube_part_type")) {
        debug && console.log("markerOffset.newPosition middle", markerOffset.newPosition)
        await component.setPositionWithIdentifiedMarker(
          markerOffset.markerName,
          markerOffset.newPosition,
          segmentedTubePositionWithMarker,
          randomHistoryKey
        )
      } else if (partInfo.type.includes("tube_part_type")) {
        await component.setPositionWithMarker(
          initialMarker,
          markerOffset.newPosition,
          randomHistoryKey
        )
      } else {
        await component.updatePositionAndRotation(
          markerOffset.newPosition,
          partInfo.rotation,
          undefined,
          randomHistoryKey
        )
      }

      // Mark this part as processed
      markerOffsetPositions.current[partId].actionsApplied.push("connectedPart_positioned")
      debug && console.log("✅ Updated position for remaining part:", partId)
      debug && component.visualDebugSetter(`Updated position for ${partPrefix}${partId.slice(-4)}`)
    }

    debug && console.log("🏁 Finished processing all parts")
    debug && console.log("markerOffsetPositions at the end", markerOffsetPositions.current)

    setEnableScalerUI(false)

    //this is to try to use the same view that the user just scaled
    setLastViewsFirstPartId(view.uniqueIds[0])

    //you need this extra time because it takes time for the parts to update their positions

    setTimeout(() => {
      debug && console.log("🏁 Creating views for scaler UI")
      //we depend on the boundingbox from general multiselect methods
      updateMultiSelectProviderWithNewMakersInfo()
      updateSelectionBoundingBoxFromHighlightedPartIds(boundingBoxFromHighlightedParts)
      updateHighlightedPartIdsLengthFromReftoState()
      //create views sets enable to true - dont worry
      createViewsForScalerUI(createViewsDebug)

      //this is to update the dimensions of the bounding box on the top
      sceneCallbacks?.updateBoundingBoxAndDimensions()

      if (callback) {
        return callback()
      }
    }, 900)
  }

  const handleScaleUpdate = (view: viewForScalerUI, position: Vector3) => {
    //console.log("Scale updated:", view, position)
  }

  useEffect(() => {
    if (resizeMode) {
      if (cameraControls.current && selectionBoundingBox.current) {
        // Create a new box that won't affect the original
        const boxToFit = new Box3().copy(selectionBoundingBox.current)
        fitBoxClassic(boxToFit, cameraControls.current, 0.25)
      }
      setEnableScalerUI(true)
      messageUtils.custom("This feature is in beta. Click on one of the circles to resize a different set of parts!", {
        duration: 8,
        showUpTo: 3,
        minTimeBetweenShows: 30,
      })
    } else {
      setEnableScalerUI(false)
    }
  }, [resizeMode,])


  if (showGroupNamingModal) {
    return <Html style={{ width: "100vw", }} className="htmlShowModal">
      <NameModal
        onCancel={handleCancel}
        onRename={handleCreateGroup}
        submitButtonText="Save"
        title="Name Group"
        loading={false}
      />
    </Html>
  }


  return (
    <MultiSelectContext.Provider
      value={{
        selectionMode,
        setSelectionMode,
        newPartIds: newPartIds.current,
        checkDuplicationStatus,
        transformMode,
        setTransformMode,
        duplicateSelectedParts,
        updateCounter,
        setUpdateCounter,
        setIdsAsHighlightedAndTurnOnControl,
        hideMultiUIControls,
        setHideMultiUIControls,
        resetSelection,
        onRotationSliderChange,
        blockUI,
        setBlockUI,
        updateMultiSelectProviderWithNewMakersInfo,
      }}
    >
      {children}
      {transformMode !== "off" && anchor && (
        <TransformControls
          ref={transformControlsRef}
          object={transformsAnchorRef.current}
          mode={transformMode}
          onMouseUp={transformsOnMouseUp}
          onMouseDown={transformsOnMouseDown}
          onChange={transformsOnChange}
        />
      )}
      {highlightedPartIdsLength > 0 && !hideMultiUIControls && (
        <MultiSelectUI
          viewsForScalerUI={viewsForScalerUI}
          hideMultiUIControls={hideMultiUIControls}
          setHideMultiUIControls={setHideMultiUIControls}
          onDelete={deleteSelectedParts}
          duplicateSelectedParts={duplicateSelectedParts}
          changeTransformMode={setTransformMode}
          changeSelectionMode={setSelectionMode}
          resetSelection={resetSelection}
          transformMode={transformMode}
          selectionMode={selectionMode}
          resizeMode={resizeMode}
          setResizeMode={setResizeMode}
          onRotationSliderChange={onRotationSliderChange}
          createGroup={createGroup}
          isAdmin={isAdmin ?? false}
          setShowGroupNamingModal={setShowGroupNamingModal}
        />
      )}
      <TopRightButtons
        changeSelectionMode={setSelectionMode}
        changeTransformMode={setTransformMode}
        selectionMode={selectionMode}
        transformMode={transformMode}
        resetSelection={resetSelection}
        onTopOfTransformControls={onTopOfTransformControls}
        updateCounter={updateCounter}
        setUpdateCounter={setUpdateCounter}
      />
      {resizeMode && enableScalerUI && viewsForScalerUI && viewsForScalerUI.length > 0 && (
        <ScalerUILines
          views={viewsForScalerUI}
          lastViewsFirstPartId={lastViewsFirstPartId}
          scene={scene}
          cameraControls={cameraControls}
          config={configForScalerUI ?? defaultConfigForScalerUI}
          highlightedPartIds={highlightedPartIds}
          enabled={enableScalerUI}
          selectionBox={selectionBoundingBox.current}
          onScaleStart={handleScaleStart}
          onScaleEnd={handleScaleEnd}
          onScaleUpdate={handleScaleUpdate}
          userIsScaling={userIsScaling}
          setUserIsScaling={setUserIsScaling}
          setOnTopOfTransformControls={setOnTopOfTransformControls}
          setAutofocusMode={setAutofocusMode}
          dontResetSelection={dontResetSelectionRef}
          autofocusMode={autofocusMode}
        />
      )}
    </MultiSelectContext.Provider>
  )
}

export default MultiSelectProvider

