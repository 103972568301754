/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable max-lines */
import { notification } from "antd"
import { WritableDraft } from "immer/dist/internal"
import { Dispatch, MutableRefObject, SetStateAction } from "react"
import { SetterOrUpdater } from "recoil"
import {
  BufferGeometry,
  InstancedMesh,
  Intersection,
  Line,
  LineBasicMaterial,
  Material,
  MathUtils,
  Mesh,
  MeshMatcapMaterial,
  Object3D,
  Quaternion,
  Raycaster,
  Scene,
  Vector3
} from "three"
import { ConnectionTypeAPI, SizeAPI } from "../../../../../../../../../common/api/Types"
import { ConnectionOfPart, PartConnectionType } from "../../../../../../../../state/scene/types"
import {
  areOpposite,
  getOtherPartOfTheConnection,
  isMarkerConnected
} from "../../../../../../../../state/scene/util"
import { AddPartState } from "../../../../../../../../state/types"
import { EnvHelper } from "../../../../../../../../../common/utils/EnvHelper"
import {
  getMarkerUserData,
  innerToOuter,
  meshToInner,
  outerToInner
} from "../../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { getFreeMarkers } from "../../../../../../../../utils/PartUtils"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { getSourceData } from "../../../../../../../../utils/swapUtils"
import {
  assertPartType,
  ConnectorPart,
  ConnectorValues,
  GenericPartState,
  Marker,
  MarkerType,
  PartIdType,
  PartTypeEnum,
  XYZ,
  XYZW
} from "../../../../../../../../utils/Types"
import { filterWithValue, ObjDictionary } from "../../../../../../../../../common/utils/utils"
import { isCollineal } from "../../../../../utils/utilsThree"
import {
  ConnectorInternalsType,
  ConnectorMarkerType,
  RayStatesType,
  RayStateType
} from "../types/types"
import { breadcrumb } from "../../../../../../../../../common/utils/sentrySetup"

export const MAIN_MODEL = "mainModel"

export const getRotationMeshName = (meshName: string): string => {
  const numberMatch = meshName.match(/\d+/)
  if (numberMatch) {
    const number = numberMatch[0]
    return `rotation${number}`
  }
  return meshName
}

export const getRotationMarker = (
  connectorValues: ConnectorPart,
  markersRef: { [name: string]: Mesh<BufferGeometry, Material | Material[]>, }
) => {
  const value = markersRef[innerToOuter(connectorValues.rotationMarkerName)]
  //console.log(value, "getRotationMarker value")
  return value
}

export const getInitialMarker = (
  connectorValues: ConnectorValues,
  markersRef: { [name: string]: Mesh<BufferGeometry, Material | Material[]>, }
) => {
  return markersRef[connectorValues.initialMarkerName]
}

export const resetMarkerValues = (marker: Mesh) => {
  marker.rotation.set(0, 0, 0)
  marker.quaternion.set(0, 0, 0, 1)
  marker.scale.set(1, 1, 1)
  marker.position.set(0, 0, 0)
  marker.matrix.identity()
  marker.matrixWorld.identity()
}

export const attachTo = (
  mesh: Mesh,
  meshes: Mesh[],
  attachedMarker: MutableRefObject<Mesh<BufferGeometry, Material | Material[]> | undefined>
) => {
  meshes.forEach(m => { mesh.attach(m) })
  attachedMarker.current = mesh
}

export const detachFrom = (
  mesh: Mesh,
  meshes: Mesh[],
  scene: Scene,
  attachedMarker: MutableRefObject<Mesh<BufferGeometry, Material | Material[]> | undefined>
) => {
  meshes.forEach(m => {
    mesh.remove(m)
    scene.add(m)
  })
  attachedMarker.current = undefined
}

const updateRotation = (
  rotation: number,
  rotationMarker: Mesh
) => {
  rotationMarker.rotation.z = MathUtils.degToRad(rotation)
}

export const setMarkerDirAndPos = (
  marker: Mesh,
  direction: Quaternion | XYZW,
  position?: Vector3 | XYZ,
) => {

  // Reset all transformations
  marker.position.set(0, 0, 0)
  marker.rotation.set(0, 0, 0)
  marker.quaternion.set(0, 0, 0, 1)
  marker.scale.set(1, 1, 1)

  // Reset matrices
  marker.matrix.identity()
  marker.matrixWorld.identity()

  // Apply new position if provided
  if (position) {
    marker.position.set(position.x, position.y, position.z)
  }

  // Apply new rotation
  marker.quaternion.set(
    direction.x,
    direction.y,
    direction.z,
    direction.w
  )

  // Ensure quaternion is normalized
  marker.quaternion.normalize()

  // Update matrices and propagate to children
  marker.updateMatrix()
  marker.updateWorldMatrix(true, true)
}

export const proyectRay = (
  scene: Scene,
  rotationMarker: Mesh,
  connectorInternals: ConnectorInternalsType,
  partConnectionsValue: ConnectionOfPart[],
  setInternals: (newConnectorInternals: ConnectorInternalsType) => void
) => {
  const { markers, guidelines, rayStates, } = connectorInternals
  guidelines.forEach((line) => {
    scene.remove(line)
  })
  const newRayStates: RayStatesType = {}
  Object.keys(markers).filter(
    m => m !== rotationMarker.name
      && m.includes(ConnectorMarkerType.inner)
      && !partConnectionsValue.some(
        ({ partMarkerName, }) => outerToInner(partMarkerName) === m
      )
  )
    .forEach(m => {
      const marker = markers[m]
      if (!rayStates[m]) {
        rayStates[m] = getRayStateDefault()
      }
      marker.getWorldPosition(rayStates[m].worldMarkerPosition)
      marker.getWorldDirection(rayStates[m].worldMarkerDirection)
      rayStates[m].ray.set(rayStates[m].worldMarkerPosition, rayStates[m].worldMarkerDirection)

      const objectsToIntersect = scene.children.filter(object => !object.userData.ignoreRaycast)
      const objects = rayStates[m].ray.intersectObjects(objectsToIntersect)
      newRayStates[m] = rayStates[m]
      newRayStates[m].objects = objects
    })

  setInternals({
    ...connectorInternals,
    rayStates: newRayStates,
    guidelines: [],
  })
}

const areCollineal = (rayState: RayStateType, markerMesh: Object3D, object: Object3D) => {
  markerMesh.getWorldDirection(rayState.worldMarkerDirection)
  markerMesh.getWorldPosition(rayState.worldMarkerPosition)
  object.getWorldDirection(rayState.colliderWorldDirection)
  object.getWorldPosition(rayState.colliderWorldPosition)

  return isCollineal(
    rayState.worldMarkerDirection,
    rayState.worldMarkerPosition,
    rayState.colliderWorldDirection,
    rayState.colliderWorldPosition
  )
}

export const mayUseGuidelines = (connector: ConnectorValues) => {
  switch (connector.markers.length) {
    case 0:
      return false
    case 1:
      return false
    case 2:
      return !areOpposite(connector.markers[0], connector.markers[1])
    default:
      return true
  }

}

export const checkGuidelines = (
  connector: ConnectorValues,
  scene: Scene,
  connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
  allConnections: PartConnectionType[],
  setInternals?: (newConnectorInternals: ConnectorInternalsType) => void
) => {
  const { rayStates, markers, guidelines, } = connectorInternalsRef.current
  let rotable = true
  if (mayUseGuidelines(connector)) {
    Object.keys(rayStates).forEach(r => {
      const markerMesh = markers[r]
      const rayState = rayStates[r]
      const rayStateObject = rayState.objects.find(o => {
        return ((
          (o.face)
          && (o.object.userData.partId !== connector.id)
          && markerMesh
          && (o.object.userData.type === MarkerType.COLLISION)))
      })
      if (rayStateObject) {
        const marker = getMarkerUserData(rayStateObject.object)
        const isMarkerAlreadyConnected = isMarkerConnected(
          marker.markerName, marker.partId, allConnections
        )
        if (
          rayStateObject.distance < EnvHelper.rotationGuidelineDistance
          && !isMarkerAlreadyConnected
        ) {
          if (areCollineal(rayState, markerMesh, rayStateObject.object)) {
            const points = [rayState.worldMarkerPosition, rayState.colliderWorldPosition,]
            const geometry = new BufferGeometry().setFromPoints(points)
            const lineMesh = new Line(
              geometry,
              new LineBasicMaterial({ color: "red", linewidth: 1, })
            )
            if (setInternals) {
              guidelines.push(lineMesh)
              setInternals({
                ...connectorInternalsRef.current,
                guidelines,
              })
              scene.add(lineMesh)
            }
          } else {
            rotable = false
          }
        }
      }
    })
  }
  return rotable
}

export const setRotation = (
  rotation: number,
  rotationMarker: Mesh,
  scene: Scene,
  connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
  connectorValues: ConnectorValues,
  partConnectionsValue: ConnectionOfPart[],
  setInternals: (newConnectorInternals: ConnectorInternalsType) => void,
  allConnections: PartConnectionType[],
) => {
  updateRotation(rotation, rotationMarker)
  proyectRay(
    scene,
    rotationMarker,
    connectorInternalsRef.current,
    partConnectionsValue,
    setInternals
  )
  checkGuidelines(connectorValues, scene, connectorInternalsRef, allConnections, setInternals)
}

export const saveRotation = (
  rotationMarker: Mesh,
  scene: Scene,
  connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
  connector: ConnectorPart,
  multipleUpdater: (
    partsUpdaters: {
      id: string,
      updater: (p: WritableDraft<GenericPartState>) => void,
    }[],
    newConnections: PartConnectionType[],
    ignoreHistory?: boolean
  ) => void,
  setSavingRotation: Dispatch<SetStateAction<boolean>>,
  connectedMarkers: string[],
  compatibilityList: ObjDictionary<ConnectionTypeAPI>,
  buildCollider: () => void,
  updateCollider: (removePart?: (() => void), connectedParts?: string[]) => void,
  connectedParts: string[],
  allConnections: PartConnectionType[],
  loaded: boolean,
  updateTempConnectedMarkers: (newConnections: PartConnectionType[]) => void
) => {
  const exactSnaps = checkExactSnap(
    scene,
    connector,
    connectorInternalsRef.current,
    getFreeMarkers(connector, connectedMarkers),
    connectedParts,
    compatibilityList
  )

  breadcrumb({
    message: "after check exact snap",
    level: "info",
    data: { exactSnapsLength: exactSnaps.length, },
  })

  const checkGuidelinesResult = checkGuidelines(
    connector,
    scene,
    connectorInternalsRef,
    allConnections,
    undefined,
  )


  //i cannot figure out why this check was here. this would basically prevent the connector save
  // if checkGuideline result is false which is does come back in come cases
  // i cant think of any case where you'd want to not save the user's rotation
  //if (exactSnaps.length > 0 || checkGuidelinesResult) {
  updateTempConnectedMarkers(exactSnaps)
  breadcrumb({
    message: "After updating temp connected markers",
    level: "info",
  })
  const initialMarker = getInitialMarker(connector, connectorInternalsRef.current.markers)
  const position = MeshUtils.copyWorldPosition(initialMarker)

  initialMarker.rotateY(MathUtils.degToRad(-180))
  const quaternion = MeshUtils.copyWorldQuaternion(initialMarker)
  initialMarker.rotateY(MathUtils.degToRad(180))

  const EPSILON = 0.0001 // Very small buffer for floating point comparisons
  if (Math.abs(quaternion.z - connector.rotation.z) < EPSILON
    && Math.abs(quaternion.x - connector.rotation.x) < EPSILON
    && Math.abs(quaternion.y - connector.rotation.y) < EPSILON
    && Math.abs(quaternion.w - connector.rotation.w) < EPSILON
    && Math.abs(position.x - connector.position.x) < EPSILON
    && Math.abs(position.y - connector.position.y) < EPSILON
    && Math.abs(position.z - connector.position.z) < EPSILON) {
    return
  }

  setSavingRotation(true)

  multipleUpdater(
    [{
      id: connector.id,
      updater: (c) => {
        assertPartType(c, PartTypeEnum.connector)
        c.rotation = { x: quaternion.x, y: quaternion.y, z: quaternion.z, w: quaternion.w, }
        c.position = { x: position.x, y: position.y, z: position.z, }
        c.rotationMarkerName = rotationMarker.name
      },
    },],
    exactSnaps
  )
  if (exactSnaps.length > 0 && !loaded) {
    SoundHelper.playUnsnap()
  }

  buildCollider()
  updateCollider(
    undefined,
    exactSnaps.map(connection => getOtherPartOfTheConnection(connection, connector.id))
  )
  breadcrumb({
    message: "After updating collider",
    level: "info",
  })
  //}
}

export const getConnectorInternals = () => {
  const markers: { [name: string]: Mesh, } = {}
  const def: {
    connectorMesh: null,
    markers: { [name: string]: Mesh, },
    rayStates: {},
    objects: [],
    guidelines: [],
    centerObject: Object3D | undefined,
    boundingBoxMesh: Mesh | undefined,
    boxMeshLastUnScaledDimensions: {
      width: number,
      height: number,
      length: number,
    } | undefined,
  } = {
    connectorMesh: null,
    markers,
    rayStates: {},
    objects: [],
    guidelines: [],
    centerObject: undefined,
    boundingBoxMesh: undefined,
    boxMeshLastUnScaledDimensions: undefined,
  }
  return def
}

export const getRayStateDefault = () => {
  return {
    worldMarkerPosition: new Vector3(),
    worldMarkerDirection: new Vector3(),
    ray: new Raycaster(new Vector3(), new Vector3()),
    markerDirection: new Vector3(),
    colliderWorldDirection: new Vector3(),
    colliderWorldPosition: new Vector3(),
    objects: [],
  }
}

export const areSameConnection = (placeholderIdA: string, placeholderIdB: string) => {
  const result = outerToInner(placeholderIdA) === outerToInner(placeholderIdB)
  return result
}

export const haveSameNumber = (str1: string, str2: string, str3?: string): boolean => {
  // Helper function to extract the first number from a string
  const extractNumber = (str: string): number | null => {
    const match = str.match(/\d+/)
    return match ? parseInt(match[0], 10) : null
  }

  const num1 = extractNumber(str1)
  const num2 = extractNumber(str2)
  const num3 = str3 ? extractNumber(str3) : num1 // If str3 is not provided, use num1 for comparison

  if (num1 === null || num2 === null || num3 === null) {
    return false // If any number is not found, return false
  }

  return num1 === num2 && num2 === num3
}


export const areSameConnectionMesh = (placeholderIdA: string, placeholderIdB: string) => {
  return meshToInner(placeholderIdA) === meshToInner(placeholderIdB)
}

export const canSwapConnector = (
  connectorValues: ConnectorPart,
  partConnectionsValue: ConnectionOfPart[],
) => {
  const markers = partConnectionsValue
    .map(pcv => connectorValues.markers.find(m => areSameConnection(m.name, pcv.partMarkerName)))
  switch (markers.length) {
    case 0:
      return false
    case 1:
      return true
    case 2:
      if (areOpposite(markers[0]!, markers[1]!)) {
        return true
      } else {
        return false
      }
    default:
      return false
  }
}

export const handleConnectorSwap = (
  props: {
    connectorValues: ConnectorPart,
    partConnectionsValue: ConnectionOfPart[],
    connectionTypes: ObjDictionary<ConnectionTypeAPI>,
    sizes: ObjDictionary<SizeAPI>,
    getMarkerData: (partId: string, markerName: string) => Marker | undefined,
    setNewConnectionData: SetterOrUpdater<AddPartState | null>,
    partIdsList: PartIdType[],
    scene: Scene,
    connectorRef: InstancedMesh<BufferGeometry, Material | Material[]>,
  }
) => {
  const { connectorValues, partConnectionsValue, connectionTypes, sizes,
    getMarkerData, setNewConnectionData, partIdsList, scene, connectorRef, } = props
  const oldMaterial = connectorRef.material
  const makeInvisible = () => {
    connectorRef.material
      = new MeshMatcapMaterial({ alphaTest: 0, visible: false, transparent: true, })
  }
  const makeVisible = () => {
    connectorRef.material = oldMaterial
  }
  const markers = partConnectionsValue
    .map(pcv => connectorValues.markers.find(m => areSameConnection(m.name, pcv.partMarkerName)))
  switch (markers.length) {
    case 0:
      notification.error({ duration: 3, message: "Cant't swap. Part has no connections.", })
      break
    case 1:
      if (partConnectionsValue[0]) {
        const markerA = getMarkerData(
          partConnectionsValue[0].destinationPartId,
          partConnectionsValue[0].destinationMarkerName,
        )!
        setNewConnectionData({
          step1: {
            source: {
              ...getSourceData(
                partConnectionsValue[0].destinationPartId,
                partConnectionsValue[0].destinationMarkerName,
                scene
              ),
              expandReduceToFitInfo: { isApplicable: false, },
              swap: {
                partToSwapId: connectorValues.id,
                partToSwapApiId: connectorValues.apiTypeId,
                connectionTypesDictionary: connectionTypes,
                sizes,
                connectionATypeId: markerA.id,
                connectionASizeId: markerA.sizeId,
                actualLength: markers[0]!.fullLeg - markers[0]!.iELenght,
                makeInvisible,
                makeVisible,
              },
            },
          },
        })
      }
      break
    case 2:
      if (areOpposite(markers[0]!, markers[1]!)) {
        const partA = partIdsList
          .find(p => p.id === partConnectionsValue[0].destinationPartId)
        const partB = partIdsList
          .find(p => p.id === partConnectionsValue[1].destinationPartId)
        const markerA = getMarkerData(
          partConnectionsValue[0].destinationPartId,
          partConnectionsValue[0].destinationMarkerName
        )!
        const markerB = getMarkerData(
          partConnectionsValue[1].destinationPartId,
          partConnectionsValue[1].destinationMarkerName
        )!
        if (partA?.type === PartTypeEnum.tube || partB?.type === PartTypeEnum.tube) {
          let destinationPartId = partConnectionsValue[0].destinationPartId
          let destinationMarkerName = partConnectionsValue[0].destinationMarkerName
          let oppositeTubeId = partB?.id
          if (partA?.type === PartTypeEnum.tube) {
            destinationPartId = partConnectionsValue[1].destinationPartId
            destinationMarkerName = partConnectionsValue[1].destinationMarkerName
            oppositeTubeId = partA.id
          }
          setNewConnectionData({
            step1: {
              source: {
                ...getSourceData(
                  destinationPartId, destinationMarkerName, scene,
                ),
                expandReduceToFitInfo: { isApplicable: false, },
                swap: {
                  partToSwapId: connectorValues.id,
                  partToSwapApiId: connectorValues.apiTypeId,
                  connectionTypesDictionary: connectionTypes,
                  sizes,
                  connectionATypeId: markerA.id,
                  connectionASizeId: markerA.sizeId,
                  connectionBTypeId: markerB.id,
                  connectionBSizeId: markerB.sizeId,
                  actualLength: markers[0]!.fullLeg - markers[0]!.iELenght,
                  oppositeTubeId,
                  makeInvisible,
                  makeVisible,
                },
              },
            },
          })
        } else {
          setNewConnectionData({
            step1: {
              source: {
                ...getSourceData(
                  partConnectionsValue[0].destinationPartId,
                  partConnectionsValue[0].destinationMarkerName,
                  scene
                ),
                expandReduceToFitInfo: { isApplicable: false, },
                swap: {
                  partToSwapId: connectorValues.id,
                  partToSwapApiId: connectorValues.apiTypeId,
                  connectionTypesDictionary: connectionTypes,
                  sizes,
                  connectionATypeId: markerA.id,
                  connectionASizeId: markerA.sizeId,
                  connectionBTypeId: markerB.id,
                  connectionBSizeId: markerB.sizeId,
                  maxLength: markers[0]?.fullLeg,
                  actualLength: markers[0]!.fullLeg - markers[0]!.iELenght,
                  makeInvisible,
                  makeVisible,
                },
              },
            },
          })
        }
      } else {
        notification.error({
          duration: 3,
          message: "Cant't swap. Connections must be opposite.",
        })
      }
      break
    default:
      notification.error({ duration: 3, message: "Cant't swap. Too many connections.", })
      break
  }
}

export const removeGuidelines = (
  connectorInternalsRef: React.MutableRefObject<ConnectorInternalsType>,
  scene: Scene,
  setInternals: (newConnectorInternals: ConnectorInternalsType) => void
) => {
  connectorInternalsRef.current.guidelines.forEach((line) => scene.remove(line))
  setInternals({
    ...connectorInternalsRef.current,
    guidelines: [],
  })
}

export const isCompatibleWith = (
  marker: Marker,
  collidingObject: Intersection<Object3D>,
  compatibilityList: ObjDictionary<ConnectionTypeAPI>
) => {
  return (
    compatibilityList[marker.id].compatibleWithMulti.find(
      compatible => compatible === collidingObject.object.userData.id
    ) && marker.sizeId === collidingObject.object.userData.sizeId
  )
}

export const isCompatibleWith2Meshes = (
  marker: Mesh,
  otherMarker: Mesh,
  compatibilityList: ObjDictionary<ConnectionTypeAPI>,
  sizeIgnoreCheck?: boolean
) => {
  const ignoreSize = compatibilityList[marker.userData.id].ignoreSizeCompatibility
  const checkForSize = () => {
    if (sizeIgnoreCheck) {
      return ignoreSize || marker.userData.sizeId === otherMarker.userData.sizeId
    } else {
      return marker.userData.sizeId === otherMarker.userData.sizeId
    }
  }
  const isSizeCompatible = checkForSize()
  //console.log(isSizeCompatible, "isSizeCompatible", marker.userData.id, otherMarker.userData.id)
  const result = compatibilityList[marker.userData.id].compatibleWithMulti.find(
    compatible => compatible === otherMarker.userData.id
  ) && isSizeCompatible
  return result
}


const checkExactSnapOnMarker = (
  scene: Scene,
  fromMarker: Mesh,
  toMarker: Mesh,
  connector: ConnectorPart,
  toIgnorePartIds: string[],
  compatibilityList: ObjDictionary<ConnectionTypeAPI>
) => {
  const fromMarkerDirection = MeshUtils.copyWorldDirection(fromMarker)
  const fromMarkerPosition = MeshUtils.copyWorldPosition(fromMarker)
  const direction = fromMarker.name.includes("outer")
    ? fromMarkerDirection.negate() : fromMarkerDirection
  const ray = new Raycaster(fromMarkerPosition, direction)
  const toIntersection = ray.intersectObject(toMarker, true)
  const marker = connector.markers.find(c => c.name === outerToInner(toMarker.name))!
  if (toIntersection.length > 0) {
    const childrenWithoutUserDatIntersects = scene.children.filter(c => !c.userData.ignoreRaycast)
    const objects = ray.intersectObjects(childrenWithoutUserDatIntersects, true)
    const intersection = objects.find(o =>
      o.distance > toIntersection[0].distance - 0.007
      && o.distance < toIntersection[0].distance + 0.007
      && o.object.userData.partId !== toMarker.userData.partId
      && !toIgnorePartIds.includes(o.object.userData.partId)
      && o.object.userData.type === MarkerType.COLLISION
      && isCompatibleWith(marker, o, compatibilityList)
    )
    if (intersection) {
      const intersectionPos = MeshUtils.copyWorldPosition(intersection.object)
      const intersectionDir = MeshUtils.copyWorldDirection(intersection.object)
      const markerAuxDir = new Vector3(
        intersectionDir.x, intersectionDir.y, intersectionDir.z)
      if (isCollineal(markerAuxDir.negate(), intersectionPos,
        direction, fromMarkerPosition)
      ) {
        return {
          partA: {
            partId: fromMarker.userData.partId as string,
            markerName: fromMarker.name,
          },
          partB: {
            partId: intersection.object.userData.partId as string,
            markerName: intersection.object.userData.markerName,
          },
        }
      }
    }
  }
}

export const checkExactSnap = (
  scene: Scene,
  connector: ConnectorPart,
  connectorInternals: ConnectorInternalsType,
  freeMarkers: string[],
  toIgnorePartIds: string[],
  compatibilityList: ObjDictionary<ConnectionTypeAPI>,
) => {
  const { markers, } = connectorInternals
  const snaps = freeMarkers
    .map(m => {
      const outerMarker = markers[innerToOuter(m)]
      const innerMarker = markers[outerToInner(m)]
      const innerIntersection = checkExactSnapOnMarker(
        scene, outerMarker, innerMarker, connector, toIgnorePartIds, compatibilityList)
      const outerIntersection = checkExactSnapOnMarker(
        scene, innerMarker, outerMarker, connector, toIgnorePartIds, compatibilityList)

      return innerIntersection || outerIntersection
    })
  return filterWithValue(snaps)
}