/* eslint-disable max-len */
import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import "rc-slider/assets/index.css"
import styled from "styled-components"
import { roundLength } from "../../../../../../../utils/utils"
import UnitSelect from "../../../UnitSelect"
import { convertCmToIn, convertInToCm } from "../../../../utils/UnitUtils"
import { DisplayInput } from "./DisplayInput"
import BaseSlider from "./BaseSlider"
import { messageUtils } from "../../../LowerRightMessages"
import isMobile from "ismobilejs"

type Props = {
    min: number,
    max: number,
    step: number,
    handleMouseUp: (e: number) => void,
    handleMouseDown?: (e: number) => void,
    onChange: (value: number, debounce?: boolean, forceImmediateUpdate?: boolean) => void,
    onInputChange?: (value: number) => void,
    getValue: () => number,
    inputNumber: boolean,
    unit: string,
    marks?: boolean,
    inputFilter?: (val: number) => number,
    readonly?: boolean,
    checkSteps?: boolean,
    disabled?: boolean,
    updateUnit?: (unit: string) => void,
    disabledTyping?: boolean,
    scalingDimension?: string,
    maxFallback?: number,
}

const handleOnChange = (e: number, props: Props, setValue: Function, isFromTyping = true,
    forceImmediateUpdate = false, isBackspace = false) => {


    const val = roundLength(props.inputFilter
        ? props.inputFilter(e)
        : e, 5)

    if (isBackspace && val === 0) {
        setValue("")
        return val
    }

    setValue(val)

    //if the the value is within the bounds, update the value
    if (val >= props.min && val <= props.max) {
        props.onChange(val, isFromTyping, forceImmediateUpdate)
    }
    if (val >= props.max) {
        props.onChange(props.max, isFromTyping, forceImmediateUpdate)
    }
    if (val <= props.min) {
        props.onChange(props.min, isFromTyping, forceImmediateUpdate)
    }


    return val
}

const handleOnDisplayChange = (
    e: string,
    setDisplayValue: Function,
) => {
    const regex = /^[0-9]*\.?[0-9]*$/
    if (regex.test(e) || e.endsWith(".")) {
        setDisplayValue(e)
    }
}

const handleOnDisplayConfirm = (
    displayValue: number,
    props: Props,
    setValue: Function,
    setDisplayValue: Function
) => {
    if (isNaN(displayValue)) {
        setDisplayValue(props.getValue())
    } else {
        props.handleMouseUp(displayValue)
    }
}

const reminderMessage = () => {
    messageUtils.custom("Make sure to press enter on the input field to update!", {
        duration: 7,
        showUpTo: 5,
        minTimeBetweenShows: 60,
    })
}


export function NumberSlider(props: Props) {
    const [value, setValue,] = useState(props.getValue())
    const [displayValue, setDisplayValue,] = useState(props.getValue())
    const updatedUnit = useRef(props.unit ?? "in")
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {

        const isBackspace = e.nativeEvent instanceof InputEvent && (e.nativeEvent as InputEvent).inputType === "deleteContentBackward"

        const newValue = e.target.valueAsNumber
        const isTyping = e.nativeEvent instanceof InputEvent
        handleOnChange(newValue, props, setValue, isTyping, true, isBackspace)
    }

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const newValue = parseFloat(e.currentTarget.value)
            applyValue(newValue)
        } else if (props.disabledTyping) {
            e.preventDefault()
        }
    }

    const applyValue = (value: number) => {
        const getDimensionName = () => {
            if (props.scalingDimension === "height") { return "height" }
            if (props.scalingDimension === "width") { return "width" }
            return "length"
        }

        handleOnChange(value, props, setValue, false, true)

        //let the user know if the value is out of bounds and update the value to the min or max
        if (value <= props.min) {
            messageUtils.custom(`The min ${getDimensionName()} allowed for this part is ${props.min} ${props.unit}`, {
                duration: 5, forceShow: true,
            })
            setValue(props.min)
        } else if (value >= props.max) {
            if (props.maxFallback) {
                messageUtils.custom(`The max ${getDimensionName()} allowed for this part is ${props.maxFallback} ${props.unit} because it has snapped to another part`, {
                    duration: 5, forceShow: true,
                })
            }
            else {
                messageUtils.custom(`The max ${getDimensionName()} allowed for this part is ${props.max} ${props.unit}`, {
                    duration: 5, forceShow: true,
                })
            }
            setValue(props.max)
        }
    }

    useEffect(() => {
        setDisplayValue(value)
        if (updatedUnit.current !== props.unit) {
            handleOnChange(value, props, setValue, false, true)
            updatedUnit.current = props.unit
        }
    }, [props.unit,])


    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const isMobileResult = isMobile(window.navigator)
        if (isMobileResult.android.device) {
            // android devices dont have submit button, so we need to apply the value on Next or blur
            const newValue = parseFloat(e.target.value)
            applyValue(newValue)
        } else {
            //creates lots of unexcpeted behavior
            /*if (value < props.min) {
            setValue(props.getValue())
        }
        props.onChange(props.getValue())*/
            reminderMessage()
        }
    }

    useEffect(() => {
        setValue(props.getValue())
    }, [props.getValue(),])

    const handleUpdateUnit = (unit: string) => {
        props.updateUnit?.(unit)
        reminderMessage()
    }

    const getPortal = () => {
        return (
            <NumberSliderContainer>
                <NumberContainer>
                    <div style={{ position: "relative", }}>
                        <input
                            readOnly={props.readonly || false}
                            type={"number"}
                            min={props.min}
                            max={roundLength(props.max)}
                            step={props.step}
                            value={value}
                            onChange={onChange}
                            onKeyDown={props.disabledTyping ? undefined : handleOnKeyDown}
                            onBlur={onBlur}
                            disabled={props.disabled}
                            style={{
                                width: "60px",
                                //color: props.unit === "cm" ? "transparent" : undefined,
                                cursor: props.disabledTyping ? "default" : undefined,
                                caretColor: props.disabledTyping ? "transparent" : undefined,
                            }}
                        />
                    </div>
                    <p>
                        {props.updateUnit ? <UnitSelect
                            updateUnit={handleUpdateUnit}
                            unit={props.unit}
                        /> : props.unit}

                    </p>
                </NumberContainer>
                <BaseSlider
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    handleMouseUp={props.handleMouseUp}
                    handleMouseDown={props.handleMouseDown}
                    onChange={props.onChange}
                    getValue={props.getValue}
                    marks={props.marks}
                    checkSteps={props.checkSteps}
                    disabled={props.disabled}
                />
            </NumberSliderContainer>
        )
    }
    return getPortal()
}

const NumberSliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
    padding: 19px 0;
    padding-bottom: 20px;
`

const NumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    p {
        margin: 0;
    }
    input {
        border-radius: 6px;
        border: 1px solid black;
    }
`

export default NumberSlider
export type SliderProps = Props