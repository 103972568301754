/* eslint-disable max-lines-per-function */
/* eslint-disable max-params */
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable max-statements */
/* eslint-disable max-len */

import { Dispatch, MutableRefObject, SetStateAction } from "react"
import { ArrowHelper, AxesHelper, Box3, Box3Helper, BufferAttribute, BufferGeometry, Color, DoubleSide, Float32BufferAttribute, Group, Intersection, Line, LineBasicMaterial, Material, MathUtils, Matrix4, Mesh, MeshBasicMaterial, MeshMatcapMaterial, MeshStandardMaterial, Object3D, OctahedronGeometry, PlaneGeometry, Points, PointsMaterial, Quaternion, Raycaster, Scene, SphereGeometry, Texture, TorusGeometry, Vector2, Vector3 } from "three"
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader"
import { UseInstancedMeshSegmentedTubeExport } from "../../../../../../../../providers/instancedMeshSegmentedTubesProvider/useInstancedMeshSegmentedTubes"
import { ConnectionOfPart, PartConnectionType } from "../../../../../../../../state/scene/types"
import { getMarkerNumber, getMarkerUserData, getSideAndPosition, isInner, isInnerOrOuter, outerToInner, isPlus, isInnerOrOuterOrPlus, createLabelAtPosition, optimizeSlidePointsToTarget } from "../../../../../../../../utils/MarkerUtil"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { SegmentedTubeValues } from "../../../../../../../../utils/Types"
import { isCollineal, isParallel, isSameDirection, metersToInch, scale } from "../../../../../utils/utilsThree"
import { areSameConnection, areSameConnectionMesh, haveSameNumber } from "../../connector/utils/ConnectorUtils"
import { BlenderProcecedData, MarkerType, SegmentedTubeInfo, SectionType, SegmentedTubeMarkers, SegmentedTubeMarker, GetCorrectConnectionsPropsForAllIntersections } from "../types/types"
import { MarkerType as SceneMarkerType } from "../../../../../../../../utils/Types"
import { SoundHelper } from "../../../../../utils/SoundHelper"
import { getOtherPartOfTheConnection, isInConnection, isMarkerConnected, isMarkerToMarkerConnected } from "../../../../../../../../state/scene/util"
import { filterWithValue, ObjDictionary, toDictionary } from "../../../../../../../../../common/utils/utils"
import { ConnectionTypeAPI, SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import { convertInToCm } from "../../../../../utils/UnitUtils"
import { getPointsArrayForSingleEnd } from "../../../../../../../../providers/moveProvider/meshBuilderEnds"
import { allConnections } from "../../../../../../../../state/scene/selectors"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader"
import helvetikerFont from "three/examples/fonts/helvetiker_regular.typeface.json"
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils"
import { createBoundingBox3FromMergedMesh, createBoxWithCenterSphere, getSegTubeLengthInMeters } from "../../../../../../../../utils/PartUtils"



const RAY_POSITION_REDUCTION = 0.2

// eslint-disable-next-line max-len
const initSegmentedTubeInfo: (scene: Scene) => SegmentedTubeInfo = (scene: Scene) => {
    return {
        scene,
        blenderData: [],
        attachmentPoint: undefined,
        originPoint: undefined,
        instancedMeshOrigins: [],
        middleSection: {},
        startSection: {},
        endSection: {},
        newPartButtons: [],
        maxPossibleLength: 80,
        snapped: { start: false, end: false, },
        movableSection: SegmentedTubeSectionType.END,
        middlePositionsEdges: {
            min: 1,
            max: 1,
        },
        guidelines: [],
        multipleMove: {
            connected: false,
            tempConnections: [],
            snapped: { start: false, end: false, },
        },
        diagonalEdges: {
            start: false,
            end: false,
        },
        mergedMesh: undefined,
        mergedMeshOrigins: [],
        zeroLengthAttachmentPoint: undefined,

    }
}

const getSectionType = (model: Object3D) => {
    if (model.name.includes("middle")) {
        return SectionType.MIDDLE
    }
    if (model.name.includes("start")) {
        return SectionType.START
    }
    if (model.name.includes("end")) {
        return SectionType.END
    }

    throw Error("The object has no section asociated")
}

const getMainModel = (model: Object3D) => {
    const mainModel = model.children.find((c) => c.name.includes("mainModel"))
    if (!mainModel) {
        throw Error("Main model was not found")
    }
    mainModel.name = "mainModel"
    return mainModel
}

const getMarkersCollection = (model: Object3D) => {
    const isMesh = (name: string) => name.includes("mesh")
    const allMarkers = model.children.filter((m) => {
        const innerOrOuter = isInnerOrOuter(m.name)
        const plus = isPlus(m.name)
        const mesh = isMesh(m.name)
        return innerOrOuter || mesh || plus
    })
    const allMakersNameCorrection = allMarkers.map((m) => {
        (m as Mesh).material = new MeshBasicMaterial({
            alphaTest: 0,
            visible: false,
            //depthTest: false,
            wireframe: true,
            //color: "purple",
            opacity: 50,
            side: DoubleSide,
            name: m.name,
        })
        if (isInner(m.name)) {
            return {
                model: m,
                type: MarkerType.INNER,
                id: m.name.split("inner")[1],
            }
        } else if (isInnerOrOuter(m.name)) {
            return {
                model: m,
                type: MarkerType.OUTER,
                id: m.name.split("outer")[1],
            }
        } else if (isPlus(m.name)) {
            return {
                model: m,
                type: MarkerType.PLUS,
                id: m.name.split("plus")[1],
            }
        } else {
            return {
                model: m,
                type: "MESH",
                id: m.name.split("mesh")[1],
            }
        }
    })

    const groupedMarkerdById: {
        [id: string]: {
            inner?: {
                model: Object3D,
                type: MarkerType.INNER,
                id: string,
            },
            outer?: {
                model: Object3D,
                type: MarkerType.OUTER,
                id: string,
            },
            mesh?: {
                model: Object3D,
                type: "MESH",
                id: string,
            },
            plus?: {
                model: Object3D,
                type: MarkerType.PLUS,
                id: string,
            },
        },
    } = {}

    allMakersNameCorrection.forEach((m) => {
        if (groupedMarkerdById[m.id]) {
            groupedMarkerdById[m.id] = {
                ...groupedMarkerdById[m.id],
                [m.type]: m,
            }
        } else {
            groupedMarkerdById[m.id] = {
                [m.type]: m,
            }
        }
    })

    return groupedMarkerdById
}

const processData = (gltf: GLTF) => {
    const modelData: BlenderProcecedData[] = []
    const sectionGroups = gltf.scene.children
    if (sectionGroups.length !== 3) {
        throw Error("The blender file must contain 3 groups: middleSection, startSection and endSection")
    }
    sectionGroups.forEach((modelSection) => {
        modelData.push({
            sectionType: getSectionType(modelSection),
            mainModel: getMainModel(modelSection) as Mesh,
            markerCollection: getMarkersCollection(modelSection),
            sectionOrigin: modelSection,
        })
    })

    return modelData
}

const createPool = (partId: string, modelName: string, mesh: Mesh, scene: Scene, instancedMeshHandler: UseInstancedMeshSegmentedTubeExport, onTubeSelected?: () => void) => {
    instancedMeshHandler.createPool(partId, modelName, mesh, scene, onTubeSelected)
}

const updateInstancedTransforms = (
    middleProvider: UseInstancedMeshSegmentedTubeExport,
    startProvider: UseInstancedMeshSegmentedTubeExport,
    endProvider: UseInstancedMeshSegmentedTubeExport,
    info: MutableRefObject<SegmentedTubeInfo>,
    segmentScaleFactor?: number,
    skipBoundingSphere?: boolean,
    movableSection?: SegmentedTubeSectionType
) => {

    const middleOrigins = [...info.current.instancedMeshOrigins,]
    const startOrigin = middleOrigins.shift()
    const endOrigin = middleOrigins.pop()

    const origins = [] as Object3D[]
    if (startOrigin) {
        origins.push(startOrigin)
    }
    if (middleOrigins.length > 0) {
        origins.push(...middleOrigins)
    }
    if (endOrigin) {
        origins.push(endOrigin)
    }


    if (segmentScaleFactor) {

        const currentZScale = origins[0].scale.z

        if (currentZScale !== segmentScaleFactor) {
            const groupForAllOrigins = new Group()
            const parentForAllOrigins = origins[0].parent

            const markersByDirection = movableSection === SegmentedTubeSectionType.START ? info.current.startSection : info.current.endSection

            let anchorMarker: Object3D | undefined
            let anchorPositionBefore: Vector3 | undefined
            let firstKey: string | undefined

            if (markersByDirection) {
                firstKey = Object.keys(markersByDirection)[0]
                anchorMarker = markersByDirection[firstKey].inner!
                anchorPositionBefore = MeshUtils.copyWorldPosition(anchorMarker)
            }

            // Add all origins to the group
            origins.forEach(o => {
                groupForAllOrigins.add(o.clone()) // Clone to avoid modifying original objects
            })

            // Apply scale to the group
            groupForAllOrigins.scale.z = segmentScaleFactor
            groupForAllOrigins.updateMatrixWorld(true)

            // Transfer the scaled transformation back to individual origins
            origins.forEach((o, index) => {
                const scaledOrigin = groupForAllOrigins.children[index]
                o.matrix.copy(scaledOrigin.matrix)
                o.matrix.decompose(o.position, o.quaternion, o.scale)
                o.updateMatrixWorld(true)
            })

            // Only apply offset if there's no movable section
            if (movableSection && anchorMarker && anchorPositionBefore) {
                // Calculate the offset caused by scaling
                const anchorPositionAfter = MeshUtils.copyWorldPosition(anchorMarker)
                const offset = anchorPositionBefore.sub(anchorPositionAfter)

                // Apply the offset to all origins to maintain the original position
                origins.forEach(o => {
                    o.position.add(offset)
                    o.updateMatrixWorld(true)
                })
            }

            // Clean up
            groupForAllOrigins.clear()

            if (parentForAllOrigins) {
                origins.forEach(o => {
                    parentForAllOrigins.add(o)
                })
            }
        }
    }

    // Update middle origins
    middleProvider.updateInstancedMeshesTranforms(middleOrigins, skipBoundingSphere, segmentScaleFactor ?? undefined)

    if (startOrigin) {
        startProvider.updateInstancedMeshesTranforms([startOrigin,], skipBoundingSphere, segmentScaleFactor ?? undefined)
    }

    if (endOrigin) {
        endProvider.updateInstancedMeshesTranforms([endOrigin,], skipBoundingSphere, segmentScaleFactor ?? undefined)
    }
}

const cleanUpPreviousOrigins = (info: React.MutableRefObject<SegmentedTubeInfo>) => {
    const origins = info.current.instancedMeshOrigins

    // Cleanup previous origins
    if (origins.length > 0) {
        origins.forEach((p) => {
            p.removeFromParent();
            (p as unknown) = null
        })
        info.current.instancedMeshOrigins = []
    }
}

const cleanUpPreviousMarkers = (info: React.MutableRefObject<SegmentedTubeInfo>) => {
    info.current.startSection = {}
    info.current.endSection = {}
    info.current.middleSection = {}
}


const createMergedMesh = (info: MutableRefObject<SegmentedTubeInfo>, partId: string, moonTexture?: Texture, color?: string, scaleFactor?: number, debug?: boolean) => {
    const mainModelGeometries: BufferGeometry[] = []

    //console.log("info", info.current)

    //const point1 = performance.now()

    info.current.instancedMeshOrigins.forEach((origin) => {
        origin.children.forEach((child) => {
            if (child.name === "mainModel" && child instanceof Mesh) {
                // Create a new Matrix4 to store the world transformation
                const worldMatrix = new Matrix4()


                if (scaleFactor) {
                    child.scale.z = scaleFactor
                }

                // Get the world matrix of the child
                child.updateWorldMatrix(true, false)
                worldMatrix.copy(child.matrixWorld)

                // Clone the geometry
                const clonedGeometry: BufferGeometry = child.geometry.clone()

                // Apply the world matrix to the cloned geometry
                clonedGeometry.applyMatrix4(worldMatrix)

                mainModelGeometries.push(clonedGeometry)

                // Optional: Change the material of the original child
                //console.log(child, "child")
                //child.material = new MeshBasicMaterial({ color: "red", wireframe: true, visible: true })
                child.removeFromParent()
            }
        })
    })

    if (mainModelGeometries.length > 0) {

        const createEmptyUVAttribute = (geometry: BufferGeometry): BufferAttribute => {
            const vertexCount = geometry.attributes.position.count
            return new BufferAttribute(new Float32Array(vertexCount * 2), 2)
        }

        const createDefaultColorAttribute = (geometry: BufferGeometry): BufferAttribute => {
            const vertexCount = geometry.attributes.position.count
            const colors = new Float32Array(vertexCount * 3)
            colors.fill(1) // Set all colors to white (1, 1, 1)
            return new BufferAttribute(colors, 3)
        }

        const normalizeGeometry = (geometry: BufferGeometry, requiredAttributes: string[]): BufferGeometry => {
            const normalizedGeometry = geometry.clone()

            requiredAttributes.forEach(attr => {
                if (!normalizedGeometry.attributes[attr]) {
                    switch (attr) {
                        case "normal":
                            normalizedGeometry.computeVertexNormals()
                            break
                        case "uv":
                            normalizedGeometry.setAttribute("uv", createEmptyUVAttribute(normalizedGeometry))
                            break
                        case "color":
                            normalizedGeometry.setAttribute("color", createDefaultColorAttribute(normalizedGeometry))
                            break
                        default:
                            break
                    }
                }
            })

            // Remove any attributes that are not in the required list
            Object.keys(normalizedGeometry.attributes).forEach(attr => {
                if (!requiredAttributes.includes(attr)) {
                    normalizedGeometry.deleteAttribute(attr)
                }
            })

            return normalizedGeometry
        }

        let mergedGeometry = BufferGeometryUtils.mergeGeometries(mainModelGeometries)

        if (!mergedGeometry) {
            const requiredAttributes = ["position", "normal", "uv",] // Add or remove attributes as needed
            const normalizedGeometries = mainModelGeometries.map(geo => normalizeGeometry(geo, requiredAttributes))
            mergedGeometry = BufferGeometryUtils.mergeGeometries(normalizedGeometries)
        }

        if (!mergedGeometry) {
            return
        }


        // Create a new material for the merged mesh
        const matcapMaterial = new MeshMatcapMaterial({
            color: color ?? "white",
            matcap: moonTexture ?? undefined,
        })

        const mergedMesh = new Mesh(mergedGeometry, matcapMaterial)
        mergedMesh.name = `MergedMesh_${partId}`
        mergedMesh.userData = {
            type: "MergedMesh", partId: partId,
        }
        //mergedMesh.position.set(0, 0, 0)
        //mergedMesh.rotation.set(0, 0, 0)
        mergedMesh.updateMatrix()

        // Add the merged mesh to the scene

        //console.log(info.current.mergedMesh, "mergedMesh")
        //console.log(info.current.attachmentPoint, "attachmentPoint")
        //console.log(info.current.originPoint, "originPoint")

        if (info.current.mergedMesh && info.current.originPoint) {
            //info.current.attachmentPoint.remove(info.current.mergedMesh)
            info.current.originPoint.remove(info.current.mergedMesh)
        }
        if (info.current.originPoint) {
            info.current.mergedMesh = mergedMesh
            info.current.originPoint.attach(mergedMesh)
            /*if (debug && mergedMesh instanceof Mesh) {
                mergedMesh.material = new MeshBasicMaterial({
                    color: 0xcccccc,  // White color
                    wireframe: true,
                    transparent: true,
                    opacity: 0.5,    // Very low opacity
                    depthWrite: false // Prevents z-fighting with other transparent objects
                })
            }*/
            // Reset the position and rotation of the merged mesh

        }


    }
    const point2 = performance.now()
    //console.log("createMergedMesh", point2 - point1)
}

const removeEmptyOrigins = (info: MutableRefObject<SegmentedTubeInfo>) => {
    //console.log(info.current.instancedMeshOrigins, "info.current.instancedMeshOrigins")
    info.current.instancedMeshOrigins = info.current.instancedMeshOrigins.filter((origin) => {
        if (origin.children.length === 0) {
            // Remove the empty origin from the scene
            //console.log(origin, "origin")
            origin.removeFromParent()
            info.current.scene.remove(origin)
            return false
        }
        return true
    })
}

type MarkerMatch = {
    middleMesh: Mesh,
    originalPosition?: {
        position?: Vector3,
        rotation?: Vector3,
    },
    matches: {
        section: "start" | "end",
        markerKey: string,
        direction?: Vector3,
        originalPosition?: {
            position: Vector3,
            rotation: Vector3,
        },
        relativePosition?: {
            position: Vector3,
            rotation: Vector3,
        },
        mesh: Mesh,
        isJoinTo?: boolean,
    }[],
}


const findMeshMarkersMatchesFromOrigins = (info: MutableRefObject<SegmentedTubeInfo>) => {
    // Get all middle section mesh markers with their keys
    const middleMeshMarkers = Object.values(info.current.middleSection).flatMap(numberSection =>
        Object.values(numberSection)
            .filter(position => position.mesh instanceof Mesh)
            .map(position => position.mesh as Mesh)
    )

    // Store matches for each middle marker
    const matches: MarkerMatch[] = []

    // Process each middle marker
    middleMeshMarkers.forEach(middleMesh => {
        const middleInner = Object.values(info.current.middleSection).find(section =>
            Object.values(section).find(pos => pos.mesh === middleMesh)
        )?.["0"]?.inner

        if (!middleInner) { return }

        const matchesForMiddle: MarkerMatch = {
            middleMesh,
            matches: [],
        }

        // Search through all instancedMeshOrigins
        info.current.instancedMeshOrigins.forEach(origin => {
            origin.children.forEach(child => {
                if (!(child instanceof Mesh)) { return }
                if (child.userData.middleSection) { return }

                // Case 1: Regular mesh matches
                if (child.name.includes("mesh") && !child.name.includes("joinTo")) {
                    // Find corresponding inner marker in the same origin
                    const childInner = origin.children.find(c =>
                        c.name.includes("inner")
                        && c.name.split("_")[0] === child.name.replace("mesh", "inner").split("_")[0]
                    ) as Mesh


                    if (childInner) {
                        const dirA = MeshUtils.copyWorldDirection(childInner)
                        const dirB = MeshUtils.copyWorldDirection(middleInner as Mesh)

                        if (isSameDirection(dirA, dirB)) {
                            child.userData.dontIntersect = true
                            matchesForMiddle.matches.push({
                                section: origin === info.current.instancedMeshOrigins[0] ? "start" : "end",
                                markerKey: child.name,
                                mesh: child,
                                isJoinTo: false,
                            })
                        }
                    }
                }

                // Case 2: JoinTo matches
                if (child.name.includes("joinTo")) {
                    const targetNum = child.name.match(/joinTo(\d+)/)?.[1]
                    if (targetNum && middleMesh.name.includes(`mesh${targetNum}`)) {
                        child.userData.dontIntersect = true
                        matchesForMiddle.matches.push({
                            section: origin === info.current.instancedMeshOrigins[0] ? "start" : "end",
                            markerKey: "joinTo",
                            mesh: child,
                            isJoinTo: true,
                        })
                    }
                }
            })
        })

        if (matchesForMiddle.matches.length > 0) {
            matches.push(matchesForMiddle)
        } else { //push empty middle mesh so that we can scale it later
            matches.push({
                middleMesh: middleMesh,
                matches: [],
            })
        }
    })

    return matches
}

const mergeChildMeshesWithParent = (info: MutableRefObject<SegmentedTubeInfo>) => {
    info.current.instancedMeshOrigins.forEach(origin => {
        origin.children.forEach(child => {
            // Only process direct children that are meshes and have "mesh" in their name
            if (child instanceof Mesh && child.name.includes("mesh")) {
                const childrenToMerge = child.children.filter(grandChild =>
                    grandChild instanceof Mesh
                )

                if (childrenToMerge.length > 0) {
                    // Create array to hold all geometries to merge
                    const geometriesToMerge: BufferGeometry[] = [child.geometry,]

                    // Store merged mesh names in parent's userData
                    if (!child.userData.mergedMeshes) {
                        child.userData.mergedMeshes = []
                    }

                    childrenToMerge.forEach(meshToMerge => {
                        if (meshToMerge instanceof Mesh) {
                            // Store mesh name before merging
                            child.userData.mergedMeshes.push(meshToMerge.name)

                            // Get world transformation
                            meshToMerge.updateWorldMatrix(true, false)
                            const worldMatrix = meshToMerge.matrixWorld

                            // Get parent's inverse world transformation
                            child.updateWorldMatrix(true, false)
                            const parentInverseMatrix = child.matrixWorld.clone().invert()

                            // Clone geometry and transform to parent's local space
                            const clonedGeometry = meshToMerge.geometry.clone()
                            clonedGeometry.applyMatrix4(worldMatrix)
                            clonedGeometry.applyMatrix4(parentInverseMatrix)

                            geometriesToMerge.push(clonedGeometry)

                            // Remove the child mesh after merging
                            meshToMerge.removeFromParent()
                            meshToMerge.geometry.dispose()
                        }
                    })

                    // Merge all geometries
                    const mergedGeometry = BufferGeometryUtils.mergeGeometries(geometriesToMerge)
                    if (mergedGeometry) {
                        // Replace parent's geometry with merged geometry
                        child.geometry.dispose()
                        child.geometry = mergedGeometry
                    }
                }
            }
        })
    })
}

const createInstancedMeshOrigins = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    middleProvider: UseInstancedMeshSegmentedTubeExport | undefined,
    startProvider: UseInstancedMeshSegmentedTubeExport | undefined,
    endProvider: UseInstancedMeshSegmentedTubeExport | undefined,
    tubeLength: number,
    tubeNegativeLength: number,
    segmentScaleFactor?: number,
    moonTexture?: Texture,
    color?: string,
    debug?: boolean,
    movableSection?: SegmentedTubeSectionType,
) => {

    if (!info.current || !info.current.originPoint) {
        throw new Error("Pivot point not defined")
    }

    const negativeLengthToUse = tubeNegativeLength

    cleanUpPreviousOrigins(info)
    cleanUpPreviousMarkers(info)

    const pivot = info.current.originPoint
    const originPos = MeshUtils.copyWorldPosition(pivot)
    const originRot = MeshUtils.copyWorldQuaternion(pivot)

    const startLengthInMeters = tube.startSegmentLength / metersToInch
    const endLengthInMeters = tube.endSegmentLength / metersToInch
    const segmentLengthInMeters = tube.segmentLength / metersToInch

    const originDir = MeshUtils.copyWorldDirection(pivot).normalize()
    originPos.addVectors(originPos, originDir.multiplyScalar(segmentLengthInMeters * negativeLengthToUse))

    // Segments length plus start and end sections
    const length = tubeLength + negativeLengthToUse + 2

    //this is because it may have been scaled before
    info.current.originPoint.scale.set(1, 1, 1)

    //console.log(length, "length")
    //console.log(tubeLength, "tubeLength")
    //console.log(tubeNegativeLength, "tubeNegativeLength")

    const offset = (startLengthInMeters * (segmentScaleFactor ?? 1)) - startLengthInMeters

    //draw sphere on the originPos using the info.current.scene
    //const sphere = new Mesh(new SphereGeometry(0.025), new MeshBasicMaterial({ color: 0xff0000, wireframe: true, }))
    //sphere.position.copy(originPos)
    //info.current.scene.add(sphere)
    //setTimeout(() => {
    //info.current.scene.remove(sphere)
    //}, 5000)

    // Create new origins
    for (let i = 0; i < length; i++) {
        const placeholder = new Object3D()
        placeholder.position.copy(originPos)
        placeholder.rotation.setFromQuaternion(originRot)
        placeholder.name = "Instanced Mesh Origin"
        pivot.attach(placeholder)

        const iaux = i - 1 < 0 ? 0 : i - 1
        let distance = 0
        //need this this in order to not get the movement issue
        if (i === 1) {
            distance = startLengthInMeters + offset
        }
        if (i > 1) {
            distance = ((segmentLengthInMeters * iaux) + startLengthInMeters) * (segmentScaleFactor ?? 1)
        }
        //distance between first and middles
        if (i === length - 1) {
            distance = ((segmentLengthInMeters * iaux) + startLengthInMeters + endLengthInMeters) * (segmentScaleFactor ?? 1)
        }
        placeholder.position.setZ(placeholder.position.z - (distance))
        info.current.instancedMeshOrigins.push(placeholder)
    }
    //console.log("info", info, tube, "tube", tubeNegativeLength, "negative length")

    createMarkers(info, tube, negativeLengthToUse, tubeLength, segmentScaleFactor ?? 1, debug ?? false)

    const scaleToExtendOneEnd = (
        middleMesh: Mesh,
        segmentLength: number,
        section: "start" | "end",
        debug?: boolean
    ) => {

        const clone = middleMesh.clone(true)

        //important so that the inner's geometry is not affected by the scale
        middleMesh.geometry = middleMesh.geometry.clone()

        const currentSize = new Vector3()
        clone.geometry.computeBoundingBox()
        clone.geometry.boundingBox?.getSize(currentSize)

        //console.log(currentSize, "currentSize for middle mesh:", middleMesh.name)

        const desiredWidthWithExtension = (convertInToCm(segmentLength) / 100) + currentSize.x

        const scaleFactor = desiredWidthWithExtension / currentSize.x

        //console.log(scaleFactor, "scaleFactor")

        //now lets apply the scale directly to the middleMesh
        const scaleMatrix = new Matrix4().makeScale(scaleFactor, 1, 1)
        middleMesh.geometry.applyMatrix4(scaleMatrix)
        middleMesh.geometry.computeVertexNormals()

        //now just apply the offset depending on the section
        const offset = (desiredWidthWithExtension - currentSize.x) / 2
        if (section === "end") {
            middleMesh.position.setZ(middleMesh.position.z - offset)
        } else {
            middleMesh.position.setZ(middleMesh.position.z + offset)
        }

        middleMesh.updateMatrix()
        middleMesh.updateMatrixWorld(true)



    }

    const extendEmptyMatches = (matches: MarkerMatch[], tube: SegmentedTubeValues, debug?: boolean) => {
        matches.forEach(match => {
            // Get missing sections
            const hasStart = match.matches.some(m => m.section === "start")
            const hasEnd = match.matches.some(m => m.section === "end")

            //debug && console.log(match.middleMesh.name, hasStart, hasEnd, "hasStart and hasEnd")

            // Handle each missing section one at a time since there can be some that only
            // have one end missing
            if (!hasStart || !hasEnd) {
                const middleMesh = match.middleMesh

                if (!hasStart) {
                    scaleToExtendOneEnd(middleMesh, tube.startSegmentLength, "start", debug)
                }

                if (!hasEnd) {
                    scaleToExtendOneEnd(middleMesh, tube.endSegmentLength, "end", debug)
                }
            }
        })
    }


    const attachMatchedMeshes = (matches: MarkerMatch[]) => {
        matches.forEach(match => {
            const middleMesh = match.middleMesh

            match.matches.forEach(matchData => {
                // Simply attach the mesh to the middle mesh
                const worldPosition = new Vector3()
                const worldQuaternion = new Quaternion()
                matchData.mesh.getWorldPosition(worldPosition)
                matchData.mesh.getWorldQuaternion(worldQuaternion)
                const clone = matchData.mesh.clone(true)
                clone.position.copy(worldPosition)
                clone.rotation.setFromQuaternion(worldQuaternion)
                middleMesh.attach(clone)
                clone.updateMatrix()
                clone.updateMatrixWorld(true)
            })
        })
    }
    const removeChildrenFromMeshes = (matches: MarkerMatch[]) => {
        matches.forEach(match => {
            const middleMesh = match.middleMesh
            // Remove all children recursively from the middle mesh
            //console.log(middleMesh, "middleMesh to remove children")
            middleMesh.traverse((child) => {
                if (child !== middleMesh) {
                    //console.log(child.name, "child to remove")
                    child.removeFromParent()
                }
            })
        })
    }



    createMergedMesh(info, tube.id, moonTexture, color, segmentScaleFactor ?? 1, false,)
    removeEmptyOrigins(info)

    //logic for joining end meshes to middle meshes from joinTo layers or
    //start and end meshes faciing the same direction as the middle mesh

    const matches = findMeshMarkersMatchesFromOrigins(info)

    //debug && console.log(matches, "matches for middles to join to it")

    //clean up just in case
    removeChildrenFromMeshes(matches)

    extendEmptyMatches(matches, tube, debug)

    //attach the meshes we found
    attachMatchedMeshes(matches)

    //merge the meshes we found
    mergeChildMeshesWithParent(info)

    // Remove matched keys from start/end sections so they dont get pluses
    matches.forEach(match => {
        match.matches.forEach(matchData => {
            const { section, mesh, } = matchData

            if (section === "start") {
                // Find and remove matching key from startSection
                Object.keys(info.current.startSection).forEach(key => {
                    if (info.current.startSection[key].mesh?.name === mesh.name) {
                        delete info.current.startSection[key]
                    }
                })
            } else if (section === "end") {
                // Find and remove matching key from endSection
                Object.keys(info.current.endSection).forEach(key => {
                    if (info.current.endSection[key].mesh?.name === mesh.name) {
                        delete info.current.endSection[key]
                    }
                })
            }
        })
    })


    //create the local slide points
    const addLocalSlidePointsForMeshes = () => {
        const markers = [
            ...Object.values(info.current.startSection).flatMap(markerGroup =>
                Object.values(markerGroup).filter(marker => marker !== undefined)
            ),
            ...Object.values(info.current.endSection).flatMap(markerGroup =>
                Object.values(markerGroup).filter(marker => marker !== undefined)
            ),
            ...Object.values(info.current.middleSection).flatMap(side =>
                Object.values(side).flatMap(markerGroup =>
                    Object.values(markerGroup).filter(marker => marker !== undefined)
                )
            ),
        ]
        markers.forEach(marker => {
            if (!marker?.name) { return }

            if (marker.name.includes("mesh")) {
                // Store IDs of corresponding inner/outer markers
                const innerMarker = markers.find(m => m?.name === marker.name.replace("mesh", "inner"))
                const outerMarker = markers.find(m => m?.name === marker.name.replace("mesh", "outer"))

                if (innerMarker || outerMarker) {
                    // Calculate distances if markers exist
                    const meshPosition = new Vector3()
                    marker.getWorldPosition(meshPosition)

                    const distances = {
                        inner: innerMarker ? meshPosition.distanceTo(MeshUtils.copyWorldPosition(innerMarker)) : Infinity,
                        outer: outerMarker ? meshPosition.distanceTo(MeshUtils.copyWorldPosition(outerMarker)) : Infinity,
                    }

                    // Store the closest marker to use when something intersects with the mesh
                    if (distances.inner < distances.outer) {
                        marker.userData.closestInnerOrOuterMarkerMeshId = innerMarker?.id
                    } else if (distances.outer < distances.inner) {
                        marker.userData.closestInnerOrOuterMarkerMeshId = outerMarker?.id
                    }
                }

                // Generate slide points on mesh
                const { freePositions, } = getPointsArrayForSingleEnd(marker, 0.5)
                //we are using 100 points because we dont need anymore than that
                const optimizedPositions = optimizeSlidePointsToTarget(freePositions, 100)
                //console.log(freePositions.length, "freePositions")
                //console.log(optimizedPositions.length, "optimizedPositions")
                marker.userData.localSlidePoints = optimizedPositions.map(point => ({
                    position: marker.worldToLocal(new Vector3().copy(point.position)),
                }))
            } else if (marker.name.includes("inner") || marker.name.includes("outer")) {
                // Store corresponding mesh ID on inner/outer markers. this is used in the multiselect provider
                const mesh = markers.find(m => m?.name === marker.name.replace(/^(inner|outer)/, "mesh"))
                if (mesh) { marker.userData.correspondingMeshMarkerId = mesh.id }
            }
        })
    }

    //we are now processing these on creation because the process is very light now becaue of the low density

    addLocalSlidePointsForMeshes()

    const newTotalLengthInM = getSegTubeLengthInMeters(tubeLength, tubeNegativeLength, tube.segmentLength, tube.endSegmentLength,
        tube.startSegmentLength, segmentScaleFactor ?? 1)

    debug && console.log(newTotalLengthInM, "newTotalLengthInM")
    //end of logic for joining end meshes to middle meshes from joinTo layers or


    //this block is where we create the boundingbox mesh and center spheres
    if (info.current.originPoint && info.current.originPoint.parent === info.current.scene && info.current.mergedMesh) {
        //this is for the first time because originPoint is not attached to the scene yet
        debug && console.log("originPoint is in the scene")

        const boundingBox3 = createBoundingBox3FromMergedMesh(info.current.mergedMesh)
        info.current.firstLoadboundingBox3 = boundingBox3

        const boundingBoxCenter = new Vector3()
        boundingBox3.getCenter(boundingBoxCenter)

        /*if (debug) {
            const helper = new Box3Helper(boundingBox3, new Color("blue"))
            info.current.scene.add(helper)
            createLabelAtPosition(info.current.scene, boundingBoxCenter, "boundingBoxCenter Of SegTube", {
                color: "white",
                fontSize: "15px",
                timer: 1000,
            })
            setTimeout(() => {
                info.current.scene.remove(helper)
            }, 1000)
        }*/



        //these are the sizes of the box without scaling
        const realSizes = {
            width: boundingBox3.max.x - boundingBox3.min.x,
            height: boundingBox3.max.y - boundingBox3.min.y,
            length: boundingBox3.max.z - boundingBox3.min.z,
        }


        const { centerSphere, boxMesh, } = createBoxWithCenterSphere({
            width: realSizes.width,
            height: realSizes.height,
            depth: realSizes.length,
            tubeId: tube.id,
            boxColor: "red",
            boxOpacity: 0.5,
            sphereVisible: !!debug,
            boxVisible: !!debug,
        })

        info.current.centerObject = centerSphere
        info.current.boundingBoxMesh = boxMesh

        centerSphere.position.copy(boundingBoxCenter)

        info.current.originPoint?.attach(centerSphere)

        //want to update both
        //keep in mind that this is the last dimensions of the box without scaling
        //if you want to get true dimensions, use the getDimensions method on the boxMesh
        info.current.boxMeshLastUnScaledDimensions = realSizes
        if (info.current.boundingBoxMesh) {
            info.current.boundingBoxMesh.userData.unScaledSizes = realSizes
        }

        debug && console.log(realSizes, "realSizes in first run - good idea ato compare this to the newtotallengthinCM")


    } else {
        const diffInLength = newTotalLengthInM - (info.current.boxMeshLastUnScaledDimensions?.length ?? 0)
        debug && console.log(diffInLength, "diffInLength", newTotalLengthInM, "newTotalLengthInM")

        if (diffInLength !== 0 && info.current.centerObject) {

            // Move center by half the difference in the positive Z direction when growing

            debug && console.log(movableSection, "movableSection")

            if (movableSection === "start") {
                info.current.centerObject.position.setZ(info.current.centerObject.position.z + (diffInLength / 2))
            } else {
                info.current.centerObject.position.setZ(info.current.centerObject.position.z - (diffInLength / 2))
            }
        }
        const realSizes = {
            width: info.current.boxMeshLastUnScaledDimensions?.width ?? 0,
            height: info.current.boxMeshLastUnScaledDimensions?.height ?? 0,
            length: newTotalLengthInM,
        }

        debug && console.log(realSizes, "realSizes with the new total length in subsequent runs")

        //want to update both

        info.current.boxMeshLastUnScaledDimensions = realSizes

        if (info.current.boundingBoxMesh) {
            info.current.boundingBoxMesh.userData.unScaledSizes = realSizes
        }

        const { centerSphere, boxMesh, } = createBoxWithCenterSphere({
            width: realSizes.width,
            height: realSizes.height,
            depth: realSizes.length,
            tubeId: tube.id,
            boxColor: "red",
            boxOpacity: 0.5,
            sphereVisible: !!debug,
            boxVisible: !!debug,
        })

        const centerObjectWorldQuaternion = new Quaternion()
        info.current.centerObject?.getWorldQuaternion(centerObjectWorldQuaternion)
        centerSphere.quaternion.copy(centerObjectWorldQuaternion)

        const centerObjectWorldPosition = new Vector3()
        info.current.centerObject?.getWorldPosition(centerObjectWorldPosition)
        centerSphere.position.copy(centerObjectWorldPosition)

        info.current.centerObject?.removeFromParent()
        info.current.boundingBoxMesh?.removeFromParent()

        //this centerSphere already has the bbox mesh attached to it
        info.current.originPoint?.attach(centerSphere)

        info.current.boundingBoxMesh = boxMesh
        info.current.centerObject = centerSphere


    }

}


const saveMarkerInfo = (marker: Object3D, info: React.MutableRefObject<SegmentedTubeInfo>, sectionType: SectionType) => {
    const markerNumber = getMarkerNumber(marker.name)
    const { markerSide, markerPosition, } = getSideAndPosition(marker.name)
    switch (sectionType) {
        case SectionType.START:
            if (!info.current.startSection[markerNumber]) {
                info.current.startSection[markerNumber] = { inner: undefined, outer: undefined, }
            }
            if (isInner(marker.name)) {
                info.current.startSection[markerNumber].inner = marker as Mesh
            } else if (isInnerOrOuter(marker.name)) {
                info.current.startSection[markerNumber].outer = marker as Mesh
            } else if (isPlus(marker.name)) {
                info.current.startSection[markerNumber].plus = marker as Mesh
            } else if (marker.name.includes("mainModel")) {
                info.current.startSection[markerNumber].mainModel = marker as Mesh
            } else {
                info.current.startSection[markerNumber].mesh = marker as Mesh
            }
            break
        case SectionType.END:
            if (!info.current.endSection[markerNumber]) {
                info.current.endSection[markerNumber] = { inner: undefined, outer: undefined, }
            }
            if (isInner(marker.name)) {
                info.current.endSection[markerNumber].inner = marker as Mesh
            } else if (isInnerOrOuter(marker.name)) {
                info.current.endSection[markerNumber].outer = marker as Mesh
            } else if (isPlus(marker.name)) {
                info.current.endSection[markerNumber].plus = marker as Mesh
            } else if (marker.name.includes("mainModel")) {
                info.current.endSection[markerNumber].mainModel = marker as Mesh
            } else {
                info.current.endSection[markerNumber].mesh = marker as Mesh
            }
            break
        case SectionType.MIDDLE:
            if (markerSide) {
                if (!info.current.middleSection[markerSide]) {
                    info.current.middleSection[markerSide] = {}
                }
                if (!info.current.middleSection[markerSide][markerPosition]) {
                    info.current.middleSection[markerSide][markerPosition] = { inner: undefined, outer: undefined, }
                }
                if (isInner(marker.name)) {
                    info.current.middleSection[markerSide][markerPosition].inner = marker as Mesh
                }
                else if (isPlus(marker.name)) {
                    info.current.middleSection[markerSide][markerPosition].plus = marker as Mesh
                }
                else if (marker.name.includes("mainModel")) {
                    info.current.middleSection[markerSide][markerPosition].mainModel = marker as Mesh
                }
                else if (marker.name.includes("mesh")) {
                    info.current.middleSection[markerSide][markerPosition].mesh = marker as Mesh
                }
                else {
                    info.current.middleSection[markerSide][markerPosition].outer = marker as Mesh
                }
            }
            break
        default:
            throw new Error("Section type doesn't exist")
    }
}
const scalePlane = (plane: Mesh, realBoundary: number) => {

    // Calculate the desired scale factor for the Y axis
    const currentSize = new Vector3()

    if (plane.geometry && plane.geometry.boundingBox) {

        plane.geometry.computeBoundingBox()
        plane.geometry.boundingBox.getSize(currentSize)
        const scaleFactorY = (2 * realBoundary) / currentSize.y

        // Apply the scale factor to the plane
        plane.scale.setY(scaleFactorY)

        // Reposition the plane to keep the center the same
        const center = new Vector3()
        plane.geometry.boundingBox.getCenter(center)
        plane.position.sub(center.multiplyScalar(scaleFactorY - 1))
    }
}

const drawPoints = (scene: Scene, points: Vector3[], color = 0xff0000) => {
    const geometry = new BufferGeometry()
    const vertices = new Float32BufferAttribute(points.flatMap(p => [p.x, p.y, p.z,]), 3)
    geometry.setAttribute("position", vertices)

    const material = new PointsMaterial({ color, size: 0.01, }) // Adjust size as needed
    const pointCloud = new Points(geometry, material)
    scene.add(pointCloud)
}


const cloneMarker = (
    tube: SegmentedTubeValues,
    blenderData: BlenderProcecedData,
    origin: Object3D,
    info: MutableRefObject<SegmentedTubeInfo>,
    index?: number,
    totalLength?: number,
    positiveLength?: number,
    negativeLength?: number,
    segmentScaleFactor?: number,
    debug?: boolean,
) => {
    const aligmentPlaneAdded = {
        start: false,
        end: false,
    }



    blenderData.sectionOrigin.traverse((object) => {

        if (isInnerOrOuterOrPlus(object.name)) {
            const marker = tube.markers.find(marker => haveSameNumber(object.name, marker.name))!
            const clone = object.clone(true)
            if (index || index === 0) {
                clone.name = `${clone.name}_${index}`
            }

            if (blenderData.sectionType === SectionType.MIDDLE && (index === 0 && index !== undefined)) {
                if (clone instanceof Mesh && debug) {
                    clone.material = new MeshBasicMaterial({
                        color: "red",
                        depthTest: true,
                        wireframe: true,

                    })
                }

                //console.log(positiveLength, negativeLength, "positive and negative length")

                // ... inside your function
                if ((positiveLength || negativeLength) && clone instanceof Mesh) {

                    const singleSegmentLength = convertInToCm(tube.segmentLength) / 100

                    const currentSize = new Vector3()
                    clone.geometry.computeBoundingBox()
                    clone.geometry.boundingBox.getSize(currentSize)
                    const width = currentSize.x
                    const scaleFactorForInnerOuter = width / singleSegmentLength

                    //console.log(scaleFactorForInnerOuter, "scaleFactorForInnerOuter")
                    //console.log(singleSegmentLength, "singleSegmentLength")
                    //console.log(currentSize, "currentSize")

                    const scaleMatrixForInnerOuter = new Matrix4().makeScale(1 / scaleFactorForInnerOuter, 1, 1)
                    clone.geometry.applyMatrix4(scaleMatrixForInnerOuter)


                    // Calculate the desired width
                    const totalActualWidth = ((positiveLength ?? 0) + (negativeLength ?? 0))

                    //console.log(tube.segmentLength, "tube.segmentLength")
                    //console.log(positiveLength, negativeLength, "positive and negative length")
                    //console.log(totalLength, "totalLength")


                    // Calculate the scale factor
                    const scaleFactor = totalActualWidth

                    //console.log(segmentScaleFactor, "segmentScaleFactor")
                    //console.log(scaleFactor, "scaleFactorX")

                    // Create a scaling matrix
                    const scaleMatrix = new Matrix4().makeScale(scaleFactor, 1, 1)

                    // Apply the matrix to the geometry of the temporary clone
                    clone.geometry.applyMatrix4(scaleMatrix)

                    // Update the geometry of the temporary clone
                    clone.geometry.computeVertexNormals()

                    //console.log(singleSegmentLength, "singleSegmentLength")

                    const positiveOffset = singleSegmentLength / 2 * (positiveLength ?? 0)
                    const negativeOffset = singleSegmentLength / 2 * (negativeLength ?? 0)

                    //console.log(positiveOffset, negativeOffset, "positive and negative offset")
                    const offset = positiveOffset - negativeOffset - singleSegmentLength / 2

                    //const offsetForSingleSegment = convertInToCm(tube.segmentLength)/100 * 0.5 * (segmentScaleFactor ?? 1)

                    //console.log(offset, "offset")

                    // Apply changes to the original clone
                    clone.position.setZ(clone.position.z - ((offset)))

                    if (marker.boundary) {
                        const realBoundary = convertInToCm(marker.boundary) / 100 * 2
                        // Calculate the current height of the geometry
                        const currentHeight = currentSize.y

                        // Calculate the scale factor for Y axis
                        const scaleFactorY = realBoundary / currentHeight

                        // Create a scaling matrix for Y axis
                        const scaleMatrixY = new Matrix4().makeScale(1, scaleFactorY, 1)

                        // Apply the Y scaling to the geometry
                        clone.geometry.applyMatrix4(scaleMatrixY)

                    }



                    //add to the origin and save the marker info
                    clone.userData = {
                        id: marker.id,
                        markerName: clone.name,
                        partId: tube.id,
                        iELength: marker.iELenght,
                        sizeId: marker.sizeId,
                        lateralFacing: marker.lateralFacing,
                        boundary: marker.boundary,
                        userDataType: "MarkerUserData",
                        type: SceneMarkerType.COLLISION,
                        middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                        sectionType: blenderData.sectionType,
                    }
                    if (debug) {
                        clone.visible = true
                    } else {
                        clone.visible = false
                    }
                    origin.add(clone)
                    clone.updateMatrixWorld()
                    //clone.matrixAutoUpdate = true


                    saveMarkerInfo(clone, info, blenderData.sectionType)
                    ///

                    if (object.name.includes("inner")) {
                        const meshClone = clone.clone(true)
                        if (debug && meshClone instanceof Mesh) {
                            meshClone.visible = true
                            meshClone.material = new MeshBasicMaterial({
                                color: "red",
                                side: DoubleSide,
                                transparent: true,
                                opacity: 0.3,
                            })
                        } else {
                            meshClone.visible = false
                            meshClone.material.transparent = false
                            meshClone.material.opacity = 1
                        }


                        if (index || index === 0) {
                            const cleanedName = meshClone.name.replace(/(inner|outer)_?/, "")
                            meshClone.name = `mesh${cleanedName}`
                        }


                        const worldPosition = new Vector3()
                        clone.getWorldPosition(worldPosition)

                        //console.log(meshClone.name, worldPosition, "worldPosition when being sent to getPointsArrayForSingleEnd")


                        //const axesHelper = new AxesHelper(0.05)
                        //axesHelper.name = "ignore_axesHelper"
                        //meshClone.add(axesHelper)


                        //const { freePositions, } = getPointsArrayForSingleEnd(meshClone, 0.25)

                        //console.log(freePositions, "freePositions")

                        meshClone.userData = {
                            id: marker.id,
                            name: meshClone.name,
                            markerName: meshClone.name,
                            partId: tube.id,
                            iELength: marker.iELenght,
                            sizeId: marker.sizeId,
                            lateralFacing: marker.lateralFacing,
                            boundary: marker.boundary,
                            userDataType: "MarkerUserData",
                            type: SceneMarkerType.COLLISION,
                            middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                            sectionType: blenderData.sectionType,
                        }
                        if (segmentScaleFactor) {
                            meshClone.scale.set(segmentScaleFactor, 1, 1)
                        }
                        origin.add(meshClone)
                        meshClone.updateMatrixWorld()

                        saveMarkerInfo(meshClone, info, blenderData.sectionType)
                    }
                }
            }

            else if (blenderData.sectionType === SectionType.MIDDLE && (index !== 0 && index !== undefined)) {
                if (clone instanceof Mesh && debug) {
                    clone.material = new MeshBasicMaterial({
                        color: "red",
                        depthTest: true,
                        wireframe: true,

                    })
                }

                //add to the origin and save the marker info
                clone.userData = {
                    id: marker.id,
                    markerName: clone.name,
                    partId: tube.id,
                    iELength: marker.iELenght,
                    sizeId: marker.sizeId,
                    lateralFacing: marker.lateralFacing,
                    boundary: marker.boundary,
                    userDataType: "MarkerUserData",
                    type: SceneMarkerType.COLLISION,
                    middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                    sectionType: blenderData.sectionType,
                }
                if (debug) {
                    clone.visible = true
                } else {
                    clone.visible = false
                }
                origin.add(clone)
                clone.updateMatrixWorld(true) // Force update of world matrix

                // Store the world position and quaternion
                const worldPosition = new Vector3()
                const worldQuaternion = new Quaternion()
                clone.getWorldPosition(worldPosition)
                clone.getWorldQuaternion(worldQuaternion)

                //saveMarkerInfo(clone, info, blenderData.sectionType)

                //console.log(info.current.middleSection, "info.current.middleSection")

                const isInner = clone.name.includes("inner")
                const isOuter = clone.name.includes("outer")

                const { markerSide, markerPosition, } = getSideAndPosition(clone.name)


                if ((!markerSide || !markerPosition) && markerSide) {
                    console.log("markerSide or markerPosition not found", markerSide, markerPosition, clone.name, info.current.middleSection[markerSide][markerPosition])
                }

                //console.log(info.current.middleSection[markerNumber], "maker.name", marker.name)

                /*if (!info.current.middleSection[markerSide]) {
                    console.log(markerPosition, clone.name, "markerPosition in not found", "makerside was:", markerSide, info.current.middleSection)
                    return
                    console.log("info.current.middleSection[markerNumber] not found", markerSide, markerPosition, clone.name, info.current)
                }*/

                if (isInner && markerSide) {
                    //console.log(markerSide, markerPosition, "markerSide and markerPosition")

                    // Add safety check for markerSide
                    if (!info.current.middleSection[markerSide]) {
                        console.warn(`Missing middle section for side: ${markerSide}`)
                        return
                    }

                    // Add safety check for position 0
                    if (!info.current.middleSection[markerSide][0]) {
                        console.warn(`Missing position 0 for side: ${markerSide}`)
                        return
                    }

                    const innerMarker = info.current.middleSection[markerSide][0].inner

                    if (innerMarker && !innerMarker.userData.middleRefs) {
                        innerMarker.userData.middleRefs = []
                    }
                    const innerLocalPosition = new Vector3()
                    innerMarker?.worldToLocal(innerLocalPosition.copy(worldPosition))
                    // Add the new reference to both inner and outer
                    if (innerMarker) {
                        //console.log(markerPosition, "markerPosition")
                        innerMarker.userData.middleRefs.push({
                            refName: clone.name,
                            localOffset: innerLocalPosition,
                        })
                    }

                }

                if (isOuter && markerSide) {
                    const outerMarker = info.current.middleSection[markerSide][0].outer
                    if (outerMarker && !outerMarker.userData.middleRefs) {
                        outerMarker.userData.middleRefs = []
                    }
                    const outerLocalPosition = new Vector3()
                    outerMarker?.worldToLocal(outerLocalPosition.copy(worldPosition))
                    if (outerMarker) {
                        outerMarker.userData.middleRefs.push({
                            refName: clone.name,
                            localOffset: outerLocalPosition,
                        })
                    }
                }


                // Detach from origin
                clone.removeFromParent()

                // Set the stored world position and quaternion
                //clone.position.copy(worldPosition)
                //clone.quaternion.copy(worldQuaternion)
                //clone.updateMatrixWorld()
                ///


            } else if (blenderData.sectionType === SectionType.START || blenderData.sectionType === SectionType.END) {
                //console.log("here2", origin, marker.name,)
                clone.userData = {
                    id: marker.id,
                    markerName: clone.name,
                    partId: tube.id,
                    iELength: marker.iELenght,
                    sizeId: marker.sizeId,
                    lateralFacing: marker.lateralFacing,
                    boundary: marker.boundary,
                    userDataType: "MarkerUserData",
                    type: SceneMarkerType.COLLISION,
                    middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                    sectionType: blenderData.sectionType,
                }

                if (clone instanceof Mesh && debug) {
                    clone.visible = true
                    clone.material = new MeshBasicMaterial({
                        color: "red",
                        depthTest: true,
                        wireframe: true,

                    })
                } else {
                    clone.visible = false
                }

                origin.add(clone)
                saveMarkerInfo(clone, info, blenderData.sectionType)
                //console.log("here", origin, marker.name)

            }

            //////////////////////////////

            if (((marker.position === SegmentedTubeSectionType.START && !aligmentPlaneAdded.start)
                || (marker.position === SegmentedTubeSectionType.END && !aligmentPlaneAdded.end))
                && !marker.lateralFacing
            ) {

                const center = clone.position.clone()
                clone.userData.localSlidePoints = [{ position: center, },]

                const globalCenter = clone.localToWorld(center.clone())
                //drawPoints(info.current.scene, [globalCenter,], 0x000000,)

                const alignmentPlaneGeometry = new PlaneGeometry(80, 90)
                const aligmentPlaneMaterial = new MeshBasicMaterial({
                    alphaTest: 0,
                    visible: false,
                    color: "red",
                    side: DoubleSide,
                })
                const aligmentPlane = new Mesh(alignmentPlaneGeometry, aligmentPlaneMaterial)
                aligmentPlane.name = `${clone.name}_aligmentPlane`
                aligmentPlane.userData = {
                    type: SceneMarkerType.COLLISION_PLANE, partId: tube.id,
                }
                if (marker.position === SegmentedTubeSectionType.START) {
                    aligmentPlaneAdded.start = true
                } else {
                    aligmentPlaneAdded.end = true
                }
                clone.add(aligmentPlane)
                saveMarkerInfo(clone, info, blenderData.sectionType)
            }

        } else if (object.name.includes("mesh")) {
            const marker = tube.markers.find(marker => areSameConnectionMesh(object.name, marker.name))!
            const clone = object.clone(true)
            if (index || index === 0) {
                clone.name = `${clone.name}_${index}`
            }


            if (debug && clone instanceof Mesh) {
                clone.material = new MeshBasicMaterial({
                    color: "red",
                    side: DoubleSide,
                    transparent: true,
                    opacity: 0.3,
                })
                clone.visible = true
            } else {
                clone.visible = false
            }

            clone.userData = {
                id: marker.id,
                markerName: clone.name,
                partId: tube.id,
                iELength: marker.iELenght,
                sizeId: marker.sizeId,
                lateralFacing: marker.lateralFacing,
                boundary: marker.boundary,
                userDataType: "MarkerUserData",
                type: SceneMarkerType.COLLISION,
                middleSection: marker.position === SegmentedTubeSectionType.MIDDLE,
                sectionType: blenderData.sectionType,
            }

            origin.add(clone)

            //const axesHelper = new AxesHelper(0.05) // Size of the axes (adjust as needed)
            //axesHelper.name = "ignore_axesHelper" // This name helps identify it later if needed
            //clone.add(axesHelper)

            saveMarkerInfo(clone, info, blenderData.sectionType)

        }
        else if (object.name.includes("mainModel")) {
            const clone = object.clone()
            clone.visible = false
            //console.log("clone", clone)
            origin.add(clone)
        } else if (object.name.includes("joinTo")) {
            const clone = object.clone()
            clone.visible = false
            origin.add(clone)
        }
    }
    )
}



const calcNegativeDistance = (
    marker: Object3D,
    negativeLength: number,
    segmentLength: number,
    segmentScaleFactor?: number
): number => {
    // If it's an END section or no negative length, return current position
    if (marker.userData.sectionType === SectionType.END || negativeLength <= 0 || marker.userData.sectionType === SectionType.MIDDLE) {
        return 0
    }

    // Calculate total negative length in cm
    const totalNegativeLengthCm = convertInToCm(negativeLength * segmentLength * (segmentScaleFactor ?? 1))

    //console.log(totalNegativeLengthCm, "totalNegativeLengthCm")

    // If the marker is a START section and there is a negative length (more than 0), we need to move the marker backwards
    if (marker.userData.sectionType === SectionType.START) {
        // Get marker's normal direction (assuming Z is forward)
        // Apply offset in opposite direction of normal
        return totalNegativeLengthCm / 100
    }

    return 0
}


const getZeroNegativeLengthPosition = (
    marker: Object3D,
    negativeLength: number,
    segmentLength: number,
    segmentScaleFactor?: number,
    debug?: boolean,
    scene?: Scene,
    originPoint?: Object3D
): Vector3 => {
    const markerPosition = new Vector3()
    marker.getWorldPosition(markerPosition)

    // If it's an END section or no negative length, return current position
    if (marker.userData.sectionType === SectionType.END || negativeLength <= 0 || marker.userData.sectionType === SectionType.MIDDLE) {
        return markerPosition
    }

    // Calculate total negative length in cm
    const totalNegativeLengthCm = convertInToCm(negativeLength * segmentLength * (segmentScaleFactor ?? 1))

    // If the marker is a START section and there is a negative length (more than 0), we need to move the marker backwards
    if (marker.userData.sectionType === SectionType.START && originPoint) {
        // Get direction based on origin's quaternion
        // you cannot depend on the end markers because sometimes they are angled
        const markerDirection = new Vector3(0, 0, 1).applyQuaternion(MeshUtils.copyWorldQuaternion(originPoint))

        if (debug && scene) {
            // Create arrow helper to visualize direction
            const arrowLength = 0.1
            const arrowHelper = new ArrowHelper(
                markerDirection,
                markerPosition,
                arrowLength,
                0xff0000
            )
            arrowHelper.name = "ignore_directionArrow"

            // Remove any existing debug arrows
            const existingArrows = scene.children.filter(child => child.name === "ignore_directionArrow")
            existingArrows.forEach(arrow => scene.remove(arrow))

            scene.add(arrowHelper)

            setTimeout(() => {
                scene.remove(arrowHelper)
            }, 2000)
        }

        // Apply offset in opposite direction of normal
        return markerPosition.sub(markerDirection.multiplyScalar(totalNegativeLengthCm / 100))
    }

    return markerPosition
}
const createMarkers = (info: React.MutableRefObject<SegmentedTubeInfo>, tube: SegmentedTubeValues, tubeNegativeLength: number, tubeLength: number, segmentScaleFactor: number, debug: boolean) => {
    const middleInfo = info.current.blenderData.find((bd) => bd.sectionType === SectionType.MIDDLE)
    const startInfo = info.current.blenderData.find((bd) => bd.sectionType === SectionType.START)
    const endInfo = info.current.blenderData.find((bd) => bd.sectionType === SectionType.END)


    let zeroFound = false
    const negativeMiddleMarkers: { origin: Object3D, markerIndex: number, totalLength: number, }[] = []

    info.current.instancedMeshOrigins.forEach((origin, index) => {
        const isLast = info.current.instancedMeshOrigins.length - 1 === index
        const isFirst = index === 0
        const totalLength = info.current.instancedMeshOrigins.length - 2
        const markerIndex = index - tubeNegativeLength - 1

        //the debug material was making the markers not raycast intersectable
        if (isFirst && startInfo && middleInfo) {
            cloneMarker(tube, startInfo, origin, info, undefined, undefined, undefined, undefined, undefined, false)
        } else if (isLast && endInfo) {
            cloneMarker(tube, endInfo, origin, info, undefined, undefined, undefined, undefined, undefined, false)
        } else if (middleInfo) {
            if (markerIndex < 0) {
                negativeMiddleMarkers.push({ origin, markerIndex, totalLength, })
            } else {
                zeroFound = true
                //console.log(tube.id, markerIndex, "markerIndex")
                cloneMarker(tube, middleInfo, origin, info, markerIndex, totalLength, tubeLength, tubeNegativeLength, segmentScaleFactor ?? undefined, false)
            }
        }
    })

    // Process negative middle markers after everything else
    negativeMiddleMarkers.forEach(({ origin, markerIndex, totalLength, }) => {
        if (middleInfo) {
            //console.log(tube.id, markerIndex, "negative markerIndex")
            cloneMarker(tube, middleInfo, origin, info, markerIndex, totalLength, tubeLength, tubeNegativeLength, segmentScaleFactor ?? undefined, false)
        }
    })

    if (!zeroFound) {
        console.log("zeroFound not found", zeroFound)
    }
}

const removeAttachmentPointsOriginWithNoChildren = (info: React.MutableRefObject<SegmentedTubeInfo>, partId: string, newAttachment: Object3D) => {
    const attachmentPoints = info.current.scene.children.filter((child) => child.name.includes("Attachment Point Origin"))
    attachmentPoints.forEach((attachmentPoint) => {
        if (attachmentPoint.children.length === 0 && attachmentPoint.userData.partId === partId && attachmentPoint !== newAttachment) {
            attachmentPoint.removeFromParent()
        }
    })
}

const setAtachmentPoint = (newAttachmentPoint: Object3D, info: React.MutableRefObject<SegmentedTubeInfo>, setter?: (newAttachmentPoint: Object3D) => void, offset?: Vector3, rotateY180?: boolean, debug?: boolean, deleteAttachmentPoint?: boolean) => {

    const newAttachment = new Mesh(
        new OctahedronGeometry(0.015),
        new MeshBasicMaterial({
            color: 0xff0000,
            transparent: true,
            opacity: 0.5,
            depthTest: false,
            depthWrite: false,
            wireframe: true,
            visible: debug ?? false,
        })
    )
    newAttachment.name = "Attachment Point Origin"
    const firstStartSectionKey = Object.keys(info.current.startSection)[0]
    const partId = info.current.startSection[firstStartSectionKey]?.inner?.userData.partId
    newAttachment.userData = {
        partId,
        attachmentMarkerName: newAttachmentPoint.name,
        attachmentMarkerUUID: newAttachmentPoint.id,
    }
    if (rotateY180) {
        newAttachment.rotateY(MathUtils.degToRad(-180))
    }
    const wp = MeshUtils.copyWorldPosition(newAttachmentPoint)
    const wr = MeshUtils.copyWorldQuaternion(newAttachmentPoint)

    if (rotateY180) {
        newAttachment.rotateY(MathUtils.degToRad(+180))
    }

    newAttachment.position.copy(wp)

    if (offset) {
        //console.log("offset in setAttachmentPoint", offset)
        //newAttachment.position.copy(offset)
    }

    newAttachment.setRotationFromQuaternion(wr.normalize())
    info.current.scene.attach(newAttachment)
    if (info.current.originPoint) {
        newAttachment.attach(info.current.originPoint)
        info.current.attachmentPoint = newAttachment
        if (typeof setter === "function") {
            setter(newAttachment)
        }
        removeAttachmentPointsOriginWithNoChildren(info, partId, newAttachment)
    }

    if (deleteAttachmentPoint) {
        newAttachmentPoint.removeFromParent()
    }
}

const findIntersections = (scene: Scene, targetBox: Box3, partId: string, debug?: boolean) => {
    const intersectingObjects: Object3D[] = []

    const isExcluded = (object: Object3D, partId: string) => {
        return object.userData.partId === partId || object.userData.dontIntersect
    }


    const expandedTargetBox = targetBox.clone().expandByScalar(0.01)

    /*const helper = new Box3Helper(expandedTargetBox, "blue")
    helper.material.depthTest = false
    const helperName = "targetBox"
    helper.name = helperName
    scene.add(helper)*/




    scene.traverse((object) => {
        if (object instanceof Mesh && ((object.name.includes("inner"))
            || (object.name.includes("outer"))
            || (object.name.includes("mesh")))
            && !isExcluded(object, partId) && !object.name.includes("aligmentPlane")
            && !object.name.includes("cloned")
            && !object.name.includes("ignore")
        ) {
            // Compute the object's geometry bounds in world space
            object.geometry.computeBoundingBox()
            const geometryBox = object.geometry.boundingBox.clone()
            geometryBox.applyMatrix4(object.matrixWorld)

            if (geometryBox.intersectsBox(expandedTargetBox)) {
                intersectingObjects.push(object)

                /*if (debug) {
                    // Draw the bounding box for the intersecting object
                    const helper = new Box3Helper(geometryBox)
                    helper.material.depthTest = false
                    helper.name = "something"
                    scene.add(helper)
                }*/

            }
        }
    })
    return intersectingObjects
}



const drawLocalSlidePoints = (scene: Scene, obj: Object3D, color: number) => {
    if (obj.userData.localSlidePoints) {
        obj.userData.localSlidePoints.forEach((point: { position: Vector3, }) => {
            const globalPosition = new Vector3()
            obj.localToWorld(globalPosition.copy(point.position))

            const sphereGeometry = new SphereGeometry(0.001, 32, 32)
            const sphereMaterial = new MeshBasicMaterial({ color: color, }) // Green color for the points
            const sphere = new Mesh(sphereGeometry, sphereMaterial)
            sphere.position.copy(globalPosition)
            scene.add(sphere)
        })
    }
}

const groupByMarkerIdAndSort = (result: { marker: any, intersectedObjects: any[], }[],) => {

    const markerMap: { [key: string]: { marker: any, intersectedObjects: any[], }, } = {}

    result.forEach(item => {
        const markerUUID = item.marker.uuid

        if (!markerMap[markerUUID]) {
            markerMap[markerUUID] = { ...item, intersectedObjects: [], }
        }

        item.intersectedObjects.forEach(intersectedObject => {
            const existingObject = markerMap[markerUUID].intersectedObjects.find(obj => obj.object.id === intersectedObject.object.id)
            if (!existingObject) {
                markerMap[markerUUID].intersectedObjects.push(intersectedObject)
            } else if (existingObject.distance > intersectedObject.distance) {
                // Replace the existing object with the new one if it has a lower distance
                const index = markerMap[markerUUID].intersectedObjects.indexOf(existingObject)
                markerMap[markerUUID].intersectedObjects[index] = intersectedObject
            }
        })
    })

    // Sort intersectedObjects by distance for each marker
    Object.values(markerMap).forEach(item => {
        item.intersectedObjects.sort((a, b) => a.distance - b.distance)
    })

    return Object.values(markerMap)
}

const findClosestPointOnBox = (point: Vector3, box: Box3): Vector3 => {
    const closest = new Vector3()

    // For each axis (x, y, z)
    closest.x = Math.max(box.min.x, Math.min(box.max.x, point.x))
    closest.y = Math.max(box.min.y, Math.min(box.max.y, point.y))
    closest.z = Math.max(box.min.z, Math.min(box.max.z, point.z))

    return closest
}

const getPartIntersectionsBoxV2 = (
    section: { [key: string]: SegmentedTubeMarker, },
    raycaster: Raycaster,
    scene: Scene,
    boundingBox: Box3,
    partId: string,
    debug?: boolean,
) => {
    const thresholdDistance = 0.03

    const intersectingObjects = findIntersections(scene, boundingBox, partId)

    if (intersectingObjects.length === 0) {
        debug && console.log("no intersecting objects", intersectingObjects)
        return []
    }

    //run compute boundingbox for each of the itnersecting objects. if you don't do this early in the functino, the bounding box will be wrong
    //and the distance calculations will be wrong
    intersectingObjects.forEach((obj: Object3D) => {
        if (obj instanceof Mesh) {
            obj.geometry.computeBoundingBox()
        }
    })

    debug && console.log(intersectingObjects, "intersectingObjects")

    // Create a spatial index for intersecting objects
    //const spatialIndex = createSpatialIndex(intersectingObjects)

    const result: { marker: any, intersectedObjects: any[], }[] = []
    const rejectedResults: { marker: any, intersectedObjects: any[], }[] = []

    // Reusable Vector3 objects
    const globalSlidePointPos = new Vector3()

    Object.values(section).forEach(markerGroup => {
        Object.values(markerGroup).forEach(marker => {
            //we have to include outers here because the distance between the markers can be considerable
            if (!(marker instanceof Mesh)
                || (!marker.name.includes("inner") && !marker.name.includes("outer") && !marker.name.includes("mesh"))
            ) {
                return
            }

            const markerIsMesh = marker.name.includes("mesh")

            //we should only be processing inner markers
            //careful with the plus layers

            const markerToUse = markerIsMesh && marker.userData.closestInnerOrOuterMarkerMeshId
                ? (scene.getObjectById(marker.userData.closestInnerOrOuterMarkerMeshId) as Mesh)
                : marker

            const intersectedObjects: { distance: number, object: Object3D, globalSlidePoint: Vector3, }[] = []
            const rejectedObjects: { distance: number, object: Object3D, globalSlidePoint: Vector3, }[] = []

            //there are some old parts in old designs that do not have a mesh so they won't have local slide points
            // so this allows them to just use the center of the marker instead

            //dont nest ternary expressions
            let localSlidePointsToUse = []
            let localSlideMarker = marker

            if (markerIsMesh && marker.userData.localSlidePoints?.length > 0) {
                localSlidePointsToUse = marker.userData.localSlidePoints
                localSlideMarker = marker
            } else {
                //this would be the center of the mesh in case there is no mesh layer
                localSlidePointsToUse = [{ position: new Vector3(), },]
                localSlideMarker = markerToUse
            }

            localSlidePointsToUse.forEach((localSlidePoint: { position: Vector3, }) => {
                localSlideMarker.localToWorld(globalSlidePointPos.copy(localSlidePoint.position))

                // Use spatial index to get nearby objects
                //const nearbyObjects = spatialIndex.getNearbyObjects(globalSlidePointPos, thresholdDistance)

                intersectingObjects.forEach((obj: Object3D) => {

                    /*obj.getWorldPosition(objWorldPos)
                    const distance = globalSlidePointPos.distanceTo(objWorldPos)*/
                    if (obj instanceof Mesh) {

                        const objIsMesh = obj.name.includes("mesh")

                        const objToUse = objIsMesh && obj.userData.closestInnerOrOuterMarkerMeshId
                            ? (scene.getObjectById(obj.userData.closestInnerOrOuterMarkerMeshId) as Mesh)
                            : obj

                        if (!objToUse) {
                            console.warn("cant find objToUse", objToUse, obj)
                        }

                        //in case we intersect with a mesh, we want to push the inner so that
                        //for the compatibility logic it passes but use the mesh layer for distance calcs

                        //in the case of a "mesh" want to use the mesh's bounding box for the distance value to be accurate

                        // Get world space bounding box
                        const worldBox = new Box3().copy(obj.geometry.boundingBox)
                        worldBox.applyMatrix4(obj.matrixWorld)

                        // Find closest point in world space
                        const closestPoint = findClosestPointOnBox(globalSlidePointPos, worldBox)
                        const distance = globalSlidePointPos.distanceTo(closestPoint)

                        //console.log(distance, "distance", obj.name)

                        if (distance < thresholdDistance) {
                            //if you comment this out, note that meshes from connectors do not have localslide points currently
                            //const localSlidePoints = obj.userData.localSlidePoints || getMarkerCenter(obj)
                            //const closestSlidePoint = findClosestSlidePoint(localSlidePoints, obj, globalSlidePointPos, thresholdDistance)

                            if (closestPoint) {
                                intersectedObjects.push({
                                    distance, object: objToUse, globalSlidePoint: closestPoint,
                                    ...(debug && objIsMesh ? { meshUsed: obj, } : {}),
                                })
                            }
                        } else {
                            debug && rejectedObjects.push({ distance, object: objToUse, globalSlidePoint: closestPoint, ...(objIsMesh ? { meshUsed: obj, } : {}), })
                        }


                    }

                })
            })

            if (intersectedObjects.length > 0) {
                result.push({
                    marker: markerToUse,
                    intersectedObjects: intersectedObjects.sort((a, b) => a.distance - b.distance),
                })
            }

            if (rejectedObjects.length > 0 && debug) {
                rejectedResults.push({
                    marker: markerToUse,
                    intersectedObjects: rejectedObjects.sort((a, b) => a.distance - b.distance),
                })
            }
        })
    })

    debug && console.log(groupByMarkerIdAndSort(rejectedResults), "rejectedResultsForLogging for section", Object.values(section)[0]?.inner?.userData?.sectionType, Object.values(section)[0]?.inner?.name?.match(/\d+(?:_\d+)?/)?.[0] || Object.values(section)[0]?.inner?.name)

    return groupByMarkerIdAndSort(filterIntersectingObjects(result))
}

// Helper functions

const createSpatialIndex = (objects: Object3D[]) => {
    // Implement a simple grid-based spatial index
    // This is a basic implementation and can be replaced with more sophisticated structures like Octree
    const cellSize = 1 // Adjust based on your scene scale
    const grid: { [key: string]: Object3D[], } = {}

    objects.forEach(obj => {
        const position = new Vector3()
        obj.getWorldPosition(position)
        const key = `${Math.floor(position.x / cellSize)},${Math.floor(position.y / cellSize)},${Math.floor(position.z / cellSize)}`
        if (!grid[key]) { grid[key] = [] }
        grid[key].push(obj)
    })

    return {
        getNearbyObjects: (position: Vector3, radius: number) => {
            const nearby: Object3D[] = []
            const cellRadius = Math.ceil(radius / cellSize)
            for (let x = -cellRadius; x <= cellRadius; x++) {
                for (let y = -cellRadius; y <= cellRadius; y++) {
                    for (let z = -cellRadius; z <= cellRadius; z++) {
                        const key = `${Math.floor(position.x / cellSize) + x},${Math.floor(position.y / cellSize) + y},${Math.floor(position.z / cellSize) + z}`
                        if (grid[key]) { nearby.push(...grid[key]) }
                    }
                }
            }
            return nearby
        },
    }
}

const getMarkerCenter = (obj: Object3D) => {
    if (obj instanceof Mesh && obj.geometry) {
        obj.geometry.computeBoundingBox()
        const boundingBox = obj.geometry.boundingBox
        const center = new Vector3()
        boundingBox.getCenter(center)
        return [{ position: center, },]
    }
    return []
}

const findClosestSlidePoint = (localSlidePoints: { position: Vector3, }[], obj: Object3D, globalSlidePointPos: Vector3, thresholdDistance: number) => {
    let closest = null
    let minDistance = Infinity
    const slidePointWorldPos = new Vector3()

    for (const point of localSlidePoints) {
        obj.localToWorld(slidePointWorldPos.copy(point.position))
        const distance = globalSlidePointPos.distanceTo(slidePointWorldPos)

        if (distance < minDistance) {
            minDistance = distance
            closest = {
                distance,
                object: obj,
                globalSlidePoint: slidePointWorldPos.clone(),
            }
        }

        if (distance < thresholdDistance) { break } // Early termination
    }

    return closest && closest.distance < thresholdDistance ? closest : null
}

const filterIntersectingObjects = (result: { marker: any, intersectedObjects: any[], }[]) => {
    return result.map(item => ({
        marker: item.marker,
        intersectedObjects: item.intersectedObjects.sort((a, b) => a.distance - b.distance),
    }))
}


const getSectionIntersections = (
    section: SegmentedTubeMarkers,
    markersToIntersect: Object3D[],
    partConnectionsValue: ConnectionOfPart[],
    raycaster: Raycaster,
    tubeDirection?: Vector3,
    intersectableMeshes?: Object3D[],
    scene?: Scene, // Add scene as a parameter to add lines for debugging
    boundingBox?: Box3 | null,
    partId?: string,
) => {
    return Object.keys(section).filter(key => {
        const innerMarker = section[key].inner
        return !partConnectionsValue.some(({ partMarkerName, }) => outerToInner(partMarkerName) === innerMarker?.name)
    })
        .map(key => {
            //accounts for middles which won't come with tube direction
            const innerMarker = section[key].inner!
            const innerMarkerPos = MeshUtils.copyWorldPosition(innerMarker)
            const direction = tubeDirection ? tubeDirection : MeshUtils.copyWorldDirection(innerMarker)
            const auxPos = new Vector3()
            const auxDir = new Vector3()
            auxDir.copy(direction)
            auxPos.addVectors(innerMarkerPos, auxDir.normalize().multiplyScalar(-RAY_POSITION_REDUCTION / metersToInch))
            raycaster.ray.set(auxPos, direction)

            //debug
            //const material = (innerMarker as Mesh).material as MeshBasicMaterial
            //material.color.set("orange")
            //material.depthTest = false
            //material.visible = true

            //
            //should update this to only be the markers from the scene instead all the scene
            let objectsToIntersect = []
            if (intersectableMeshes && intersectableMeshes.length > 0) {
                objectsToIntersect = intersectableMeshes
            } else if (markersToIntersect && markersToIntersect.length > 0) {
                objectsToIntersect = markersToIntersect
            } else {
                objectsToIntersect = scene?.children || []
            }
            objectsToIntersect = objectsToIntersect.filter(object => !object.userData.ignoreRaycast)

            const objects = raycaster.intersectObjects(objectsToIntersect)

            const filteredObjects = objects.filter(intersection => {
                const objectName = intersection.object.name
                const isMesh = intersection.object instanceof Mesh
                const hasValidName = (objectName.includes("inner") || objectName.includes("outer")) && !objectName.includes("aligmentPlane") && !objectName.includes("ignore")
                const isDifferentPartId = tubeDirection || intersection.object.userData.partId !== section[key].inner?.userData.partId
                return isMesh && hasValidName && isDifferentPartId
            })


            /*
            filteredObjects.forEach(intersection => {
                const material = (intersection.object as Mesh).material as MeshBasicMaterial
                //material.color.set(0x00ff00) // Green color
                material.wireframe = false
                material.depthTest = false
                //material.visible = true
            })

            if (scene) {
                // Debugging: Draw the ray
                const rayLength = 10 // Adjust the length as needed
                const endPos = new Vector3().copy(auxPos)
                .add(direction.multiplyScalar(rayLength))
                const points = [auxPos, endPos,]
                const geometry = new BufferGeometry().setFromPoints(points)
                const material = new LineBasicMaterial({ color: 0xff0000, }) // Red color for the ray
                const line = new Line(geometry, material)
                //scene.add(line)
            }*/

            return { marker: innerMarker, intersectedObjects: filteredObjects, }
        })
}

type SectionIntersectionsType = { marker: Mesh, intersectedObjects: Intersection[], }[]
type SegmentedTubeIntersectionsType = {
    startSection: SectionIntersectionsType,
    middleSection: { [side: string]: SectionIntersectionsType, },
    endSection: SectionIntersectionsType,
}

const getSectionIntersectionsOld = (
    section: SegmentedTubeMarkers,
    markersToIntersect: Object3D[],
    partConnectionsValue: ConnectionOfPart[],
    raycaster: Raycaster,
    tubeDirection?: Vector3,
    intersectableMeshes?: Object3D[],
) => {
    return Object.keys(section).filter(key => {
        const innerMarker = section[key].inner
        return !partConnectionsValue.some(({ partMarkerName, }) => outerToInner(partMarkerName) === innerMarker?.name)
    })
        .map(key => {
            const innerMarker = section[key].inner!
            const innerMarkerPos = MeshUtils.copyWorldPosition(innerMarker)
            const direction = tubeDirection ? tubeDirection : MeshUtils.copyWorldDirection(innerMarker)

            const auxPos = new Vector3()
            const auxDir = new Vector3()
            auxDir.copy(direction)
            auxPos.addVectors(innerMarkerPos, auxDir.normalize().multiplyScalar(-RAY_POSITION_REDUCTION / metersToInch))
            raycaster.ray.set(auxPos, direction)
            const intersectableMeshesFiltered = intersectableMeshes ? intersectableMeshes?.filter(object => !object.userData.ignoreRaycast) : undefined
            const markersToIntersectFiltered = markersToIntersect ? markersToIntersect?.filter(object => !object.userData.ignoreRaycast) : undefined
            const objectsToIntersect = intersectableMeshesFiltered || markersToIntersectFiltered || []
            const objects = raycaster.intersectObjects(objectsToIntersect)
            return { marker: innerMarker, intersectedObjects: objects, }
        })
}

const proyectRays = (
    markersToIntersect: Object3D[],
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    newMiddlePositions: string[],
    markersToIntersectMiddle: Mesh[],
    scene?: Scene,
    allMiddles?: boolean,
    boundingBox?: Box3 | null,
    tube?: SegmentedTubeValues,
    debug?: boolean,
) => {
    const getFirstChildKeys = (middleSection: Record<string, any>): string[] => {
        const firstChildKey = Object.keys(middleSection)[0]
        if (firstChildKey) {
            return Object.keys(middleSection[firstChildKey])
        }
        return []
    }

    const { startSection, middleSection, endSection, } = info.current

    const firstChild = Object.values(info.current.startSection)[0]
    const partId = firstChild?.inner?.userData.partId || firstChild?.outer?.userData.partId || firstChild?.plus?.userData.partId || firstChild?.mesh?.userData.partId

    const keys = getFirstChildKeys(middleSection)

    const intersections: SegmentedTubeIntersectionsType = {
        startSection: [],
        middleSection: {},
        endSection: [],
    }

    const raycaster = new Raycaster()

    const tubeDirection = getTubeDirection(info, movableSectionName)

    if (!tubeDirection) {
        return {
            startSection: [],
            middleSection: {},
            endSection: [],
        }
    }

    if (movableSectionName === SegmentedTubeSectionType.END && scene && boundingBox) {
        intersections.startSection = getPartIntersectionsBoxV2(endSection, raycaster, scene, boundingBox, partId, debug)
        intersections.endSection = getPartIntersectionsBoxV2(startSection, raycaster, scene, boundingBox, partId, debug)
    }
    if (movableSectionName === SegmentedTubeSectionType.START && scene && boundingBox) {
        intersections.endSection = getPartIntersectionsBoxV2(endSection, raycaster, scene, boundingBox, partId, debug)
        intersections.startSection = getPartIntersectionsBoxV2(startSection, raycaster, scene, boundingBox, partId, debug)
    }
    if (allMiddles) {
        Object.keys(middleSection).forEach(key => {
            const newMiddleSection = keys.reduce((pv: SegmentedTubeMarkers, cv: string) => {
                pv[cv] = middleSection[key][cv]
                return pv
            }, {})
            if (scene && boundingBox) { intersections.middleSection[key] = getPartIntersectionsBoxV2(newMiddleSection, raycaster, scene, boundingBox, partId, debug) }
        })
    }
    else {
        Object.keys(middleSection).forEach(key => {
            const newMiddleSection = newMiddlePositions.reduce((pv: SegmentedTubeMarkers, cv: string) => {
                pv[cv] = middleSection[key][cv]
                return pv
            }, {})
            if (scene && boundingBox) { intersections.middleSection[key] = getPartIntersectionsBoxV2(newMiddleSection, raycaster, scene, boundingBox, partId, debug) }
        })
    }
    return intersections
}

const getTubeDirection = (
    info: MutableRefObject<SegmentedTubeInfo>,
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const pivot = info.current.originPoint
    if (pivot) {
        const pivotDirection = MeshUtils.copyWorldDirection(pivot)
        if (movableSectionName === SegmentedTubeSectionType.END) {
            return pivotDirection.multiplyScalar(-1)
        }
        return pivotDirection
    }
    return undefined
}

const proyectMovableSectionRay = (
    scene: Scene,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    proyectionMarker: MarkerType,
    intersectableMeshes?: Object3D[],
    tubeDirectionRay?: boolean
) => {
    const movableSection = movableSectionName === SegmentedTubeSectionType.END ? info.current.endSection : info.current.startSection
    const raycaster = new Raycaster()
    const movableSectionMarkers: { [key: string]: SegmentedTubeMarker, } = {}
    Object.keys(movableSection).forEach(key => {
        const marker = proyectionMarker === MarkerType.INNER ? movableSection[key].inner : movableSection[key].outer
        if (!marker?.userData.lateralFacing) {
            movableSectionMarkers[key] = movableSection[key]
        }
    })
    const tubeDirection = tubeDirectionRay ? getTubeDirection(info, movableSectionName) : undefined
    return getSectionIntersectionsOld(movableSectionMarkers, scene.children, partConnectionsValue, raycaster, tubeDirection, intersectableMeshes)
}

const orderIntersections = (a: Intersection, b: Intersection) => a.distance - b.distance

const getClosestMovableSectionIntersection = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    checkCollineal?: boolean
) => {
    const tubeMovableSectionIntersections = proyectMovableSectionRay(scene, info, partConnectionsValue, movableSection, MarkerType.INNER, undefined, true)
    const closestIntersections = tubeMovableSectionIntersections.map(tubeMovableSectionIntersection => {
        const filteredIntersections = tubeMovableSectionIntersection.intersectedObjects.filter(intersection =>
            intersection.object.userData.partId !== tube.id
            && intersection.object.userData.type === SceneMarkerType.COLLISION
            && (!checkCollineal || areCollineal(tubeMovableSectionIntersection.marker, intersection.object))
        )
        return { marker: tubeMovableSectionIntersection.marker, intersectedObject: filteredIntersections.sort(orderIntersections)[0], }
    }).filter(intersection => intersection.intersectedObject)
    return closestIntersections.sort((a, b) => orderIntersections(a.intersectedObject, b.intersectedObject))[0]
}

const areCollineal = (marker: Object3D, object: Object3D) => {
    const markerWorldDirection = MeshUtils.copyWorldDirection(marker)
    const markerWorldPosition = MeshUtils.copyWorldPosition(marker)
    const objectWorldDirection = MeshUtils.copyWorldDirection(object)
    const objectWorldPosition = MeshUtils.copyWorldPosition(object)

    return isCollineal(
        markerWorldDirection,
        markerWorldPosition,
        objectWorldDirection,
        objectWorldPosition
    )
}

const areParrallel = (marker: Object3D, object: Object3D) => {
    const markerWorldDirection = MeshUtils.copyWorldDirection(marker)
    const objectWorldDirection = MeshUtils.copyWorldDirection(object)

    return isParallel(markerWorldDirection, objectWorldDirection)
}

interface CompatibilityCheck {
    marker: {
        name: string,
        id: string,
        sizeId: string,
    };
    collidingObject: {
        name: string,
        id: string,
        sizeId: string,
    };
}

const logCompatibilityCheck = (check: CompatibilityCheck, details: Record<string, unknown>) => {
    console.log("=== Compatibility Check ===")
    console.log("Marker:", check.marker)
    console.log("Colliding Object:", check.collidingObject)
    console.log("Check Details:", details)
    console.log("=====================")
}

const isCompatibleWith = (
    marker: Mesh,
    collidingObject: Intersection<Object3D>,
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    debug?: boolean
) => {
    const markerId = marker.userData.id
    const markerSizeId = marker.userData.sizeId
    const collidingId = collidingObject.object.userData.id
    const collidingSizeId = collidingObject.object.userData.sizeId

    // Check if marker exists in compatibility list
    if (!compatibilityList[markerId]) {
        console.warn("Marker not found in compatibility list:", markerId)
        return false
    }

    const { ignoreSizeCompatibility, compatibleWithMulti, } = compatibilityList[markerId]

    // Check size compatibility
    const isSizeCompatible = ignoreSizeCompatibility || markerSizeId === collidingSizeId

    // Check if objects are compatible
    const isInCompatibleList = compatibleWithMulti.includes(collidingId)

    // Final compatibility result
    const isCompatible = isInCompatibleList && isSizeCompatible

    if (debug) {
        logCompatibilityCheck(
            {
                marker: {
                    name: marker.name,
                    id: markerId,
                    sizeId: markerSizeId,
                },
                collidingObject: {
                    name: collidingObject.object.name,
                    id: collidingId,
                    sizeId: collidingSizeId,
                },
            },
            {
                ignoreSizeCheck: ignoreSizeCompatibility,
                isSizeCompatible,
                compatibleWithList: compatibleWithMulti,
                isInCompatibleList,
                finalResult: isCompatible,
            }
        )
    }

    return isCompatible
}

const isSomeMarkerNameInConnections = (
    partConnectionsValue: ConnectionOfPart[],
    markerNames: string[]
): boolean => {
    return partConnectionsValue
        .some(connection => markerNames
            .some(markerName => outerToInner(markerName) === outerToInner(connection.partMarkerName)))
}

const isSomeMovableMarkerConnected = (
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const movableSection = movableSectionName === SegmentedTubeSectionType.END ? info.current.endSection : info.current.startSection
    const movableMarkersNames = Object.keys(movableSection).map(key => movableSection[key].inner?.name)
    const result = isSomeMarkerNameInConnections(partConnectionsValue, filterWithValue(movableMarkersNames))
    return result
}

const checkSnap = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    allConnections: PartConnectionType[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    newLength: number,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    maxPossibleLength: number
) => {
    const closestIntersection = getClosestMovableSectionIntersection(scene, tube, info, partConnectionsValue, movableSection, true)
    let playSound = false
    if (closestIntersection && closestIntersection.intersectedObject) {
        const marker = getMarkerUserData(closestIntersection.intersectedObject.object)
        const intersectionConnection = allConnections.find(c => isInConnection(c, marker.partId, marker.markerName))
        if (!intersectionConnection) {
            const intersectedDir = MeshUtils.copyWorldDirection(closestIntersection.intersectedObject.object)
                .normalize()
                .multiplyScalar(-1)
            const markerDir = MeshUtils.copyWorldDirection(closestIntersection.marker)
                .normalize()
            const isMarkerAlreadyConnected = isMarkerConnected(
                marker.markerName, marker.partId, allConnections
            )
            if (isCompatibleWith(closestIntersection.marker, closestIntersection.intersectedObject, compatibilityList)
                && closestIntersection.intersectedObject.distance * metersToInch < (0.1 + RAY_POSITION_REDUCTION)
                && !isMarkerAlreadyConnected
                && areCollineal(closestIntersection.intersectedObject.object, closestIntersection.marker)
                && isSameDirection(intersectedDir, markerDir)
            ) {
                playSound = movableSection === SegmentedTubeSectionType.START ? !info.current.snapped.start : !info.current.snapped.end
            }
        }
    }
    if (!playSound) {
        if (newLength >= maxPossibleLength * tube.segmentLength) {
            if (info.current.multipleMove.connected) {
                playSound = true
            } else if ((movableSection === SegmentedTubeSectionType.START && !info.current.snapped.start)
                || (movableSection === SegmentedTubeSectionType.END && !info.current.snapped.end)
            ) {
                playSound = isSomeMovableMarkerConnected(info, partConnectionsValue, movableSection)
            }
        }
    }
    if (playSound) {
        if (movableSection === SegmentedTubeSectionType.START) {
            if (info.current.multipleMove.connected) {
                info.current.multipleMove.snapped.start = true
            } else {
                info.current.snapped.start = true
            }
        } else {
            if (info.current.multipleMove.connected) {
                info.current.multipleMove.snapped.end = true
            }
            info.current.snapped.end = true
        }
        //SoundHelper.playUnsnap()
    }
}

const getMaxPossibleLength = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    actualLength: {
        positiveLength: number,
        negativeLength: number,
    },
    partsToMoveIds?: string[]
) => {
    const closestIntersection = getClosestMovableSectionIntersection(scene, tube, info, partConnectionsValue, movableSection)
    if (isSomeMovableMarkerConnected(info, partConnectionsValue, movableSection)) {
        const isTemporarilyUnsnapped = movableSection === SegmentedTubeSectionType.END ? !info.current.snapped.end : !info.current.snapped.start
        if (partsToMoveIds && partsToMoveIds.length > 0
            && info.current.multipleMove.maxPossibleLength
        ) {
            return actualLength.positiveLength + actualLength.negativeLength + Math.round(info.current.multipleMove.maxPossibleLength * metersToInch / tube.segmentLength)
        } else if (isTemporarilyUnsnapped) {
            return movableSection === SegmentedTubeSectionType.END
                ? tube.length + actualLength.negativeLength
                : tube.lengthNegativeSide + actualLength.positiveLength
        } else if (partsToMoveIds && partsToMoveIds.length > 0) {
            return tube.maxMiddles || 80
        } else {
            return actualLength.positiveLength + actualLength.negativeLength
        }
    } else if (closestIntersection && closestIntersection.intersectedObject
        && isCompatibleWith(closestIntersection.marker, closestIntersection.intersectedObject, compatibilityList)
        && areParrallel(closestIntersection.intersectedObject.object, closestIntersection.marker)
    ) {
        return actualLength.positiveLength
            + actualLength.negativeLength
            + Math.round(
                (closestIntersection.intersectedObject.distance
                    - (RAY_POSITION_REDUCTION / metersToInch))
                * metersToInch / tube.segmentLength)
    } else {
        return tube.maxMiddles || 80
    }
}

const checkMaxPossibleLength = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    setMaxPossibleLength: Dispatch<SetStateAction<number>>,
    actualLength: {
        positiveLength: number,
        negativeLength: number,
    },
    partsToMoveIds?: string[],
) => {
    setMaxPossibleLength(
        getMaxPossibleLength(
            scene,
            tube,
            info,
            partConnectionsValue,
            compatibilityList,
            movableSection,
            actualLength,
            partsToMoveIds
        )
    )
}

const checkCollinear = (
    diagonal: boolean,
    marker: Mesh,
    intersectedObject: Intersection
) => {
    if (diagonal) {
        return MeshUtils.areApproximatelyCollinear(marker, intersectedObject.object)
    } else {
        return areCollineal(intersectedObject.object, marker)
    }
}

type GetCorrectConnectionsProps = {
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    allConnections: PartConnectionType[],
    tube: SegmentedTubeValues,
    allMiddles?: boolean,
} & (
        {
            diagonal: false,
            intersections: {
                marker: Mesh,
                intersectedObject: Intersection | undefined,
            }[],
        }
        | {
            diagonal: true,
            intersections: {
                marker: Mesh,
                intersectedObject: Intersection[] | undefined,
            }[],
        }
    )

const visualizeConnections = (connections: PartConnectionType[], colorA: number, colorB: number, scene: Scene) => {
    const font = new FontLoader().parse(helvetikerFont)

    const findMarkerInScene = (scene: Scene, markerName: string, partId: string): Object3D | null => {
        let foundMarker: Object3D | null = null
        scene.traverse((object) => {
            if (object.name === markerName && object.userData.partId === partId) {
                foundMarker = object
            }
        })
        return foundMarker
    }

    const createTextMesh = (text: string, color: number) => {
        const geometry = new TextGeometry(text, {
            font: font,
            size: 0.01,
            height: 0.001,
        })
        const material = new MeshBasicMaterial({ color: color, depthTest: false, })
        return new Mesh(geometry, material,)
    }

    connections.forEach(connection => {
        const objectsToRemove = scene.children.filter(obj =>
            obj.name === "ignore_connectionVisualizationCloneA"
            || obj.name === "ignore_connectionVisualizationCloneB"
            || obj.name === "ignore_connectionVisualizationLine"
            || obj.name === "ignore_connectionVisualizationTextA"
            || obj.name === "ignore_connectionVisualizationTextB"
        )
        objectsToRemove.forEach(obj => scene.remove(obj))

        const markerA = findMarkerInScene(scene, connection.partA.markerName, connection.partA.partId)
        const markerB = findMarkerInScene(scene, connection.partB.markerName, connection.partB.partId)

        if (markerA && markerB) {
            const cloneA = markerA.clone()
            const cloneB = markerB.clone()

            const worldPositionA = new Vector3()
            const worldQuaternionA = new Quaternion()

            const worldPositionB = new Vector3()
            const worldQuaternionB = new Quaternion()

            markerA.getWorldPosition(worldPositionA)
            markerA.getWorldQuaternion(worldQuaternionA)

            markerB.getWorldPosition(worldPositionB)
            markerB.getWorldQuaternion(worldQuaternionB)


            const textMeshA = createTextMesh(`${markerA.name} (${connection.partA.partId.slice(0, 4)})`, colorA)
            textMeshA.name = "ignore_connectionVisualizationTextA"
            //textMeshA.position.copy(worldPositionA)
            //textMeshA.quaternion.copy(worldQuaternionA)

            cloneA.position.copy(worldPositionA)
            cloneA.quaternion.copy(worldQuaternionA)
            textMeshA.position.add(new Vector3(0.01, 0.01, 0.01)) // Offset from the marker

            const textMeshB = createTextMesh(`${markerB.name} (${connection.partB.partId.slice(0, 4)})`, colorB)
            textMeshB.name = "ignore_connectionVisualizationTextB"
            //textMeshB.position.copy(worldPositionB)
            //textMeshB.quaternion.copy(worldQuaternionB)
            textMeshB.position.add(new Vector3(0.01, 0.01, 0.01)) // Offset from the marker

            cloneA.name = "ignore_connectionVisualizationCloneA"
            cloneA.userData = {}
            cloneB.name = "ignore_connectionVisualizationCloneB"
            cloneB.userData = {}

            cloneB.position.copy(worldPositionB)
            cloneB.quaternion.copy(worldQuaternionB)

            // Make markers visible and set colors
            const materialA = new MeshBasicMaterial({ color: colorA, side: DoubleSide, transparent: true, opacity: 0.7, depthTest: false, })
            const materialB = new MeshBasicMaterial({ color: colorB, side: DoubleSide, transparent: true, opacity: 0.7, depthTest: false, })

            if (cloneA instanceof Mesh) {
                cloneA.material = materialA
            }
            if (cloneB instanceof Mesh) {
                cloneB.material = materialB
            }

            // Ensure the clones are visible
            cloneA.visible = true
            cloneB.visible = true

            // Set world position and quaternion for clones
            cloneA.position.setFromMatrixPosition(markerA.matrixWorld)
            cloneA.quaternion.setFromRotationMatrix(markerA.matrixWorld)

            cloneB.position.setFromMatrixPosition(markerB.matrixWorld)
            cloneB.quaternion.setFromRotationMatrix(markerB.matrixWorld)

            // Add TextGeometry meshes to clones
            cloneA.add(textMeshA)
            cloneB.add(textMeshB)

            scene.add(cloneA)
            scene.add(cloneB)

            // Add a line connecting the markers using world positions
            const lineGeometry = new BufferGeometry().setFromPoints([
                cloneA.position,
                cloneB.position,
            ])
            const lineMaterial = new LineBasicMaterial({ color: 0xffffff, depthTest: false, })
            const line = new Line(lineGeometry, lineMaterial)
            line.name = "ignore_connectionVisualizationLine"
            scene.add(line)
        }
    })
}



const getOrientedBoundingBox = (mesh: Mesh, shrinkAmount = 0, scene?: Scene, debug = false) => {
    if (!mesh.geometry.boundingBox) {
        mesh.geometry.computeBoundingBox()
    }

    //the shrink amount is used to eliminate cases where we consider the borders overllaping to not be a valid connection

    const localBox = mesh.geometry.boundingBox?.clone()

    // Shrink the local box by the specified amount
    if (shrinkAmount > 0) {
        localBox?.min.add(new Vector3(shrinkAmount, shrinkAmount, shrinkAmount))
        localBox?.max.sub(new Vector3(shrinkAmount, shrinkAmount, shrinkAmount))
    }

    if (!localBox) {
        return null
    }

    const vertices = [
        new Vector3(localBox.min.x, localBox.min.y, localBox.min.z),
        new Vector3(localBox.min.x, localBox.min.y, localBox.max.z),
        new Vector3(localBox.min.x, localBox.max.y, localBox.min.z),
        new Vector3(localBox.min.x, localBox.max.y, localBox.max.z),
        new Vector3(localBox.max.x, localBox.min.y, localBox.min.z),
        new Vector3(localBox.max.x, localBox.min.y, localBox.max.z),
        new Vector3(localBox.max.x, localBox.max.y, localBox.min.z),
        new Vector3(localBox.max.x, localBox.max.y, localBox.max.z),
    ]

    const worldVertices = vertices.map(vertex => vertex.clone().applyMatrix4(mesh.matrixWorld))

    const center = new Vector3()
    const size = new Vector3()
    const rotation = new Quaternion()

    mesh.matrixWorld.decompose(center, rotation, size)

    const axes = [
        new Vector3(1, 0, 0).applyQuaternion(rotation),
        new Vector3(0, 1, 0).applyQuaternion(rotation),
        new Vector3(0, 0, 1).applyQuaternion(rotation),
    ]

    if (scene && debug) {
        const edges = [
            [0, 1,], [1, 3,], [3, 2,], [2, 0,],
            [4, 5,], [5, 7,], [7, 6,], [6, 4,],
            [0, 4,], [1, 5,], [2, 6,], [3, 7,],
        ]

        edges.forEach(([start, end,]) => {
            const lineGeometry = new BufferGeometry().setFromPoints([
                worldVertices[start],
                worldVertices[end],
            ])
            const line = new Line(
                lineGeometry,
                new LineBasicMaterial({ color: 0xff0000, })
            )
            line.name = "orientedBoxEdge"
            scene.add(line)
        })
    }

    return {
        center,
        axes,
        vertices: worldVertices,
        size,
        containsPoint: (point: Vector3) => {
            const localPoint = point.clone().sub(center)
            return Math.abs(localPoint.dot(axes[0])) <= size.x / 2
                && Math.abs(localPoint.dot(axes[1])) <= size.y / 2
                && Math.abs(localPoint.dot(axes[2])) <= size.z / 2
        },
        getClosestPoint: (point: Vector3) => {
            const localPoint = point.clone().sub(center)
            const closest = new Vector3()

            axes.forEach((axis, i) => {
                const projection = localPoint.dot(axis)
                const clamped = Math.max(-size.getComponent(i) / 2,
                    Math.min(size.getComponent(i) / 2, projection))
                closest.add(axis.clone().multiplyScalar(clamped))
            })

            return closest.add(center)
        },
        // New method to check intersection with another mesh
        intersectsMesh: (otherMesh: Mesh) => {
            // Get the OBB for the other mesh
            const otherOBB = getOrientedBoundingBox(otherMesh)

            if (!otherOBB) {
                return true
            }

            // Separating Axis Theorem (SAT) test
            const axesToTest = [
                ...axes,                    // axes from this OBB
                ...otherOBB.axes,          // axes from other OBB
                // Cross products of all pairs of axes
                ...axes.flatMap(axis1 =>
                    otherOBB.axes.map(axis2 => {
                        const cross = axis1.clone().cross(axis2)
                        return cross.length() > 0.001 ? cross.normalize() : null
                    })
                ).filter((axis): axis is Vector3 => axis !== null),
            ]

            // Project both OBBs onto each axis and check for overlap
            for (const axis of axesToTest) {
                const [min1, max1,] = projectOBBOntoAxis(worldVertices, axis)
                const [min2, max2,] = projectOBBOntoAxis(otherOBB.vertices, axis)

                // If there's a gap along any axis, the OBBs don't intersect
                if (max1 < min2 || max2 < min1) {
                    return false
                }
            }

            return true
        },
    }
}

// Helper function to project vertices onto an axis
const projectOBBOntoAxis = (vertices: Vector3[], axis: Vector3): [number, number] => {
    let min = Infinity
    let max = -Infinity

    vertices.forEach(vertex => {
        const projection = vertex.dot(axis)
        min = Math.min(min, projection)
        max = Math.max(max, projection)
    })

    return [min, max,]
}

// Example usage for checking intersection between two meshes
const checkPlanesOverlap = (
    scene: Scene,
    mesh1: Mesh,
    mesh2: Mesh,
    shrinkAmount = 0.005,
    debug = false
): boolean => {
    const obb1 = getOrientedBoundingBox(mesh1, shrinkAmount, scene, debug)
    const obb2 = getOrientedBoundingBox(mesh2, shrinkAmount, scene, debug)

    //console.log(obb1?.intersectsMesh(mesh2), "obb1.intersectsMesh(mesh2)")

    return obb1?.intersectsMesh(mesh2) ?? true
}

const getCorrectConnectionsForAllIntersections = (props: GetCorrectConnectionsPropsForAllIntersections, debug = false) => {
    const newConnections: PartConnectionType[] = []
    const existingConnections: PartConnectionType[] = []
    const closestIntersections = props.intersections

    //console.log(props.allConnections, "allConnections")

    //console.log(closestIntersections, "closestIntersections")

    const rejectedConnections: {
        partA: PartConnectionType["partA"],
        partB: PartConnectionType["partB"],
        conditions: {
            conditionA: boolean,
            conditionC: boolean,
            isMarkerToMarkerConnectedExplicit: boolean,
        },
    }[] = []


    closestIntersections.forEach(intersection => {
        if ("intersectedObjects" in intersection && intersection.intersectedObjects) {
            intersection.intersectedObjects.forEach(intersectedObject => {
                const markerDir = MeshUtils.copyWorldDirection(intersection.marker)
                    .normalize()
                const marker = getMarkerUserData(intersection.marker)

                //this version of the function wasn't reliable
                //const isMarkerAlreadyConnected = isMarkerConnected(
                //    intersectedObject.object.name, intersectedObject.object.userData.partId, props.allConnections, intersection.marker.name
                //)

                const isMarkerToMarkerConnectedExplicit = isMarkerToMarkerConnected(
                    props.tube.id,
                    intersection.marker.name,
                    intersectedObject.object.userData.partId,
                    intersectedObject.object.name,
                    props.allConnections
                )

                //normalizing the directions for more accurate comparison is important

                const intersectedDir = MeshUtils.copyWorldDirection(intersectedObject.object)
                    .normalize()
                    .multiplyScalar(-1)

                //const areCollinear = areCollineal(intersection.marker, intersectedObject.object)

                const conditionA = isCompatibleWith(intersection.marker, intersectedObject, props.compatibilityList)
                //const conditionB = checkCollinear(props.diagonal, intersection.marker, intersection.intersectedObject)
                const conditionC = intersectedObject.object.userData.specialRotationMarker
                    || isSameDirection(markerDir, intersectedDir)                //the distance condition is checked earlier so not needed here
                //const conditionD = intersectedObject.distance * metersToInch < (0.1 + RAY_POSITION_REDUCTION)
                const conditions = conditionA && conditionC && !isMarkerToMarkerConnectedExplicit

                //note I removed conditionC for already connected because there are some special cases where the
                //connecting markers are perpendicular to each other. So here we are depending on the idea that they
                //are already connected so that logic is fine and no need to check for that
                const conditionsForAlreadyConnected = conditionA && conditionC && isMarkerToMarkerConnectedExplicit

                //console.log(areCollinear, "areCollinear", marker.markerName, intersectedObject.object.name)
                //console.log(conditionC, "isSameDirection", marker.markerName, intersectedObject.object.name)
                //console.log(conditionD, "conditionD", intersectedObject.object.name, intersection.marker.name)

                let meshesFromIntersectingObjectsOverlap = true

                //we put this as true so that connectors which don't have meshes are still passed
                //there are some that do have meshes and in those cases, it will check for overlap


                if (conditions || conditionsForAlreadyConnected) {

                    const intersectionMeshID = intersection.marker.userData.matchingMeshID
                    const intersectionMesh = props.scene.getObjectById(intersectionMeshID)

                    const intersectedMeshID = intersectedObject.object.userData.matchingMeshID
                    const intersectedMesh = props.scene.getObjectById(intersectedMeshID)

                    //here we are finding the corresponding mesh layers which extend to the edges of the part

                    if (intersectionMesh && intersectedMesh) {
                        meshesFromIntersectingObjectsOverlap = checkPlanesOverlap(
                            props.scene,
                            intersectionMesh as Mesh,
                            intersectedMesh as Mesh,
                            0.005,
                            false,
                        )
                        debug && console.log(meshesFromIntersectingObjectsOverlap, intersectionMesh, intersectedMesh, "intersectionMesh, intersectedMesh")

                    } else {
                        debug && console.warn("no meshes found for intersection", intersection.marker.name, intersectedObject.object.name)
                    }
                }


                if (conditions && meshesFromIntersectingObjectsOverlap) {

                    newConnections.push({
                        partA: {
                            partId: props.tube.id,
                            markerName: intersection.marker.name,
                        },
                        partB: {
                            partId: intersectedObject.object.userData.partId,
                            markerName: intersectedObject.object.name,
                        },
                    })
                }
                if (conditionsForAlreadyConnected && meshesFromIntersectingObjectsOverlap) {
                    existingConnections.push({
                        partA: {
                            partId: props.tube.id,
                            markerName: intersection.marker.name,
                        },
                        partB: {
                            partId: intersectedObject.object.userData.partId,
                            markerName: intersectedObject.object.name,
                        },
                    })
                } else if (debug) {
                    rejectedConnections.push({
                        partA: {
                            partId: props.tube.id,
                            markerName: intersection.marker.name,
                        },
                        partB: {
                            partId: intersectedObject.object.userData.partId,
                            markerName: intersectedObject.object.name,
                        },
                        conditions: {
                            conditionA,
                            conditionC,
                            isMarkerToMarkerConnectedExplicit,
                        },
                    })
                }

            })
        }
    })

    if (debug) {
        console.log(rejectedConnections, "rejectedConnections")
    }

    return { newConnections, existingConnections, }
}

const getClosestIntersections = (intersections: Intersection[]) => {
    if (intersections.length > 0) {
        intersections.sort(orderIntersections)
        const closestDistance = intersections[0].distance
        return intersections.filter(intersection => intersection.distance < closestDistance + 0.1 / metersToInch)
    } else {
        return []
    }
}

const getMostCenteredCloseDiagonal = (marker: Mesh, intersections: Intersection[]) => {
    const markerDir = MeshUtils.copyWorldDirection(marker)
    const markerPos = MeshUtils.copyWorldPosition(marker)
    const closestIntersections = getClosestIntersections(intersections)
    const closestIntersectionsWithDistanceToCenter = filterWithValue(closestIntersections.map(intersection => {
        const distanceToCenterIntersection = MeshUtils.checkAproxCollinearToDirection(markerPos, markerDir, intersection.object, false)
        if (distanceToCenterIntersection) {
            return { ...intersection, distanceToCenter: distanceToCenterIntersection.distance, }
        } else {
            return undefined
        }
    }))
    closestIntersectionsWithDistanceToCenter.sort((a, b) => a.distanceToCenter - b.distanceToCenter)
    return closestIntersectionsWithDistanceToCenter[0]
}

type GetFilteredIntersectionsType<B> =
    B extends true ? {
        diagonal: true,
        intersections: {
            marker: Mesh,
            intersectedObject: Intersection[],
        }[],
    } : {
        diagonal: false,
        intersections: {
            marker: Mesh,
            intersectedObject: Intersection,
        }[],
    }

const getFilteredIntersections = <B extends boolean>(section: SectionIntersectionsType, tube: SegmentedTubeValues, diagonal: B): GetFilteredIntersectionsType<B> => {
    const filteredIntersections = section.map(markerIntersections => {
        const filteredIntersections = markerIntersections.intersectedObjects.filter(intersection => {
            const conditionA = intersection.object.userData.partId !== tube.id
            const conditionB = intersection.object.userData.type === SceneMarkerType.COLLISION
            //const conditionBB = areCollineal(markerIntersections.marker, intersection.object)
            const markerDir = MeshUtils.copyWorldDirection(markerIntersections.marker)
            const intersectionDir = MeshUtils.copyWorldDirection(intersection.object)
            const conditionBB = isParallel(markerDir, intersectionDir)
            const conditionC = diagonal || conditionBB
            //condition B now changed to parrallel
            return conditionA && conditionC && conditionB
        })

        if (diagonal) {
            return {
                marker: markerIntersections.marker,
                filteredIntersections,
            }
        } else {
            return {
                marker: markerIntersections.marker,
                filteredIntersections,
            }
        }
    })

    if (diagonal) {
        return {
            diagonal: true,
            intersections: filteredIntersections.map(intersection => {
                return {
                    marker: intersection.marker,
                    intersectedObject: getClosestIntersections(intersection.filteredIntersections),
                }
            }),
        } as unknown as GetFilteredIntersectionsType<B>
    } else {
        return {
            diagonal: false,
            intersections: filteredIntersections.map(intersection => {
                return {
                    marker: intersection.marker,
                    intersectedObject: intersection.filteredIntersections.sort(orderIntersections)[0],
                }
            }),
        } as unknown as GetFilteredIntersectionsType<B>
    }
}

const checkSnapOnEditionMovableSection = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    compatibilityList: ObjDictionary<ConnectionTypeAPI>,
    allConnections: PartConnectionType[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    newMiddlePositions: string[],
    markersToIntersectMiddle: Mesh[],
    playSound?: boolean,
    markersToIntersect?: Object3D[],
    allMiddles?: boolean,
    boundingBox?: Box3 | null,
    isAdmin?: boolean,
) => {
    let newConnections = []

    let existingConnections: PartConnectionType[] = []
    const tubeIntersections = proyectRays(
        markersToIntersect ? markersToIntersect : scene.children,
        info,
        partConnectionsValue,
        movableSection,
        newMiddlePositions,
        markersToIntersectMiddle,
        scene,
        allMiddles,
        boundingBox,
        tube,
        isAdmin,
    )

    console.log("checkSnap")

    if (isAdmin) {
        console.log(tubeIntersections, "checkSnapOnEditionMovableSection tubeIntersections")
    }

    const { newConnections: endIntersectionsConnections, existingConnections: endAllConnections, } = getCorrectConnectionsForAllIntersections({
        intersections: tubeIntersections.endSection,
        compatibilityList,
        allConnections,
        tube,
        diagonal: false,
        scene,
    }, isAdmin)


    newConnections = endIntersectionsConnections

    const { newConnections: startIntersectionsConnections, existingConnections: startAllConnections, } = getCorrectConnectionsForAllIntersections({
        intersections: tubeIntersections.startSection,
        compatibilityList,
        allConnections,
        tube,
        diagonal: false,
        scene,
    }, isAdmin)

    newConnections = newConnections.concat(startIntersectionsConnections)

    let newConnectionsforMiddles: PartConnectionType[] = []
    let existingConnectionsforMiddles: PartConnectionType[] = []

    Object.keys(tubeIntersections.middleSection).forEach(cv => {
        const { newConnections, existingConnections, } = getCorrectConnectionsForAllIntersections({
            intersections: tubeIntersections.middleSection[cv],
            compatibilityList,
            allConnections,
            tube,
            diagonal: false,
            scene,
        }, isAdmin)
        newConnectionsforMiddles = newConnectionsforMiddles.concat(newConnections)
        existingConnectionsforMiddles = existingConnectionsforMiddles.concat(existingConnections)
    })

    newConnections = newConnections.concat(newConnectionsforMiddles)

    const filterConnections = (newConnections: PartConnectionType[], existingConnections: PartConnectionType[]) => {
        const getPrefix = (str: string) => outerToInner(str.split("_")[0])

        if (newConnections.length === 0) {
            return []
        }

        if (existingConnections.length === 0 && newConnections.length > 0) {
            return newConnections
        }

        return newConnections.filter(intersection => {
            const { partA, partB, } = intersection
            return !existingConnections.some(newConn => {
                const samePartA = newConn.partA.partId === partA.partId && getPrefix(newConn.partA.markerName) === getPrefix(partA.markerName)
                const samePartB = newConn.partB.partId === partB.partId && getPrefix(newConn.partB.markerName) === getPrefix(partB.markerName)
                return samePartA && samePartB
            })
        })
    }

    existingConnections = existingConnections
        .concat(endAllConnections)
        .concat(startAllConnections)
        .concat(existingConnectionsforMiddles)


    const filteredConnections = filterConnections(newConnections, existingConnections)

    if (isAdmin) {
        console.log(newConnections, "newConnections from checkSnapOnEditionMovableSection")
        console.log(existingConnections, "existingConnections from checkSnapOnEditionMovableSection")
    }


    //this logic now keeps the order of the connections
    const seenParts = new Set()
    const uniqueConnections = filteredConnections.filter(connection => {
        const otherPartId = getOtherPartOfTheConnection(connection, tube.id)
        if (seenParts.has(otherPartId)) {
            return false
        }
        seenParts.add(otherPartId)
        return true
    })

    //this logic was overwriting the closest conncetion
    //const partsDictionary = toDictionary(filteredConnections, part => getOtherPartOfTheConnection(part, tube.id))

    newConnections = uniqueConnections

    //const newConnectionsUnfiltered = Object.values(partsDictionary)

    const newConnectionsUnfiltered = filteredConnections

    if (isAdmin) {
        console.log(newConnections, "newConnections after object ditionary")
        console.log(newConnectionsUnfiltered, "newConnectionsUnfiltered after object ditionary")
    }

    //here we are removing any connections that are going to come in existing connection from new connections
    //to prevent duplicate connections between the same two parts

    newConnections = newConnections.filter(connection => {
        const otherPartId = getOtherPartOfTheConnection(connection, tube.id)

        return !existingConnections.some(existingConnection =>
            existingConnection.partA.partId === otherPartId || existingConnection.partB.partId === otherPartId
        )
    })

    if (isAdmin) {
        console.log(newConnections, "newConnections after removing duplicates")
    }

    return { newConnections, existingConnections, newConnectionsUnfiltered, }
}

const checkUnsnap = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    newLengthMovableSide: number,
    unsnap: (id: string, markerNames: string[]) => void,
    movableSectionName: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    const actualLength = movableSectionName === SegmentedTubeSectionType.START ? tube.lengthNegativeSide : tube.length
    if (actualLength > newLengthMovableSide) {
        const movableSection = movableSectionName === SegmentedTubeSectionType.END ? info.current.endSection : info.current.startSection
        const movableMarkersNames = filterWithValue(Object.keys(movableSection).map(key => {
            const marker = movableSection[key]
            return [marker.inner?.name, marker.outer?.name,]
        })
            .flat(1))
        unsnap(tube.id, movableMarkersNames)
    }
}

const checkUnsnapSound = (
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    newLength: number,
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>,
    maxPossibleLength: number,
) => {
    let snappedState = false
    if (info.current.multipleMove.connected) {
        snappedState = movableSection === SegmentedTubeSectionType.START ? info.current.multipleMove.snapped.start : info.current.multipleMove.snapped.end
    } else {
        snappedState = movableSection === SegmentedTubeSectionType.START ? info.current.snapped.start : info.current.snapped.end
    }
    if (
        snappedState
        && newLength < maxPossibleLength * tube.segmentLength
    ) {
        if (movableSection === SegmentedTubeSectionType.START) {
            if (info.current.multipleMove.connected) {
                info.current.multipleMove.snapped.start = false
            } else {
                info.current.snapped.start = false
            }
        } else if (info.current.multipleMove.connected) {
            info.current.multipleMove.snapped.end = false
        } else {
            info.current.snapped.end = false
        }
        //SoundHelper.playSnap()
    }
}

const setSnappedStateOnLoad = (
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
) => {
    const startMarkerNames = filterWithValue(Object.keys(info.current.startSection).map(key => info.current.startSection[key].inner?.name))
    const endMarkerNames = filterWithValue(Object.keys(info.current.endSection).map(key => info.current.endSection[key].inner?.name))
    info.current.snapped.start = isSomeMarkerNameInConnections(partConnectionsValue, startMarkerNames)
    info.current.snapped.end = isSomeMarkerNameInConnections(partConnectionsValue, endMarkerNames)
}

const removeGuidelines = (
    scene: Scene,
    info: MutableRefObject<SegmentedTubeInfo>,
) => {
    info.current.guidelines.forEach((line) => scene.remove(line))
    info.current.guidelines = []
}

const renderGuidelines = (
    scene: Scene,
    info: MutableRefObject<SegmentedTubeInfo>,
    guidelinesIntersections: {
        marker: Mesh,
        intersectedObjects: Intersection[],
    }[]
) => {
    guidelinesIntersections.forEach(intersection => {
        const markerPos = MeshUtils.copyWorldPosition(intersection.marker)
        intersection.intersectedObjects.forEach(intersectedObject => {
            const objectPos = MeshUtils.copyWorldPosition(intersectedObject.object)
            const points = [markerPos, objectPos,]
            const geometry = new BufferGeometry().setFromPoints(points)
            const lineMesh = new Line(
                geometry,
                new LineBasicMaterial({ color: "red", linewidth: 1, })
            )
            scene.add(lineMesh)
            info.current.guidelines.push(lineMesh)
        })
    })
}

const checkGuidelines = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSection: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
) => {
    removeGuidelines(scene, info)
    const tubeMovableSectionIntersections = proyectMovableSectionRay(scene, info, partConnectionsValue, movableSection, MarkerType.OUTER)
    const guidelinesIntersections = tubeMovableSectionIntersections.map(tubeMovableSectionIntersection => {
        const filteredIntersections = tubeMovableSectionIntersection.intersectedObjects.filter(intersection =>
            intersection.distance * metersToInch <= 0.1 + RAY_POSITION_REDUCTION
            && intersection.object.userData.partId !== tube.id
            && intersection.object.userData.type === SceneMarkerType.COLLISION_PLANE)
        return { marker: tubeMovableSectionIntersection.marker, intersectedObjects: filteredIntersections.sort(orderIntersections), }
    }).filter(intersection => intersection.intersectedObjects.length > 0)
    renderGuidelines(scene, info, guidelinesIntersections)
}

const checkAlignments = (
    scene: Scene,
    tube: SegmentedTubeValues,
    info: MutableRefObject<SegmentedTubeInfo>,
    partConnectionsValue: ConnectionOfPart[],
    movableSections: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>[],
    intersectableMeshes: Object3D[]
) => {
    removeGuidelines(scene, info)
    const DISTANCE_TO_ACTIVATE_GUIDELINE = 20
    const initialValue: {
        marker: Mesh,
        intersectedObjects: Intersection[],
    }[] = []
    const movableSectionIntersections = movableSections.reduce((pv, cv) => {
        return pv.concat(proyectMovableSectionRay(
            scene,
            info,
            partConnectionsValue,
            cv,
            MarkerType.OUTER,
            intersectableMeshes
        ))
    }, initialValue)
    movableSectionIntersections.forEach(movableSectionIntersection => {
        const markerDirection = MeshUtils.copyWorldDirection(movableSectionIntersection.marker)
        const markerPosition = MeshUtils.copyWorldPosition(movableSectionIntersection.marker)
        const filteredIntersections = movableSectionIntersection.intersectedObjects.filter(intersection =>
            intersection.object.userData.type === SceneMarkerType.COLLISION
            && intersection.object.userData.partId !== tube.id
        ).sort((a, b) => a.distance - b.distance)
        const closestIntersection = filteredIntersections[0]
        if (closestIntersection && closestIntersection.distance < DISTANCE_TO_ACTIVATE_GUIDELINE) {
            const intersectionMarkerDirection = MeshUtils.copyWorldDirection(closestIntersection.object)
            const intersectionMarkerPosition = MeshUtils.copyWorldPosition(closestIntersection.object)

            if (isCollineal(markerDirection, markerPosition, intersectionMarkerDirection, intersectionMarkerPosition)) {
                const geometry = new BufferGeometry().setFromPoints([markerPosition, intersectionMarkerPosition,])
                const lineMesh = new Line(
                    geometry,
                    new LineBasicMaterial({ color: "red", linewidth: 1, })
                )
                info.current.guidelines.push(lineMesh)
                scene.add(lineMesh)
            }
        }
    })
}

const checkDiagonalEdges = (
    info: MutableRefObject<SegmentedTubeInfo>
) => {
    const startSectionValues = Object.values(info.current.startSection)
    const endSectionValues = Object.values(info.current.endSection)

    if (startSectionValues.length === 0) {
        console.warn("Start section is empty")
    }
    if (endSectionValues.length === 0) {
        console.warn("End section is empty")
    }

    const startMarkerCandidate = startSectionValues.length > 0
        ? startSectionValues.find(marker => marker?.outer && !marker.outer.userData.lateralFacing)
        : undefined

    const endMarkerCandidate = endSectionValues.length > 0
        ? endSectionValues.find(marker => marker?.outer && !marker.outer.userData.lateralFacing)
        : undefined

    if (!startMarkerCandidate) {
        console.warn("No valid start marker found with outer property and lateralFacing", startSectionValues)
    }
    if (!endMarkerCandidate) {
        console.warn("No valid end marker found with outer property and lateralFacing", endSectionValues)
    }

    const startMarker = startMarkerCandidate?.outer
    const endMarker = endMarkerCandidate?.outer

    if (!info.current.originPoint) {
        console.warn("Origin point is undefined")
    }

    info.current.diagonalEdges = {
        start: startMarker ? !areParrallel(startMarker, info.current.originPoint!) : false,
        end: endMarker ? !areParrallel(endMarker, info.current.originPoint!) : false,
    }
}

const getMiddleFreeMarker = (section: SegmentedTubeMarkers, length: number) => {
    const keys = Object.keys(section).sort((a, b) => Number(a) - Number(b))
    return keys[Math.floor(length / 2)]
}

const getMarkerByFreePositions = (
    partId: string,
    connections: PartConnectionType[],
    middleSection: Record<string, SegmentedTubeMarkers>,
    length: number
) => {
    let markerKey: string | undefined
    const connectionsWithPart = connections.filter(c => isInConnection(c, partId))
    const middleSectionSidesKeys = Object.keys(middleSection)
    if (connectionsWithPart.length) {
        const freePositions: number[] = []

        Object.keys(Object.entries(middleSection)[0][1]).forEach((markerSectionKey) => {
            const SomeSideConnected = middleSectionSidesKeys.some((side) =>
                connectionsWithPart.some(c => isInConnection(c, partId, `outer${side}_${markerSectionKey}`))
            )

            if (!SomeSideConnected) {
                freePositions.push(parseInt(markerSectionKey, 10))
            }
        })
        const auxValue = [...freePositions,]
        const freePositionsGroupedByLot: number[][] = []
        freePositions.forEach((v, index) => {
            if (auxValue.length <= 2) {
                freePositionsGroupedByLot.push(auxValue)
            } else if (freePositions[index - 1] !== v - 1 || index === freePositions.length - 1) {
                freePositionsGroupedByLot.push(auxValue.splice(0, auxValue.findIndex(val => val === v)))
            }
        })
        freePositionsGroupedByLot.sort((a, b) => b.length - a.length)
        if (freePositionsGroupedByLot.length) {
            markerKey = freePositionsGroupedByLot[0][Math.floor(freePositionsGroupedByLot[0].length / 2)].toString()
        }
    }
    return markerKey || getMiddleFreeMarker(middleSection[middleSectionSidesKeys[0]], length)
}

const SegmentedTubeUtils = {
    createMergedMesh,
    checkGuidelines,
    getMaxPossibleLength,
    checkMaxPossibleLength,
    checkSnap,
    checkSnapOnEditionMovableSection,
    checkUnsnap,
    checkUnsnapSound,
    cleanUpPreviousOrigins,
    createInstancedMeshOrigins,
    createPool,
    initSegmentedTubeInfo,
    isSomeMovableMarkerConnected,
    processData,
    removeGuidelines,
    setAtachmentPoint,
    setSnappedStateOnLoad,
    updateInstancedTransforms,
    checkAlignments,
    visualizeConnections,
    checkDiagonalEdges,
    getMarkerByFreePositions,
    getTubeDirection,
    getZeroNegativeLengthPosition,
    calcNegativeDistance,
}

export default SegmentedTubeUtils