/* eslint-disable max-len */
import React from "react"
import styled from "styled-components"
import { PartTypeAPI } from "../../../../../../../../common/api/Types"
import ReactDOMServer from "react-dom/server"
import { ServerStyleSheet } from "styled-components"

const WindowContainer = styled.div`
  padding: 40px;
  background: #f7f9fc;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
`

const Title = styled.h2`
  text-align: center;
  margin-bottom: 40px;
  color: #2c3e50;
  font-size: 28px;
  font-weight: 600;
`

const ComparisonLayout = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  gap: 40px;
  max-width: 1400px;
  margin: 0 auto;
`

const OriginalPartSection = styled.div`
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
`

const PartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
`

const PartCard = styled.div`
  background: white;
  border: 1px solid #edf2f7;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  
  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
    transform: translateY(-3px);
  }
`

const PartImage = styled.img`
  width: 125px;
  height: 125px;
  object-fit: contain;
  margin-bottom: 16px;
  padding: 12px;
  background: #f8fafc;
  border-radius: 8px;
  transition: all 0.3s ease;

  ${PartCard}:hover & {
    background: #f1f5f9;
  }
`

const PartName = styled.div`
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.4;
`

const SectionTitle = styled.h3`
  margin-bottom: 24px;
  color: #4a5568;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  
  span {
    color: #718096;
    font-size: 16px;
    margin-left: 8px;
  }
`

interface PartsComparisonWindowProps {
  originalPart: PartTypeAPI;
  compatibleParts: PartTypeAPI[];
  incompatibleParts: PartTypeAPI[];
}

export const openPartsComparisonWindow = (
  originalPart: PartTypeAPI,
  compatibleParts: PartTypeAPI[],
  incompatibleParts: PartTypeAPI[]
) => {
  const width = 1400
  const height = 900
  const left = window.screen.width / 2 - width / 2
  const top = window.screen.height / 2 - height / 2

  const newWindow = window.open(
    "",
    "PartsComparison",
    `width=${width},height=${height},left=${left},top=${top}`
  )

  if (newWindow) {
    const sheet = new ServerStyleSheet()

    const PartsComparisonWindow: React.FC<PartsComparisonWindowProps> = ({
      originalPart,
      compatibleParts,
      incompatibleParts,
    }) => {
      return (
        <WindowContainer>
          <Title>Parts Comparison</Title>
          <ComparisonLayout>
            <OriginalPartSection>
              <SectionTitle>Original Part</SectionTitle>
              <PartCard>
                <PartImage src={originalPart.modelIcon} alt={originalPart.name} />
                <PartName>{originalPart.name}</PartName>
              </PartCard>
            </OriginalPartSection>

            <div>
              <PartsGrid>
                <SectionTitle>Compatible Parts <span>({compatibleParts.length})</span></SectionTitle>
                {compatibleParts.map((part) => (
                  <PartCard key={part.id}>
                    <PartImage src={part.modelIcon} alt={part.name} />
                    <PartName>{part.name}</PartName>
                  </PartCard>
                ))}
              </PartsGrid>

              <PartsGrid>
                <SectionTitle>Incompatible Parts <span>({incompatibleParts.length})</span></SectionTitle>
                {incompatibleParts.map((part) => (
                  <PartCard key={part.id}>
                    <PartImage src={part.modelIcon} alt={part.name} />
                    <PartName>{part.name}</PartName>
                  </PartCard>
                ))}
              </PartsGrid>
            </div>
          </ComparisonLayout>
        </WindowContainer>
      )
    }

    try {
      const html = ReactDOMServer.renderToString(
        sheet.collectStyles(
          <PartsComparisonWindow
            originalPart={originalPart}
            compatibleParts={compatibleParts}
            incompatibleParts={incompatibleParts}
          />
        )
      )

      const styleTags = sheet.getStyleTags()

      newWindow.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <meta charset="UTF-8">
            <title>Parts Comparison</title>
            <style>
              body { 
                margin: 0; 
                padding: 0;
                background: #f7f9fc;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }
              #root { 
                min-height: 100vh;
              }
              * {
                box-sizing: border-box;
              }
            </style>
            ${styleTags}
          </head>
          <body>
            <div id="root">${html}</div>
          </body>
        </html>
      `)

      newWindow.document.close()
    } finally {
      sheet.seal()
    }
  }
}