/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */

import React, { FC, useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { message } from "antd"
import { initialData, selectedItemID, userDesigns } from "../../../state/atoms"
import { ApiClient } from "../../../../common/api/ApiClient"
import Loading, { LoadingContent } from "../../../../common/components/Loading"
import useGetDebugVariables from "./utils/useGetDebugVariables"
import { isSaveInProgressAtom, sceneAtom } from "../../../state/scene/atoms"
import { useLocation, useParams } from "react-router-dom"
import { DesignsApi } from "../../../../common/api/firebase/designs"
import { SceneType } from "../../../state/scene/types"
import { PartTypeEnum, SegmentedTubeValues } from "../../../utils/Types"
import GPUBench from "./debug/GPUBench"
import History from "./History"
import MainLayout from "./mainLayout/MainLayout"
import Scene from "./scene/Scene"
import { useGetData } from "./utils/getData"
import HistoryLogger from "./History"
import { events, useEventsData } from "../../../../common/utils/rudderAnalyticsUtils"
import { authState } from "../../../../common/state/LoginState/RecoilAuthState"
import { useUpdateUnit } from "../../../state/scene/setters"
import { messageUtils } from "./scene/LowerRightMessages"

const useDataProvider = () => {
    const initialData = useGetData({ getData: ApiClient.getInitialData, query: "", })
    if (initialData.error) {
        throw initialData.error
    }
    return initialData.data
}

const useApp = () => {
    const setInitialData = useSetRecoilState(initialData)
    const data = useDataProvider()
    const [loading, setLoading,] = useState(true)
    useEffect(() => {
        if (data) {
            setInitialData(data)
            setLoading(false)
        }
    }, [data,])

    return {
        loading,
    }
}

const InitialDataProvider: FC<{ children: React.ReactNode, }> = ({ children, }) => {
    const { loading, } = useApp()
    const isSaveInProgress = useRecoilValue(isSaveInProgressAtom)
    if (loading) {
        return <LoadingContent><Loading />Loading assets</LoadingContent>
    }
    if (isSaveInProgress) {
        return <LoadingContent><Loading />Saving design...</LoadingContent>
    }
    return <>{children}</>
}

const DesignScreen = () => {
    const [loaded, setLoaded,] = useState(false)
    const { getVariables, } = useGetDebugVariables()
    const [sceneCallbacks, setSceneCallbacks,] = useState({})
    const setSceneAtom = useSetRecoilState(sceneAtom)
    const resetSceneAtom = useResetRecoilState(sceneAtom)
    const resetSelectedPart = useResetRecoilState(selectedItemID)
    const allParts = useRecoilValue(initialData)
    const { userId, designId, } = useParams()
    const location = useLocation()
    const eventsData = useEventsData()
    const { user, } = useRecoilValue(authState)
    const updateUnit = useUpdateUnit()
    const setIsSaveInProgress = useSetRecoilState(isSaveInProgressAtom)
    const backupLoaded = useRef(false)
    const backupMsgShown = useRef(false)

    useEffect(() => {
        setTimeout(() => {
            messageUtils.custom("We've just added the ability to resize multiple elements at once. You can try it by selecting multiple parts and then rotating the camera until you see the resize handles. Give it a try!", {
                duration: 5,
            })
        }, 8000)
    }, [])

    const migratePartData = (partFromJson: SegmentedTubeValues) => {
        const partFromAPI = allParts?.parts.find(p => p.id === partFromJson.apiTypeId)
        partFromJson.markers.forEach(marker => {
            const markerFromAPI
                = partFromAPI?.connections.find(m => m.placeholderId === marker.name)
            if (markerFromAPI && markerFromAPI.boundary) {
                marker.boundary = markerFromAPI.boundary
            }
        })
    }

    const deleteBackup = () => {
        const backup = localStorage.getItem("scene_backup")
        if (backup) {
            localStorage.removeItem("scene_backup")
        }
    }

    const load = async (userId: string, designId: string, state?: SceneType, name?: string) => {
        try {
            if (state) {
                setLoaded(false)
            }
            resetSelectedPart()
            const design = await DesignsApi.getDesignById(userId, designId)
            //console.log("design", design)
            const stateToUse = state ? state : JSON.parse(design.state) as SceneType

            if (state) {
                //console.log("loading from backup", state, userId, designId, name)
            }

            stateToUse.partsIds.forEach(id => {
                const part = stateToUse.parts[id.id]
                if (part) {
                    part.loaded = true
                    if (part.type === PartTypeEnum.connector) {
                        part.instanciated = false
                        if (!part.rotationMarkerName) {
                            part.rotationMarkerName = part.initialMarkerName
                        }
                    }
                    if (part.type === PartTypeEnum.segmentedTube) {
                        if (!part.lengthNegativeSide) {
                            part.lengthNegativeSide = 0
                        }
                        migratePartData(part)
                    }
                }
            })
            setIsSaveInProgress(false)
            setSceneAtom({ ...stateToUse, name: name || design.name, })
            if (state) {
                backupLoaded.current = true
            }
            setLoaded(true)
            if (state) {
                deleteBackup()
            }
        } catch (e: any) {
            setIsSaveInProgress(false)
            message.error("Couldn't load the design")
            throw new Error("Couldn't load the design", e)
        }
    }

    useEffect(() => {
        if (!designId) {
            events.initialDesign(eventsData())
        }
    }, [])


    useEffect(() => {
        const fetchDesigns = async () => {
            if (user) {
                const userDesigns = await DesignsApi.fetchUserDesigns(1)
                if (userDesigns.length && userDesigns[0].unit) {
                    updateUnit(userDesigns[0].unit)
                }
            }
        }
        fetchDesigns()
    }, [user, location.pathname,])

    useEffect(() => {
        if (allParts) {
            if (userId && designId) {
                load(userId, designId)
            } else {
                setLoaded(true)
            }
        }
        return () => resetSceneAtom()
    }, [location.pathname, allParts,])


    const checkForRecentCrash = () => {
        try {
            const crashInfo = localStorage.getItem("last_crash_info")
            if (!crashInfo) { return false }

            const { timestamp, } = JSON.parse(crashInfo)
            const crashTime = new Date(timestamp)
            const fiveMinutesAgo = new Date(Date.now() - 5 * 60 * 1000)

            return crashTime > fiveMinutesAgo
        } catch (e) {
            return false
        }
    }

    const checkForBackup = (userId?: string, designId?: string) => {
        try {
            const backup = localStorage.getItem("scene_backup")
            if (!backup) { return }

            const backupData = JSON.parse(backup)

            // Case 1: Base path - look for backup without IDs
            if (!userId && !designId) {
                if (!backupData.userId && !backupData.designId) {
                    //console.log("Found a design to restore from crash (unsaved design)")
                    return backupData
                }
            }
            // Case 2: Specific design path - look for matching IDs
            else if (userId && designId) {
                if (backupData.userId === userId && backupData.designId === designId) {
                    //console.log("Found a design to restore from crash (saved design)")
                    return backupData
                }
            }
        } catch (e) {
            console.error("Error checking for backup:", e)
        }
    }

    const handleBackupRestore = (backup: any) => {
        load(backup.userId, backup.designId, backup.sceneData, `(Backup Restored) ${backup.sceneData.name}`)
    }



    useEffect(() => {
        const hasRecentCrash = checkForRecentCrash()
        //console.log(hasRecentCrash, "hasRecentCrash")
        if (hasRecentCrash) {
            const backup = checkForBackup(userId, designId)
            //console.log(backup, "backup")
            if (!backup) {
                messageUtils.clearMessage("backup-msg")
            }
            if (backup) {
                // You might want to show a UI element here to let the user choose
                // whether to restore the backup
                //console.log("Backup data available:", backup)
                //alert("found a backup")
                if (backupMsgShown.current) {
                    return
                }
                backupMsgShown.current = true
                messageUtils.custom("Looks like we had an error that resulted in a crash. Would you like to restore from your last backup?", {
                    buttonText: "Restore",
                    duration: 20,
                    showCloseIcon: true,
                    forceShow: true,
                    key: "backup-msg",
                    onButtonClick: () => {
                        handleBackupRestore(backup)
                    },
                })

            }
        }
    }, [userId, designId, loaded,])


    return <InitialDataProvider>
        {getVariables().gpuData && getVariables().gpuData === "TRUE" && <GPUBench />}
        <History sceneCallbacks={sceneCallbacks} loaded={loaded} />
        {
            loaded
                ? <MainLayout scene={<Scene setSceneCallbacks={setSceneCallbacks} sceneCallbacks={sceneCallbacks} />} sceneCallbacks={sceneCallbacks} />
                : null
        }
    </InitialDataProvider>
}

export default DesignScreen