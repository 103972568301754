/* eslint-disable no-continue */
/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-negated-condition */
/* eslint-disable max-len */
import hotkeys from "hotkeys-js"
import produce, { applyPatches, Patch } from "immer"
import React, { useCallback, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import {
    atom, useRecoilCallback, useRecoilState, useRecoilTransactionObserver_UNSTABLE,
    useRecoilValue, useResetRecoilState, useSetRecoilState
} from "recoil"
import { sceneAtom } from "../../../state/scene/atoms"
import Button from "../../../../common/components/Button"
import { RedoIcon, UndoIcon } from "../../../../common/components/icons/Icons"
import { layoutState } from "../../../state/layoutState"
import { pendingChangesAtom, saveDesignAtom, selectedItemID } from "../../../state/atoms"
import { breadcrumb } from "../../../../common/utils/sentrySetup"
import { useLevaControls } from "../../../providers/debugProvider/useLevaControls"

type Props = {
    sceneCallbacks: any,
    loaded: boolean,
}

type HistoryPart = {
    undoPatches: Patch[],
    redoPatches: Patch[],
    groupKey?: string,
    timestamp: number,
}

export type HistoryType = {
    resetRedo: boolean,
    history: HistoryPart[],
    undoRedoInProgress: boolean,
    changeInProgress: any,
}

export const historyAtom = atom<HistoryType>({
    key: "historyAtom",
    default: {
        resetRedo: false,
        history: [],
        undoRedoInProgress: false,
        changeInProgress: "",
    },
})

export const redoHistoryAtom = atom<HistoryPart[]>({
    key: "redoHistoryAtom",
    default: [],
})

export const HistoryLogger = ({ seeHistoryLogs, seeSceneAtomLogs, seePendingChangesLogs, }: { seeHistoryLogs: boolean, seeSceneAtomLogs: boolean, seePendingChangesLogs: boolean, }) => {
    useRecoilTransactionObserver_UNSTABLE(({ snapshot, }) => {
        const pendingChanges = snapshot.getLoadable(pendingChangesAtom).contents
        const history = snapshot.getLoadable(historyAtom).contents
        const scene = snapshot.getLoadable(sceneAtom).contents
        //console.log("pendingChangesAtom:", pendingChanges)
        if (seeHistoryLogs) {
            console.log("historyAtom:", history)
        }
        if (seeSceneAtomLogs) {
            console.log("sceneAtom:", scene)
        }
        if (seePendingChangesLogs) {
            console.log("pendingChangesAtom:", pendingChanges)
        }
    })

    return null
}

export const useAddToHistoryPrivate = (resetRedo?: boolean, groupKey?: string) => {
    const setPendingChanges = useSetRecoilState(pendingChangesAtom)
    const setHistory = useSetRecoilState(historyAtom)
    const history = useRecoilValue(historyAtom)
    const BUFFER_TIME = 1500

    return (p: Patch[], ip: Patch[], historyKey?: string) => {
        const filteredRedoPatches = p.filter(patch => (patch.op as string) !== "")
        const filteredUndoPatches = ip.filter(patch => (patch.op as string) !== "")

        if (filteredRedoPatches.length === 0 && filteredUndoPatches.length === 0) {
            return
        }

        const now = Date.now()

        // Create new history entry without grouping logic first
        const newHistoryEntry = {
            redoPatches: [...filteredRedoPatches,],
            undoPatches: [...filteredUndoPatches,],
            groupKey: groupKey || historyKey || `auto-group-${now}`,
            timestamp: now,
        }

        setPendingChanges(true)

        // Update history and handle grouping in the same operation
        setHistory(produce((draft) => {
            if (resetRedo) {
                draft.resetRedo = resetRedo
            }

            const lastEntry = draft.history[draft.history.length - 1]

            // Check if we should group with the last entry
            if (lastEntry && (now - lastEntry.timestamp < BUFFER_TIME)) {
                newHistoryEntry.groupKey = lastEntry.groupKey!
            }
            draft.history.push(newHistoryEntry)
        }))


        breadcrumb({
            message: "After adding changes to history",
            level: "info",
        })
    }
}

export const useGoBack = () => {
    return useRecoilCallback(
        ({ set, }) => {
            return (patch: Patch[]) => {
                set(sceneAtom, (atom) => applyPatches(atom, patch))
            }
        }
    )
}

const validateAndFilterPatches = (
    patches: HistoryPart[],
    scene: any,
    isRedo: boolean,
    fullUndoHistory: HistoryPart[],
    fullRedoHistory: HistoryPart[],
    debug = false,
) => {
    const validPatches = []
    let i = 0
    const TIME_THRESHOLD = 10000

    while (i < patches.length) {
        const patch = patches[i]
        const operations = isRedo ? patch.redoPatches : patch.undoPatches
        let shouldSkip = false

        for (const op of operations) {
            if (op.path[0] === "parts") {
                const partId = op.path[1]

                if (op.op !== "add" && !scene.parts[partId]) {
                    if (debug) {
                        console.log(`validateAndFilterPatches Found operation on non-existent part ${partId} ${op.op} ${op.path} ${op.value}`)
                    }

                    // Look in appropriate history for creation/removal
                    const creationPatch = isRedo
                        ? fullRedoHistory.find(p => p.redoPatches.some(op => op.op === "add" && op.path[1] === partId))
                        : fullUndoHistory.find(p => p.undoPatches.some(op => op.op === "add" && op.path[1] === partId))

                    const removalPatch = isRedo
                        ? fullUndoHistory.find(p => p.undoPatches.some(op => op.op === "remove" && op.path[1] === partId))
                        : fullRedoHistory.find(p => p.redoPatches.some(op => op.op === "remove" && op.path[1] === partId))

                    if (creationPatch) {
                        const timeDiff = Math.abs(creationPatch.timestamp - patch.timestamp)

                        if (timeDiff <= TIME_THRESHOLD) {
                            const creationIndex = patches.findIndex(p => p === creationPatch)
                            if (creationIndex !== -1) {
                                if (debug) {
                                    console.log(`validateAndFilterPatches Found creation of part ${partId} in history. Reordering operations.`)
                                }
                                patches.splice(creationIndex, 1)
                                patches.splice(i, 0, creationPatch)
                                continue
                            }
                        }
                    } else if (removalPatch) {
                        const timeDiff = Math.abs(removalPatch.timestamp - patch.timestamp)

                        if (timeDiff <= TIME_THRESHOLD) {
                            // Find the removal group in our current patches
                            const removalGroupStart = patches.findIndex(p => p.groupKey === removalPatch.groupKey)
                            if (removalGroupStart !== -1) {
                                //console.log(`validateAndFilterPatches Found removal of part ${partId} in history. Moving operation before removal group.`)
                                const currentPatch = patches[i]
                                patches.splice(i, 1)
                                patches.splice(removalGroupStart, 0, currentPatch)
                                i = removalGroupStart
                                continue
                            }
                        }
                    }

                    //console.log(`validateAndFilterPatches No valid creation/removal found for part ${partId}. Details:`, {
                    //    foundCreation: !!creationPatch,
                    //    creationTimeDiff: creationPatch ? Math.abs(creationPatch.timestamp - patch.timestamp) : null,
                    //    foundRemoval: !!removalPatch,
                    //    removalTimeDiff: removalPatch ? Math.abs(removalPatch.timestamp - patch.timestamp) : null,
                    //    timeThreshold: TIME_THRESHOLD,
                    //})
                    shouldSkip = true
                    break
                }

                // Case 1: Trying to remove a non-existent part
                if (op.op === "remove" && !scene.parts[partId]) {
                    //console.log(`validateAndFilterPatches Skipping operation: Attempting to remove non-existent part ${partId}`)
                    shouldSkip = true
                    break
                }
            }
        }

        if (!shouldSkip) {
            validPatches.push(patch)
        }
        i++
    }

    return validPatches
}

const History: React.FC<Props> = (props: Props) => {
    const [historyState, setHistoryState,] = useRecoilState(historyAtom)
    const historyUndoRef = useRef<HistoryPart[]>(historyState.history)
    const [redoHistory, setRedoHistory,] = useRecoilState(redoHistoryAtom)
    const layoutValue = useRecoilValue(layoutState)
    const saveDesign = useRecoilValue(saveDesignAtom)
    const goBack = useGoBack()
    const header = useRef(document.getElementById("header-content"))
    const [forceUpdate, setForceUpdate,] = useState(0)
    const resetSelectedItem = useResetRecoilState(selectedItemID)
    const scene = useRecoilValue(sceneAtom)
    const { includeHistoryLogger, seeHistoryLogs, seeSceneAtomLogs, seePendingChangesLogs, } = useLevaControls()

    const undo = useCallback(() => {
        if (historyUndoRef.current.length > 0) {
            //console.group("Undo Operation")
            const historyPatch = historyUndoRef.current[historyUndoRef.current.length - 1]

            //have to rethink the filtering a bit more so removing it for now

            // Batch related patches
            const relatedPatches = historyPatch.groupKey
                ? historyUndoRef.current
                    .filter(p => p.groupKey === historyPatch.groupKey)
                    .reverse()
                : [historyPatch,]

            // Pass full history arrays for validation context
            /*relatedPatches = validateAndFilterPatches(
                relatedPatches,
                scene,
                false,
                historyUndoRef.current,  // Full undo history
                redoHistory              // Full redo history
            )*/

            if (relatedPatches.length === 0) {
                //console.log("No valid operations to undo")
                //console.groupEnd()
                return
            }

            setHistoryState(produce(historyDraft => {
                historyDraft.undoRedoInProgress = true
            }))

            Promise.all(relatedPatches.map(patch => {
                //console.log("Applying undo patch:", patch)
                goBack(patch.undoPatches)
                setRedoHistory(current => [...current, patch,])

                return setHistoryState(produce(historyDraft => {
                    if (patch.undoPatches.length > 0 && patch.redoPatches[0].path.length > 2) {
                        historyDraft.changeInProgress = patch.redoPatches[0].path[2]
                    }
                    historyDraft.resetRedo = false
                    historyDraft.history.pop()
                }))
            })).then(() => {
                setTimeout(() => {
                    setHistoryState(produce(draft => {
                        draft.undoRedoInProgress = false
                    }))
                }, 200)
                resetSelectedItem()
                //console.groupEnd()
            })
        }
    }, [setHistoryState, setRedoHistory, scene, redoHistory,])

    const redo = useCallback(() => {
        if (redoHistory.length > 0) {
            //console.group("Redo Operation")
            const historyPatch = redoHistory[redoHistory.length - 1]

            // Batch related patches
            const relatedPatches = historyPatch.groupKey
                ? redoHistory
                    .filter(p => p.groupKey === historyPatch.groupKey)
                    .reverse()
                : [historyPatch,]

            // Pass full history arrays for validation context
            /*relatedPatches = validateAndFilterPatches(
                relatedPatches,
                scene,
                true,
                historyUndoRef.current,  // Full undo history
                redoHistory              // Full redo history
            )*/

            if (relatedPatches.length === 0) {
                //console.log("No valid operations to redo")
                //console.groupEnd()
                return
            }

            setHistoryState(produce(historyDraft => {
                historyDraft.undoRedoInProgress = true
            }))

            Promise.all(relatedPatches.map(patch => {
                //console.log("Applying redo patch:", patch)
                goBack(patch.redoPatches)
                setRedoHistory(current => current.slice(0, -1))

                return setHistoryState(produce(historyDraft => {
                    if (patch.redoPatches.length > 0 && patch.redoPatches[0].path.length > 2) {
                        historyDraft.changeInProgress = patch.redoPatches[0].path[2]
                    }
                    historyDraft.resetRedo = false
                    historyDraft.history.push(patch)
                }))
            })).then(() => {
                setTimeout(() => {
                    setHistoryState(produce(draft => {
                        draft.undoRedoInProgress = false
                    }))
                }, 200)
                resetSelectedItem()
                //console.groupEnd()
            })
        }
    }, [setHistoryState, setRedoHistory, redoHistory, scene,])

    useEffect(() => {
        if (historyState.resetRedo) {
            //setRedoHistory([])
        }
        historyUndoRef.current = historyState.history
        //console.log("historyUndoRef", historyUndoRef.current)
    }, [historyState, setRedoHistory,])

    useEffect(() => {
        hotkeys("command+z,command+shift+z", { keyup: true, }, (event, handler) => {
            event.preventDefault()
            switch (handler.key) {
                case "command+z":
                    undo()
                    break
                case "command+shift+z":
                    redo()
                    break
                default:
                    break
            }
        })
    }, [redo, undo,])

    useEffect(() => {
        if (props.loaded) {
            header.current = document.getElementById("header-content")
            setForceUpdate(forceUpdate + 1)
        }
    }, [props.loaded,])

    const portal = props.loaded && header.current ? (
        ReactDOM.createPortal(
            <React.Fragment>
                {includeHistoryLogger && <HistoryLogger seeHistoryLogs={seeHistoryLogs} seeSceneAtomLogs={seeSceneAtomLogs} seePendingChangesLogs={seePendingChangesLogs} />}
                <Button
                    disabled={
                        layoutValue.isDrawerOpen || historyState.undoRedoInProgress
                        || saveDesign || historyState.history.length === 0}
                    onClick={undo}
                    $useWrapper={false}><UndoIcon color="black" /></Button>
                <Button
                    disabled={
                        layoutValue.isDrawerOpen
                        || saveDesign
                        || redoHistory.length === 0
                        || historyState.undoRedoInProgress
                    }
                    onClick={redo}
                    $useWrapper={false}><RedoIcon color="black" /></Button>
            </React.Fragment>,
            header.current
        )
    ) : null

    return portal as React.ReactElement | null
}

export default History