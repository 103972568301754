/* eslint-disable max-len */
import { Euler, Group, Object3D, Quaternion, Vector3, Raycaster } from "three"
import { DragPoint, FreePositions } from "../../../../../../../../../providers/moveProvider/types"
import { getMeasurement, getDistances } from "./MeasurementHelpers"
import { SegmentedTubeMarkers } from "../../../segmentedTube/types/types"
import { MeshStrategy } from "../../../../../../../../../providers/moveProvider/meshHelpers"
import { useDebugTools } from "../../../../../../../../../providers/debugProvider/useDebugTools"
import { useThree } from "@react-three/fiber"
import { getOBB } from "../../../../../../../../../providers/moveProvider/OBBBuilder"
import { useLevaControls } from "../../../../../../../../../providers/debugProvider/useLevaControls"

interface Measurement {
    up: Vector3 | null;
    down: Vector3 | null;
    right: Vector3 | null;
    left: Vector3 | null;
}

interface UseMeasurementsProps {
    center: Vector3;
    strategy: MeshStrategy;
    slideSide: SegmentedTubeMarkers;
}

const useMeasurements = (props: UseMeasurementsProps) => {
    const {
        center,
        strategy,
        slideSide,
    } = props

    const { drawVector3Point, visualizeRay, } = useDebugTools()
    const { scene, } = useThree()
    const { debugMeasurements, } = useLevaControls()

    const firstSlideSide = slideSide[Object.keys(slideSide)[0]]
    const outerMarker = firstSlideSide.outer
    const plusMarker = firstSlideSide.plus

    const worldQuaternion = new Quaternion()
    outerMarker?.getWorldQuaternion(worldQuaternion).normalize()

    const isSingleMesh = !outerMarker?.userData.boundary && strategy === MeshStrategy.Markers

    const group = new Group()

    if (plusMarker) {
        const plusMarkerQuaternion = new Quaternion()
        plusMarker?.getWorldQuaternion(plusMarkerQuaternion).normalize()
        group.setRotationFromQuaternion(plusMarkerQuaternion)
    } else {
        group.setRotationFromQuaternion(worldQuaternion)
    }

    group.position.copy(center)

    const getMeasurementFromRaycaster = (props: UseMeasurementsProps): Measurement => {
        const {
            center,
            slideSide,
        } = props

        const firstSlideSide = slideSide[Object.keys(slideSide)[0]]
        const meshMarker = firstSlideSide.mesh

        if (!meshMarker) {
            return {
                up: null,
                down: null,
                right: null,
                left: null,
            }
        }

        const obb = getOBB(meshMarker, 0)

        const raycaster = new Raycaster()
        const localDirections = [
            new Vector3(0, 1, 0),  // up
            new Vector3(0, -1, 0), // down
            new Vector3(1, 0, 0),  // right
            new Vector3(-1, 0, 0), // left
        ]

        const intersectionPoints: Measurement = {
            up: null,
            down: null,
            right: null,
            left: null,
        }

        localDirections.forEach((localDirection, index) => {
            // Transform direction according to group's rotation
            const worldDirection = localDirection.clone().applyQuaternion(group.quaternion)

            // Set raycaster position and direction
            raycaster.set(center, worldDirection)

            // Get intersections with the mesh
            if (!obb?.bboxMesh) {
                return
            }
            const intersects = raycaster.intersectObject(obb?.bboxMesh)

            if (intersects.length > 0) {
                const point = intersects[intersects.length - 1].point
                const directionKey = ["up", "down", "right", "left",][index]
                intersectionPoints[directionKey as keyof Measurement] = point
                if (debugMeasurements) {
                    drawVector3Point(point, 0xff0000, 0.001, 5000)
                    visualizeRay(scene, raycaster, 5, true)
                }
            }
        })

        return intersectionPoints
    }

    const newMeasurement = getMeasurementFromRaycaster(props)
    const distances = getDistances(center, newMeasurement)

    return { distances, group, isSingleMesh, }
}

export default useMeasurements
