/* eslint-disable max-len */
import { useContext, useEffect, useRef, useState } from "react"
import { useThree } from "@react-three/fiber"
import * as THREE from "three"
import { Group, InstancedMesh, Mesh, MeshBasicMaterial, Vector3 } from "three"
import {
    acceleratedRaycast,
    computeBoundsTree,
    disposeBoundsTree,
    MeshBVH,
    MeshBVHVisualizer
} from "three-mesh-bvh"
import { ConvexHull } from "three/examples/jsm/math/ConvexHull"
import { ConvexGeometry } from "three/examples/jsm/geometries/ConvexGeometry"
import { collisionContext, } from "./CollisionsProvider"
import { useRecoilCallback, useRecoilValue } from "recoil"
import { sceneAtom, scenePartsSelector } from "../../state/scene/atoms"
import { isInConnection } from "../../state/scene/util"
import { EnvHelper } from "../../../common/utils/EnvHelper"
import { PartTypeEnum } from "../../utils/Types"
import { MeshUtils } from "../../utils/MeshUtils"
import { useLevaControls } from "../debugProvider/useLevaControls"
THREE.Mesh.prototype.raycast = acceleratedRaycast
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree

// eslint-disable-next-line max-lines-per-function
const useCollision = (
    id: string,
    group: React.MutableRefObject<Group | Mesh | undefined>,
    mesh: React.MutableRefObject<InstancedMesh | Mesh | undefined>,
    onCollide: (colliding: boolean) => void,
    type: PartTypeEnum,
    collider?: Mesh | undefined) => {

    const sceneAtomList = useRecoilValue(scenePartsSelector)
    const tubes = Object.values(sceneAtomList)
        .filter(part => part.type === PartTypeEnum.tube)

    const { scene, } = useThree()
    const context = useContext(collisionContext)
    const boundsVizRef = useRef<MeshBVHVisualizer>()
    const colliderMesh = useRef<Mesh>()
    const meshBVH = useRef<MeshBVH>()
    const wp = useRef(new Vector3())
    const { viewColliders, } = useLevaControls()

    const [turnOnCollider, setTurnOnCollider,] = useState(viewColliders)

    const update = () => {
        if (context) {
            const collisions = context.getPreviousCollisions(id)
            if (collisions.length > 0) {
                onCollide(true)
            } else {
                onCollide(false)
            }
        }
    }

    useEffect(() => {
        if (context) {
            context.registerCollisionUpdate(id, update)
        }
    }, [])

    const convexHullBuild = (mesh: Mesh, material: MeshBasicMaterial) => {
        const convexHull = new ConvexHull()
        convexHull.setFromObject(mesh)
        const geometryCVX = new ConvexGeometry(convexHull.vertices.map((v) => v.point))
        colliderMesh.current = new Mesh(geometryCVX, material)
    }

    // eslint-disable-next-line max-statements
    const buildCollider = () => {
        if (mesh.current && context && turnOnCollider) {
            deleteCollider()

            const parent = mesh.current.parent!
            scene.attach(mesh.current)

            const p = MeshUtils.copyWorldPosition(mesh.current)
            const q = MeshUtils.copyWorldQuaternion(mesh.current)

            mesh.current.matrix.identity().decompose(mesh.current.position, mesh.current.quaternion, mesh.current.scale)

            const material = new MeshBasicMaterial({ color: 0x00ff00, wireframe: true, })

            if (type === PartTypeEnum.tube) {
                convexHullBuild(mesh.current, material)
                scene.add(colliderMesh.current!)
                meshBVH.current = colliderMesh.current!.geometry.computeBoundsTree()
            }

            if (type === PartTypeEnum.connector) {
                if (collider) {
                    colliderMesh.current = new Mesh(collider.geometry.clone(), material)
                } else {
                    convexHullBuild(mesh.current, material)
                }
                scene.add(colliderMesh.current!)
                meshBVH.current = colliderMesh.current!.geometry.computeBoundsTree()
            }

            context.registerShape(id, colliderMesh.current!)
            boundsVizRef.current = new MeshBVHVisualizer(colliderMesh.current!)
            const debug = viewColliders
            colliderMesh.current!.visible = !!debug
            //if (debug) {
            scene.add(boundsVizRef.current)
            //}
            setColliderPositionAndRotation()

            mesh.current.position.copy(p)
            mesh.current.quaternion.copy(q)

            parent.attach(mesh.current)
        }
    }

    const setColliderPositionAndRotation = () => {
        if (group.current && colliderMesh.current && boundsVizRef.current && turnOnCollider) {
            group.current.getWorldPosition(wp.current)
            // eslint-disable-next-line no-negated-condition
            if (type === PartTypeEnum.connector) {
                const ws = new Vector3()
                group.current.getWorldPosition(wp.current)
                group.current.getWorldScale(ws)
                colliderMesh.current.setRotationFromMatrix(group.current.matrixWorld)
                colliderMesh.current.position.copy(wp.current)
                colliderMesh.current.scale.copy(ws)
                colliderMesh.current.updateMatrixWorld(true)
            } else if (mesh.current) {
                const ws = new Vector3()
                mesh.current.getWorldPosition(wp.current)
                mesh.current.getWorldScale(ws)
                colliderMesh.current.setRotationFromMatrix(mesh.current.matrixWorld)
                colliderMesh.current.position.copy(wp.current)
                colliderMesh.current.scale.copy(ws)
                colliderMesh.current.updateMatrixWorld(true)
            }
            boundsVizRef.current.update()
        }
    }

    const getPartsConnectedWith = useRecoilCallback(({ snapshot, }) => (originId: string) => {
        const conn = snapshot.getLoadable(sceneAtom).getValue().connections
        const partConnections = conn.filter(c => isInConnection(c, originId))
        const ret = partConnections.map(({ partA, partB, }) => {
            if (partA.partId === originId) {
                return {
                    partMarkerName: partA.markerName,
                    destinationPartId: partB.partId,
                    destinationMarkerName: partB.markerName,
                }
            }
            return {
                partMarkerName: partB.markerName,
                destinationPartId: partA.partId,
                destinationMarkerName: partA.markerName,
            }
        })
        return ret.map((p) => p.destinationPartId)
    })

    const getPartsConnectedWithRef = useRef(getPartsConnectedWith)
    getPartsConnectedWithRef.current = getPartsConnectedWith


    // eslint-disable-next-line max-statements
    const updateCollider = (removePart?: () => void, newConnectedParts?: string[]) => {
        if (group.current && colliderMesh.current && boundsVizRef.current && turnOnCollider) {
            setColliderPositionAndRotation()

            const collisions: string[] = []

            const shapes = context!.getShapes(id)

            shapes.forEach((shape) => {
                const transformMatrix
                    = new THREE.Matrix4()
                        .copy(colliderMesh.current!.matrixWorld)
                        .invert()
                        .multiply(shape.mesh.matrixWorld)
                const hit = colliderMesh.current!.geometry.boundsTree!.intersectsGeometry(
                    shape.mesh.geometry, transformMatrix
                );
                (shape.mesh.material as MeshBasicMaterial).color.setColorName(hit ? "red" : "green")
                if (hit) {
                    collisions.push(shape.id)
                }
            });

            (colliderMesh.current.material as MeshBasicMaterial)
                .color
                .setColorName("green")

            if (collisions.length > 0 && context) {
                // Get parts that are connected to this part and filter them from colliders

                let nextPartsToCheck = [id,]

                let toCheck = [id,]
                const toIgnore = new Set<string>([id,])
                for (let index = 0; index < EnvHelper.collisionSteps; index++) {
                    toCheck = nextPartsToCheck
                    nextPartsToCheck = []
                    // eslint-disable-next-line no-loop-func
                    toCheck.forEach(partToCheckId => {
                        const connectedPartsId = getPartsConnectedWithRef.current(partToCheckId)

                        nextPartsToCheck.push(
                            ...connectedPartsId
                                .filter(connectedToCheckedId => !toIgnore.has(connectedToCheckedId)
                                    && !tubes.some(tube => tube.id === connectedToCheckedId))
                        )

                        connectedPartsId.forEach(c => toIgnore.add(c))
                    })
                }
                newConnectedParts?.forEach(newConnectedPart => {
                    toIgnore.add(newConnectedPart)
                })
                const filteredParts = collisions.filter((collisionId) =>
                    !toIgnore.has(collisionId)
                )

                // Get the colliders that are not colliding anymore with this part and update them
                const previousCollisions = context.getPreviousCollisions(id)
                context.update(id, previousCollisions)

                // Update colliders of actual collider
                context.pushCollision(id, filteredParts)

                if (filteredParts.length > 0) {
                    onCollide(true)
                    if (removePart) {
                        removePart()
                    }
                } else {
                    onCollide(false)
                }
            } else {
                onCollide(false)
            }
        }
    }

    const deleteCollider = () => {
        if (colliderMesh.current && boundsVizRef.current && context) {
            const previousCollisions = context.getPreviousCollisions(id)
            context.update(id, previousCollisions)

            scene.remove(colliderMesh.current)
            context.unregisterShape(id)
            scene.remove(boundsVizRef.current)
        }
    }

    return { updateCollider, buildCollider, deleteCollider, }
}

export default useCollision