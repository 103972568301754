/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { useState } from "react"
import {
    LeftContent,
    LeftRightContainer,
    ButtonContainer,
    RightContent,
    SliderContainer
} from "./ActionPanelStyles"
import Button from "../../../../../../../../../common/components/Button"
import { SegmentedTubeSectionType } from "../../../../../../../../../common/api/Types"
import DirectionSelector from "./DirectionSelector"
import NumberSlider from "../../sliders/Slider"
import { convertInToCm } from "../../../../../utils/UnitUtils"
import Dropdown from "antd/es/dropdown"
import Select from "antd/es/select"
import { messageUtils } from "../../../../LowerRightMessages"
import { DoubleButton } from "../../ActionPanelStyles"
import {
    ReactComponent as DirectionArrow
} from "../../../../../../../../assets/icons/DirectionArrow.svg"

interface Props {
    value: number;
    min: number;
    max: number;
    validMin: number;
    validMax: number;
    step: number;
    unit: string;
    updateUnit: (unit: string) => void;
    onMouseUp: (value: number) => void;
    onChange: (value: number) => void;
    onMouseDown: (value: number) => void;
    onInputChange: (value: number) => void;
    realWidth: number | undefined;
    realHeight: number | undefined;
    heightScaling: boolean | undefined;
    widthScaling: boolean | undefined;
    tubeLength: number;
    tubeNegativeLength: number;
    valueDirect: number;
    onFinishEditing: () => void;
    scalingDimension: "length" | "width" | "height";
    setScalingDimension: (dimension: "length" | "width" | "height") => void;
    setDirection: (
        direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
    ) => void;
    direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>;
}

//note we need to remove the duplicate concept of validMin and validMax. We just are using min and max now

const SegmentedTubeLengthSlider = (props: Props) => {
    const [length, setLength,] = useState(props.value)
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)


    //update the value when scaling dimension updates
    useEffect(() => {
        setLength(props.valueDirect)
    }, [props.scalingDimension, props.valueDirect,])

    const updateValue = useCallback((newValue: number, useInputChange = false) => {
        setLength(newValue)
        if (!useInputChange) {
            props.onChange(newValue)
        }
        if (useInputChange) {
            props.onInputChange(newValue)
        }
    }, [props,])



    const debouncedUpdate = useCallback((newValue: number,
        forceImmediateUpdate = false) => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current)
        }
        //I put 1 second because people complaining that it updates too faster
        //added the enter pressing to account for it
        debounceTimeoutRef.current = setTimeout(() => {
            updateValue(newValue, forceImmediateUpdate)
        }, 1000)
    }, [updateValue,])

    const handleOnChange = useCallback((value: number, debounce = false,
        forceImmediateUpdate = false) => {

        const getDimensionName = () => {
            if (props.scalingDimension === "height") { return "height" }
            if (props.scalingDimension === "width") { return "width" }
            return "length"
        }
        if (value >= props.max) {
            messageUtils.custom(`You've reached the max value because it has snapped to another part or it's the ${getDimensionName()} for the part`, {
                duration: 5, forceShow: true,
            })
        } else if (value <= props.min) {
            const canReduceMore = (props.direction === SegmentedTubeSectionType.START && props.tubeLength > 1)
                || (props.direction === SegmentedTubeSectionType.END && props.tubeLength > 1)

            messageUtils.custom(
                `You've reached the min ${getDimensionName()} for this part.${canReduceMore ? " Looks like you can still reduce if you click the arrow to the right" : ""
                }`, {
                duration: 5,
                forceShow: true,
            }
            )
        }
        //debounced version was only used when user was typing. so now the user has to press Enter

        if (value >= props.max) {
            if (debounce) {
                //debouncedUpdate(validMaxAdjusted, forceImmediateUpdate)
            } else {
                updateValue(props.max, forceImmediateUpdate)
            }
        } else if (value <= props.min) {
            if (debounce) {
                //debouncedUpdate(validMinAdjusted, forceImmediateUpdate)
            } else {
                updateValue(props.min, forceImmediateUpdate)
            }
        } else if (debounce) {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current)
                debounceTimeoutRef.current = null
            }
            //debouncedUpdate(value, forceImmediateUpdate)
        } else {
            updateValue(value, forceImmediateUpdate)
        }
    }, [updateValue, props.max, props.min,])

    const dimensionOptions = useMemo(() => {
        const options = [{ value: "length", label: "Length", },]
        if (props.widthScaling) {
            options.push({ value: "width", label: "Width", })
        }
        if (props.heightScaling) {
            options.push({ value: "height", label: "Height", })
        }
        return options
    }, [props.widthScaling, props.heightScaling,])

    const renderDoubleColorBackground = (
        direction: Exclude<SegmentedTubeSectionType, SegmentedTubeSectionType.MIDDLE>
    ) => {
        return <>
            <div style={{
                width: "50%",
                height: "100%",
                position: "absolute",
                left: "0px",
                background: direction === SegmentedTubeSectionType.START
                    ? "#ffffff" : "#f0f0f0",
                borderRadius: "2px 0px 0px 2px",
                cursor: "pointer",
            }} />
            <div style={{
                width: "50%",
                height: "100%",
                position: "absolute",
                right: "0px",
                background: direction === SegmentedTubeSectionType.END
                    ? "#ffffff" : "#f0f0f0",
                borderRadius: "0px 2px 2px 0px",
                cursor: "pointer",
            }} />
            <div style={{
                position: "absolute",
                height: "100%",
                borderLeft: " 1px solid #00000024",
                left: "50%",
                cursor: "pointer",
            }} />
        </>
    }

    return <SliderContainer>
        <LeftRightContainer>
            <LeftContent>
                <NumberSlider
                    unit={props.unit}
                    updateUnit={props.updateUnit}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                    handleMouseUp={props.onMouseUp}
                    onChange={handleOnChange}
                    onInputChange={props.onInputChange}
                    getValue={() => length}
                    inputNumber={true}
                    handleMouseDown={props.onMouseDown}
                    disabledTyping={false}
                    scalingDimension={props.scalingDimension}
                />
            </LeftContent>

            <RightContent>
                {dimensionOptions.length > 1 && (
                    <Select
                        options={dimensionOptions}
                        value={props.scalingDimension}
                        onChange={props.setScalingDimension}
                        style={{ width: 88, }}
                    />
                )}
                {props.scalingDimension !== "height" && props.scalingDimension !== "width" && (
                    <DirectionSelector direction={props.direction} setDirection={props.setDirection} />

                )}
            </RightContent>

        </LeftRightContainer>
    </SliderContainer >
}

export default SegmentedTubeLengthSlider