import React from "react"
import { useRecoilValue } from "recoil"
import { unitSelector } from "../../../../state/scene/atoms"
import { useUpdateUnit } from "../../../../state/scene/setters"
import { UnitType } from "../../../../state/scene/types"
import PillToggle from "../../../../../common/components/PillToggle"

const UnitToggle = ({ sceneCallbacks, }: { sceneCallbacks?: any, }) => {
    const unit = useRecoilValue(unitSelector)
    const updateUnit = useUpdateUnit()

    const handleToggle = (newUnit: string) => {
        if (sceneCallbacks?.updateBoundingBoxAndDimensions) {
            sceneCallbacks.updateBoundingBoxAndDimensions()
        }
        updateUnit(newUnit as UnitType)

    }

    return (
        <PillToggle
            options={["in", "cm",]}
            activeOption={unit}
            onChange={handleToggle}
        />
    )
}

export default UnitToggle
