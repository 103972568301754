/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-statements */
import { ArrowHelper, Box3, Camera, Color, Matrix4, MeshBasicMaterial, Scene } from "three"
import { Mesh } from "three"
import { Vector3 } from "three"
import { DirectionalMarkers, ScalerOBBResult, ScalerOBBOptions, LineSet, viewForScalerUI } from "../../../ScalerUILines"
import { box3ToMesh, MeshUtils } from "../../../../utils/MeshUtils"
import tinycolor2 from "tinycolor2"
import { CameraControls } from "../../../../providers/cameraProvider/CameraControls"
import { isSameDirection } from "./utilsThree"
import { createLabelAtPosition } from "../../../../utils/MarkerUtil"
import { drawVector3Point } from "../../../../utils/PartUtils"
import { PartConnectionType } from "../../../../state/scene/types"


export const scalerCreateOrientedBoundingBox = (
    markers: DirectionalMarkers,
    options: ScalerOBBOptions = {}
): ScalerOBBResult => {
    const {
        minWidth = 0,
        minHeight = 0,
        minDepth = 0,
        padding = 0,
    } = options

    // 1. First, let's extract all positions and compute the center
    const allPositions = [
        ...markers.normalAligned.map(m => m.position),
        ...markers.inverseAligned.map(m => m.position),
    ]

    const center = new Vector3()
    allPositions.forEach(pos => center.add(pos))
    center.divideScalar(allPositions.length)

    // 2. Get the primary axis (normal direction) from the first marker
    const normal = markers.normalAligned.length > 0
        ? markers.normalAligned[0].direction.clone()
        : markers.inverseAligned[0].direction.clone().negate()

    // 3. Find a perpendicular vector to use as the second axis
    const up = new Vector3(0, 1, 0)
    const right = new Vector3().crossVectors(normal, up)
        .normalize()
    if (right.lengthSq() < 0.1) {
        // If normal is parallel to up, use forward instead
        up.set(0, 0, 1)
        right.crossVectors(normal, up).normalize()
    }

    // Get the third axis by crossing normal and right
    const secondAxis = right
    const thirdAxis = new Vector3().crossVectors(normal, secondAxis)
        .normalize()

    // 4. Create rotation matrix from these axes
    const rotationMatrix = new Matrix4()
    rotationMatrix.makeBasis(secondAxis, thirdAxis, normal)

    // 5. Transform all points to the oriented space
    const inverseRotation = rotationMatrix.clone().invert()
    const transformedPoints = allPositions.map(pos => {
        return pos.clone()
            .sub(center)
            .applyMatrix4(inverseRotation)
    })

    // 6. Find min and max in the oriented space
    const min = new Vector3(Infinity, Infinity, Infinity)
    const max = new Vector3(-Infinity, -Infinity, -Infinity)
    transformedPoints.forEach(point => {
        min.min(point)
        max.max(point)
    })

    // 7. Create box and compute dimensions, applying minimum sizes
    const box = new Box3(min, max)
    box.expandByScalar(padding)
    const size = box.getSize(new Vector3())

    // Apply minimum dimensions while maintaining the center
    const adjustedSize = new Vector3(
        Math.max(size.x, minWidth),
        Math.max(size.y, minHeight),
        Math.max(size.z, minDepth)
    )

    // Calculate size differences
    const sizeDiff = new Vector3(
        adjustedSize.x - size.x,
        adjustedSize.y - size.y,
        adjustedSize.z - size.z
    )

    // Adjust min and max to maintain center while applying minimum dimensions
    min.x -= sizeDiff.x / 2
    min.y -= sizeDiff.y / 2
    min.z -= sizeDiff.z / 2
    max.x += sizeDiff.x / 2
    max.y += sizeDiff.y / 2
    max.z += sizeDiff.z / 2

    // 8. Generate the face points using adjusted dimensions
    const halfSize = adjustedSize.clone().multiplyScalar(0.5)
    const startFaceLocal = [
        new Vector3(-halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, halfSize.y, -halfSize.z),
        new Vector3(-halfSize.x, halfSize.y, -halfSize.z),
    ]

    const endFaceLocal = startFaceLocal.map(p =>
        new Vector3(p.x, p.y, halfSize.z * 2 + p.z)
    )

    // Transform faces back to world space
    const startFace = startFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    const endFace = endFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    // Left face
    const leftFaceLocal = [
        new Vector3(-halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(-halfSize.x, halfSize.y, -halfSize.z),
        new Vector3(-halfSize.x, halfSize.y, halfSize.z),
        new Vector3(-halfSize.x, -halfSize.y, halfSize.z),
    ]

    // Right face
    const rightFaceLocal = [
        new Vector3(halfSize.x, -halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, halfSize.y, -halfSize.z),
        new Vector3(halfSize.x, halfSize.y, halfSize.z),
        new Vector3(halfSize.x, -halfSize.y, halfSize.z),
    ]

    const leftFace = leftFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    const rightFace = rightFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(center)
    )

    // 9. Create final transformation matrix
    const transformation = new Matrix4()
        .multiply(new Matrix4().makeTranslation(center.x, center.y, center.z))
        .multiply(rotationMatrix)


    const mesh = box3ToMesh(new Box3(min, max))
    mesh.applyMatrix4(transformation)
    //add transparency to the mesh
    const material = new MeshBasicMaterial({
        color: tinycolor2.random().toHexString(),
        transparent: true,
        opacity: 0.25,
        wireframe: true,
    })
    mesh.material = material

    return {
        box: new Box3(min, max),
        startFace,
        endFace,
        leftFace,
        rightFace,
        width: size.x,
        height: size.y,
        depth: size.z,
        adjustedWidth: adjustedSize.x,
        adjustedHeight: adjustedSize.y,
        adjustedDepth: adjustedSize.z,
        center,
        transformation,
        mesh,
    }
}

export function createCombinedOrientedBoundingBox(
    boxMeshes: Mesh[],
    viewNormal: Vector3,
    options: ScalerOBBOptions = {}
): ScalerOBBResult {
    const {
        minWidth = 0,
        minHeight = 0,
        minDepth = 0,
        padding = 0,
    } = options

    // 1. Get all corners from all boxes in world space
    const allWorldCorners: Vector3[] = []
    boxMeshes.forEach(boxMesh => {
        if (boxMesh?.userData?.getWorldCorners) {
            const corners = boxMesh.userData.getWorldCorners()
            allWorldCorners.push(...corners)
        }
    })

    if (allWorldCorners.length === 0) {
        console.warn("No valid box meshes provided")
    }

    // Calculate initial center for transformation reference
    const initialCenter = new Vector3()
    allWorldCorners.forEach(pos => initialCenter.add(pos))
    initialCenter.divideScalar(allWorldCorners.length)

    // 3. Set up coordinate system based on view normal
    const up = new Vector3(0, 1, 0)
    const right = new Vector3().crossVectors(viewNormal, up)
        .normalize()
    if (right.lengthSq() < 0.1) {
        up.set(0, 0, 1)
        right.crossVectors(viewNormal, up).normalize()
    }

    // Get the third axis by crossing normal and right
    const secondAxis = right
    const thirdAxis = new Vector3().crossVectors(viewNormal, secondAxis)
        .normalize()

    // 4. Create rotation matrix
    const rotationMatrix = new Matrix4()
    rotationMatrix.makeBasis(secondAxis, thirdAxis, viewNormal)

    // 5. Transform points to oriented space
    const inverseRotation = rotationMatrix.clone().invert()
    const transformedPoints = allWorldCorners.map(pos => {
        return pos.clone()
            .sub(initialCenter)
            .applyMatrix4(inverseRotation)
    })

    // 6. Find bounds in oriented space
    const min = new Vector3(Infinity, Infinity, Infinity)
    const max = new Vector3(-Infinity, -Infinity, -Infinity)
    transformedPoints.forEach(point => {
        min.min(point)
        max.max(point)
    })

    // 7. Apply padding and minimum dimensions
    const box = new Box3(min, max)
    const sizeBeforePadding = box.getSize(new Vector3())
    box.expandByScalar(padding)
    const size = box.getSize(new Vector3())

    const adjustedSize = new Vector3(
        Math.max(size.x, minWidth),
        Math.max(size.y, minHeight),
        Math.max(size.z, minDepth)
    )

    // Adjust bounds while maintaining center
    const sizeDiff = new Vector3(
        adjustedSize.x - size.x,
        adjustedSize.y - size.y,
        adjustedSize.z - size.z
    )

    min.x -= sizeDiff.x / 2
    min.y -= sizeDiff.y / 2
    min.z -= sizeDiff.z / 2
    max.x += sizeDiff.x / 2
    max.y += sizeDiff.y / 2
    max.z += sizeDiff.z / 2

    // Calculate the true center from the final min/max in oriented space
    const center = new Vector3()
    center.addVectors(min, max)
        .multiplyScalar(0.5)
        .applyMatrix4(rotationMatrix)
        .add(initialCenter)

    // 8. Generate face points
    const startFaceLocal = [
        new Vector3(min.x, min.y, min.z),
        new Vector3(max.x, min.y, min.z),
        new Vector3(max.x, max.y, min.z),
        new Vector3(min.x, max.y, min.z),
    ]

    const endFaceLocal = [
        new Vector3(min.x, min.y, max.z),
        new Vector3(max.x, min.y, max.z),
        new Vector3(max.x, max.y, max.z),
        new Vector3(min.x, max.y, max.z),
    ]

    const leftFaceLocal = [
        new Vector3(min.x, min.y, min.z),
        new Vector3(min.x, min.y, max.z),
        new Vector3(min.x, max.y, max.z),
        new Vector3(min.x, max.y, min.z),
    ]

    const rightFaceLocal = [
        new Vector3(max.x, min.y, min.z),
        new Vector3(max.x, min.y, max.z),
        new Vector3(max.x, max.y, max.z),
        new Vector3(max.x, max.y, min.z),
    ]

    // Create the final transformation matrix
    const transformation = new Matrix4()
        .multiply(new Matrix4().makeTranslation(initialCenter.x, initialCenter.y, initialCenter.z))
        .multiply(rotationMatrix)

    // Transform all faces to world space
    const startFace = startFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    const endFace = endFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    const leftFace = leftFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    const rightFace = rightFaceLocal.map(p =>
        p.clone()
            .applyMatrix4(rotationMatrix)
            .add(initialCenter)
    )

    // Create and transform the mesh
    const mesh = box3ToMesh(new Box3(min, max))
    mesh.applyMatrix4(transformation)

    const material = new MeshBasicMaterial({
        color: tinycolor2.random().toHexString(),
        transparent: true,
        opacity: 0.9,
        wireframe: true,
    })
    mesh.material = material

    return {
        box: new Box3(min, max),
        width: sizeBeforePadding.z,
        height: sizeBeforePadding.y,
        depth: sizeBeforePadding.x,
        center,
        transformation,
        mesh,
        startFace,
        endFace,
        leftFace,
        rightFace,
        adjustedWidth: adjustedSize.x,
        adjustedHeight: adjustedSize.y,
        adjustedDepth: adjustedSize.z,
    }
}


const getPointPosition = (
    origin: Vector3,
    normal: Vector3,
    point: Vector3,
    buffer = 0.1
): "left" | "right" | "top" | "bottom" => {
    // Get vector from origin to point
    const toPoint = new Vector3().subVectors(point, origin)

    // Get up vector (assuming Y is up)
    const up = new Vector3(0, 1, 0)

    // Get right vector by crossing normal with up
    const right = new Vector3().crossVectors(normal, up)
        .normalize()

    // Get a more stable up vector by crossing right with normal
    const stableUp = new Vector3().crossVectors(right, normal)
        .normalize()

    // Project point onto normal and right vectors
    const normalProjection = toPoint.dot(normal)
    const rightProjection = toPoint.dot(right)
    const upProjection = toPoint.dot(stableUp)

    // Use projections to determine position
    if (Math.abs(normalProjection) > Math.abs(rightProjection) && Math.abs(normalProjection) > Math.abs(upProjection)) {
        // Point is more aligned with normal direction
        return normalProjection > buffer ? "right" : "left"
    } else if (Math.abs(upProjection) > Math.abs(rightProjection)) {
        // Point is more aligned with up/down direction
        return upProjection > buffer ? "top" : "bottom"
    } else {
        // Point is more aligned with right/left direction
        return rightProjection > buffer ? "top" : "bottom"
    }
}

export const getNormalRelativePositions = (
    widthSet: [[Vector3, Vector3] | [], [Vector3, Vector3] | []],
    heightSet: [[Vector3, Vector3] | [], [Vector3, Vector3] | []],
    center: Vector3,
    normal: Vector3,
    scene: Scene,
    debug = false
): LineSet[] => {
    // Combine all lines into a single array
    const allLines = [...widthSet, ...heightSet,]

    // Get center point and position for each line
    const linePositions = allLines.map(line => {
        const start = line[0]
        const end = line[1]
        if (!start || !end) {
            console.warn("Line has no start or end", line)
            return null
        }
        const lineCenter = start.clone().add(end)
            .multiplyScalar(0.5)
        const position = getPointPosition(center, normal, lineCenter)
        debug && console.log(position, "getNormalRelativePositions position")

        return {
            line,
            position,
            lineCenter,
        }
    }).filter(Boolean)

    //if debug is true, draw the center of the lines
    if (debug) {
        console.log(linePositions, "getNormalRelativePositions linePositions")
        linePositions.forEach(line => {
            line?.lineCenter && drawVector3Point(line.lineCenter, scene, "blue", 0.011, 5000, true, undefined, "line_center")
        })
    }

    // Create the LineSet array by finding lines for each position
    const result: (LineSet)[] = [
        {
            points: linePositions.find(l => l?.position === "top")?.line as [Vector3, Vector3],
            viewType: "width",
            position: "top",
            isPerpendicular: false,
        },
        {
            points: linePositions.find(l => l?.position === "bottom")?.line as [Vector3, Vector3],
            viewType: "width",
            position: "bottom",
            isPerpendicular: false,
        },
        {
            points: linePositions.find(l => l?.position === "right")?.line as [Vector3, Vector3],
            viewType: "height",
            position: "right",
            isPerpendicular: true,
            offsetDirection: normal.clone(),
        },
        {
            points: linePositions.find(l => l?.position === "left")?.line as [Vector3, Vector3],
            viewType: "height",
            position: "left",
            isPerpendicular: true,
            offsetDirection: normal.clone().negate(),
        },
    ]

    //draw labels at each line center
    if (debug) {
        console.log(result, "getNormalRelativePositions result")
        result.forEach(line => {
            if (!line.points) { return }
            const lineCenter = line.points[0].clone().add(line.points[1])
                .multiplyScalar(0.5)
            createLabelAtPosition(scene, lineCenter, line.position, { color: "red", fontSize: "12px", timer: 5000, dontAddToScene: false, })
        })
    }

    return result
}


export const generateDirectionalMarkers = (
    view: viewForScalerUI,
    scene: Scene,
    showScalerNormals: boolean
): DirectionalMarkers => {
    const viewDirectionalMarkers: DirectionalMarkers = {
        normalAligned: [],
        inverseAligned: [],
    }

    view.uniqueIds.forEach((partId: string) => {
        const startAndEndMarkers = Object.values(view.startAndEndMarkers?.[partId] ?? {})
        if (!startAndEndMarkers) { return }

        // Establish the two directions
        const normal = view.normal.clone()
        const inverseNormal = normal.clone().negate()

        // Draw debug arrows for the normal and inverse normal
        if (showScalerNormals) {
            const blue = new Color(0x00008B)
            const green = new Color(0x008B00)
            const arrowHelperInverse = new ArrowHelper(inverseNormal, new Vector3(0, 0, 0), 1.25, blue, 0.08, 0.08)
            // Position label at the tip of the inverse arrow with offset
            const inverseArrowTip = new Vector3(0, 0, 0).addScaledVector(inverseNormal, 1.25)
            const inverseLabelOffset = new Vector3(0.2, 0.2, 0)
            createLabelAtPosition(scene, inverseArrowTip.clone().add(inverseLabelOffset), "Inverse Normal", {
                color: "blue",
                fontSize: "12px",
            })

            const arrowHelperNormal = new ArrowHelper(normal, new Vector3(0, 0, 0), 1.25, green, 0.18, 0.18)
            // Position label at the tip of the normal arrow with offset
            const normalArrowTip = new Vector3(0, 0, 0).addScaledVector(normal, 1.25)
            const normalLabelOffset = new Vector3(0.2, 0.2, 0)
            createLabelAtPosition(scene, normalArrowTip.clone().add(normalLabelOffset), "Normal", {
                color: "green",
                fontSize: "12px",
            })
            scene.add(arrowHelperInverse)
            scene.add(arrowHelperNormal)

            setTimeout(() => {
                scene.remove(arrowHelperInverse)
                scene.remove(arrowHelperNormal)
            }, 5000)
        }

        startAndEndMarkers.forEach((marker) => {
            if (!marker) { return }

            const markerPosition = marker.getWorldPosition(new Vector3())
            const markerDirection = MeshUtils.copyWorldDirection(marker)

            // Draw debug arrow for marker direction
            if (showScalerNormals) {
                const randomColor = tinycolor2.random().toHexString()
                const arrowHelperMarker = new ArrowHelper(
                    markerDirection,
                    markerPosition,
                    0.5,
                    randomColor,
                    0.04,
                    0.04
                )
                scene.add(arrowHelperMarker)

                setTimeout(() => {
                    scene.remove(arrowHelperMarker)
                }, 10000)
            }

            // Check marker alignment with normal or inverse normal
            if (isSameDirection(markerDirection, normal)) {
                viewDirectionalMarkers.normalAligned.push({
                    position: markerPosition,
                    partId,
                    markerName: marker.name,
                    direction: markerDirection,
                })
            } else if (isSameDirection(markerDirection, inverseNormal)) {
                viewDirectionalMarkers.inverseAligned.push({
                    position: markerPosition,
                    partId,
                    markerName: marker.name,
                    direction: markerDirection,
                })
            }
        })
    })

    return viewDirectionalMarkers
}

export const drawAllFacesOfOBBAndCenter = (
    obb: ScalerOBBResult,
    scene: Scene,
    duration = 5000
) => {
    // Draw corners for all faces
    obb.startFace.forEach((point) => {
        drawVector3Point(point, scene, 0xff0000, 0.011, 5000, true, undefined, "obb_corner")
    })

    obb.endFace.forEach((point) => {
        drawVector3Point(point, scene, 0xff0000, 0.011, 5000, true, undefined, "obb_corner")
    })

    obb.leftFace.forEach((point) => {
        drawVector3Point(point, scene, 0x00ff00, 0.011, 5000, true, undefined, "obb_corner")
    })

    obb.rightFace.forEach((point) => {
        drawVector3Point(point, scene, 0x00ff00, 0.011, 5000, true, undefined, "obb_corner")
    })

    drawVector3Point(obb.center, scene, 0x0000ff, 0.011, 5000, true, undefined, "obb_corner")
}

const getConnectedPartSequences = (
    orderedParts: string[],
    connections: PartConnectionType[],
    debug = false
): string[][] => {
    const sequences: string[][] = []
    const processedParts = new Set<string>()

    debug && console.groupCollapsed("Getting Connected Part Sequences")
    debug && console.log("Input ordered parts:", orderedParts)

    // Helper function to get direct connections for a part
    const getDirectConnections = (partId: string): string[] => {
        return connections
            .filter(conn =>
                (conn.partA.partId === partId || conn.partB.partId === partId)
            )
            .map(conn =>
                (conn.partA.partId === partId ? conn.partB.partId : conn.partA.partId)
            )
            .filter(connectedId =>
                orderedParts.includes(connectedId) && !processedParts.has(connectedId)
            )
    }

    // Process each part in order
    while (processedParts.size < orderedParts.length) {
        // Find first unprocessed part
        const nextPart = orderedParts.find(partId => !processedParts.has(partId))
        if (!nextPart) { break }

        const currentSequence = [nextPart,]
        processedParts.add(nextPart)

        // Build connected sequence recursively
        const buildSequence = (currentPartId: string) => {
            const connectedParts = getDirectConnections(currentPartId)
                .sort((a, b) => orderedParts.indexOf(a) - orderedParts.indexOf(b))

            connectedParts.forEach(connectedId => {
                if (!processedParts.has(connectedId)) {
                    currentSequence.push(connectedId)
                    processedParts.add(connectedId)
                    buildSequence(connectedId)
                }
            })
        }

        buildSequence(nextPart)
        debug && console.log("Found sequence:", currentSequence)
        sequences.push(currentSequence)
    }

    debug && console.log("Final sequences:", sequences)
    debug && console.groupEnd()

    return sequences
}

export const getCursorType = (
    camera: Camera,
    normal: Vector3
): string => {
    // Get camera's up vector
    const cameraUp = new Vector3(0, 1, 0)
        .applyQuaternion(camera.quaternion)

    // Calculate dot product between view normal and camera up
    // This tells us if the normal is pointing more up/down or left/right
    const upAlignment = Math.abs(normal.dot(cameraUp))

    // If normal is more aligned with up/down (closer to 1 or -1)
    // then the resize should be horizontal (ew-resize)
    // If normal is more aligned with left/right (closer to 0)
    // then the resize should be vertical (ns-resize)
    return upAlignment > 0.5 ? "ns-resize" : "ew-resize"
}

function hasSignificantOverlap(overlap: { x: number, y: number, z: number, }): boolean {
    // Consider boxes overlapping if they intersect in all dimensions
    // Even a small actual intersection (e.g. 0.01 or 1%) indicates overlap
    const overlapThreshold = 0.001 // 1% overlap threshold
    return overlap.x > overlapThreshold
        && overlap.y > overlapThreshold
        && overlap.z > overlapThreshold
}

export const getDirectionMultiplier = (
    activeLine: { points: Vector3[], position: "left" | "right" | "top" | "bottom", },
    allLines: { points: Vector3[], position: "left" | "right" | "top" | "bottom", }[],
    camera: Camera,
    normal: Vector3
): number => {
    // Get camera's up vector
    const cameraUp = new Vector3(0, 1, 0)
        .applyQuaternion(camera.quaternion)

    // Calculate dot product between view normal and camera up
    const upAlignment = Math.abs(normal.dot(cameraUp))

    // Calculate active line center in world space
    const activeLineCenter = new Vector3()
        .addVectors(activeLine.points[0], activeLine.points[1])
        .multiplyScalar(0.5)

    // Convert to screen space
    const activeScreenCenter = activeLineCenter.clone().project(camera)

    // Find the opposite line based on position
    const oppositePosition = {
        left: "right",
        right: "left",
        top: "bottom",
        bottom: "top",
    }[activeLine.position]

    const oppositeLine = allLines.find(line => line.position === oppositePosition)
    if (!oppositeLine) { return 1 } // fallback

    const oppositeLineCenter = new Vector3()
        .addVectors(oppositeLine.points[0], oppositeLine.points[1])
        .multiplyScalar(0.5)

    const oppositeScreenCenter = oppositeLineCenter.clone().project(camera)

    if (upAlignment > 0.5) {
        // Vertical case - compare Y positions in screen space
        return activeScreenCenter.y > oppositeScreenCenter.y ? -1 : 1
    } else {
        // Horizontal case - compare X positions in screen space
        return activeScreenCenter.x < oppositeScreenCenter.x ? -1 : 1
    }
}

export const createSpatialRelationships = (
    combinedOBB: ScalerOBBResult,
    individualBoxes: Mesh[],
    viewNormal: Vector3,
    overlapThreshold = 0.01,
    scene?: Scene,
    debug = false
): Map<string, BoxSpatialInfo> => {
    // 1. Transform all boxes to OBB local space
    const boxesInLocalSpace = new Map<string, LocalGridPosition>()
    const inverseTransform = combinedOBB.transformation.clone().invert()

    const up = new Vector3(0, 1, 0)
    const right = new Vector3().crossVectors(viewNormal, up)
        .normalize()
    if (right.lengthSq() < 0.1) {
        up.set(0, 0, 1)
        right.crossVectors(viewNormal, up).normalize()
    }

    // Get the third axis by crossing normal and right
    const secondAxis = right
    const thirdAxis = new Vector3().crossVectors(viewNormal, secondAxis)
        .normalize()

    if (debug && scene) {
        const referencePoints = [
            { pos: new Vector3(0, 0, 0), label: "Center (0.5x, 0.5y, 0.5z)", },
            { pos: new Vector3(-1, -1, -1), label: "Min (0x, 0y, 0z)", },
            { pos: new Vector3(1, 1, 1), label: "Max (1x, 1y, 1z)", },
            // Middle points of each face
            { pos: new Vector3(0, 0, -1), label: "Back (0.5x, 0.5y, 0z)", },
            { pos: new Vector3(0, 0, 1), label: "Front (0.5x, 0.5y, 1z)", },
            { pos: new Vector3(0, -1, 0), label: "Bottom (0.5x, 0y, 0.5z)", },
            { pos: new Vector3(0, 1, 0), label: "Top (0.5x, 1y, 0.5z)", },
            { pos: new Vector3(-1, 0, 0), label: "Left (0x, 0.5y, 0.5z)", },
            { pos: new Vector3(1, 0, 0), label: "Right (1x, 0.5y, 0.5z)", },
        ]

        referencePoints.forEach(({ pos, label, }) => {
            // Convert from (-1,1) space to (0,1) space
            const normalizedPos = new Vector3(
                (pos.x + 1) / 2,
                (pos.y + 1) / 2,
                (pos.z + 1) / 2
            )

            // Convert to local OBB space
            const localPos = new Vector3(
                combinedOBB.box.min.x + normalizedPos.x * (combinedOBB.box.max.x - combinedOBB.box.min.x),
                combinedOBB.box.min.y + normalizedPos.y * (combinedOBB.box.max.y - combinedOBB.box.min.y),
                combinedOBB.box.min.z + normalizedPos.z * (combinedOBB.box.max.z - combinedOBB.box.min.z)
            )

            // Transform to world space
            const worldPos = localPos.clone().applyMatrix4(combinedOBB.transformation)

            // Draw point
            drawVector3Point(worldPos, scene, "#ffff00", 0.05, 15000)

            // Add label
            createLabelAtPosition(
                scene,
                worldPos.clone().add(new Vector3(0.1, 0.1, 0.1)),
                label,
                {
                    color: "#ffff00",
                    fontSize: "12px",
                    timer: 15000,
                }
            )
        })

        const origin = combinedOBB.center
        const size = 1 // Size of debug arrows

        // Create arrows for each axis
        const xAxis = new ArrowHelper(
            secondAxis,
            origin,
            size,
            0xff0000, // Red for X
            0.1,
            0.05
        )
        const yAxis = new ArrowHelper(
            thirdAxis,
            origin,
            size,
            0x00ff00, // Green for Y
            0.1,
            0.05
        )
        const zAxis = new ArrowHelper(
            viewNormal,
            origin,
            size,
            0x0000ff, // Blue for Z
            0.1,
            0.05
        )

        // Add labels for each axis
        const labelOffset = 0.2
        createLabelAtPosition(scene, origin.clone().add(secondAxis.clone().multiplyScalar(size + labelOffset)), "X (right)", {
            color: "#ff0000",
            fontSize: "14px",
            timer: 15000,
        })
        createLabelAtPosition(scene, origin.clone().add(thirdAxis.clone().multiplyScalar(size + labelOffset)), "Y (up)", {
            color: "#00ff00",
            fontSize: "14px",
            timer: 15000,
        })
        createLabelAtPosition(scene, origin.clone().add(viewNormal.clone().multiplyScalar(size + labelOffset)), "Z (normal)", {
            color: "#0000ff",
            fontSize: "14px",
            timer: 15000,
        })


        // Add axes to scene
        scene.add(xAxis)
        scene.add(yAxis)
        scene.add(zAxis)

        // Remove after delay
        setTimeout(() => {
            scene.remove(xAxis)
            scene.remove(yAxis)
            scene.remove(zAxis)
        }, 15000)
    }

    individualBoxes.forEach(boxMesh => {
        const worldCorners = boxMesh.userData.getWorldCorners()
        const center = boxMesh.userData.getWorldCenter()

        // Transform corners and center to OBB local space
        const localCorners = worldCorners.map((corner: Vector3) =>
            corner.clone().applyMatrix4(inverseTransform)
        )
        const localCenter = center.clone().applyMatrix4(inverseTransform)

        // Normalize positions relative to OBB bounds (0-1)
        const normalizedCenter = new Vector3(
            (localCenter.x - combinedOBB.box.min.x) / (combinedOBB.box.max.x - combinedOBB.box.min.x),
            (localCenter.y - combinedOBB.box.min.y) / (combinedOBB.box.max.y - combinedOBB.box.min.y),
            (localCenter.z - combinedOBB.box.min.z) / (combinedOBB.box.max.z - combinedOBB.box.min.z)
        )

        boxesInLocalSpace.set(boxMesh.uuid, {
            x: normalizedCenter.x,
            y: normalizedCenter.y,
            z: normalizedCenter.z,
            corners: localCorners,
            center: localCenter,
        })
    })

    // 2. Create spatial relationships for each box
    const spatialRelationships = new Map<string, BoxSpatialInfo>()

    individualBoxes.forEach(boxA => {
        const boxALocal = boxesInLocalSpace.get(boxA.uuid)!
        const relationships: BoxSpatialInfo = {
            id: boxA.uuid,
            partId: boxA.userData.partId,
            localPosition: boxALocal,
            neighbors: {
                front: [],
                back: [],
                above: [],
                below: [],
                left: [],
                right: [],
            },
            overlapping: [],
            touching: [],
        }

        individualBoxes.forEach(boxB => {
            if (boxA.uuid === boxB.uuid) { return }

            const boxBLocal = boxesInLocalSpace.get(boxB.uuid)!
            const relativePos = getRelativePosition(boxALocal, boxBLocal, overlapThreshold)
            const proximity = checkBoxProximity(boxALocal, boxBLocal)

            const boxBInfo = { id: boxB.uuid, partId: boxB.userData.partId, }

            // Check overlaps first
            if (hasSignificantOverlap(relativePos.overlap)) {
                relationships.overlapping.push(boxBInfo)
                return
            }

            // Check touching
            if (proximity.isTouching) {
                relationships.touching.push(boxBInfo)
            }

            // Add to appropriate neighbor lists based on relative position
            if (relativePos.isInFront) { relationships.neighbors.front.push(boxBInfo) }
            else { relationships.neighbors.back.push(boxBInfo) }

            if (relativePos.isAbove) { relationships.neighbors.above.push(boxBInfo) }
            else { relationships.neighbors.below.push(boxBInfo) }

            if (relativePos.isToRight) { relationships.neighbors.right.push(boxBInfo) }
            else { relationships.neighbors.left.push(boxBInfo) }
        })

        spatialRelationships.set(boxA.uuid, relationships)
    })

    return spatialRelationships
}

export const getTouchingPartsMap = (spatialMap: Map<string, BoxSpatialInfo>): Map<string, string[]> => {
    const touchingMap = new Map<string, string[]>()

    Array.from(spatialMap.values()).forEach(info => {
        touchingMap.set(
            info.partId,
            info.touching.map(t => t.partId)
        )
    })

    return touchingMap
}

export const getPartIdsByAxis = (
    spatialRelationships: Map<string, BoxSpatialInfo>,
    axis: "x" | "y" | "z",
    ascending = true
): string[] => {
    // Convert map to array and sort by specified axis
    const sorted = Array.from(spatialRelationships.values())
        .sort((a, b) => {
            const aValue = a.localPosition[axis]
            const bValue = b.localPosition[axis]
            return ascending ? aValue - bValue : bValue - aValue
        })

    // Return just the partIds
    return sorted.map(info => info.partId)
}

type LocalGridPosition = {
    x: number, // Local coordinate in OBB space (0-1)
    y: number,
    z: number,
    corners: Vector3[], // Corners in OBB local space
    center: Vector3,   // Center in OBB local space
}

type RelativePosition = {
    isInFront: boolean,
    isAbove: boolean,
    isToRight: boolean,
    distance: number,
    overlap: {
        x: number,
        y: number,
        z: number,
    },
}


export type BoxSpatialInfo = {
    id: string,
    partId: string, // Add partId
    localPosition: LocalGridPosition,
    neighbors: {
        front: { id: string, partId: string, }[], // Update to include partId
        back: { id: string, partId: string, }[],
        above: { id: string, partId: string, }[],
        below: { id: string, partId: string, }[],
        left: { id: string, partId: string, }[],
        right: { id: string, partId: string, }[],
    },
    overlapping: { id: string, partId: string, }[], // Update to include partId
    touching: { id: string, partId: string, }[],
}


function calculateOverlap(
    cornersA: Vector3[],
    cornersB: Vector3[],
    axis: "x" | "y" | "z"
): number {
    // Find the min and max bounds for each box along the given axis
    const minA = Math.min(...cornersA.map(c => c[axis]))
    const maxA = Math.max(...cornersA.map(c => c[axis]))
    const minB = Math.min(...cornersB.map(c => c[axis]))
    const maxB = Math.max(...cornersB.map(c => c[axis]))

    // Calculate actual overlap distance
    const overlap = Math.min(maxA, maxB) - Math.max(minA, minB)

    // Get the smaller box size to calculate overlap percentage
    const sizeA = maxA - minA
    const sizeB = maxB - minB
    const smallerSize = Math.min(sizeA, sizeB)

    // Return overlap as a percentage of the smaller box size
    return overlap > 0 ? overlap / smallerSize : 0
}

function getRelativePosition(
    boxA: LocalGridPosition,
    boxB: LocalGridPosition,
    overlapThreshold: number
): RelativePosition {
    const dx = boxB.center.x - boxA.center.x
    const dy = boxB.center.y - boxA.center.y
    const dz = boxB.center.z - boxA.center.z

    // Calculate overlap in each dimension
    const overlapX = calculateOverlap(boxA.corners, boxB.corners, "x")
    const overlapY = calculateOverlap(boxA.corners, boxB.corners, "y")
    const overlapZ = calculateOverlap(boxA.corners, boxB.corners, "z")


    return {
        isInFront: dz > overlapThreshold,
        isAbove: dy > overlapThreshold,
        isToRight: dx > overlapThreshold,
        distance: Math.sqrt(dx * dx + dy * dy + dz * dz),
        overlap: {
            x: overlapX,
            y: overlapY,
            z: overlapZ,
        },
    }
}

type BoxProximity = {
    isOverlapping: boolean,
    isTouching: boolean,
    overlap: {
        x: number,
        y: number,
        z: number,
    },
    distance: number,
}


function checkBoxProximity(
    boxA: LocalGridPosition,
    boxB: LocalGridPosition,
    touchingThreshold = 0.001
): BoxProximity {
    // Get the axes we need to test (15 total for OBB)
    const axes = getAxesForSAT(boxA.corners, boxB.corners)

    let minOverlap = Infinity
    let touchingAxis = null

    // Test each axis
    for (const axis of axes) {
        const projectionA = projectOntoAxis(boxA.corners, axis)
        const projectionB = projectOntoAxis(boxB.corners, axis)

        const overlap = getOverlap(projectionA, projectionB)

        // If there's no overlap on any axis, boxes are separated
        if (overlap < 0) {
            return {
                isOverlapping: false,
                isTouching: false,
                overlap: { x: 0, y: 0, z: 0, },
                distance: -overlap, // Use negative overlap as distance
            }
        }

        if (overlap < minOverlap) {
            minOverlap = overlap
            touchingAxis = axis
        }
    }

    // If we get here, boxes are either touching or overlapping
    const isTouching = minOverlap <= touchingThreshold
    const isOverlapping = minOverlap > touchingThreshold

    return {
        isOverlapping,
        isTouching,
        overlap: { x: minOverlap, y: minOverlap, z: minOverlap, },
        distance: minOverlap,
    }
}

function getAxesForSAT(cornersA: Vector3[], cornersB: Vector3[]): Vector3[] {
    const axes: Vector3[] = []

    // Get face normals from both boxes (6 axes)
    const boxAAxes = getBoxFaceNormals(cornersA)
    const boxBAxes = getBoxFaceNormals(cornersB)

    // Add face normals
    axes.push(...boxAAxes, ...boxBAxes)

    // Add cross products of all pairs of edges (9 axes)
    for (const axisA of boxAAxes) {
        for (const axisB of boxBAxes) {
            const crossAxis = new Vector3().crossVectors(axisA, axisB)
            if (crossAxis.lengthSq() > 0.001) { // Avoid near-parallel axes
                crossAxis.normalize()
                axes.push(crossAxis)
            }
        }
    }

    return axes
}

function getBoxFaceNormals(corners: Vector3[]): Vector3[] {
    // Assuming corners are arranged in a standard order
    const edge1 = new Vector3().subVectors(corners[1], corners[0])
    const edge2 = new Vector3().subVectors(corners[3], corners[0])
    const edge3 = new Vector3().subVectors(corners[4], corners[0])

    return [
        edge1.normalize(),
        edge2.normalize(),
        edge3.normalize(),
    ]
}

function projectOntoAxis(corners: Vector3[], axis: Vector3): { min: number, max: number, } {
    let min = Infinity
    let max = -Infinity

    for (const corner of corners) {
        const projection = corner.dot(axis)
        min = Math.min(min, projection)
        max = Math.max(max, projection)
    }

    return { min, max, }
}

function getOverlap(projA: { min: number, max: number, }, projB: { min: number, max: number, }): number {
    // Return negative number if there's a gap
    if (projA.max < projB.min) { return projB.min - projA.max }
    if (projB.max < projA.min) { return projA.min - projB.max }

    // Return positive overlap otherwise
    return Math.min(projA.max - projB.min, projB.max - projA.min)
}
