/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import { Mesh, Quaternion, Vector3, ArrowHelper, Scene } from "three"
import { MeshUtils } from "../utils/MeshUtils"
import { PartTypeAPI, RotationDiff } from "../../common/api/Types"
import { useConsole } from "./useConsole"
import { useLevaControls } from "../providers/debugProvider/useLevaControls"
import { groupCollapsed } from "console"
import { getMarkerNumber } from "../utils/MarkerUtil"
import { PartTypeEnum } from "../utils/Types"

type RotationDifference = {
    w: number,
    x: number,
    y: number,
    z: number,
}

type QuaternionMap = {
        [key: string]: {
            rotationDiffs: RotationDiff,
            section: sectionType,
        },
}

type sectionType = "middle" | "start" | "end"

type ComparisonResult = {
    isCompatible: boolean,
    equivalences?: { [key: string]: string, },
}

// Add debug options type
type DebugOptions = {
    enabled: boolean,
    visualize?: boolean,
}

function correctBlenderQuat(quat: Quaternion): Quaternion {
    // Blender uses Z-up, while Three.js uses Y-up
    // We need to swap Y and Z components
    return new Quaternion(quat.x, quat.z, -quat.y, quat.w)
}

function objToQuat(obj: { w: number, x: number, y: number, z: number, }): Quaternion {
    return new Quaternion(obj.x, obj.y, obj.z, obj.w)
}

function getQuaternionInfoFromPart(part: PartTypeAPI) {
    const data: QuaternionMap = {}
    part.connections.forEach(connection => {
        if (connection.rotationDiffs) {
            data[connection.placeholderId] = {
                rotationDiffs: connection.rotationDiffs,
                section: connection.position as sectionType,
            }
        }
    })
    return data
}

export function useMarkerComparison(scene?: Scene) {
    const { log, groupCollapsed, groupEnd, warn, }
    = useConsole("MarkerComparison", "multiswapDebug")
    const { multiswapDebug, } = useLevaControls()

    function compareMarkers(
        original: Mesh,
        target: Mesh,
        quaternion: RotationDifference,
        debug?: DebugOptions
    ): boolean {
        log("Compare Markers Debug:")
        log("Original position:", original.position)
        log("Target position:", target.position)
        log("Input quaternion:", quaternion)


        const clone = original.clone()
        const targetQuat = objToQuat(quaternion)
        const correctedQuat = correctBlenderQuat(targetQuat)

        const targetGlobalPos = new Vector3()
        target.getWorldPosition(targetGlobalPos)
        clone.position.copy(targetGlobalPos)

        const originalGlobalQuat = MeshUtils.copyWorldQuaternion(original)
        const newQuat = originalGlobalQuat.clone().multiply(correctedQuat)
        clone.quaternion.copy(newQuat)
        clone.updateMatrixWorld(true)

        // Get normals for comparison
        const cloneNormal = MeshUtils.copyWorldDirection(clone).normalize()
        const targetNormal = MeshUtils.copyWorldDirection(target).normalize()

        log("Clone normal:", cloneNormal)
        log("Target normal:", targetNormal)
        log("Distance:", cloneNormal.distanceTo(targetNormal))

        if (debug?.visualize && scene) {
            // Visualize direction vectors
            const arrowHelperClone = new ArrowHelper(
                cloneNormal,
                clone.position,
                1,
                0xff0000
            )
            scene.add(arrowHelperClone)
            const arrowHelperTarget = new ArrowHelper(
                targetNormal,
                targetGlobalPos,
                1,
                0x00ff00
            )
            scene.add(arrowHelperTarget)
        }

        const tolerance = 0.0005234
        // const tolerance = 1
        const isCompatible = cloneNormal.distanceTo(targetNormal) < tolerance

        // Cleanup
        if (!debug?.visualize) {
            clone.geometry.dispose()
            if (Array.isArray(clone.material)) {
                clone.material.forEach(m => m.dispose())
            } else {
                clone.material.dispose()
            }
        }

        return isCompatible
    }

    function runComparison(
        map: QuaternionMap,
        markers: Mesh[],
        debug?: DebugOptions,
        part?: PartTypeAPI
    ): ComparisonResult {
        log("Running comparison with map:", map)
        log("Available markers:", markers)


        const mapMarkers = Object.keys(map)
        const realMarkers = Object.keys(markers)

        const checkSectionCompatibility = (realMarker: string, mapMarker: string) => {
            const realMarkerNumber = parseInt(realMarker, 10)
            const realMarkerSection = markers[realMarkerNumber].userData.sectionType
            const mapMarkerSection = map[mapMarker].section
            if (!mapMarkerSection || !realMarkerSection) {
                // connectors don't have sections, in which case we skip the compatibility check
                return true
            }
            return mapMarkerSection.toLowerCase() === realMarkerSection.toLowerCase()
        }

        // Try each map marker as the base marker
        for (const baseMapMarker of mapMarkers) {
            // Try each real marker as the potential match for base marker
            for (const baseRealMarker of realMarkers) {
                log(`Trying base mapping: ${baseMapMarker} -> ${baseRealMarker}`)
                const isSectionCompatible = checkSectionCompatibility(baseRealMarker, baseMapMarker)

                const currentMapping: { [key: string]: string, } = {
                    [baseMapMarker]: baseRealMarker,
                }

                // Try to map remaining markers
                if (isSectionCompatible && tryRemainingMarkers(baseMapMarker, currentMapping)) {
                    const equivalentMeshes = getEquivalentMeshesForMap(map, markers, currentMapping)
                    const markerNamesMap = getMarkerNamesMap(equivalentMeshes)
                    return {
                        isCompatible: true,
                        equivalences: markerNamesMap,
                    }
                }
            }
        }

        return { isCompatible: false, }

        // Helper function to map remaining markers
        function tryRemainingMarkers(
            baseMapMarker: string,
            currentMapping: { [key: string]: string, }
        ): boolean {
            // Early exit if we've mapped all markers
            if (Object.keys(currentMapping).length === mapMarkers.length) {
                return true
            }

            // Get unmapped markers
            const remainingMapMarkers = mapMarkers.filter(
                marker => !currentMapping[marker]
            )
            const remainingRealMarkers = realMarkers.filter(
                marker => !Object.values(currentMapping).includes(marker)
            )

            // Early exit if we don't have enough remaining markers
            if (remainingRealMarkers.length < remainingMapMarkers.length) {
                warn("Not enough remaining markers to map", part?.name)
                return false
            }

            // Try mapping the next marker
            const nextMapMarker = remainingMapMarkers[0]

            // For each remaining real marker, try to map it to the next map marker
            for (const realMarker of remainingRealMarkers) {
                // Check compatibility with base marker
                const baseRotation = map[baseMapMarker].rotationDiffs[nextMapMarker]
                const sectionsCompatible = checkSectionCompatibility(realMarker, nextMapMarker)
                if (baseRotation) {
                    const markerMesh = markers[parseInt(currentMapping[baseMapMarker], 10)]
                    const isCompatible = compareMarkers(
                        markerMesh,
                        markers[parseInt(realMarker, 10)],
                        baseRotation,
                        debug
                    )
                    if (isCompatible && sectionsCompatible) {
                        // Try this mapping
                        currentMapping[nextMapMarker] = realMarker
                        if (tryRemainingMarkers(baseMapMarker, currentMapping)) {
                            return true
                        }
                        // If didn't work, remove mapping and try next
                        delete currentMapping[nextMapMarker]
                    }
                } else {
                    // If no base rotation to check, try the mapping directly
                    currentMapping[nextMapMarker] = realMarker
                    if (tryRemainingMarkers(baseMapMarker, currentMapping)) {
                        return true
                    }
                    // If didn't work, remove mapping and try next
                    delete currentMapping[nextMapMarker]
                }
            }

            return false
        }
    }

    function getEquivalentMeshesForMap(map: QuaternionMap, markers: Mesh[], equivalences: { [key: string]: string, }) {
        const equivalentMeshes: { [key: string]: Mesh, } = {}
        for (const equivalenceMarker of Object.keys(equivalences)) {
            equivalentMeshes[equivalenceMarker] = markers[parseInt(equivalences[equivalenceMarker], 10)]
        }
        return equivalentMeshes
    }

    function getMarkerNamesMap(equivalentMeshes: { [key: string]: Mesh, }) {
        const markerNamesMap: { [key: string]: string, } = {}
        for (const equivalenceMarker of Object.keys(equivalentMeshes)) {
            markerNamesMap[equivalenceMarker] = equivalentMeshes[equivalenceMarker].name
        }
        const numbersMap: { [key: string]: string, } = {}
        for (const markerName of Object.keys(markerNamesMap)) {
            const markerNumberNew = getMarkerNumber(markerName).split("_0")[0]
            const markerNumberOriginal = getMarkerNumber(markerNamesMap[markerName]).split("_0")[0]
            numbersMap[markerNumberNew] = markerNumberOriginal
        }
        const nameMap: { [key: string]: string, } = {}
        for (const markerNumber of Object.keys(numbersMap)) {
            nameMap[`inner${markerNumber}`] = `inner${numbersMap[markerNumber]}`
            nameMap[`outer${markerNumber}`] = `outer${numbersMap[markerNumber]}`
        }
        return nameMap
    }



    function getQuaternionAndRunComparison(
        thisPart: PartTypeAPI,
        part: PartTypeAPI,
        markers: Mesh[],
        debug?: DebugOptions
    ) {
        groupCollapsed("Getting quaternion and running comparison for part:", part.name)
        const otherPartQuaternionInfo = getQuaternionInfoFromPart(part)
        let comparison: ComparisonResult
        if (thisPart.tube && part.tube) {
            comparison = { isCompatible: true, equivalences: {
                "TOP": "TOP",
                "BOTTOM": "BOTTOM",
            }, }
        } else if (thisPart.tube && !part.tube) {
            comparison = { isCompatible: false, }
        } else {
            comparison = runComparison(otherPartQuaternionInfo, markers, debug, part)
        }
        groupEnd()
        if (comparison.isCompatible) {
            log({
                text: "Comparison result success:",
                style: { color: "green", bold: true, },
                comparison,
            })
        } else {
            log({
                text: "Comparison result failure:",
                style: { color: "red", bold: true, },
                comparison,
            })
        }
        return comparison
    }


    return {
        compareMarkers,
        runComparison,
        getQuaternionAndRunComparison,
    }
}