import { useCallback } from "react"
import { useLevaControls } from "../providers/debugProvider/useLevaControls"

type ConsoleMethod =
    "log" | "info" | "warn" | "error" | "debug" | "group" | "groupEnd" | "groupCollapsed";
type ColorOption = "blue" | "green" | "yellow" | "red" | "purple" | "gray";

interface StyleOptions {
    color?: ColorOption;
    bold?: boolean;
    italic?: boolean;
}

const colorMap: Record<ColorOption, string> = {
    blue: "#2196F3",
    green: "#4CAF50",
    yellow: "#FFC107",
    red: "#F44336",
    purple: "#9C27B0",
    gray: "#9E9E9E",
}

export function useConsole(namespace?: string, controlKey?: string) {
    const controls = useLevaControls()

    const formatMessage = useCallback((style?: StyleOptions) => {
        const css: string[] = []

        if (style?.color) {
            css.push(`color: ${colorMap[style.color]}`)
        }
        if (style?.bold) {
            css.push("font-weight: bold")
        }
        if (style?.italic) {
            css.push("font-style: italic")
        }

        return css.join(";")
    }, [])

    const createConsoleMethod = useCallback((method: ConsoleMethod) => {
        return (...args: any[]) => {
            // Check if logging is enabled via controls
            const isEnabled = controlKey
            ? Boolean(controls[controlKey as keyof typeof controls]) : true

            if (!isEnabled) {return}

            const prefix = namespace ? `[${namespace}]` : ""

            if (args[0]?.style) {
                const { style, text, ...rest } = args[0]
                const css = formatMessage(style)
                console[method](
                    `${prefix} %c${text}`,
                    css,
                    ...Object.values(rest)
                )
            } else {
                console[method](prefix, ...args)
            }
        }
    }, [namespace, formatMessage, controls, controlKey,])

    return {
        log: createConsoleMethod("log"),
        info: createConsoleMethod("info"),
        warn: createConsoleMethod("warn"),
        error: createConsoleMethod("error"),
        debug: createConsoleMethod("debug"),
        group: createConsoleMethod("group"),
        groupEnd: createConsoleMethod("groupEnd"),
        groupCollapsed: createConsoleMethod("groupCollapsed"),
    }
}